import { Controller } from "../../lib/controller";
import { computed, when } from "mobx";
import { client } from "../../client/client";
import { FormLegacy } from "../../client/form.legacy";
import { mcbSearchCtrl } from "../../mcb/client/search";
import { SupportedFieldTypes, TypeClassField } from "../../lib/types/formTypes.legacy";
import { filterFormFieldsByService } from "../../mcb/lib/common";
import { stateCtrl } from "../../client/state";
import { isEmpty } from "../../utils/helpers";

export class McbMarketCriteriaFormController extends Controller {
  @computed get isLoggedIn(): boolean {
    return client.isLoggedIn;
  };
  @computed get selectedService(): number {
    return mcbSearchCtrl.selectedService;
  };
  @computed get searchForm(): FormLegacy {
    return stateCtrl.customSearchForm || mcbSearchCtrl.searchForm;
  };
  @computed get criteriaFields(): TypeClassField<SupportedFieldTypes>[] {
    if (!this.selectedService) return [];
    const fields = (this.searchForm && this.searchForm.renderable
    .filter(field => field.flags && field.flags.search));
    if (isEmpty(stateCtrl.customSearchForm)) {
      return filterFormFieldsByService(fields, this.selectedService) || [] as TypeClassField<SupportedFieldTypes>[];
    }
    return fields;
  };

  isReady = async () => when(() => mcbSearchCtrl.ready);
}