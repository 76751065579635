import { ui } from "../../client/ui";
import { UIText } from "../../client/lang";
import { Appointment, AppointmentDateTimeOption, AppointmentStatus, AppointmentType, Attendance, Attendee } from "./types/dataTypes";
import { api } from "../../client/api";
import { endpointConfig } from "../../config/api";
import { isEmpty } from "../../utils/helpers";
import { client } from "../../client/client";
import { fileCtrl } from "../../client/file";
import { removeLastName } from "./common";

export const getProviderGroupByAppointment = async (appointment: Appointment) => {
  if (isEmpty(appointment)) return;
  return api.GET(endpointConfig.get_appointment_provider_group(appointment.id))
  .then(response => response.data)
  .then(async group => {
    if (isEmpty(group.profile)) {
      await client.getProfileById(group.profileId)
      .then(profile => (group.profile = profile) && group);
    }
    if (!appointment.inviteeResponded) removeLastName(group.profile);
    if (group.members && group.members[0] && !group.members[0].profile) {
      group.members[0].profile = group.profile;
    }
    return group;
  });
};

export const getOrganizerGroupByAppointment = async (appointmentId: number) => {
  if (isEmpty(appointmentId)) return;
  return api.GET(endpointConfig.get_appointment_organizer_group(appointmentId))
  .then(response => response.data);
};

export const getAppointmentZoomMeetingUrl = async (appointmentId: number) => {
  if (isEmpty(appointmentId)) return;
  return api.GET(endpointConfig.get_appointment_zoom_meeting_url(appointmentId))
  .then(response => response.data);
};

export const filterPresentPastAppointment = (appointment: Appointment): boolean => {
  if (isEmpty(appointment)) return false;
  const { type, appointmentStatus } = appointment;
  const now = new Date();
  if (type === AppointmentType.INTERVIEW) {
    if (appointmentStatus !== AppointmentStatus.CONFIRMED) return true;
    const { dateTimeOptions, confirmedDateTimeOptionId } = appointment;
    const confirmedDateTimeOption = dateTimeOptions.find(opt => opt.id === confirmedDateTimeOptionId);
    if (!confirmedDateTimeOption) return false;
    return now < new Date(confirmedDateTimeOption.end);
  } else {
    const { dateTimeOptions } = appointment;
    if (isEmpty(dateTimeOptions)) return false;
    return !dateTimeOptions.every(opt => now > new Date(opt.end));
  }
};

export const popupAppointmentCancelConfirm = (
  appointment: Appointment,
  handler: () => any
) => {
  const { type } = appointment || {};
  return ui.showAlert({
    title: UIText.cancelAppointment,
    message: UIText.generalReconfirm(
      UIText.generalCancel.toLowerCase(),
      `${type === AppointmentType.INTERVIEW
        ? UIText.appointment.toLowerCase()
        : UIText.appointmentGroup.toLowerCase()} "${appointment.description}"`
    ),
    buttons: [{
      color: "secondary",
      text: UIText.generalNo,
      handler: ui.dismissAlert
    }, {
      color: "primary",
      text: type === AppointmentType.INTERVIEW
        ? UIText.cancelAppointment
        : UIText.cancelAppointmentGroup,
      handler
    }]
  });
};

export const handleAppointmentUpdate = async (
  appointment: Appointment,
  data: Partial<Appointment>,
  refresh: () => any
) => {
  const { id } = appointment || {};
  if (!id) return;
  return api.PATCH({
    endpoint: endpointConfig.appointment_by_id(id),
    data
  })
  .then(refresh);
};

export const handleAppointmentNotesUpdate = async (
  appointment: Appointment,
  notes: string,
  refresh: () => any
) => {
  const { id } = appointment || {};
  if (!id) return;
  return api.PATCH({
    endpoint: endpointConfig.update_appointment_notes(id),
    data: { notes }
  })
  .then(refresh);
};

export const handleAppointmentEditAttendance = async (
  appointment: Appointment,
  attendeeId: number,
  attendance: Attendance,
  refresh: () => any
) => {
  const { id, attendees } = appointment || {};
  if (!id) return;
  if (!attendeeId || !attendees.some(at => at.id === attendeeId)) return;
  return api.PATCH({
    endpoint: endpointConfig.update_appointment_attendance(id),
    data: { attendeeId, attendance }
  })
  .then(refresh);
};

export const handleRemoveAttendee = async (
  appointment: Appointment,
  attendeeId: number,
  attendeeName: string,
  refresh: () => any
) => {
  const { id, attendees } = appointment || {};
  if (!id) return;
  if (!attendeeId || !attendees.some(at => at.id === attendeeId)) return;
  ui.dismissAlert();
  const handler = () => {
    ui.dismissAlert();
    return api.DELETE(endpointConfig.appointment_attendee_by_id(id, attendeeId))
    .then(refresh);
  };
  return ui.showAlert({
    title: "Remove attendee",
    message: UIText.generalReconfirm(UIText.generalRemove.toLowerCase(), `${attendeeName} from participants`),
    buttons: [{
      color: "secondary",
      text: UIText.generalNo,
      handler: ui.dismissAlert
    }, {
      text: UIText.generalYes,
      handler
    }]
  });
};

export const handleAppointmentAttendeesUpdate = async (
  appointment: Appointment,
  attendees: Partial<Attendee>[],
  refresh: () => any
) => {
  const { id } = appointment || {};
  if (!id) return;
  return api.PATCH({
    endpoint: endpointConfig.update_appointment_attendees(id),
    data: { attendees }
  })
  .then(() => ui.showAlert({
    title: UIText.appointment,
    message: "Participants have been updated."
  }))
  .then(refresh);
};

export const handleUpdateCreateZoomMeeting = async (
  appointment: Appointment,
  createZoomMeeting: boolean,
  refresh: () => any
) => {
  const { id } = appointment || {};
  if (!id) return;
  return api.PATCH({
    endpoint: endpointConfig.appointment_by_id(id),
    data: { createZoomMeeting }
  })
  .then(refresh);
};

export const handleRequestNewDateTimeOption = async (
  appointment: Appointment,
  dateTimeOption: AppointmentDateTimeOption | AppointmentDateTimeOption[],
  replace: boolean,
  refresh: () => any
) => {
  const { id, type } = appointment || {};
  if (!id) return;
  return api.PATCH({
    endpoint: endpointConfig.request_new_date_time_options(id, replace),
    data: { dateTimeOptions: Array.isArray(dateTimeOption) ? dateTimeOption : [dateTimeOption] }
  })
  .then(() => type === AppointmentType.INTERVIEW && ui.showAlert({
    title: UIText.appointment,
    message: `Your new ${type === AppointmentType.INTERVIEW
      ? UIText.appointment.toLowerCase()
      : UIText.appointmentGroup.toLowerCase()} date/times have been requested.`
  }))
  .then(refresh);
};

export const handleConfirmAppointment = async (
  appointment: Appointment,
  dateTimeOptionId: string,
  refresh: () => any
) => {
  const { id, dateTimeOptions, appointmentStatus } = appointment || {};
  if (!id) return;
  if (appointmentStatus !== AppointmentStatus.INVITED) return;
  if (!(dateTimeOptions || []).some(option => option.id === dateTimeOptionId)) return;
  return api.GET(endpointConfig.confirm_appointment(id, dateTimeOptionId))
  .then(() => ui.showAlert({
    title: UIText.appointment,
    message: "Your appointment date/time is now confirmed."
  }))
  .then(refresh);
};

export const handleDownloadAppointmentIcs = async (appointment: Appointment) => {
  const { id, description } = appointment || {};
  if (!id) return;
  return api.GET({
    endpoint: endpointConfig.download_ics(id),
    responseType: "blob"
  })
  .then(response => response.data)
  .then(blob => window.URL.createObjectURL(blob))
  .then(url => fileCtrl.invokeWebDownload(url, `appointment-${id}-${description}.ics`));
};