import React from "react";
import { withStyles } from "direflow-component";
import styles from "./styles.css";
import Avatar from "@material-ui/core/Avatar";
import ProfileUtilButtons from "../ProfileUtilButtons";
import { observer } from "mobx-react";
import { responsive } from "../../config/styles/responsive";
import { isEmpty } from "../../utils/helpers";
import Chip from "@material-ui/core/Chip";
import { UIText } from "../../client/lang";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { AssignmentInd, Business, Close } from "@material-ui/icons";
import { ui } from "../../client/ui";
import ProfileRatingDisplay, { workspacePremium } from "../ProfileRatingDisplay";
import { Achievement } from "../../mcb/lib/types/dataTypes";
import { achievementColors } from "../../mcb/config/constants";

export interface ProfileCardWithDetailsProps {
  displayName: string;
  businessName?: string;
  avatar?: string;
  yearOfExp?: number;
  price: number;
  abilities?: string[];
  languages?: string[];
  isSaved?: boolean;
  isSaveLoading?: boolean;
  shortlistingAvailable?: boolean;
  ratingLoading?: boolean;
  rating?: number;
  ratingSamples?: number;
  achievement?: Achievement;
  adminLink?: string;
  onSaveClick?: (event: any) => void;
  onChatClick?: (event: any) => void;
  onRatingClick?: (event: any) => void;
  onCloseClick?: (event: any) => void;
}

const ProfileCardWithDetails: React.FC<ProfileCardWithDetailsProps> = observer(
  ({
     displayName,
     businessName,
     avatar,
     yearOfExp,
     price,
     abilities,
     languages,
     isSaved,
     isSaveLoading,
     shortlistingAvailable,
     ratingLoading,
     rating,
     ratingSamples,
     achievement,
     adminLink,
     onSaveClick,
     onChatClick,
     onRatingClick,
     onCloseClick
   }) => {
    const { isMobileIntermediate } = responsive.deviceDimension;
    const Languages = <div className="flex languages">
      <Typography className="textBold">
        <span className="font-s">{UIText.profileCardWithDetailLanguages}:&nbsp;&nbsp;</span>
        {languages.map((language, i) =>
          <span key={language} className="lang font-xs">{language}{(i !== languages.length - 1) && ", "}</span>
        )}
      </Typography>
    </div>;
    const Abilities = !isEmpty(abilities) && <div className="flex chips align-items-center justify-content-end">
      {abilities.map(ability => <Chip key={ability} label={ability} />)}
    </div>;
    const CloseButton = onCloseClick &&
      <IconButton
        className="closeButton"
        onClick={onCloseClick}
        color="secondary"
        style={{ marginLeft: "10px" }}
      >
        <Close />
      </IconButton>;

    const achievementBackground = achievement?.achievementLevel && !ui.isMobile ? {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${encodeURIComponent(
        `${achievementColors[achievement.achievementLevel]}1A`
      )}">${workspacePremium}</svg>')`,
      backgroundPosition: "110% -70px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "400px"
    } : undefined;

    return <div className="profileCardWithDetails flex column">
      <div className="flex align-items-start">
        <Avatar variant="square" alt={displayName} src={avatar} className="avatar" >
          {displayName.charAt(0)}
        </Avatar>
        <div className="info flex column justify-content-center" style={achievementBackground}>
          <div className="flex cardRow justify-content-between">
            <div className="flex align-items-center font-xl textBold displayName">
              {displayName}
              {adminLink && <>
                &nbsp;&nbsp;
                <Tooltip title="View in Admin Centre" PopperProps={{ style: { zIndex: 114515 } }}>
                  <IconButton className="saveIconButton" color="secondary" onClick={() => window.open(adminLink, "_blank")}>
                    <AssignmentInd />
                  </IconButton>
                </Tooltip>
              </>}
            </div>
            <div className="flex align-items-center justify-content-end">
              <ProfileUtilButtons
                buttons={[
                  onChatClick ? "chat" : undefined,
                  shortlistingAvailable ? "save" : undefined
                ]}
                isSaved={isSaved}
                saveLoading={isSaveLoading}
                onSaveClick={onSaveClick}
                onChatClick={onChatClick}
              />
              {!ui.isMobile && CloseButton}
            </div>
          </div>

          <div className="flex justify-content-between yoEPriceChips">
            <div className="flex column align-items-start justify-content-center">
              {businessName && <div className="flex align-items-center textBold font-m businessName"><Business />&nbsp;{businessName}</div>}
              <ProfileRatingDisplay
                offsetStart
                loading={ratingLoading}
                samples={ratingSamples}
                rating={rating}
                achievement={achievement}
                badgeSize={28}
                onClick={onRatingClick}
              />
              {!!yearOfExp && <div className="yearOfExp">{UIText.profileYoE(yearOfExp)}</div>}
              {!isNaN(Number(price)) && !!Number(price) && <div className="textBold price">${price}/hr</div>}
            </div>
            {!isMobileIntermediate && Abilities}
          </div>

          {!isMobileIntermediate && Languages}
        </div>
      </div>
      {isMobileIntermediate && Abilities}
      {isMobileIntermediate && Languages}
    </div>;
  });

export default withStyles(styles)(ProfileCardWithDetails);
