import { Observer, observer } from "mobx-react";
import React from "react";
import { withStyles } from "direflow-component";
import styles from "./styles.css";
import { FormLegacy } from "../../client/form.legacy";
import { PickerField, SupportedFieldTypes, TypeClassField } from "../../lib/types/formTypes.legacy";
import FormField from "../FormLegacy/FormFields";
import { asyncPause, getEventRealValue, isEmpty } from "../../utils/helpers";
import { computed, observable } from "mobx";
import { Accordion, AccordionSummary, Button, CircularProgress, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Controller } from "../../lib/controller";
import { ui } from "../../client/ui";
import { UIText } from "../../client/lang";

export interface MarketplaceCriteriaProps {
  loading?: boolean;
  hidden?: boolean;
  form: FormLegacy;
  fields: Array<TypeClassField<SupportedFieldTypes>>;
  serviceField?: TypeClassField<PickerField>;
  useMobile?: boolean;
  onServiceChange?: FormLegacy["set"];
  onShowHideCriteria: (event: any) => void;
}

class CriteriaExpansionController extends Controller<{ sets: {} }> {
  @computed get sets() {
    this.storage.initProperty("sets", {});
    return this.store.sets || {};
  };
  constructor() { super("MarketplaceCriteriaExpansion") }
}

const Field = observer(({ field, formSet }) => {
  const onChange = event => {
    const fieldValue = getEventRealValue(event);
    if (fieldValue === field.value) return;
    formSet(field.name, fieldValue);
  };
  return <FormField
    field={field}
    disabled={field.hasInputData}
    onChange={onChange}
  />;
});

const FieldSet = observer(({ setName, title, fields, expanded, onExpand, formSet }) => (
  <Accordion elevation={0} expanded={expanded} onChange={onExpand} square>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography className="font-xs textBold">
        {title}
      </Typography>
    </AccordionSummary>
    <div className="flex column">
      {fields
      .filter(field => field.set === setName)
      .map(field => <Observer key={field.name}>{() => <Field field={field} formSet={formSet} />}</Observer>)}
    </div>
  </Accordion>
));

@observer
class MarketplaceCriteria extends React.Component<MarketplaceCriteriaProps> {
  @observable elm: HTMLDivElement;
  @observable contentHeight: number = 0;

  expansionController = new CriteriaExpansionController();

  @computed get expansion(): CriteriaExpansionController["sets"] {
    return this.expansionController.sets || {};
  };

  componentDidUpdate() {
    if (!this.props.hidden) return this.ensureHeight();
  }

  regElm = ref => this.elm = ref;

  setHeight = () => {
    if (this.elm && this.elm.scrollHeight) this.contentHeight = this.elm.scrollHeight;
  };

  ensureHeight = () => {
    let counter = 0;
    const getHeight = async () => {
      if (!this.elm) return setTimeout(getHeight);
      if (this.contentHeight !== this.elm.scrollHeight) {
        counter = 0;
        this.setHeight();
      }
      await asyncPause(100);
      counter++;
      return counter < 10 && getHeight();
    };
    return getHeight();
  };

  handleSetExpand = (e, set) => {
    this.contentHeight = undefined;
    this.expansion[set] = !this.expansion[set];
    setTimeout(this.ensureHeight, 300);
  };

  render() {
    const {
      loading,
      hidden,
      form,
      fields,
      serviceField,
      useMobile,
      onServiceChange,
      onShowHideCriteria
    } = this.props;

    const duration = 300;
    const isMobile = useMobile || ui.isMobile;

    return <div className="flex column marketplaceCriteria">
      {isMobile && <Button variant="text" onClick={onShowHideCriteria}>
        {hidden ? <ExpandMore /> : <ExpandLess />}&nbsp;
        <Typography className="font-xs">
          {hidden ? UIText.marketplaceShowCriteria : UIText.marketplaceHideCriteria}
        </Typography>
      </Button>}
      <div
        ref={this.regElm}
        style={{
          maxHeight: isMobile ? !hidden ? this.contentHeight : 0 : undefined,
          ...(duration && {
            transitionDuration: `${duration / 1000}s`
          })
        }}
      >
        <form className="form flex column">
          {loading && <div className="flex column max justify-content-center align-items-center">
            <CircularProgress size={30} />
          </div>}
          {!loading && !isEmpty(serviceField) && <Field key={serviceField.name} field={serviceField} formSet={onServiceChange} />}
          {!loading && fields.map(field => (
            <Observer key={field.name}>{() => {
              const { set, title } = field;
              const isInSet = !!field.set && !field.title && fields.some(f => f.set === field.set);
              if (set && !isInSet) {
                return <FieldSet
                  setName={set}
                  title={title}
                  fields={fields}
                  expanded={!!this.expansion[set]}
                  onExpand={e => this.handleSetExpand(e, set)}
                  formSet={form.set}
                />;
              }
              return !isInSet && <Field field={field} formSet={form.set} />;
            }}</Observer>)
          )}
        </form>
      </div>
      {isMobile && !hidden && <Button variant="text" onClick={onShowHideCriteria}>
        <ExpandLess />&nbsp;
        <Typography className="font-xs">
          {UIText.marketplaceHideCriteria}
        </Typography>
      </Button>}
    </div>;
  }
}

export default withStyles(styles)(MarketplaceCriteria);