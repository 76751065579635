import isbot from "isbot";

export const rootDomain = window.location.hostname.match(/localhost/i) ? "localhost" : (window.location.hostname.match(/(?:.*\.)?(.*\..*)$/) || [])[1];
export const env: "prod" | "test" | "dev" | "webdev" | "debug" | "local" =
  window.location.hostname.match(/^(?:app|www)?\.?mycarebase\.com$/g)
    ? "prod"
    : window.location.hostname.match(/^wwwtest|^testapp|zcognvxuhq/g)
    ? "test"
    : window.location.hostname.match(/localhost|debug/g)
    ? "debug"
    // Everything else goes to dev.
    : "dev";

export const webAppEnv = ["local", "debug", "dev"].includes(env) ? "devtest" : env;


export const isCrawler = isbot(navigator.userAgent);