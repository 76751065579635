import { Controller } from "../../lib/controller";
import { computed } from "mobx";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { Profile } from "../../lib/types/dataTypes";
import { client } from "../../client/client";
import { getDisplayNameEng } from "../../utils/helpers";

export class McbSignUpSignInController extends Controller {
  @computed get ready(): boolean {
    return !!mcbSessionCtrl.deviceId;
  };
  @computed get isLoggedIn(): boolean {
    return mcbSessionCtrl.appIsLoggedIn;
  };
  @computed get displayName(): Profile {
    return getDisplayNameEng(client.defaultProfile);
  };

  onLogout = async () => mcbSessionCtrl.sessionLogout();
}