import general from './general';

const mcb = {
  getStarted: "Get started",
  letsGetStarted: "Let's Get Started",
  getStartedSubHeader: "Find affordable, pre-screened caregivers or service providers who meet your specific needs in your area!",
  getStartedRoleSelect: "Who are you caring for?",
  selectService: "Select a service",
  expandCriteria: "Expand search criteria",
  search: "Search",
  location: "Location",
  province: "Province",
  city: "City",
  territory: "Territory",
  myself: "Myself",
  registerHeader: "Register for a myCareBase Account",
  registerSubHeader: "Our marketplace offers a range of services. Explore our full services marketplace",
  registerSignUpBtn: "Sign up as Service Provider",
  registerSignInBtn: "Sign in as a Family Member",
  registerCheckEntries: "Please review your entries.",
  marketplaceSearchBarSearchBtnDirty: "Update Listings",
  marketplaceSearchBarSearchBtn: "Search Listings",
  marketDefaultShortlistName: "Website",
  profile: "Profile",
  profileUtilViewButton: isCaregiver => isCaregiver ? "View Caregiver" : "View Profile",
  profileUtilSaveButton: isCaregiver => isCaregiver ? "Save Caregiver" : "Save Profile",
  profileUtilUnSaveButton: isCaregiver => isCaregiver ? "Saved Caregiver" : "Saved Profile",
  profileUtilBookButton: hasInterview => `Book an ${hasInterview ? "appointment" : "interview"}`,
  marketplaceSavedToggle: "Show saved",
  marketplaceStatusBarText: (quantity, unit) => `Search: Showing <b>${quantity}</b> ${Number(quantity) > 1 ? "results" : "result"}`,
  marketplaceSortBy: "Sort by",
  marketplacePrice: "Price",
  marketplaceRelevance: "Relevance",
  marketplaceHideCriteria: "Hide criteria",
  marketplaceShowCriteria: "Show criteria",
  marketplaceLiveIn: "Live-in",
  marketplaceOvernight: "Overnight",
  marketplaceAbleCar: "Car",
  profileAbout: "About",
  profileYoE: years => `${years} ${years > 1 ? "YEARS" : "YEAR"} EXPERIENCE`,
  profileAbleTasks: "Skill & capabilities",
  profileAbleConditions: "Experience in the following conditions",
  getConnected: "Get connected",
  getConnectedContactInfo: "Contact info",
  getConnectedContentCaregivers: "Please enter your name and contact information to get our personalized assistance in getting matched and connected to one of our caregivers. A myCareBase advisor will be in touch very soon.",
  getConnectedContentServiceProviders: "Please enter your name and contact information to get our personalized assistance in getting matched and connected to one of our service providers. A myCareBase advisor will be in touch very soon.",
  getConnectedFooter: "Provide a phone number if you'd like to speak with an advisor who can assist you through the hiring process over the phone",
  getConnectedNeeds: "Please tell us more about your needs.",
  getConnectedSubmit: "Submit Contact Details",
  getConnectedSubmitted: "Thank you for your submission.",
  getConnectedReference: "Reference",
  profileCardWithDetailLanguages: "Languages",
  referenceHelpText: "Such as the name of the person who needs care.",
  languageSetTitleProvider: "What language would you like the service provider to speak in?",
  familySigningUpAs: "I'm signing up as",
  careCircle: "Care Circle",
  household: "Household",
  caregiver: "Professional Caregiver",
  provider: "Other Service Provider",
  genericSignUpSubmitted: "Thank you for signing up.",
  // caregiverApplicationSubmitted: "Thank you for your application",
  // caregiverApplicationSubmittedMessage: "Please check your email for further instructions.",
  caregiverApplicationSubmitted: "Thank you",
  caregiverApplicationSubmittedMessage: "Please check your mailbox to verify your email address and continue from there.",
  providerBookInterviewMessage: "Please book an interview appointment with us.",
  serviceCategory: "Service category",
  caregiverSubmitApplication: "Submit application",
  vendorRecruitOption: "I would like to go through an interview/screening process to enhance my profile.",
  vendorRecruitHelperText: "If you check this box, a company representative will be in touch with you shortly.",
  forgotPassword: "I forgot my password",
  dontHaveAccount: "I don't have an account yet",
  appointment: "Appointment",
  appointmentGroup: "Appointment group",
  bookAppointment: "Book appointment",
  bookAppointmentGroup: "Book appointment group",
  bookAnAppointment: "Book an appointment",
  bookAppointmentNoAccount: `If you don't have an account, please close this and sign up using the "Get connected" form`,
  bookAppointmentThankYouMessage: `Thank you for booking this appointment, your request has been submitted successfully. 
  
We will get in touch with you once your appointment is confirmed.`,
  signInToBookAppointment: "Please sign in to book appointment",
  signInToSeeAppointment: plural => `Please sign in to see your appointment${plural ? "s" : ""}`,
  appointmentName: "Appointment name",
  appointmentGroupName: "Appointment group name",
  appointmentDuration: "Duration",
  typeOfAppointment: "Type of appointment",
  interview: "Interview",
  service: "Service",
  welcomeMessage: displayName => `Welcome, ${displayName}!`,
  contactFormSignOut: displayName => `Not ${displayName}? Sign out.`,
  appointmentSubmit: "Book now",
  appointmentSubmitted: "✔",
  appointmentCancelled: "Your appointment has been cancelled",
  appointmentGroupCancelled: "Your appointment group has been cancelled",
  appointmentInvitationChangeConfirm: "Invitation emails will be sent to newly added participant(s) and removed participant(s) will be notified.\n\nConfirm participants update?",
  cancelAppointment: "Cancel appointment",
  cancelAppointmentGroup: "Cancel appointment group",
  bookingFormMissingContext: "Missing group context, please refresh the page or re-open the booking request form.",

  requireScratchpad: (isMobile, buttonName, buttonColor) =>
    `Please select your search criteria ${isMobile ? "above" : "to the left"} and ${isMobile ? "tap" : "click"} the <span style="color: ${buttonColor}; cursor: pointer" class="searchButton">${buttonName}</span> button.`,

  ratingReviews: "Reviews & Ratings",
  ratingNoReviews: "No reviews at this time.",
  overallRating: "Overall rating",
  reviewNoContent: "(No comment.)",
  ratingTestimonial: "Testimonial",
  closeRatingReviewDetailDesktop: "BACK TO GET CONNECTED",
  closeRatingReviewDetailMobile: "Back to profile",

  get wpAccountAutoLoginPleaseWait() { return `${general.pleaseWait}, loading account information...` },

  errorCheckout: "Error Checking-out",
  learnMore: "Learn more",

  relatedSubscriptionH2: "Subscription Details",
  relatedOrderH2: "Original order"
};

export default mcb;
