import { observer } from "mobx-react";
import { AlertBase } from "../AlertBase";
import ProfileRatingDetail from "../ProfileRatingDetails";
import React from "react";
import { Profile } from "../../lib/types/dataTypes";

export interface MarketplacePopupAggregatorProps {
  container: HTMLDivElement;
  extraStyles?: string;
  profileOpen: boolean;
  ratingDetailOpen: boolean;
  ratingDetailProfileId: number;
  isCaregiver: boolean;
  getProfileAvatarUri: (profile: Profile) => string;
  onProfileModalClose: (event: any) => void;
  onRatingModalClose: (event: any) => void;
}

export const MarketplacePopupAggregator: React.FC<MarketplacePopupAggregatorProps> = observer(
  ({
     container,
     extraStyles,
     profileOpen,
     ratingDetailOpen,
     ratingDetailProfileId,
     isCaregiver,
     getProfileAvatarUri,
     onProfileModalClose,
     onRatingModalClose
   }) => {
    const profile = { detail: `mcb-listing-profile` }; // make direflow html tag a pseudo react component.

    return <>
      <AlertBase
        container={container}
        isOpen={profileOpen}
        onClose={onProfileModalClose}
        extraStyles={extraStyles}
        zIndex={114514}
      >
        <profile.detail></profile.detail>
      </AlertBase>

      <AlertBase
        container={container}
        isOpen={ratingDetailOpen}
        onClose={onRatingModalClose}
        extraStyles={extraStyles}
        zIndex={114514}
      >
        <ProfileRatingDetail
          profileId={ratingDetailProfileId}
          isCaregiver={isCaregiver}
          showNameAvatar
          showOverallRating
          getAvatarUri={getProfileAvatarUri}
          onClose={onRatingModalClose}
        />
      </AlertBase>
    </>;
  });