import React from "react";
import { observer } from "mobx-react";
import { UIText } from "../../client/lang";
import { Styled } from "direflow-component";
import { StdErr } from "../../lib/types/miscTypes";
import { ui } from "../../client/ui";
import styles from "./styles.css";
import { preventDefaultStopProp } from "../../utils/helpers";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { McbSignUpSignInController } from "./controller";
import { AlertBase } from "../../components/AlertBase";
import McbComponentPastry from "../../components/McbComponentPastry";
import withJssMap from "../../components/withJssMap";
import { stateCtrl } from "../../client/state";
import { observable } from "mobx";
import { ExitToApp, PlayCircleOutline } from "@material-ui/icons";
import { mcbSearchCtrl } from "../../mcb/client/search";
import { mcbSessionCtrl } from "../../mcb/client/session";

@observer
class McbSignUpSignIn extends React.Component {
  controller: McbSignUpSignInController = {} as McbSignUpSignInController;

  @observable loading: boolean = false;

  constructor(props) {
    super(props);
    this.controller = new McbSignUpSignInController();
  };

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({ err, actionName: actionName || UIText.loginError });

  // handleSignIn = event => window.open(`${webAppUrl}${signInUrl}`, "_blank");

  // handleSignUp = event => window.open(`${webAppUrl}${signUpCaregiver}`, "_blank");

  clickNativeBackdrop = () => {
    const nativeBackdrop = document.getElementsByClassName("x-off-canvas-bg")[0] as HTMLDivElement;
    if (nativeBackdrop) nativeBackdrop.click();
  };

  handleLogin = event => {
    preventDefaultStopProp(event);
    this.clickNativeBackdrop();
    mcbSearchCtrl.setDoNotRestoreSearchForm(false);
    // if (env === "prod") return window.open(webAppUrl);
    return stateCtrl.mcbSignInFormOpen = true;
  };

  handleLogout = event => {
    preventDefaultStopProp(event);
    this.loading = true;
    this.controller.onLogout()
    .finally(() => {
      this.loading = false;
      this.clickNativeBackdrop();
    });
  };

  render() {
    const { ready, isLoggedIn, displayName } = this.controller;

    const Form = { signin: "mcb-signin-form" }; // make direflow html tag a pseudo react component.

    return <McbComponentPastry style={{ minHeight: "50px", display: "flex" }}>
      <Styled styles={styles}>
        {ready && <div className="mcbSignUpSignIn">
          {isLoggedIn ? (
            <Button
              className="textNoTransform mcbSignInSignUpButton"
              color="secondary"
              variant="outlined"
              disableElevation
              disabled={this.loading}
              onClick={this.handleLogout}
            >
              {this.loading ? <CircularProgress size={22} color="primary" /> : <>
                <Typography>
                  {UIText.logout}
                </Typography>
                <ExitToApp />
              </>}
            </Button>
          ) : (
            <Button
              className="textNoTransform mcbSignInSignUpButton signIn"
              variant="outlined"
              color="secondary"
              disableElevation
              onClick={this.handleLogin}
            >
              <Typography>
                {UIText.login}
              </Typography>
              <PlayCircleOutline className="font-l" />
            </Button>
          )}

          {isLoggedIn && (
            <div className="mcbSignUpSignInWelcome absolute">
              <Typography className="welcomeText textBold">
                {UIText.welcomeMessage(displayName)}&nbsp;{(
                !mcbSessionCtrl.isValidVisitor
                && !mcbSessionCtrl.wpLoginSuccess
                && !mcbSessionCtrl.wpLoginError
                && "Signing in your WordPress..."
              )}{(
                !mcbSessionCtrl.isValidVisitor
                && mcbSessionCtrl.wpLoginError
                && <span className="textUnderline signInLink" onClick={() => ui.showError({ err: mcbSessionCtrl.wpLoginError })}>WordPress session error.</span>
              )}
              </Typography>
            </div>
          )}

          <AlertBase
            isOpen={stateCtrl.mcbSignInFormOpen}
            onClose={e => stateCtrl.closeSignInform(e)}
            extraStyles={styles}
          >
            <Form.signin></Form.signin>
          </AlertBase>
        </div>}
      </Styled>
    </McbComponentPastry>;
  }
}

export default withJssMap(McbSignUpSignIn);