import { Controller } from "../lib/controller";
import { JSONString, KeyValuePairs } from "../lib/types/miscTypes";
import {
  ModifierField,
  PickerField,
  PickerOption,
  SupportedFieldTypes,
  TypeClass,
  TypeClassField,
  TypeClassFieldDependency,
  TypeClassFieldValidationSet
} from "../lib/types/formTypes";
import { autorun, computed, observable, toJS, when } from "mobx";
import { isEmpty, isEqual, isNonZeroFalse, safeParseJSON, validateEmail } from "../utils/helpers";
import { api } from "./api";
import { endpointConfig } from "../config/api";
import { AxiosResponse } from "axios";
import { ui } from "./ui";
import { client } from "./client";
import { typeClassIds } from "../config/constants";
import { UIText } from "./lang";

// TODO:
//  Private _errorMessage
//  DynamicSet
//  InputType
//  Input Min Max
//  Checkbox icon appearance
//  Unique validator

// Form Class.
// Used for creating a smart object of typeClass Form instance
// and static utility methods of typeClass Form.
export class Form<Data = KeyValuePairs | any> {
  @observable ready: boolean = false;
  @observable flags: KeyValuePairs<boolean> = {};
  @observable readonly typeClassId: TypeClass["id"];
  @observable private _rawData: Data = {} as Data;
  @observable protected _origData: Data = {} as Data;
  @observable private _rawForm: TypeClassField<SupportedFieldTypes, Data>[] = [];
  @observable form: TypeClassField<SupportedFieldTypes, Data>[] = [];

  @computed get typeClass(): TypeClass {
    return formCtrl.findTypeClassById(this.typeClassId);
  };
  @computed get blank(): TypeClassField<SupportedFieldTypes, {}>[] {
    return Form.assembleFormData(this._rawForm,{}, this.flags);
  };
  @computed get renderable(): TypeClassField<SupportedFieldTypes, Data>[] {
    return Form.assembleRenderableFormData(this.form);
  };
  @computed get data(): Data {
    const localModifier = this.modifierField.processLevel === "data" && this.dataModifier;
    return Form.disassembleFormData(this.form, localModifier) as Data;
  };
  @computed get json(): JSONString {
    return this.toJSON();
  };
  // @computed get isValid(): boolean {
  //   return this.form.every(field => !!field.value || !field.required);
  // };
  @computed get isDirty(): boolean {
    return !isEqual(toJS(this._origData), toJS(this.data));
  };
  @computed get modifierField(): TypeClassField<ModifierField, Data> {
    return (
      (this.form.find(field => field.type === "modifier") ||
      {}) as TypeClassField<ModifierField>
    );
  }
  @computed get dataModifier(): ((input: Data) => Data) {
    return Form.getModifierFunction(this.modifierField.formula) as (input: Data) => Data;
  };

  constructor(
    form: TypeClass["id"] | JSONString | TypeClassField[],
    inputData?: JSONString | Data,
    formFlags?: KeyValuePairs<boolean>
  ) {
    this._initForm();
    this._setData(inputData);
    if (typeof form === "number") {
      this._setForm();
      this.typeClassId = form as number;
    } else {
      this._rawForm = typeof form === "string"
        ? safeParseJSON(form)
        : typeof form === "object"
          ? form
          : undefined;
      this.ready = true;
    }
    if (formFlags) this.flags = formFlags;
    setTimeout(() => Form.checkUniqueFields(this._rawForm, this.typeClass));
    return this;
  }

  protected _initForm = () => autorun(() => {
    const newForm = Form.assembleFormData(this._rawForm, this._rawData, this.flags);
    for (let i = 0; i < newForm.length; i++) (this.form[i] = {...newForm[i]});
  });

  protected _setData = (data: JSONString | Data) => {
    if (!data) return;
    this._rawData = typeof data === "string"
      ? safeParseJSON(data)
      : toJS(data);
  };

  // One time reaction to set form soon as typeClass data arrives.
  protected _setForm = () => when(
    () => (!isNonZeroFalse(this.typeClassId) && (!isEmpty(this.typeClass) || !!this.typeClass.metadata)),
    async () => {
      this._rawForm = Form.parseFormFromTypeClass(this.typeClass);
      when(() => !isEmpty(this.form), () => {
        this._origData = toJS(this.data);
        this.ready = true;
      });
    });

  toJSON = (overrides?: Data): JSONString => {
    let data = toJS(this.data);
    const localModifier = this.modifierField.processLevel === "json" && this.dataModifier;
    if (localModifier) data = localModifier(data);
    if (!isEmpty(overrides)) return JSON.stringify({
      ...data,
      ...overrides
    });
    return JSON.stringify(data);
  };

  get = (name: TypeClassField<SupportedFieldTypes, Data>["name"]): string | boolean | number => this.data[name];

  set = (name: TypeClassField<SupportedFieldTypes, Data>["name"], value: Data[keyof Data]) =>
    this._rawData[name] = value;

  getField = (name: TypeClassField["name"]): TypeClassField<any, Data> =>
    this.form.find(field => field.name === name) || {};

  setField = (
    name: TypeClassField<SupportedFieldTypes, Data>["name"],
    update: Partial<TypeClassField<SupportedFieldTypes>>
  ) => {
    const field = this._rawForm.find(field => field.name === name);
    if (isEmpty(field)) return;
    for (const key in update) {
      if (key === "value") {
        this.set(name, update[key]);
        continue;
      }
      field[key] = update[key];
    }
  };

  getRenderedField = (name: TypeClassField<SupportedFieldTypes, Data>["name"]): TypeClassField<any, Data> =>
    this.renderable.find(field => field.name === name) || {};

  getRenderedValue = (name: TypeClassField<SupportedFieldTypes, Data>["name"]): Data[keyof Data] =>
    ((this.getRenderedField(name) as PickerField).valuePlaceholder || this.getRenderedField(name).value) || "";

  isReady = () => when(() => this.ready);

  getDirty = (): TypeClassField<SupportedFieldTypes, Data>[] => {
    if (!this.isDirty) return [];
    const data = toJS(this.data);
    const keys = Object.keys(this._origData);
    return keys.map(key => (data[key] !== this._origData[key]) && this.getField(key)).filter(Boolean);
  };

  resetDirty = () => this._origData = toJS(this.data);

  reset = () => {
    this._rawData = this._origData;
    const fresh = Form.parseFormFromTypeClass(this.typeClass);
    for (let i = 0; i < fresh.length; i++) (this._rawForm[i] = {...fresh[i]});
  };

  clearData = () => {
    const fresh = Form.parseFormFromTypeClass(this.typeClass);
    for (let i = 0; i < fresh.length; i++) (this._rawForm[i] = {...fresh[i]});
  };

  validate = async (name?: TypeClassField<SupportedFieldTypes, Data>["name"]): Promise<boolean> => {
    // TODO: Smarter target control for each individual fields.
    const validate = async field => {
      if (field.hasInputData) return;
      const value = this.get(field.name);
      const _errorMessage = typeof field.errorMessage === "string"
        ? field.errorMessage || true
        : true;
      const errorMessageType = field.errorMessageType || "";
      this.setField(field.name, { _errorMessage: undefined });
      this.setField(field.name, { _errorMessage:
          field.required &&
          isEmpty(value) &&
          (errorMessageType.match(/(^$)|all|empty/g) ? _errorMessage : true)
      });
      if (field.name.match(/password|repeat/g)) {
        this.setField("repeat", {
          _errorMessage:
            this.get("repeat") &&
            this.get("password") !== this.get("repeat") &&
            (errorMessageType.match(/(^$)|all|password/g) ? _errorMessage : true)
        });
      }
      if (field.value && field.validationRegex) {
        const validateFormula = (validationSet: TypeClassFieldValidationSet) => {
          const validationMethod = Form.getValidationFunction(validationSet.validationRegex);
          if (!validationMethod) return true;
          const pass = validationMethod(field.value);
          this.setField(field.name, {
            _errorMessage:
               !pass && (validationSet.errorMessage
                ? validationSet.errorMessage
                : (errorMessageType.match(/(^$)|all|formula/g) ? _errorMessage : true))
          });
          return pass;
        };
        if (typeof field.validationRegex === "string") {
          validateFormula({ validationRegex: field.validationRegex });
        } else {
          for (const set of field.validationRegex) {
            if (!validateFormula(set)) break;
          }
        }
      }
      if (!!field.unique) {
        await this.validateUniqueIdentifier(field);
      }
    };
    if (name) {
      const field = this.getField(name);
      await validate(field);
      return (field.required ? !!field.value : true) && !field._errorMessage;
    }
    for (const field of this.form) await validate(field);
    return this.form.every(field =>
      (field.required ? !!field.value : true) && !field._errorMessage
    );
  };

  validateUniqueIdentifier = async (field: TypeClassField<SupportedFieldTypes>) => {
    const identifier = field.unique;
    const value = this.get(identifier);
    const isValidEmail = validateEmail(value);
    const isPlusSignError = !isValidEmail && (value as string || "").match(/\+/);
    this.setField(identifier, { _errorMessage: !isValidEmail && (isPlusSignError ? UIText.registrationEmailNoPlusSign : UIText.invalidEmail) });
    if (!isValidEmail) return;
    const result = await client.checkUniqueIdentifier(identifier, value.toString());
    const owned = field.uniquePermitOwned && client.allEmailAddresses.includes(value.toString());
    if ((!result.exists || owned) && !result.error) {
      this.setField(identifier, { _errorMessage: undefined });
    } else {
      const _errorMessage = result.exists
        ? `${UIText.registrationFields[identifier]} ${UIText.registrationUniqueExists}`
        : result.error;
      this.setField(identifier, {
        _errorMessage: (field.errorMessageType || "").match(/(^$)|all|unique/g) ? _errorMessage : true
      });
    }
  };

  /**
   * Static Methods
   */
  static checkUniqueFields = (form: TypeClassField[], typeClass?: TypeClass) => {
    if (isEmpty(form) || !Array.isArray(form)) return;
    const fieldNames: string[] = [];
    for (const field of form) {
      if (fieldNames.includes(field.name)) console.warn(
        "Detected duplicate field name in form",
        typeClass || "Unknown typeClass",
        toJS(field)
      );
      fieldNames.push(field.name);
    }
  };

  static parseFormFromTypeClass = (typeClass: TypeClass): TypeClassField<SupportedFieldTypes>[] => {
    let formFields = [];
    if (isEmpty(typeClass)) return formFields;
    if (isEmpty(typeClass.metadata)) return formFields;
    formFields = safeParseJSON(typeClass.metadata) || [];
    return formFields;
  };

  static assembleFormData = (
    form: TypeClassField<SupportedFieldTypes>[],
    inputData: KeyValuePairs,
    flags: KeyValuePairs<boolean>
  ): TypeClassField<SupportedFieldTypes>[] => {
    // options = options || {};
    let formFields = toJS(form) || [];
    if (isEmpty(formFields)) return formFields;
    const data = inputData || [];
    return formFields.map(field => {
      // Assign per field value.
      field.value = data[field.name] || field.value;
      // Picker options dependency
      if (field.type === "picker") {
        // + Picker options dependency hide
        for (const option of (field as TypeClassField<PickerField>).options) {
          option.hidden = !Form.getDependencyHide(option, formFields);
        }
        // Reset picker value to visible ones if current value is hidden.
        const visibleOptions = field.options.filter(option => !option.hidden);
        if (!visibleOptions.some(option => option.name === field.value)) {
          field.value = visibleOptions[0].name;
        }
        // Add handle placeholder getter for picker field.
        const getValuePlaceholder = (field: TypeClassField<PickerField>) =>
          ((field.options || []).find(o => o.name === field.value) || {}).placeholder;
        Object.defineProperty(field, "valuePlaceholder", {
          enumerable: true,
          get: () => getValuePlaceholder(field as TypeClassField<PickerField>)
        });
      }
      // + Dependency hide
      const dependencyHidden = !Form.getDependencyHide(field, formFields);
      field.hidden = field.hidden || dependencyHidden ||
      // + Flag hide
      (!isEmpty(field.flags) && (flags && Object.keys(flags).some(key => flags[key] !== field.flags[key])));
      // + Dependency clear value
      if (dependencyHidden) field.value = false;
      // + Dependency formula
      field.value = Form.getDependencyValue(field, formFields);
      // + Normalization formula
      field.value = Form.formulateValue(field);
      // Return field;
      return field;
    });
    // TODO: Form engine needs to handle dynamic data (Data pairs that are out-of-scope to the typeClassFields)
  };

  static disassembleFormData = (
    form: TypeClassField<SupportedFieldTypes>[],
    dataModifier?: (input: KeyValuePairs) => KeyValuePairs
  ): KeyValuePairs => {
    // options = options || {};
    let data = {};
    if (isEmpty(form)) return data;
    for (const field of form) {
      field.type !== "link" &&
      field.type !== "text" &&
      field.type !== "button" &&
      field.type !== "modifier" &&
      (data[field.name] = field.value);
    }
    if (dataModifier) return dataModifier(data);
    return data;
  };

  static assembleRenderableFormData = (form: TypeClassField<SupportedFieldTypes>[]):
    TypeClassField<SupportedFieldTypes>[] => {
    if (isEmpty(form)) return [];
    const processRenderableField = (field: TypeClassField<SupportedFieldTypes>) => {
      // + Display formula
      if (field.displayFormula) {
        const processor = Form.getFormulaFunction(field.displayFormula);
        if (processor) field.value = processor(field.value); /* (value) => string | boolean; */
      }
      return field;
    };
    return toJS(form)
    .filter(field => !field.hidden)
    .map(processRenderableField).filter(Boolean);
  };

  static getDependencyHide = (field: TypeClassField | PickerOption, form: TypeClassField[]): boolean => {
    const checkDependency = (dependency: TypeClassFieldDependency) => {
      const { name, value, mode } = dependency;
      const field = form.find(f => f.name === name);
      // No field then return according to empty condition.
      if (!field) return mode === "matchEmpty";
      return mode === "matchEmpty"
        ? !field.value
        : mode === "matchNotEmpty"
          ? !!field.value
          : mode === "matchExact"
            ? (Array.isArray(value) ? value.includes(field.value) : field.value === value)
            : mode === "matchOtherwise"
              ? (Array.isArray(value) ? !value.includes(field.value) : field.value !== value)
              : true
    };
    let fulfil = true;
    const { dependency } = field || {};
    if (!dependency) return fulfil;
    fulfil = Array.isArray(dependency)
      ? dependency.every(checkDependency)
      : checkDependency(dependency);
    return fulfil;
  };

  static getDependencyValue = (field: TypeClassField, form: TypeClassField[]) => {
    const { dependencyFormula, dependency } = field || {};
    const value = field.value || false;
    if (!dependencyFormula) return value;
    const dependencyFieldNames: string[] = Array.isArray(dependency)
      ? dependency.map(d => d.name)
      : [dependency.name];
    const dependencyFields = form.filter(field => dependencyFieldNames.includes(field.name));
    const processor = Form.getFormulaFunction(dependencyFormula);
    return processor(value, dependencyFields); /* (value, dependencyFields) => string | boolean; */
  };

  static formulateValue = (field: TypeClassField) => {
    let value = field.value;
    if (field.onChangeFormula) {
      const processor = Form.getFormulaFunction(field.onChangeFormula);
      if (processor) value = processor(value); /* (value) => string | boolean; */
    }
    return value || false;
  };

  static getFormulaFunction = stringToEval => {
    let method = (value, ...args: any[]) => value;
    try {
      // eslint-disable-next-line no-new-func
      (method as unknown) = new Function("value, fields", `return ${stringToEval}`);
      return method;
    }
    catch (e) {
      console.warn("formCtrl getFormulaFunction", e);
      return method;
    }
  };

  static getModifierFunction = stringToEval => {
    // eslint-disable-next-line no-new-func
    const method = stringToEval && new Function("data", `${stringToEval}; return data;`);
    if (!method || typeof method !== "function") return null;
    return method as ((input: KeyValuePairs) => KeyValuePairs);
  };

  static getValidationFunction = stringToEval => {
    // eslint-disable-next-line no-new-func
    const method = stringToEval && new Function("value", `return !!(value || "").match(${stringToEval});`);
    if (!method || typeof method !== "function") return null;
    return method as ((input: TypeClassField["value"]) => boolean);
  };

  static mandatoryFieldLabel = label => `${label} *`;
}


// FormStore
// Persistent storage model for FormController Service
export interface FormStore {
  typeClasses: TypeClass[];
}

// FormController.
// Main class instance for Form (TypeClass) Service
// persistent data management and store.
// We would append Controller name here to prevent confusion with actual data.
export class FormController extends Controller<FormStore> {
  @observable initialized: boolean = false;
  loadQueue: number[] = [];

  @computed get typeClasses(): TypeClass[] {
    this.storage.initProperty("typeClasses", []);
    return this.store.typeClasses;
  };

  constructor() {
    super();

    client.storage.isReady()
    .then(this.storage.isReady)
    .then(this.initialize);

    client.onLogin(this.initialize);
    client.onLogout(this.storage.clearStore);

    //   Debug use.
    //   const results = [];
    //   this.getAndStoreAllTypeClasses()
    //   .then(typeClasses => {
    //     for (const typeClass of typeClasses) {
    //       const form = new Form(typeClass.id, {});
    //       results.push({
    //         id: typeClass.id,
    //         ...form.data
    //       });
    //     }
    //   })
    //   .then(() => console.log(JSON.stringify(results)));
  }

  initialize = async () => {
    if (!client.isLoggedIn) return;
    // return this.loadAllData().then(() => this.initialized = true);
    return this.initialized = true;
  };

  loadAllData = async () => Promise.all([
    this.getAndStoreAllTypeClasses()
  ]);

  getAndStoreAllTypeClasses = async (): Promise<TypeClass[]> => {
    if (!client.isLoggedIn) return;
    return api.GET(endpointConfig.type_classes)
    .then((response: AxiosResponse<TypeClass[]>) => {
      const typeClasses: TypeClass[] = response.data || [];
      typeClasses.forEach(this.updateTypeClass);
      return typeClasses;
    });
  };

  getAndStoreTypeClassById = async (typeClassId: TypeClass["id"]): Promise<TypeClass> => {
    if (this.loadQueue.includes(typeClassId)) return {} as TypeClass;
    this.loadQueue.push(typeClassId);
    return api.GET(endpointConfig.type_class_by_id(typeClassId))
    .then((response: AxiosResponse<TypeClass>) => {
      const typeClass = response.data || {} as TypeClass;
      this.updateTypeClass(typeClass);
      return typeClass;
    });
  };

  getTypeClassNameById = (typeClassId: TypeClass["id"]): string => {
    const typeClass: TypeClass = this.findTypeClassById(typeClassId);
    if (isEmpty(typeClass)) return "";
    return typeClass.name;
  };

  getTypeClassTypeById = (typeClassId: TypeClass["id"]): string => {
    const typeClass: TypeClass = this.findTypeClassById(typeClassId);
    if (isEmpty(typeClass)) return "";
    return typeClass.type;
  };

  updateTypeClass = (data: TypeClass) => {
    const typeClassId = data && data.id;
    if (isNonZeroFalse(typeClassId) || isEmpty(data)) return;
    const typeClass = this.findTypeClassById(typeClassId);
    if (isEmpty(typeClass)) return this.typeClasses.push(data) && data;
    if (isEqual(typeClass, data)) return;
    // Use patch style update to prevent data error.
    Object.assign(typeClass, data);
    // Return final data;
    return typeClass;
  };

  findTypeClassById = (typeClassId: TypeClass["id"]): TypeClass => {
    if (isNonZeroFalse(typeClassId)) return {} as TypeClass;
    const typeClass = this.typeClasses.find(
      typeClass => typeClass.id.toString() === typeClassId.toString()
    );
    if (!typeClass) this.getAndStoreTypeClassById(typeClassId)
    .catch(err => ui.showError({ err }));
    return typeClass || {} as TypeClass;
  };

  matchTypeClassId = (typeClassId: TypeClass["id"], name: keyof typeof typeClassIds): boolean => {
    const typeClasses = typeClassIds[name];
    if (isEmpty(typeClasses)) return false;
    const typeClassIdList = Object.keys(typeClasses).map(version => typeClasses[version].id);
    return !isEmpty(typeClassIdList) && typeClassIdList.includes(typeClassId);
  };
}

// We would append Controller name here to prevent confusion with actual data.
export let formCtrl = {} as FormController;
export const initFormCtrl = constructor => formCtrl = constructor;
