import { safeParseJSON } from "../utils/helpers";
import { AxiosResponse } from "axios";
import { EntityProfileDuoDTO } from "./types/dataTypes";

export class DualDTOParser<T = any> {
  constructor(private readonly secondKey: string) {}
  parse = (data: EntityProfileDuoDTO<T> | T): T => {
    const keys = Object.keys(data);
    if (keys.length !== 2) return data as T;
    const { profile } = data as EntityProfileDuoDTO<T>;
    if (profile) delete (data as EntityProfileDuoDTO<T>).profile;
    const key = keys[0];
    if (!data.hasOwnProperty(key)) return data as T;
    data[key][this.secondKey] = profile;
    return {...data[key]} as T;
  };
}

export class DataParser<T = {}> {
  constructor(
    private readonly extraProcessor?: (data: T) => T
  ) {}

  parseDTO = (data: T): T | any => {
    if (Array.isArray(data)) {
      for (let elm of data) elm = this.parseDTO(elm);
      return data;
    }
    const keys = Object.keys(data);
    for (let key of keys) {
      if (key.match(/ResponseDTO$/g) && data[key] !== "undefined") {
        data[key.replace(/ResponseDTO/g, "")] =
          !!data[key] && this.parseDTO(data[key]);
        delete data[key];
      }
      if (key.match(/ResponseDTOList$/g) && data[key] !== "undefined") {
        data[key.replace(/ResponseDTOList$/g, "s")] =
          !!data[key] && this.parseDTO(data[key]);
        delete data[key];
      }
      if (key.match(/TimeLong$/g) && data[key] !== "undefined") {
        data[key.replace(/TimeLong$/g, "Time")] = data[key];
      }
      if (key === "data") {
        const JsonData = safeParseJSON(data[key]);
        data[key] = JsonData || {};
      }
    }
    if (this.extraProcessor) data = this.extraProcessor(data);
    return data;
  };

  parseResponseArray = (response: AxiosResponse<T[]>) => {
    const dataArray = Array.isArray(response.data) && response.data;
    return (dataArray || []).map(data => this.parseDTO(data));
  };

  parseResponseObject = (response: AxiosResponse<T>) => {
    const { data } = response;
    return (data && this.parseDTO(data)) || {};
  };
}