import { observer } from "mobx-react";
import { Checkbox, Typography } from "@material-ui/core";
import React from "react";
import styles from "./styles.css";
import { termsUrl } from "../../mcb/config/constants";
import { withStyles } from "direflow-component/dist";
import { stopProp } from "../../utils/helpers";

const TermsCheckbox = observer(({ checked, onChange, error }) => {
  return <div className="flex align-items-center justify-content-center termsCheckbox">
    <Checkbox
      className={error ? "error" : ""}
      name="terms"
      checked={checked}
      onChange={onChange}
    />
    <Typography onClick={onChange} className={error ? "error" : ""}>
      I have read and agree to <a onClick={stopProp} href={termsUrl} target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.
    </Typography>
  </div>
});

export default withStyles(styles)(TermsCheckbox);