import { observable } from "mobx";

class ResponsiveStyles {
  mobileWidth: number = 768;
  mobileWidthIntermediate: number = 1200;
  viewMaxWidth: number = 1366;

  mql = window.matchMedia(`(max-width: ${this.mobileWidth}px)`);
  miMql = window.matchMedia(`(max-width: ${this.mobileWidthIntermediate}px)`);
  viewMaxMql = window.matchMedia(`(max-width: ${this.viewMaxWidth}px)`);
  @observable deviceDimension = {
    isMobile: this.mql.matches,
    isMobileIntermediate: this.miMql.matches,
    isViewMax: this.viewMaxMql.matches,
    width: window.innerWidth
  };

  constructor () {
    this.mql.addListener(event => (
      this.deviceDimension.isMobile = event.matches
    ));
    this.miMql.addListener(event => (
      this.deviceDimension.isMobileIntermediate = event.matches
    ));
    this.viewMaxMql.addListener(event => (
      this.deviceDimension.isViewMax = event.matches
    ));
    window.addEventListener("resize", this.updateWidth);
  }

  updateWidth = () => this.deviceDimension.width = window.innerWidth;
}

export const responsive = new ResponsiveStyles();