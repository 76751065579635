import { Controller } from "../../lib/controller";
import { computed, IObservableArray, observable, toJS, when } from "mobx";
import { stateCtrl } from "../../client/state";
import { mcbSearchCtrl } from "../../mcb/client/search";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { Group, Member, Profile } from "../../lib/types/dataTypes";
import { client } from "../../client/client";
import { removeLastName } from "../../mcb/lib/common";
import { asyncPause, contextReject, isEmpty, whenFulfill } from "../../utils/helpers";
import { groupTypeIds } from "../../mcb/config/constants";
import { UIText } from "../../client/lang";
import { computedFn } from "mobx-utils";
import { Appointment, AppointmentDateTimeOption, Attendance, CreateAppointmentDTO, HasInterviewAppointmentData } from "../../mcb/lib/types/dataTypes";
import { api } from "../../client/api";
import { endpointConfig } from "../../config/api";
import {
  getAppointmentZoomMeetingUrl,
  handleAppointmentEditAttendance,
  handleAppointmentNotesUpdate,
  handleAppointmentUpdate,
  handleConfirmAppointment,
  handleDownloadAppointmentIcs,
  handleRequestNewDateTimeOption
} from "../../mcb/lib/appointment-utilities";
import { AxiosResponse } from "axios";
import { fileCtrl } from "../../client/file";

export class McbBookingFormController extends Controller {
  @observable _profile: Profile;
  @observable profileGroup: Group;
  @observable organizerGroup: Group;
  @observable organizerGroupMembers: IObservableArray<Member> = observable([]);
  @observable selfProfile: Profile;

  @observable createAppointmentDTO: CreateAppointmentDTO = {} as CreateAppointmentDTO;
  @observable existingAppointment: Appointment;
  @observable hasInterview: boolean;

  @computed get isVisible(): boolean {
    return stateCtrl.bookingFormVisible;
  };

  @computed get profileId(): number {
    return stateCtrl.bookingFormProfileId;
  };
  @computed get profile(): Profile {
    return this._profile?.id === this.profileGroup?.profileId && this._profile;
  };
  @computed get providerAvatar() {
    return this.getProfileAvatarUri(this.profile);
  };
  @computed get organizerGroupId(): number {
    return stateCtrl.bookingFormOrganizerGroupId;
  };

  @computed get userGroups(): Group[] {
    return client.findVisibleGroups();
  };
  @computed get userCareCircles(): Group[] {
    return this.userGroups.filter(group => group.typeId === groupTypeIds.careCircle);
  };
  @computed get userHouseholds(): Group[] {
    return this.userGroups.filter(group => group.typeId === groupTypeIds.household);
  };
  @computed get bookableGroups(): Group[] {
    return this.profileGroup?.typeId === groupTypeIds.caregiver
      ? this.userCareCircles
      : this.profileGroup?.typeId === groupTypeIds.household
      ? this.userHouseholds
      : [];
  };

  @computed get selfMember(): Member {
    return client.findMyMemberInGroup(this.organizerGroup);
  };
  @computed get timezone(): string {
    return this.existingAppointment?.timezone || this.organizerGroup?.profile?.data?.timezone;
  };

  loadBookingForm = async () => {
    await when(() => !mcbSessionCtrl.runningAuthChangeSequence);
    // await mcbSearchCtrl.candidateSyncReady();
    await client.isLoggedInAndReady();

    await this.getProfileGroupByProfileId(this.profileId).catch(contextReject);

    if (mcbSessionCtrl.isValidVisitor) await this.showLogin(stateCtrl.bookingFormProfileId);

    await whenFulfill(() => !isEmpty(this.userGroups));
    await whenFulfill(() => !isEmpty(mcbSearchCtrl.scratchpadGroup));

    if (!isEmpty(client.findGroupById(this.profileGroup?.id))) {
      this.cleanup();
      return Promise.reject("Sorry, you cannot book an appointment with yourself.");
    }

    if (this.profileGroup && !this.organizerGroupId) {
      stateCtrl.setBookingFormOrganizerGroupId(
        this.profileGroup.typeId === groupTypeIds.caregiver
          ? mcbSearchCtrl.careCircleScratchpad.id
          : mcbSearchCtrl.householdScratchpad.id
      );
    }

    if (!this.organizerGroupId) {
      this.cleanup();
      return Promise.reject("No organizer group.");
    }

    return this.onOrganizerGroupChange(this.organizerGroupId)
    .then(this.onProfileChange)
    .then(() => setTimeout(this.monitorLoginState));
  };

  showLogin = async (profileId?: number) => {
    let abandonedFlow;
    const dismissAndOpenProfile = () => {
      abandonedFlow = true;
      mcbSearchCtrl.setDoNotRestoreSearchForm(false);
      this.openProfileDetail(profileId)();
    };
    this.setVisible(false);
    mcbSearchCtrl.setDoNotRestoreSearchForm(true);
    stateCtrl.mcbSignInFormOpen = true;
    stateCtrl.mcbSignInFormOptions.subHeading = UIText.signInToBookAppointment;
    stateCtrl.mcbSignInFormOptions.hideCreateAccount = true;
    stateCtrl.mcbSignInFormOptions.extraLinks = [{
      text: stateCtrl.mcbListingProfileIsOpen
        ? UIText.bookAppointmentNoAccount
        : UIText.dontHaveAccount,
      handler: stateCtrl.mcbListingProfileIsOpen
        ? this.dismissLogin
        : dismissAndOpenProfile,
      below: true
    }];
    stateCtrl.mcbSignInFormOptions.onClose = () => {
      stateCtrl.dismissBookingForm();
      stateCtrl.mcbSignInFormOptions.subHeading = null;
      stateCtrl.mcbSignInFormOptions.onClose = null;
      stateCtrl.mcbSignInFormOptions.hideCreateAccount = null;
      stateCtrl.mcbSignInFormOptions.extraLinks = null;
      abandonedFlow = true;
    };
    return whenFulfill(() => !mcbSessionCtrl.isValidVisitor || abandonedFlow)
    .then(() => {
      !abandonedFlow && this.setVisible(true);
    });
  };

  dismissLogin = () => stateCtrl.closeSignInform(null);

  setVisible = (isVisible: boolean) => stateCtrl.setBookingFormVisible(isVisible);

  openProfileDetail = (profileId: number) => () => {
    this.dismissLogin();
    const group = mcbSearchCtrl.allResultGroups.find(g => g.profileId === profileId);
    if (!group) return;
    mcbSearchCtrl.setViewGroupId(group.id);
    return stateCtrl.openMcbListingProfile(group.id);
  };

  onOrganizerGroupChange = (groupId: number) => {
    stateCtrl.setBookingFormOrganizerGroupId(groupId);
    return this.getOrganizerGroup()
    .then(this.getOrganizerGroupMembers)
    .then(this.checkHasInterviewAppointment)
    .then(() => this.getProfileById(this.selfMember.profileId))
    .then(profile => this.selfProfile = profile)
    // .then(this.getExistingAppointments);
  };

  onProfileChange = () => this.getProfileById(this.profileId, !this.existingAppointment?.inviteeResponded)
  .then(profile => this._profile = profile);

  getProfileById = async (profileId: number, remove?: boolean) => {
    await client.isLoggedInAndReady();
    if (!profileId) return;
    return client.getProfileById(profileId)
    .then(profile => remove ? removeLastName(profile) : profile);
  };

  getProfileGroupByProfileId = async (profileId: number) => {
    await client.isLoggedInAndReady();
    if (!profileId) return;
    return api.GET(endpointConfig.get_group_by_profile_id(profileId))
    .then(response => response.data)
    .then(group => this.profileGroup = group);
  };

  getOrganizerGroup = async () => {
    await client.isLoggedInAndReady();
    return this.organizerGroup = client.findGroupById(this.organizerGroupId);
  };

  getOrganizerGroupMembers = async () => {
    this.organizerGroup && this.organizerGroupMembers.replace(this.organizerGroup.members);
    this.organizerGroupMembers.replace(this.organizerGroupMembers.filter(m => m.id !== this.selfMember.id));
    if (isEmpty(this.organizerGroupMembers)) return;
    const checkProfile = (member: Member) => {
      if (!isEmpty(member.profile)) return;
      return client.getProfileById(member.profileId)
      .then(profile => member.profile = profile);
    };
    return Promise.all(this.organizerGroupMembers.map(checkProfile));
  };

  checkHasInterviewAppointment = async () => {
    await client.isLoggedInAndReady();
    const data: Partial<HasInterviewAppointmentData> = {
      organizerGroupId: this.organizerGroupId,
      providerGroupId: this.profileGroup?.id
    };
    return api.POST({
      endpoint: endpointConfig.check_has_accepted_interview,
      data
    })
    .then((response: AxiosResponse<HasInterviewAppointmentData>) => response.data)
    .then(data => this.hasInterview = data.hasInterview);
  };

  getProfileAvatarUri = computedFn((profile: Profile) => fileCtrl.getProfileAvatarUri((profile?.data || {}).avatar, this.profileGroup?.id, "group"));

  getExistingAppointments = async () => {
    if (isEmpty(this.selfMember) || isEmpty(this.profileGroup)) return;
    return api.GET(endpointConfig.get_appointment_by_member_id_provider_group_id(
      this.selfMember.id, this.profileGroup.id
    ))
    .then(response => response.data)
    .then(existingAppointment => this.existingAppointment = existingAppointment);
  };

  getZoomMeetingUrl = async () => {
    if (isEmpty(this.existingAppointment)) return;
    return getAppointmentZoomMeetingUrl(this.existingAppointment.id);
  };

  onUpdateDTO = (data: Partial<CreateAppointmentDTO>) => {
    Object.assign(this.createAppointmentDTO, data);
    this.createAppointmentDTO.groupId = this.organizerGroup?.id || this.organizerGroupId;
    this.createAppointmentDTO.providerGroupId = this.profileGroup?.id;
  };

  onSubmit = async (data: Partial<CreateAppointmentDTO>) => {
    this.onUpdateDTO(data);
    if (!this.organizerGroupId) return Promise.reject(UIText.bookingFormMissingContext);
    return api.POST({
      endpoint: endpointConfig.book_appointment(this.organizerGroupId),
      data: toJS(this.createAppointmentDTO)
    });
  };

  onCancel = async () => {
    if (isEmpty(this.existingAppointment)) return;
    return api.DELETE(endpointConfig.appointment_by_id(this.existingAppointment.id));
  };

  onNameTypeUpdate = async (data: Partial<Appointment>) => handleAppointmentUpdate(this.existingAppointment, data, this.getExistingAppointments);

  onNotesUpdate = async (notes: string) => handleAppointmentNotesUpdate(this.existingAppointment, notes, this.getExistingAppointments);

  onAttendanceUpdate = async (
    attendeeId: number,
    attendance: Attendance
  ) => handleAppointmentEditAttendance(
    this.existingAppointment,
    attendeeId,
    attendance,
    this.getExistingAppointments
  );

  onRequestDateTimeOption = async (dateTimeOption: AppointmentDateTimeOption) => handleRequestNewDateTimeOption(
    this.existingAppointment,
    dateTimeOption,
    false,
    this.getExistingAppointments
  );

  onRequestDateTimeOptions = async (dateTimeOptions: AppointmentDateTimeOption[]) => handleRequestNewDateTimeOption(
    this.existingAppointment,
    dateTimeOptions,
    true,
    this.getExistingAppointments
  );

  onConfirmAppointment = async (dateTimeOptionId: string) => handleConfirmAppointment(
    this.existingAppointment,
    dateTimeOptionId,
    this.getExistingAppointments
  );

  onDownloadIcs = async () => handleDownloadAppointmentIcs(this.existingAppointment);

  syncStateCtrlContextGroupId = () =>
    this.selfMember?.groupId && (stateCtrl.appointmentListContextGroupId = this.selfMember?.groupId);

  cleanup = () => {
    this._profile = null;
    this.profileGroup = null;
    stateCtrl.setBookingFormOrganizerGroupId(null);
    this.organizerGroup = null;
    this.organizerGroupMembers.clear();
    this.selfProfile = null;
    this.createAppointmentDTO = {} as CreateAppointmentDTO;
    this.existingAppointment = null;
    this.disposers && this.disposers.map(dispose => dispose());
  };

  monitorLoginState = async () => {
    while (!mcbSessionCtrl.isValidVisitor) {
      await asyncPause(1000);
    }
    return this.loadBookingForm();
  };
}