import React from "react";
import { withStyles } from "direflow-component";
import styles from "./styles.css";
import { Button, CircularProgress, FormControl, IconButton, InputLabel, Select, Typography } from "@material-ui/core";
import { UIText } from "../../client/lang";
import { ExpandMore, LocationSearching } from "@material-ui/icons";
import { PickerField, TypeClassField } from "../../lib/types/formTypes.legacy";
import { observer } from "mobx-react";
import { isEmpty } from "../../utils/helpers";
import { ui } from "../../client/ui";

export interface MarketplaceSearchBarProps {
  loading?: boolean;
  geoLoading?: boolean
  criteriaDirty?: boolean;
  availableServices: TypeClassField<PickerField>["options"];
  provinceOptions: TypeClassField<PickerField>["options"];
  municipalityOptions: TypeClassField<PickerField>["options"];
  service: TypeClassField["value"];
  city: TypeClassField["value"];
  province: TypeClassField["value"];
  onChange?: (event: any) => void;
  onSearch?: (event: any) => void;
  onGeo?: (event: any) => void;
}

const MarketplaceSearchBar: React.FC<MarketplaceSearchBarProps> = observer(
  ({
     loading,
     geoLoading,
     criteriaDirty,
     availableServices,
     provinceOptions,
     municipalityOptions,
     service,
     province,
     city,
     onChange,
     onSearch,
     onGeo
   }) => {
    return <div className={`marketplaceSearchBar flex justify-content-center align-items-center ${ui.isMobile ? "column" : ""}`}>
      <IconButton onClick={onGeo} color="primary">
        {geoLoading ? <CircularProgress size={24} /> : <LocationSearching />}
      </IconButton>
      <form className="flex justify-content-center align-items-center marketplaceSearchBarForm">
        {!isEmpty(availableServices) && <FormControl variant="outlined">
          <InputLabel>{UIText.selectService}</InputLabel>
          <Select
            native
            name="service"
            IconComponent={ExpandMore}
            value={!loading && service}
            onChange={onChange}
            className="flex select"
          >
            {availableServices.map(option =>
              <option key={option.name} value={option.name}>{option.placeholder}</option>
            )}
          </Select>
        </FormControl>}
        {!isEmpty(provinceOptions) && <FormControl variant="outlined">
          <InputLabel>{UIText.province}</InputLabel>
          <Select
            native
            name="provinces"
            IconComponent={ExpandMore}
            value={province}
            onChange={onChange}
            className="flex select"
          >
            {provinceOptions.map(option =>
              !option.hidden && <option key={option.name} value={option.name}>{option.placeholder}</option>
            )}
          </Select>
        </FormControl>}
        {!isEmpty(municipalityOptions) && <FormControl variant="outlined">
          <InputLabel>{UIText.city}</InputLabel>
          <Select
            native
            name="municipalities"
            IconComponent={ExpandMore}
            value={city}
            onChange={onChange}
            className="flex select"
          >
            {municipalityOptions.map(option =>
              !option.hidden && <option key={option.name} value={option.name}>{option.placeholder}</option>
            )}
          </Select>
        </FormControl>}
        {!loading && <Button
          className="flex textNoTransform searchBtn"
          variant="contained"
          color={criteriaDirty ? "primary" : "secondary"}
          disableElevation
          onClick={onSearch}
        >
          <Typography>
            {criteriaDirty ? UIText.marketplaceSearchBarSearchBtnDirty : UIText.marketplaceSearchBarSearchBtn}
          </Typography>
        </Button>}
      </form>

    </div>

  });

export default withStyles(styles)(MarketplaceSearchBar);
