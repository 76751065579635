import Icon from "@mdi/react";
import React from "react";
import { observer } from "mobx-react";
import { Colors } from "../../lib/types/miscTypes";

interface MdIconProps extends Pick<React.HTMLProps<HTMLDivElement>, "className" | "onClick" | "style"> {
  icon: string;
  size?: number | string | null;
  color?: Colors | string;
}

const MdIcon: React.FC<MdIconProps> = observer(
  ({ className, style, icon, size, color }) => {
    return <Icon className={`mdIcon ${className || ""}`} path={icon} size={size || 1} color={color} style={style} />;
  });

export default MdIcon;