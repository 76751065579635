import { Achievement, AggregatedRatingReviewResponseDTO, RatingReview, Testimonial } from "../../mcb/lib/types/dataTypes";
import React from "react";
import { mcbSearchCtrl } from "../../mcb/client/search";
import { computed } from "mobx";
import { Styled } from "direflow-component";
import styles from "./styles.css";
import Avatar from "@material-ui/core/Avatar";
import { Group, Profile } from "../../lib/types/dataTypes";
import { getDisplayNameEng, isEmpty } from "../../utils/helpers";
import ProfileRatingDisplay from "../ProfileRatingDisplay";
import { UIText } from "../../client/lang";
import { getYoEAndPrice } from "../../mcb/lib/common";
import { Button, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import McbComponentPastry from "../McbComponentPastry";
import { ui } from "../../client/ui";
import { observer } from "mobx-react";
import { stateCtrl } from "../../client/state";


export interface ProfileRatingDetailProps {
  profileId: number;
  isCaregiver?: boolean;
  showNameAvatar?: boolean;
  showOverallRating?: boolean;
  getAvatarUri?: (profile: Profile) => string;
  onClose?: (event: any) => void;
}

@observer
class ProfileRatingDetail extends React.Component<ProfileRatingDetailProps> {
  @computed get group(): Group {
    const searchResultGroups = mcbSearchCtrl.allResultGroups;
    const popupGroups = stateCtrl.mcbMarketResultsPopupGroups;
    return [...searchResultGroups, ...popupGroups].find(g => g.profileId === this.props.profileId);
  };
  @computed get profile(): Profile {
    return (this.group || {}).profile;
  };
  @computed get displayName(): string {
    return getDisplayNameEng(this.profile);
  };
  @computed get yearOfExp(): number {
    const { yearOfExp } = getYoEAndPrice((this.profile || {}).data, this.props.isCaregiver);
    return yearOfExp;
  };
  @computed get aggregatedRating(): AggregatedRatingReviewResponseDTO {
    return mcbSearchCtrl.findAggregatedRatingForProfile(this.props.profileId);
  };
  @computed get rating(): number {
    return (this.aggregatedRating || {}).aggregatedRating;
  };
  @computed get reviews(): Partial<RatingReview>[] {
    return (this.aggregatedRating || {}).reviews || [];
  };
  @computed get testimonials() {
    return (this.aggregatedRating || {}).testimonials || [];
  };
  @computed get achievement(): Achievement {
    return mcbSearchCtrl.findProfileAchievement(this.profile);
  };

  renderReview = (review: RatingReview, index: number) => {
    if (isEmpty(review)) return null;
    const submissionDate = new Date(review.submissionDate);
    const authorName = getDisplayNameEng((review.authorMember || {}).profile);
    const name = (authorName || "").split(/\s+/g)[0];
    return <div className="flex column review" key={index}>
      <div className="flex justify-content-between font-s">
        <div>
          <Typography className="textBold" color="primary">
            {name}
          </Typography>
          <Typography className="font-xs submissionDate">
            {submissionDate.toLocaleDateString()}
          </Typography>
        </div>
        <ProfileRatingDisplay offsetStart rating={review.rating} hideSamples />
      </div>
      <Typography className="textPre">
        {review.review || <span className="noComment">{UIText.reviewNoContent}</span>}
      </Typography>
    </div>;
  };

  renderTestimonial = (testimonial: Testimonial) => {
    if (isEmpty(testimonial)) return null;
    const submissionDate = new Date(testimonial.createTime);
    const name = (testimonial.name || "").split(/\s+/g)[0];
    return <div className="flex column review" key={submissionDate.getTime()}>
      <div className="flex justify-content-between font-s">
        <div>
          <Typography className="textBold" color="primary">
            {name}
          </Typography>
          <Typography className="font-xs submissionDate">
            {submissionDate.toLocaleDateString()}
          </Typography>
        </div>
        <Typography className="textBold" color="secondary">
          {UIText.ratingTestimonial}
        </Typography>
      </div>
      <Typography className="textPre">
        {testimonial.review || <span className="noComment">{UIText.reviewNoContent}</span>}
      </Typography>
    </div>;
  };

  render() {
    const { showNameAvatar, showOverallRating, getAvatarUri, onClose } = this.props;

    return <McbComponentPastry>
      <Styled styles={styles}>
        <div className="flex column profileRatingDetail">
          {onClose && ui.isMobile && (
            <Button
              className="modalClose"
              variant="contained"
              color="secondary"
              disableElevation
              onClick={onClose}
            >
              <Close />&nbsp;{UIText.closeRatingReviewDetailMobile}
            </Button>
          )}
          <div className="container">
            {(showNameAvatar || showOverallRating) && (
              <div className="flex align-items-center header">
                {showNameAvatar && (
                  <Avatar
                    variant="square"
                    alt={this.displayName}
                    src={getAvatarUri && getAvatarUri(this.profile)}
                    className="avatar"
                  >
                    {this.displayName.charAt(0)}
                  </Avatar>
                )}
                <div className="flex justify-content-between headerContent">
                  {showNameAvatar && (
                    <div className="flex column basicInfo">
                      <div className="font-l textBold displayName">
                        {this.displayName}
                      </div>
                      {this.yearOfExp && <div className="yearOfExp">
                        {UIText.profileYoE(this.yearOfExp)}
                      </div>}
                    </div>
                  )}
                  {showOverallRating && (
                    <div className="flex column overallRating">
                      <Typography color="secondary" className="textBold">
                        {UIText.overallRating}
                      </Typography>
                      <ProfileRatingDisplay
                        offsetStart
                        rating={this.rating}
                        samples={this.reviews.length}
                        achievement={this.achievement}
                        size={ui.isMobile ? 22 : 24}
                        badgeSize={ui.isMobile ? 30 : 35}
                      />
                    </div>
                  )}
                </div>
                {onClose && !ui.isMobile && (
                  <IconButton onClick={onClose} color="secondary" className="closeButton">
                    <Close />
                  </IconButton>
                )}
              </div>
            )}
            <div className="reviews">
              {(isEmpty(this.reviews) && isEmpty(this.testimonials)) ? (
                <Typography className="noReviews font-s">
                  {UIText.ratingNoReviews}
                </Typography>
              ) : <>
                {this.testimonials.map(this.renderTestimonial)}
                {this.reviews.map(this.renderReview)}
              </>}
            </div>
          </div>
        </div>
      </Styled>
    </McbComponentPastry>;
  }
}

export default ProfileRatingDetail;