import React from "react";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "../../config/styles/theme";
import styles from "../../config/styles/baseline.css";
import { Styled } from "direflow-component/dist";

const McbComponentPastry: React.FC<React.HTMLProps<HTMLDivElement>> = ({ style, children }) => (
  <ThemeProvider theme={theme}>
    <Styled styles={styles}>
      <div className="McbComponentPastryBaseline" style={style}>
        {children}
      </div>
    </Styled>
  </ThemeProvider>
);

export default McbComponentPastry;