import { observer } from "mobx-react";
import React from "react";
import styles from "./styles.css";
import { Styled } from "direflow-component";
import { Group, Profile } from "../../lib/types/dataTypes";
import { ExpandMore } from "@material-ui/icons";
import { getDisplayNameEng } from "../../utils/helpers";
import { FormControl, Select } from "@material-ui/core";
import { toJS } from "mobx";
import { groupTypeIds } from "../../mcb/config/constants";

const getGroupText = (group: Group, profile: Profile) =>
  group.typeId === groupTypeIds.myCareBaseStaff
    ? "myCareBase Staff"
    : `${getDisplayNameEng(profile)} - ${group?.groupTypeName}`;

export interface GroupSelectorProps extends React.HTMLProps<HTMLDivElement> {
  groups: Group[];
  groupTypeId?: number;
  selectedGroupId: number;
  onGroupChange: (groupId: number) => void;
}

const GroupSelector: React.FC<GroupSelectorProps> = observer(({ groups, groupTypeId, selectedGroupId, onGroupChange }) => {
  const handleChange = (event: any) => {
    const { value } = event.target;
    return onGroupChange && onGroupChange(Number(value));
  };
  const sortByAlphabet = (a: Group, b: Group) =>
    (getGroupText(a, a.profile) || "").toLowerCase() > (getGroupText(b, b.profile) || "").toLowerCase() ? 1 : -1;
  const mCBGroup = groups.find(g => g.typeId === groupTypeIds.myCareBaseStaff);
  return <Styled styles={styles}>
    <div className="relative groupSelector">
      <FormControl variant="standard" color="secondary">
        <Select
          className="textBold"
          native
          IconComponent={ExpandMore}
          value={(selectedGroupId || "").toString()}
          onChange={handleChange}
        >
          {mCBGroup && (
            <option key="mcb" value={(mCBGroup.id || "").toString()}>{getGroupText(mCBGroup, mCBGroup.profile)}</option>
          )}
          {toJS(groups || [])
          .filter(g => g.typeId !== groupTypeIds.myCareBaseStaff && (!groupTypeId || g.typeId === groupTypeId))
          .sort(sortByAlphabet)
          .filter(Boolean).map(group => (
            <option key={group.id} value={(group.id || "").toString()}>{getGroupText(group, group.profile)}</option>
          ))}
        </Select>
      </FormControl>
    </div>
  </Styled>
});

export default GroupSelector;