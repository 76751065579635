import { Group, GroupType, GroupTypeRole, Member, Profile, ProfilePrimitive, Role } from "./types/dataTypes";
import { endpointConfig } from "../config/api/index";
import { DataParser } from "./parser";
import { api } from "../client/api";
import { getDisplayNameEng, isEmpty, safeParseJSON } from "../utils/helpers";
import { UIException, UIText } from "../client/lang";
import { client } from "../client/client";
import { stateCtrl } from "../client/state";
import { groupTypeIds, groupTypeRoleIds, serviceProviderGroupTypeIds } from "../mcb/config/constants";
import { KeyValuePairs } from "./types/miscTypes";
import { typeClassIds } from "../config/constants";

export const createGroupProfile = async (
  group: Partial<Group>,
  profile: ProfilePrimitive,
  groupTypeRoleId: GroupTypeRole["id"]
) =>
  api.POST({
    endpoint: endpointConfig.create_group_profile,
    data: {
      group,
      profile
    }
  })
  .then(client.groupParser.parseResponseObject)
  .then(group => {
    if (isEmpty(group)) throw new UIException("CREATE_GROUP_PROFILE_EMPTY_RETURN");
    return {
      group, profile, profileDetail: safeParseJSON(profile.data), groupTypeRoleId
    };
  });

export const getGroupRoles = async (createGroupSequence: {
  group: Partial<Group>,
  profile: ProfilePrimitive,
  profileDetail: Profile["data"],
  groupTypeRoleId: GroupTypeRole["id"]
}) => {
  const { group, profile, profileDetail, groupTypeRoleId } = createGroupSequence;
  const groupId = group.id;
  if (!groupId) throw new UIException("GET_GROUP_ROLES_INVALID_GROUP_ID");
  return api.GET(endpointConfig.type_roles_by_group_id(groupId, groupTypeRoleId))
  .then(new DataParser<Role[]>().parseResponseObject)
  .then(roles => ({
    group, profile, profileDetail, roles, groupTypeRoleId
  }));
};

export const createSelfMember = async (getGroupRolesSequence: {
  group: Partial<Group>,
  profile: ProfilePrimitive,
  profileDetail: Profile["data"],
  roles: Role[],
  groupTypeRoleId: GroupTypeRole["id"]
}) => {
  const { group, roles, profileDetail, groupTypeRoleId } = getGroupRolesSequence;
  const member: Partial<Member> = {
    userId: client.userId,
    groupId: group.id,
    profileId: group.profileId,
    email: profileDetail.email,
    phone: profileDetail.phone
  };
  const roleIdList = [
    (roles.find(role => role["groupTypeRoleId"] === groupTypeRoleId) || {}).id
  ];
  return api.POST({
    endpoint: endpointConfig.create_member,
    data: {
      member,
      roleIdList
    }
  });
};

export const createSelfMemberProfile = async (getGroupRolesSequence: {
  group: Partial<Group>,
  profile: ProfilePrimitive,
  profileDetail: Profile["data"],
  roles: Role[],
  groupTypeRoleId: GroupTypeRole["id"]
}) => {
  const { group, profile, profileDetail, roles, groupTypeRoleId } = getGroupRolesSequence;
  const member: Partial<Member> = {
    userId: client.userId,
    groupId: group.id,
    email: profileDetail.email,
    phone: profileDetail.phone
  };
  const profileData: ProfilePrimitive = {
    userId: client.userId,
    type: 2,
    typeClassId: profile.typeClassId,
    typeClassVersion: profile.typeClassVersion,
    data: profile.data,
  };
  const roleIdList = [
    (roles.find(role => role["groupTypeRoleId"] === groupTypeRoleId) || {}).id
  ];
  return api.POST({
    endpoint: endpointConfig.create_member_profile,
    data: {
      member,
      profile: profileData,
      roleIdList
    }
  });
};

export const createCareReceiverMember = async ({ group, profileDetail, roles }) => {
  if (isEmpty(group)) throw new UIException("CREATE_CARE_RECIPIENT_EMPTY_GROUP");
  const groupId = group.id;
  const member = {
    groupId: groupId,
    profileId: group.profileId,
    email: profileDetail.email,
    phone: profileDetail.phone
  };
  const roleIdList = [
    roles.find(role => role["groupTypeRoleId"] === groupTypeRoleIds.careReceiver).id
  ];
  return await api.POST({
    endpoint: endpointConfig.create_member,
    data: {
      member,
      roleIdList
    }
  })
  .then(() => ({ group, profileDetail, roles }));
};

export const getGroupTypeNameByGroup = (group: Group) => {
  if (isEmpty(group)) return UIText.unknownGroupType;
  const groupTypes: GroupType[] = client.groupTypes;
  const typeName: string = (groupTypes.find(type => type.id === group.typeId) || {}).typeName;
  return UIText.groupTypes[typeName];
};

export const setLastSetupGroupId = createGroupSequence => {
  const { group } = createGroupSequence;
  stateCtrl.lastSetupGroupId = group.id;
  return createGroupSequence;
};

export const notifyCareReceiverGroupCreation = async (group: Group, minuteLimit: boolean) => {
  if (isEmpty(group)) return Promise.reject({
    message: "Cannot find group data. [notifyCareReceiverGroupCreation]"
  });
  const selfMember = client.findMyMemberByGroupId(group.id);
  if (isEmpty(selfMember)) return;
  const selfMemberProfileData = (selfMember.profile || {}).data || client.defaultProfile.data;
  const careCircleGroupProfileRequestDTO = {
    data: (group.profile || {}).data && JSON.stringify(group.profile.data)
  };
  const personalMemberProfileRequestDTO = {
    data: JSON.stringify(selfMemberProfileData)
  };
  const data = {
    careCircleGroupProfileRequestDTO,
    personalMemberProfileRequestDTO,
    groupId: group.id,
    groupTypeId: group.typeId,
    userId: client.userId,
    ifCheckCreatedTime: minuteLimit ? 1 : 0
  };
  return api.POST({
    endpoint: endpointConfig.notify_care_circle_creation,
    data
  })
  .then(console.log);
};

export const getServiceGroupTypeToGroupTypeRoleMatchMap = () => {
  const result = {};
  for (const groupTypeId of serviceProviderGroupTypeIds) {
    const keyName = Object.keys(groupTypeIds).find(k => groupTypeIds[k] === groupTypeId);
    result[groupTypeId] = groupTypeRoleIds[keyName];
  }
  return result;
};

export const matchServiceGroupTypeRoleByGroupTypeId = groupTypeId => {
  const map = getServiceGroupTypeToGroupTypeRoleMatchMap();
  return map[groupTypeId];
};

export const getServiceProviderGroupProfileFlags = () => {
  const result = {};
  const map = getServiceGroupTypeToGroupTypeRoleMatchMap();
  for (const id of Object.values(map)) {
    result[id as number] = ["caregiver"];
  }
  return result;
};

export const createCareCircleGroup = async (searchCriteria: KeyValuePairs<string | number | boolean | undefined>) => {
  const group = {
    typeId: groupTypeIds.careReceiver, // Care Receiver type.
    owner: client.userId,
    parentId: null
  };
  const profile = {
    type: 3 as const,
    data: JSON.stringify({
      ...searchCriteria,
      firstName: "Care",
      lastName: "Recipient"
    }),
    typeClassId: typeClassIds.careReceiverProfile.v3.id,
    typeClassVersion: typeClassIds.careReceiverProfile.v3.version
  };
  const selfProfileDetail = {
    email: client.defaultProfile.data.email,
    phone: client.defaultProfile.data.phone,
    firstName: client.defaultProfile.data.firstName,
    lastName: client.defaultProfile.data.lastName,
    displayName: getDisplayNameEng(client.defaultProfile),
    address: ""
  };
  const selfProfile = {
    userId: client.userId,
    type: 2 as const,
    data: JSON.stringify(selfProfileDetail),
    typeClassId: typeClassIds.familyMemberProfile.v1.id,
    typeClassVersion: typeClassIds.familyMemberProfile.v1.version
  };
  return createGroupProfile(group, profile, null)
  .then(getGroupRoles)
  .then(createCareReceiverMember)
  .then(({ group, roles }) => ({
    group,
    profile: selfProfile,
    profileDetail: selfProfileDetail,
    roles,
    groupTypeRoleId: groupTypeRoleIds.primaryFamilyCaregiver
  }))
  .then(createSelfMemberProfile)
  .then(client.getAndStoreUserGroupsAndMembers);
};

export const createHouseholdGroup = async (searchCriteria: KeyValuePairs<string | number | boolean | undefined>) => {
  const group = {
    typeId: groupTypeIds.household, // Care Receiver type.
    groupName: `${client.defaultProfile.data["lastName"]} Household`,
    owner: client.userId,
    parentId: null
  };
  const selfProfileDetail = {
    email: client.defaultProfile.data.email,
    phone: client.defaultProfile.data.phone,
    firstName: client.defaultProfile.data.firstName,
    lastName: client.defaultProfile.data.lastName,
    displayName: getDisplayNameEng(client.defaultProfile),
    address: ""
  };
  const selfProfile = {
    userId: client.userId,
    type: 2 as const,
    data: JSON.stringify(selfProfileDetail),
    typeClassId: typeClassIds.familyMemberProfile.v1.id,
    typeClassVersion: typeClassIds.familyMemberProfile.v1.version
  };
  const profile = {
    type: 3 as const,
    data: JSON.stringify({
      ...searchCriteria,
      firstName: client.defaultProfile.data["lastName"],
      lastName: "Household",
      displayName: group.groupName,
      email: selfProfileDetail.email
    }),
    typeClassId: typeClassIds.careReceiverProfile.v3.id,
    typeClassVersion: typeClassIds.careReceiverProfile.v3.version
  };
  return createGroupProfile(group, profile, null)
  .then(getGroupRoles)
  .then(({ group, roles }) => ({
    group,
    profile: selfProfile,
    profileDetail: selfProfileDetail,
    roles,
    groupTypeRoleId: groupTypeRoleIds.primaryMember
  }))
  .then(createSelfMember)
  .then(client.getAndStoreUserGroupsAndMembers);
};