import { observer } from "mobx-react";
import { Dialog } from "@material-ui/core";
import styles from "./styles.css";
import React from "react";
import { Styled } from "direflow-component";
import { ui } from "../../client/ui";

export interface AlertBaseProps extends Partial<React.ComponentProps<typeof Dialog>> {
  container?: HTMLDivElement;
  isOpen?: boolean;
  onClose?: (event: any) => void;
  extraStyles?: string;
  showBackdrop?: boolean;
  zIndex?: number;
}

export const AlertBase: React.FC<AlertBaseProps> = observer(
  ({ disableBackdropClick, showBackdrop, isOpen, onClose, extraStyles, zIndex, children }) => (
  <Dialog
    style={{ zIndex: zIndex || 1145141414 }}
    fullScreen={ui.isMobile}
    maxWidth="xl"
    disableScrollLock
    disableBackdropClick={disableBackdropClick}
    open={isOpen}
    onClose={onClose}
  >
    <>
      {typeof showBackdrop === "boolean" && !showBackdrop && (
        <style>{`.MuiBackdrop-root { opacity: 0 !important }`}</style>
      )}
      <Styled styles={styles + extraStyles}>
        <div className="flex column justify-content-center align-items-center noEvents alertModalBase">
          <div className="alertModalContent">
            {children}
          </div>
        </div>
      </Styled>
    </>
  </Dialog>
));