import React from "react";
import { observer, Observer } from "mobx-react";
import { Styled } from "direflow-component";
import styles from "./styles.css";
import McbComponentPastry from "../../components/McbComponentPastry";
import withJssMap from "../../components/withJssMap";
import { CircularProgress, Typography } from "@material-ui/core";
import { UIText } from "../../client/lang";
import { computed, observable } from "mobx";
import { ui } from "../../client/ui";
import { McbShopPresentationController } from "./controller";
import ProductCard from "../../components/ProductCard";
import { ProductResponseDTO } from "../../mcb/lib/types/dataTypes";
import { isEmpty, preventDefaultStopProp } from "../../utils/helpers";

export interface McbShopPresentationProps {

}

@observer
class McbShopPresentation extends React.Component<McbShopPresentationProps> {
  controller: McbShopPresentationController = {} as McbShopPresentationController;

  @observable loading: boolean = true;
  @observable entering: boolean = false;

  @computed get cartable(): boolean {
    return this.controller.cartable;
  };
  @computed get cartableReady(): boolean {
    return this.controller.cartableReady;
  };
  @computed get cartableLoading(): boolean {
    return this.cartable && !this.cartableReady;
  };

  constructor(props) {
    super(props);
    this.controller = new McbShopPresentationController();
    this.controller.isReady().then(() => this.loading = false);
  };

  showError = err => ui.showError({ err, actionName: err.actionName || UIText.generalError });

  handleProductCartingClick = (event: any, product: ProductResponseDTO) => {
    preventDefaultStopProp(event);
    if (!this.cartable || !this.cartableReady) return;
    if (isEmpty(product.wpProduct)) return;
    this.entering = true;
    return this.controller.addProductToCart(product.wpProduct).catch(this.showError);
  };

  render() {
    const {
      categorizedProducts,
      productCategoryName
    } = this.controller;


    return <McbComponentPastry>
      <Styled styles={styles}>
        <div className="flex column mcbShopPresentation">
          {this.loading ? (
            <div style={{ flex: 1 }} className="flex justify-content-center align-items-center">
              <CircularProgress size={30} />
            </div>
          ) : (
            <div>
              <Observer>{() => (
                <Typography className="mcbShopPresentationHeader font-m textBold" color="primary">
                  {productCategoryName}
                </Typography>
              )}</Observer>

              <Observer>{() => <div className="flex column mcbShopPresentationList">{
                categorizedProducts.map(product => (
                  <Observer key={product.wpProduct.id}>{() => (
                    <ProductCard
                      product={product}
                      loading={this.loading || this.entering || this.cartableLoading}
                      cartable={this.cartable}
                      onCarting={this.handleProductCartingClick}
                    />
                  )}</Observer>
                ))
              }</div>}</Observer>
            </div>
          )}
        </div>
      </Styled>
    </McbComponentPastry>;
  }
}

export default withJssMap(McbShopPresentation);