import React from "react";
import { observer } from "mobx-react";
import { UIText } from "../../client/lang";
import { Styled } from "direflow-component";
import { computed, observable, when } from "mobx";
import { StdErr } from "../../lib/types/miscTypes";
import { ui } from "../../client/ui";
import styles from "./styles.css";
import { McbListingProfileController } from "./controller";
import ProfileDetail from "../../components/ProfileDetails";
import GetConnected from "../../components/GetConnected";
import { getDisplayNameEng, preventDefaultStopProp } from "../../utils/helpers";
import McbComponentPastry from "../../components/McbComponentPastry";
import ProfileCardWithDetails from "../../components/ProfileCardWithDetails";
import { env } from "../../config/env";
import withJssMap from "../../components/withJssMap";
import { stateCtrl } from "../../client/state";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { ChevronRightSharp, Close } from "@material-ui/icons";
import { getYoEAndPrice } from "../../mcb/lib/common";
import ProfileRatingDetail from "../../components/ProfileRatingDetails";
import { AlertBase } from "../../components/AlertBase";
import { mcbSearchCtrl } from "../../mcb/client/search";

@observer
class McbListingProfile extends React.Component {
  controller: McbListingProfileController = {} as McbListingProfileController;
  container: HTMLDivElement;

  @observable _loading: boolean = true;
  @observable _saveLoading: boolean = false;
  @observable submitting: boolean = false;
  @observable submitted: boolean = false;
  @observable submissionMode: McbListingProfile["isEmailOnly"];

  @observable showRating: boolean = false;

  @computed get isEmbedded(): boolean {
    return stateCtrl.mcbListingProfileIsEmbedded;
  };
  @computed get isEmailOnly(): boolean {
    return this.controller.isEmailOnly;
  };
  @computed get loading(): boolean {
    return this._loading || this.controller.loading;
  };
  @computed get saveLoading(): boolean {
    return this.controller.isCheckingShortlists
      || (stateCtrl.finderContextGroupId && stateCtrl.isMarketResultsPopupProfileSaving(this.controller.profileId))
      || this._saveLoading;
  };

  constructor(props) {
    super(props);
    this.controller = new McbListingProfileController();
    this.controller.initialize()
    .then(this.controller.isReady)
    .then(() => this._loading = false)
    .catch(this.showError);
  };

  showError = (err: StdErr, actionName?: string) =>
    ui.showError({ err, actionName: actionName || UIText.generalError });

  handleProfileSaveClick = (event: any) => {
    preventDefaultStopProp(event);
    this._saveLoading = true;
    const { savedProfileIds, profileId } = this.controller;
    return (
      savedProfileIds.includes(profileId)
        ? this.controller.onProfileUnSave
        : this.controller.onProfileSave
    )()
    .catch(this.showError)
    .finally(() => this._saveLoading = false);
  };

  handleContactSubmit = (event: any) => {
    preventDefaultStopProp(event);
    if (this.submitting || this.submitted) return;
    this.submitting = true;
    return this.controller.onFormSubmit()
    .then(ok => {
      this.submitting = false;
      this.submitted = ok;
      if (ok) this.submissionMode = this.isEmailOnly;
    })
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleProfileModalClose = (event: any) => stateCtrl.closeMcbListingProfile();

  handleRatingClick = (event: any) => {
    preventDefaultStopProp(event);
    return when(() => !this.controller.ratingLoading)
    .then(() => this.showRating = !this.showRating);
  };

  handleRatingClose = (event: any) => {
    preventDefaultStopProp(event);
    return this.showRating = false;
  };

  handleLogin = () => {
    mcbSearchCtrl.setDoNotRestoreSearchForm(true);
    stateCtrl.mcbSignInFormOptions = {};
    stateCtrl.mcbSignInFormOpen = true;
  };

  handleLogout = () => {
    this._loading = true;
    this.submitted = this.submitting = false;
    this.submissionMode = undefined;
    return this.controller.onLogout()
    .then(this.controller.isReady)
    .then(() => this._loading = false)
    .catch(this.showError);
  };

  handleBooking = () => stateCtrl.showBookingForm(this.controller.profileId);

  handleChatClick = () => stateCtrl.marketResultsPopupChatHandler && stateCtrl.marketResultsPopupChatHandler(this.controller.profileId);

  render() {
    const {
      profile,
      profileForm,
      contactForm,
      serviceType,
      savedProfileIds,
      isCaregiver,
      isOwnProfile,
      shortlistingAvailable,
      defaultProfile,
      ratingLoading,
      hasInterview,
      achievement,
      adminLink,
      showChat,
      getProfileAvatarUri,
      getProfileAbilities,
      getProfileLanguages,
      findGroupRatingScore
    } = this.controller;

    const data = profile.data || {};

    const { yearOfExp, price } = getYoEAndPrice(data, isCaregiver);

    const { rating, samples } = findGroupRatingScore(profile);

    return <McbComponentPastry>
      <Styled styles={styles}>
        <div className={`flex column relative mcbListingProfile ${env}`}>
          {this.loading && <div className="flex justify-content-center align-items-center loading">
            <CircularProgress color="primary" />
          </div>}
          {ui.isMobile && this.isEmbedded && !this.loading && (
            <Button
              className="modalClose"
              variant="contained"
              color="secondary"
              disableElevation
              onClick={this.handleProfileModalClose}
            >
              <Close />&nbsp;{UIText.generalClose}&nbsp;{UIText.profile}
            </Button>
          )}
          <div className="relative mcbListingProfileHeader">
            {!this.loading && <ProfileCardWithDetails
              displayName={getDisplayNameEng(profile)}
              businessName={!isCaregiver && data.businessName}
              avatar={getProfileAvatarUri(profile)}
              yearOfExp={yearOfExp}
              price={price}
              isSaved={savedProfileIds.includes(profile.id)}
              isSaveLoading={this.saveLoading}
              shortlistingAvailable={shortlistingAvailable}
              abilities={getProfileAbilities(profile)}
              languages={getProfileLanguages(profile)}
              ratingLoading={ratingLoading}
              rating={rating}
              ratingSamples={samples}
              achievement={achievement}
              adminLink={adminLink}
              onSaveClick={this.handleProfileSaveClick}
              onChatClick={showChat && this.handleChatClick}
              onRatingClick={this.handleRatingClick}
              onCloseClick={this.handleProfileModalClose}
            />}
          </div>
          {!this.loading && <div className="flex relative mcbListingMain">
            <div className="flex column mcbListingProfileDetail">
              <ProfileDetail
                loading={this.loading}
                form={profileForm}
                profile={profile}
                isCaregiver={isCaregiver}
              />
            </div>
            <div className="flex column align-items-center relative mcbListingProfileSidebar">
              {!ui.isMobile && (
                <div className={`ratingDetail absolute fancyScroll ${this.showRating ? "visible" : ""}`}>
                  <Typography
                    className="flex align-items-center justify-content-end textUnderline textBold textUpperCase font-m closeRatingLink"
                    color="secondary"
                    onClick={this.handleRatingClose}
                  >
                    {UIText.closeRatingReviewDetailDesktop}
                    <ChevronRightSharp />
                  </Typography>
                  <ProfileRatingDetail
                    profileId={profile.id}
                    isCaregiver={isCaregiver}
                    getAvatarUri={getProfileAvatarUri}
                  />
                </div>
              )}
              <GetConnected
                className={this.showRating ? "hidden" : ""}
                loading={this.loading}
                isEmailOnly={this.submissionMode !== undefined ? this.submissionMode : this.isEmailOnly}
                submitting={this.submitting}
                submitted={this.submitted}
                hasInterview={hasInterview}
                serviceType={serviceType}
                form={contactForm}
                clientProfile={defaultProfile}
                onSubmit={this.handleContactSubmit}
                onLogin={this.handleLogin}
                onLogout={this.handleLogout}
                onBooking={isCaregiver && !isOwnProfile && this.handleBooking}
              />
            </div>
          </div>}

          {ui.isMobile && (
            <AlertBase
              container={this.container}
              isOpen={this.showRating}
              onClose={this.handleRatingClose}
              extraStyles={styles}
              zIndex={114514}
            >
              <ProfileRatingDetail
                profileId={profile.id}
                isCaregiver={isCaregiver}
                showNameAvatar
                showOverallRating
                getAvatarUri={getProfileAvatarUri}
                onClose={this.handleRatingClose}
              />
            </AlertBase>
          )}
        </div>
      </Styled>
    </McbComponentPastry>;
  };
}

export default withJssMap(McbListingProfile);