import { observer } from "mobx-react";
import React from "react";
import { computed, observable } from "mobx";
import { McbMarketCriteriaFormController } from "./controller";
import MarketplaceCriteria from "../../components/MarketplaceCriteria";
import { Styled } from "direflow-component/dist";
import styles from "./styles.css";
import { ui } from "../../client/ui";
import McbComponentPastry from "../../components/McbComponentPastry";
import withJssMap from "../../components/withJssMap";
import { UIText } from "../../client/lang";
import { stateCtrl } from "../../client/state";

@observer
class McbMarketCriteriaForm extends React.Component<{ criteriaHidden: boolean }> {
  controller: McbMarketCriteriaFormController = {} as McbMarketCriteriaFormController;
  container: HTMLDivElement;

  @observable _loading: boolean = true;
  @observable _criteriaHidden: boolean = false;

  @computed get loading(): boolean {
    return !this.controller.isLoggedIn || this._loading;
  };
  @computed get isMobile(): boolean {
    return stateCtrl.mcbMarketCriteriaFormUseMobile || ui.isMobile;
  }
  @computed get criteriaHidden(): boolean {
    return this.isMobile && this._criteriaHidden;
  };

  constructor(props) {
    super(props);
    this.controller = new McbMarketCriteriaFormController();
    this.controller.isReady().then(() => this._loading = false);
    this._criteriaHidden = props.criteriaHidden;
  };

  showError = err => ui.showError({ err, actionName: UIText.generalError });

  containerRef = elm => this.container = elm;

  toggleCriteriaHide = () => this._criteriaHidden = !this._criteriaHidden;

  render() {
    const {
      searchForm,
      criteriaFields,
    } = this.controller;

    return <McbComponentPastry>
      <Styled styles={styles}>
        <div className={`flex marketplaceCriteriaForm justify-content-center ${this.isMobile ? "column" : ""}`}>
          <MarketplaceCriteria
            hidden={this.criteriaHidden}
            loading={this.loading}
            form={searchForm}
            fields={criteriaFields}
            useMobile={stateCtrl.mcbMarketCriteriaFormUseMobile}
            // serviceField={serviceField}
            onShowHideCriteria={this.toggleCriteriaHide}
          />
        </div>
      </Styled>
    </McbComponentPastry>;
  }
}

export default withJssMap(McbMarketCriteriaForm);