import React from "react";
import styles from "./styles.css";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import ProfileUtilButtons, { ProfileUtilButtonsProps } from "../ProfileUtilButtons";
import { observer } from "mobx-react";
import { Styled } from "direflow-component/dist";
import { isEmpty } from "../../utils/helpers";
import { responsive } from "../../config/styles/responsive";
import { AssignmentInd, Business } from "@material-ui/icons";
import { UIText } from "../../client/lang";
import ProfileRatingDisplay, { workspacePremium } from "../ProfileRatingDisplay";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import BookmarkSharpIcon from "@material-ui/icons/BookmarkSharp";
import BookmarkBorderSharpIcon from "@material-ui/icons/BookmarkBorderSharp";
import { Achievement } from "../../mcb/lib/types/dataTypes";
import { achievementColors } from "../../mcb/config/constants";

export interface ProfileCardProps extends Partial<ProfileUtilButtonsProps> {
  displayName: string;
  businessName?: string;
  avatar?: string;
  intro?: string;
  yearOfExp?: number;
  price?: number;
  languages?: string[];
  abilities?: string[];
  shortlistingAvailable?: boolean;
  ratingLoading?: boolean;
  rating?: number;
  ratingSamples?: number;
  hasInterview?: boolean;
  hasInterviewLoading?: boolean;
  achievement?: Achievement;
  adminLink?: string;
  onRatingClick?: (event: any) => void;
}

const ProfileCard: React.FC<ProfileCardProps> = observer(
  ({
     displayName,
     businessName,
     avatar,
     intro,
     yearOfExp,
     price,
     languages,
     abilities,
     isSaved,
     saveLoading,
     shortlistingAvailable,
     ratingLoading,
     rating,
     ratingSamples,
     hasInterview,
     hasInterviewLoading,
     achievement,
     adminLink,
     isCaregiver,
     onSaveClick,
     onViewClick,
     onRatingClick,
     onChatClick,
     onBookingClick
   }) => {
    const isMobileIntermediate = responsive.deviceDimension.width < 1050;
    // const { isMobileIntermediate } = responsive.deviceDimension;
    intro = intro || "";
    const nameLength = ((displayName || "") + (businessName || "")).length;
    const dpNameDynamicFontSize = {
      ...nameLength >= 30 && { fontSize: "18px" },
      ...nameLength >= 47 && { fontSize: "16px" },
      ...nameLength >= 54 && { fontSize: "14.5px" },
    };
    const buttons = <ProfileUtilButtons
      buttons={[
        "view",
        (shortlistingAvailable && isMobileIntermediate) ? "save" : undefined,
        onBookingClick ? "book": undefined,
        onChatClick ? "chat" : undefined
      ]}
      isSaved={isSaved}
      isCaregiver={isCaregiver}
      saveLoading={saveLoading}
      bookingLoading={hasInterviewLoading}
      bookingHasInterview={hasInterview}
      onSaveClick={onSaveClick}
      onViewClick={onViewClick}
      onChatClick={onChatClick}
      onBookingClick={onBookingClick}
    />;
    const profileRatingDisplay = (
      <ProfileRatingDisplay
        achievement={achievement}
        loading={ratingLoading}
        samples={ratingSamples}
        rating={rating}
        onClick={onRatingClick}
      />
    );

    const achievementShadow = achievement?.achievementLevel ? {
      boxShadow: `0 0 0.4em 0 ${
        achievement.achievementLevel === "silver"
          ? "#aabcc9"
          : achievementColors[achievement.achievementLevel]
      }${achievement.achievementLevel === "silver" ? "" : "80"}`
    } : undefined;

    const achievementBackground = achievement?.achievementLevel ? {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${encodeURIComponent(
        `${achievementColors[achievement.achievementLevel]}26`
      )}">${workspacePremium}</svg>')`,
      backgroundPosition: isMobileIntermediate ? "105% -10px" : "120% -40px",
      backgroundRepeat: "no-repeat",
      backgroundSize: isMobileIntermediate ? "290px" : "360px"
    } : undefined;

    return <Styled styles={styles}>
      {!isMobileIntermediate ? (
        <div className="profileCard flex" style={achievementShadow}>
          <Avatar variant="square" alt={displayName} src={avatar} className="avatar" >
            {displayName.charAt(0)}
          </Avatar>
          <div className="info flex column justify-content-center" style={achievementBackground}>
            <div className="flex cardRow justify-content-between">
              <div className="flex align-items-center textBold displayName" style={dpNameDynamicFontSize}>
                {displayName}{businessName && <>&nbsp;|&nbsp;<Business />&nbsp;{businessName}</>}
                <Tooltip title={isSaved ? UIText.profileUtilUnSaveButton(isCaregiver) : UIText.profileUtilSaveButton(isCaregiver)} PopperProps={{ style: { zIndex: 114515 } }}>
                  <IconButton className="saveIconButton" color={isSaved ? "primary" : undefined} onClick={onSaveClick} disabled={saveLoading}>
                    {saveLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      isSaved ? <BookmarkSharpIcon /> : <BookmarkBorderSharpIcon />
                    )}
                  </IconButton>
                </Tooltip>
                {adminLink && (
                  <Tooltip title="View in Admin Centre" PopperProps={{ style: { zIndex: 114515 } }}>
                    <IconButton className="saveIconButton" color="secondary" onClick={() => window.open(adminLink, "_blank")}>
                      <AssignmentInd />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <div className="flex chips align-items-center justify-content-end">
                {!isEmpty(languages) && languages.slice(0, 2).map(lang => <Chip key={lang} label={lang} />)}
                {languages.length > 2 && <Chip label={`+ ${languages.length - 2}`} />}
              </div>
            </div>

            <div className="flex align-items-center">
              <>{profileRatingDisplay}{!!yearOfExp && <>&nbsp;|&nbsp;</>}</>
              {!!yearOfExp && <div className="yearOfExp" style={{ paddingLeft: 0 }}>{UIText.profileYoE(yearOfExp)}</div>}
            </div>
            {!isNaN(Number(price)) && !!Number(price) && <div className="textBold price">${price}/hr</div>}
            {/*TODO: line-clamp*/}
            <div className="intro">{intro.substr(0, 180).concat(intro.length > 180 ? "..." : "")}</div>

            <div className="flex justify-content-between align-items-center">
              {buttons}
              <div className="flex chips">
                {!isEmpty(abilities) && abilities.map(ability => <Chip key={ability} label={ability} />)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="profileCard flex column" style={achievementShadow}>
          <div className="flex justify-content-between avatarRow">
            <Avatar variant="square" alt={displayName} src={avatar} className="avatar">
              {displayName.charAt(0)}
            </Avatar>
            <div className="flex column justify-content-evenly align-items-center">
              <div className="flex align-items-center displayName textBold">
                {displayName}
                <Tooltip title={isSaved ? UIText.profileUtilUnSaveButton(isCaregiver) : UIText.profileUtilSaveButton(isCaregiver)} PopperProps={{ style: { zIndex: 114515 } }}>
                  <IconButton className="saveIconButton" color={isSaved ? "primary" : undefined} onClick={onSaveClick} disabled={saveLoading}>
                    {saveLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      isSaved ? <BookmarkSharpIcon /> : <BookmarkBorderSharpIcon />
                    )}
                  </IconButton>
                </Tooltip>
                {adminLink && (
                  <Tooltip title="View in Admin Centre" PopperProps={{ style: { zIndex: 114515 } }}>
                    <IconButton className="saveIconButton" color="secondary" onClick={() => window.open(adminLink, "_blank")}>
                      <AssignmentInd />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              {businessName && <div className="flex cardRow align-items-center justify-content-center font-s textBold businessName">
                <Business />&nbsp;{businessName}
              </div>}
              <div className="flex chips align-items-center justify-content-end">
                {!isEmpty(languages) && languages.slice(0, 1).map(lang => <Chip key={lang} label={lang} />)}
                {languages.length > 1 && <Chip label={`+ ${languages.length - 1}`} />}
              </div>
            </div>
          </div>
          <div className="info flex column justify-content-center pSans" style={achievementBackground}>
            {profileRatingDisplay}
            {!!yearOfExp && <div className="yearOfExp">{UIText.profileYoE(yearOfExp)}</div>}
            <div className="textBold price">${price}/hr</div>
            {/*TODO: line-clamp*/}
            <div className="intro">
              {intro
              .replace(/\r?\n|\r/g, " ")
              .substr(0, 180)
              .concat(intro.length > 180 ? "..." : "")}
            </div>

            <div className="flex chips align-items-center" style={{ marginLeft: "5px" }}>
              {!isEmpty(abilities) && abilities.map(ability => <Chip key={ability} label={ability} />)}
            </div>

            <div className="flex justify-content-between align-items-center cardButtons">
              {buttons}
            </div>
          </div>
        </div>
      )}
    </Styled>;
  });

export default ProfileCard;
