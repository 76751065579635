import { observer } from "mobx-react";
import React from "react";
import { FormFieldCommonProps } from "../../../../lib/types/propTypes";
import { TextField } from "@material-ui/core";

export interface InputFieldProps extends FormFieldCommonProps {
  multiline?: boolean;
  secured?: boolean;
  alwaysShowPlaceholder?: boolean;
}

const Input: React.FC<InputFieldProps> = observer(
  ({
     name,
     required,
     placeholder,
     alwaysShowPlaceholder,
     helperText,
     errorMessage,
     value,
     multiline,
     secured,
     maxLength,
     minLength,
     disabled,
     onChange,
     onBlur
   }) => {
    // placeholder = (minLength || maxLength)
    //   ? `${placeholder || ""} (${minLength || 0} ~ ${maxLength || "*"})` // Textarea mode
    //   : placeholder; // Input mode

    placeholder = (disabled && !alwaysShowPlaceholder) ? "—" : placeholder;

    return <TextField
      color="secondary"
      required={required}
      label={placeholder}
      variant="outlined"
      name={name}
      error={!!errorMessage}
      type={secured ? "password" : undefined}
      multiline={multiline}
      rows={multiline && 4}
      helperText={helperText || errorMessage}
      value={value || ""}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
    />;
  });

export default Input;