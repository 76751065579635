import React from "react";
import { observer } from "mobx-react";
import { Styled } from "direflow-component/dist";
import { Typography } from "@material-ui/core";
import { UIText } from "../../client/lang";
import styles from "./styles.css";
import { FormLegacy } from "../../client/form.legacy";
import { isEmpty } from "../../utils/helpers";
import { Profile } from "../../lib/types/dataTypes";
import { Check } from "@material-ui/icons";

export interface ProfileDetailProps {
  loading: boolean;
  form: FormLegacy;
  profile: Profile;
  isCaregiver?: boolean;
}

// const matchRenderTels = (string: string) => {
//   const phoneRegex = /\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})/g;
//   const matches = string.match(phoneRegex);
//   if (matches) for (const match of matches) {
//     string = string.replace(match, `<a href="tel:${match}">${match}</a>`);
//   }
//   return <Typography
//     className="font-xs"
//     style={!!matches ? { whiteSpace: "nowrap" } : undefined}
//     dangerouslySetInnerHTML={{ __html: string }}
//   />;
// };

const matchRenderUrl = (string: string) => {
  const urlRegex = /(https?:\/\/(www\.)?)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
  const matches = string.match(urlRegex);
  if (matches) for (const match of matches) {
    string = string.replace(match, (
      `<a target="_blank" rel="noopener noreferer" href="//${match.replace(/(http|https):\/\//g, "")}">${match}</a>`)
    );
  }
  return <Typography
    className="font-xs"
    dangerouslySetInnerHTML={{ __html: string }}
  />;
};

const ProfileDetail: React.FC<ProfileDetailProps> = observer(
  ({
     loading,
     form,
     profile,
     isCaregiver
   }) => {
    if (loading || isEmpty(form) || !form.ready || isEmpty(profile)) return null;
    const data = profile.data || {};
    const ableTasks = Object.keys(data)
    .filter(key => key.match(/task/ig))
    .map(key => !!data[key] && key)
    .filter(Boolean);
    const ableConditions = Object.keys(data)
    .filter(key => key.match(/conditions/ig))
    .map(key => !!data[key] && key)
    .filter(Boolean);
    const skillsAndCapabilities = (data.skillsAndCapabilities || "")
    .split(",")
    .filter(Boolean);
    const introduction = data.introduction || data.oldIntroduction;

    return <Styled styles={styles}>
      <div className="profileDetail flex column">
        {/*{!isCaregiver && (!!data.email || !!data.phone) && <div className="flex unitRow contactRow justify-content-between">*/}
        {/*  {!!data.email && <div className="flex column justify-content-center align-items-start about">*/}
        {/*    <Typography className="textBold font-m">*/}
        {/*      {form.getRenderedField("email").placeholder}*/}
        {/*    </Typography>*/}
        {/*    <a href={`mailto:${data.email}`}>*/}
        {/*      <Typography className="font-xs">*/}
        {/*        {data.email}*/}
        {/*      </Typography>*/}
        {/*    </a>*/}
        {/*  </div>}*/}
        {/*  {!!data.phone && <div className="flex column justify-content-center align-items-end about">*/}
        {/*    <Typography className="textBold font-m">*/}
        {/*      {form.getRenderedField("phone").placeholder}*/}
        {/*    </Typography>*/}
        {/*    {matchRenderTels(data.phone)}*/}
        {/*  </div>}*/}
        {/*</div>}*/}
        {/*{(data.introduction || data.unavailable) && (*/}
        {(introduction || data.unavailable) && (
          <div className="flex unitRow detailRow">
            {introduction && (
              <div className="flex column justify-content-center align-items-start about">
                <Typography className="textBold font-m">
                  {form.getRenderedField("introduction").placeholder}:&nbsp;
                </Typography>
                {matchRenderUrl(introduction)}
              </div>
            )}
            {/*{data.unavailable && (*/}
            {/*  <div className="flex column justify-content-center align-items-start unavail">*/}
            {/*    <Typography className="textBold font-m">*/}
            {/*      {form.getRenderedField("unavailable").placeholder}:&nbsp;*/}
            {/*      /!*{UIText.profileYoE}:&nbsp;*!/*/}
            {/*    </Typography>*/}
            {/*    <Typography>*/}
            {/*      {data.unavailable}*/}
            {/*      /!*{data.yearsOfExperienceAsCaregiver}*!/*/}
            {/*    </Typography>*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        )}
        {isCaregiver && <div className="flex column unitRow tasksRow">
          <Typography className="textBold font-m">
            {UIText.profileAbleTasks}:&nbsp;
          </Typography>
          <div className="flex tickBoxes">
            {ableTasks.map(key => {
              const { placeholder } = form.getField(key) || {};
              return placeholder && <Typography key={key} className="flex align-items-center font-xs tickBox">
                <Check />{placeholder}
              </Typography>
            })}
          </div>
        </div>}
        {isCaregiver && <div className="flex column unitRow conditionsRow">
          <Typography className="textBold font-m">
            {UIText.profileAbleConditions}:&nbsp;
          </Typography>
          <div className="flex tickBoxes">
            {ableConditions.map(key => {
              const { placeholder } = form.getField(key) || {};
              return placeholder && <Typography key={key} className="flex align-items-center font-xs tickBox">
                <Check />{placeholder}
              </Typography>
            })}
          </div>
        </div>}
        {!isCaregiver && <div className="flex column unitRow skillsRow">
          <Typography className="textBold font-m">
            {form.getRenderedField("skillsAndCapabilities").placeholder}:&nbsp;
          </Typography>
          <div className="flex tickBoxes">
            {skillsAndCapabilities.map(skill => (
              <Typography key={skill} className="flex align-items-center font-xs tickBox">
                <Check />{skill.trim()}
              </Typography>
            ))}
          </div>
        </div>}
        {!isCaregiver && <div className="flex column unitRow serviceOffersRow">
          <div className="flex column justify-content-center align-items-start about">
            <Typography className="textBold font-m">
              {form.getRenderedField("servicesOffered").placeholder}:&nbsp;
            </Typography>
            <Typography className="font-xs">
              {data.servicesOffered}
            </Typography>
          </div>
        </div>}
      </div>
    </Styled>;
  });

export default ProfileDetail;
