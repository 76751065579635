import { Observer, observer } from "mobx-react";
import React from "react";
import styles from "./styles.css";
import { FormLegacy } from "../../client/form.legacy";
import { Accordion, AccordionSummary, CircularProgress, Typography } from "@material-ui/core";
import FormField from "../FormLegacy/FormFields";
import { getEventRealValue } from "../../utils/helpers";
import { withStyles } from "direflow-component";
import { ExpandMore } from "@material-ui/icons";
import { computed, observable } from "mobx";
import { TypeClassField } from "../../lib/types/formTypes.legacy";

@observer
class Field extends React.Component<{ field: TypeClassField; formSet: FormLegacy["set"]; formValidate: FormLegacy["validate"]}> {
  handleChange = event => {
    const { field, formSet } = this.props;
    const { name } = event.target;
    const fieldValue = getEventRealValue(event);
    if (fieldValue === field.value) return;
    formSet(name, fieldValue);
  };
  validateField = event => {
    const { field, formValidate } = this.props;
    const { name } = event.target;
    if (!field || field.disabled) return;
    return formValidate(name, name === "repeat").catch(console.warn);
  };
  render() {
    const { field } = this.props;
    return <FormField
      field={field}
      disabled={field.disabled}
      onChange={this.handleChange}
      onBlur={this.validateField}
    />;
  };
}

@observer
class FieldSet extends React.Component<{
  setName: string;
  title: string;
  fields: TypeClassField[];
  expanded: boolean;
  onExpand: (event: any) => void;
  formSet: FormLegacy["set"];
  formValidate: FormLegacy["validate"];
  fieldRef: GenericFormProps["fieldRef"];
}> {
  @computed get fields(): TypeClassField[] {
    return this.props.fields.filter(field => field.set === this.props.setName);
  };
  renderFields = (fields: TypeClassField[], formSet: FormLegacy["set"], formValidate: FormLegacy["validate"]) => (
    <Observer>{() => <>
      {fields.map(field => (
        <div key={field.name} ref={elm => this.props.fieldRef(elm, field)}>
          <Field
            field={field}
            formSet={formSet}
            formValidate={formValidate}
          />
        </div>
      ))}
    </>}</Observer>
  );
  render() {
    const { title, expanded, onExpand, formSet, formValidate } = this.props;
    return <Accordion elevation={0} expanded={expanded} onChange={onExpand} square>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className="font-xs textBold" gutterBottom>
          {title}
        </Typography>
      </AccordionSummary>
      <div className="flex column">
        {this.renderFields(this.fields, formSet, formValidate)}
      </div>
    </Accordion>;
  };
}


export interface GenericFormProps {
  loading?: boolean;
  form: FormLegacy;
  fieldRef?: (field: HTMLDivElement, formField: TypeClassField<any>) => void;
}

@observer
class GenericForm extends React.Component<GenericFormProps> {
  @observable expandedSets = {};

  @computed get expansion() {
    return this.expandedSets;
  };

  handleSetExpand = (e, set) => this.expansion[set] = !this.expansion[set];

  render() {
    const { loading, form, fieldRef } = this.props;

    return <form className="form flex column genericForm">
      {loading && <div className="flex column max justify-content-center align-items-center">
        <CircularProgress size={30} />
      </div>}
      {!loading && form.renderable.map(field => (
        <Observer key={field.name}>{() => {
          const { set, title } = field;
          const isInSet = !!field.set && !field.title && form.renderable.some(f => f.set === field.set);
          if (set && !isInSet) {
            return <FieldSet
              fieldRef={fieldRef}
              setName={set}
              title={title}
              fields={form.renderable}
              expanded={!this.expansion[set]}
              onExpand={e => this.handleSetExpand(e, set)}
              formSet={form.set}
              formValidate={form.validate}
            />;
          }
          return !isInSet && <div ref={elm => this.props.fieldRef(elm, field)}>
            <Field
              field={field}
              formSet={form.set}
              formValidate={form.validate}
            />
          </div>
        }}</Observer>)
      )}
    </form>;
  }
}

export default withStyles(styles)(GenericForm);