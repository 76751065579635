import { Controller } from "../../lib/controller";
import { computed, IObservableArray, observable, reaction, when } from "mobx";
import { Appointment } from "../../mcb/lib/types/dataTypes";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { client } from "../../client/client";
import { stateCtrl } from "../../client/state";
import { UIText } from "../../client/lang";
import { api } from "../../client/api";
import { endpointConfig } from "../../config/api";
import { arrayFlat, asyncPause, getBrowserTimeZoneName, isEmpty } from "../../utils/helpers";
import { getOrganizerGroupByAppointment, getProviderGroupByAppointment } from "../../mcb/lib/appointment-utilities";
import { Group, Profile } from "../../lib/types/dataTypes";
import { computedFn } from "mobx-utils";
import { fileCtrl } from "../../client/file";

export class McbAppointmentListController extends Controller {
  @observable ready: boolean;
  @observable signInBoxClosed: boolean;

  @observable appointments: IObservableArray<Appointment> = observable([]);
  @observable providerGroups: IObservableArray<{ appointmentId: number, group: Group }> = observable([]);
  @observable organizerGroups: IObservableArray<{ appointmentId: number, group: Group }> = observable([]);

  @computed get userGroups(): Group[] {
    return client.findVisibleGroups();
  };
  @computed get currentGroupId(): number {
    return stateCtrl.appointmentListContextGroupId;
  };
  @computed get currentGroup(): Group {
    return client.findGroupById(this.currentGroupId);
  };
  @computed get timezone(): string {
    return this.currentGroup?.profile?.data?.timezone || getBrowserTimeZoneName();
  };

  constructor() {
    super();
    this.disposers.push(this.listenStateCtrlContextGroup());
    this.disposers.push(this.onCloseAutoRefresh());
  }

  listenStateCtrlContextGroup = () => reaction(
    () => this.currentGroup,
    () => this.getAppointments()
  );

  onCloseAutoRefresh = () => reaction(
    () => stateCtrl.appointmentManagerId,
    () => !stateCtrl.appointmentManagerId && this.getAppointments()
  );

  loadAllData = async () => {
    await when(() => !mcbSessionCtrl.runningAuthChangeSequence);
    await client.isLoggedInAndReady();

    if (mcbSessionCtrl.isValidVisitor) {
      stateCtrl.mcbSignInFormOpen = true;
      stateCtrl.mcbSignInFormOptions.subHeading = UIText.signInToSeeAppointment(true);
      stateCtrl.mcbSignInFormOptions.onClose = () => {
        stateCtrl.mcbSignInFormOptions.subHeading = null;
        stateCtrl.mcbSignInFormOptions.onClose = null;
        this.signInBoxClosed = true;
      };
      await when(() => !mcbSessionCtrl.isValidVisitor || this.signInBoxClosed);
      if (this.signInBoxClosed) return Promise.resolve();
      this.signInBoxClosed = false;
    }

    await when(() => !isEmpty(this.userGroups));

    if (!this.currentGroupId || isEmpty(this.currentGroup)) {
      stateCtrl.setAppointmentListContextGroup((this.userGroups[0] || {}).id);
    }

    this.ready = true;

    return this.getAppointments().then(() => setTimeout(this.monitorLoginState));
  };

  onGroupChange = async (groupId: number) => {
    if (!groupId) return;
    if (this.currentGroupId === groupId) return;
    return stateCtrl.setAppointmentListContextGroup(groupId);
  };

  getAppointments = async () => {
    if (!this.ready) return;
    if (!this.currentGroupId) return;
    return api.GET(endpointConfig.get_appointments_by_group_id(this.currentGroupId))
    .then(response => response.data)
    .then(appointments => this.appointments.replace(appointments))
    .then(this.populateAdditionalData);
  };

  populateAdditionalData = async () => Promise.all(this.appointments.map(appointment => arrayFlat([
    this.getProviderGroupByAppointment(appointment),
    this.getOrganizerGroupByAppointment(appointment)
  ])));

  getProviderGroupByAppointment = async (appointment: Appointment) => {
    if (isEmpty(appointment)) return;
    return getProviderGroupByAppointment(appointment)
    .then(group => this.providerGroups.push({
      appointmentId: appointment.id,
      group
    }));
  };

  getOrganizerGroupByAppointment = async (appointment: Appointment) => {
    if (isEmpty(appointment)) return;
    return getOrganizerGroupByAppointment(appointment.id)
    .then(group => this.organizerGroups.push({
      appointmentId: appointment.id,
      group
    }));
  };

  findProviderGroup = computedFn((appointment: Appointment) =>
    appointment && (this.providerGroups.find(pg => pg.appointmentId === appointment.id) || {}).group);

  findOrganizerGroup = computedFn((appointment: Appointment) =>
    appointment && (this.organizerGroups.find(pg => pg.appointmentId === appointment.id) || {}).group);

  findSelfAttendeeMember = computedFn((appointment: Appointment) => {
    if (isEmpty(appointment)) return;
    const { attendees } = appointment;
    const selfMembers = client.findMembers(m => m.userId === client.userId);
    let selfMember;
    const selfAttendee = (attendees || []).find(at => {
      const member = selfMembers.find(m => at.memberId === m.id);
      if (member) selfMember = member;
      return !!member;
    });
    return { selfMember, selfAttendee };
  });

  selfIsProvider = computedFn((appointment: Appointment) => {
    const providerGroup = this.findProviderGroup(appointment);
    const { selfMember } = this.findSelfAttendeeMember(appointment);
    return selfMember && providerGroup && providerGroup.id === selfMember.groupId;
  });

  getPartyAvatar = computedFn((profile: Profile, group: Group) =>
    fileCtrl.getProfileAvatarUri(profile?.data?.avatar, group?.id, "group"));

  monitorLoginState = async () => {
    while (!mcbSessionCtrl.isValidVisitor) {
      await asyncPause(1000);
    }
    this.ready = false;
    stateCtrl.closeAppointmentManagerEntry();
    this.appointments.clear();
    return this.loadAllData();
  };
}