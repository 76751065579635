import { Controller } from "../../lib/controller";
import { autorun, computed, observable, reaction, toJS, when } from "mobx";
import { Group, Member, Profile } from "../../lib/types/dataTypes";
import { McbSearchController, mcbSearchCtrl } from "../../mcb/client/search";
import { client } from "../../client/client";
import { FormLegacy } from "../../client/form.legacy";
import { contactForm, ContactFormData } from "../../mcb/config/contactForm";
import { asyncPause, contextReject, getDisplayNameEng, isEmpty, safeParseJSON } from "../../utils/helpers";
import { api } from "../../client/api";
import { endpointConfig } from "../../config/api";
import { Achievement, AggregatedRatingReviewResponseDTO, LeadTopicData, SearchResult } from "../../mcb/lib/types/dataTypes";
import { Topic } from "../../lib/types/topicTypes";
import { groupTypeIds, topicTypeIds, webAppUrl } from "../../mcb/config/constants";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { removeLastName } from "../../mcb/lib/common";
import { typeClassIds } from "../../config/constants";
import { JSONString } from "../../lib/types/miscTypes";
import { topicCtrl } from "../../client/topic";
import { env } from "../../config/env";
import { UIException, UIText } from "../../client/lang";
import { stateCtrl } from "../../client/state";

export class McbListingProfileController extends Controller {
  leadTrackingDebounceTime = 300;
  leadTrackingDebouncer;

  loadingRetryCount: number = 0;

  @observable creatingLead: boolean = false;

  @observable group: Group = {} as Group;
  @observable profile: Profile = {} as Profile;
  @observable memberProfile: Profile;

  contactForm: FormLegacy<ContactFormData> = {} as FormLegacy<ContactFormData>;
  leadTopic: Topic = {} as Topic;

  @computed get finderContextGroup(): Group {
    return client.findGroupById(stateCtrl.finderContextGroupId);
  };
  @computed get finderContextIsMCBStaff(): boolean {
    return this.finderContextGroup.typeId === groupTypeIds.myCareBaseStaff;
  };
  @computed get finderContextGroupSelfMember(): Member {
    return this.finderContextGroup.members?.find(m => m.userId === client.userId);
  };
  @computed get selfMember(): Member {
    return stateCtrl.finderContextGroupId ? this.finderContextGroupSelfMember : mcbSearchCtrl.member;
  };

  @computed get groupId(): Group["id"] {
    return mcbSearchCtrl.viewGroupId;
  };
  @computed get profileId(): Profile["id"] {
    return this.group && this.group.profileId;
  };
  @computed get profileForm(): FormLegacy {
    return mcbSearchCtrl.caregiverForm || {} as FormLegacy;
  };
  @computed get availableServices(): McbSearchController["availableServices"] {
    return mcbSearchCtrl.availableServices || [];
  };
  @computed get serviceType(): McbSearchController["selectedService"] {
    return this.group.typeId;
  };
  @computed get selectedServiceName(): string {
    return (this.availableServices.find(s => s.name === this.serviceType) || {}).placeholder;
  };
  @computed get isCaregiver(): boolean {
    return this.serviceType === groupTypeIds.caregiver;
  };
  @computed get isOwnProfile(): boolean {
    return !isEmpty(client.findGroupById(this.groupId));
  };
  @computed get allResultGroups(): SearchResult[] | Group[] {
    return [
      ...mcbSearchCtrl.searchResults,
      ...mcbSearchCtrl.candidateGroups
    ];
  };
  @computed get candidateTopics(): Topic[] {
    return mcbSearchCtrl.candidateTopics || [];
  };
  @computed get candidateProfiles(): Profile[] {
    const groups = this.allResultGroups.filter(g => this.candidateTopics.some(t => t.groupIdList && t.groupIdList.includes(g.id)));
    return groups.map(group => group.profile);
  };
  @computed get savedProfileIds(): Profile["id"][] {
    if (stateCtrl.finderContextGroupId) return stateCtrl.marketResultsPopupSavedProfileIds;
    return this.candidateProfiles.map(profile => profile.id);
  };

  @computed get isCheckingShortlists(): boolean {
    return !!mcbSearchCtrl.isCheckingShortlists[mcbSearchCtrl.scratchpadGroup?.id];
  };
  @computed get isCheckingCandidates(): boolean {
    return mcbSearchCtrl.isCheckingCandidates;
  };
  @computed get shortlistingAvailable(): boolean {
    return mcbSearchCtrl.shortlistingAvailable;
  };

  @computed get isEmailOnly(): boolean {
    return !mcbSessionCtrl.isValidVisitor;
  };
  @computed get defaultProfile(): Profile {
    return client.defaultProfile;
  };

  @computed get loading(): boolean {
    return mcbSessionCtrl.runningAuthChangeSequence
      || !mcbSearchCtrl.ready
      || !this.contactForm.ready
      || !client.initialized;
  };

  @computed get rating(): AggregatedRatingReviewResponseDTO {
    return mcbSearchCtrl.findAggregatedRatingForGroup(this.group);
  };
  @computed get ratingLoading(): boolean {
    return isEmpty(this.rating) || mcbSearchCtrl.ratingLoading;
  };

  @computed get hasInterview(): boolean {
    const data = mcbSearchCtrl.findHasInterviewAppointmentData(
      mcbSearchCtrl.scratchpadGroup.id,
      this.groupId
    );
    return data?.hasInterview;
  };

  @computed get achievement(): Achievement {
    return mcbSearchCtrl.findProfileAchievement(this.profile);
  };

  @computed get adminLink(): string {
    if (!this.finderContextIsMCBStaff) return "";
    return `${webAppUrl}/AdminCentre?type=${this.group.typeId === groupTypeIds.caregiver ? "3" : "providers"}&profile=${this.profile.id}`;
  };

  @computed get showChat(): boolean {
    return mcbSessionCtrl.isEmbedded
      && stateCtrl.finderContextGroupId
      && stateCtrl.marketResultsPopupSavedProfileIds
      && stateCtrl.marketResultsPopupSavedProfileIds.includes(this.profileId);
  };

  constructor() {
    super();
    this.resetContactForm();
    this.disposers = [
      // reaction(() => this.groupId, this.loadAllData),
      reaction(() => this.isEmailOnly, () => {
        this.resetContactForm();
        return this.isUserAndPrePopulate();
      }),
      reaction(() => this.isEmailOnly, this.createLeadTracking, { delay: 100 }),
      autorun(this.autoHideReference)
    ];
  }


  initialize = async () =>
    this.loadAllData()
    .then(this.createLeadTracking)
    .catch(this.retry);

  loadAllData = async () =>
    this.getGroupById()
    .then(this.getProfileById)
    .then(this.getRatingForGroup)
    .then(this.getAchievement)
    .then(this.getHasInterviewAppointmentData)
    .then(this.isUserAndPrePopulate);

  isReady = async () => when(() =>
    mcbSearchCtrl.ready &&
    this.contactForm.ready &&
    !isEmpty(this.group) &&
    !isEmpty(this.profile)
  );

  retry = async (err: Error) => {
    if (this.loadingRetryCount >= 3) throw err;
    await asyncPause(2000);
    this.loadingRetryCount++;
    return this.loadAllData()
    .then(this.createLeadTracking)
    .catch(this.retry);
  };


  findGroupRatingScore = (profile: Profile) => mcbSearchCtrl.findGroupRatingScore(this.group);

  resetContactForm = () => this.contactForm = new FormLegacy(contactForm(!this.isEmailOnly));

  getGroupById = async () => {
    await client.isLoggedInAndReady();
    if (!this.groupId) return;
    return client.getGroupById(this.groupId)
    .then(group => this.group = group);
  };

  getProfileById = async () => {
    await client.isLoggedInAndReady();
    if (!this.profileId) return;
    return client.getProfileById(this.profileId)
    .then(this.checkIsApprovedProfile)
    .then(profile => profile && (this.profile = removeLastName(profile)));
  };

  getRatingForGroup = () => setTimeout(() => mcbSearchCtrl.getRatingForGroup(this.group));

  getAchievement = () => setTimeout(() => mcbSearchCtrl.getAchievementForGroup(this.group));

  getHasInterviewAppointmentData = () => setTimeout(() => mcbSearchCtrl.getHasInterviewAppointmentDataForGroup({
    organizerGroupId: mcbSearchCtrl.scratchpadGroup.id,
    providerGroupId: this.groupId
  }));

  checkIsApprovedProfile = async profile => {
    if (isEmpty(profile)) return;
    const data = typeof profile.data === "string" ? safeParseJSON(profile.data) : profile.data;
    if (!data["approvalOfCandidateConfirmedByReference"]) {
      setTimeout(stateCtrl.closeMcbListingProfile);
      return null;
    }
    return profile;
  };

  isUserAndPrePopulate = async () => {
    if (!this.isEmailOnly) return;
    const contactFormLock = (disabled: boolean) => {
      this.contactForm.setField("firstName", { disabled });
      this.contactForm.setField("lastName", { disabled });
      this.contactForm.setField("phone", { disabled });
      this.contactForm.setField("email", { disabled });
      this.contactForm.setField("needs", { disabled });
    };
    contactFormLock(true);
    this.contactForm.set("firstName", UIText.wpAccountAutoLoginPleaseWait);
    this.contactForm.set("lastName", "...");
    this.contactForm.set("phone", "...");
    this.contactForm.set("email", "...");
    await client.isLoggedInAndReady();
    const member = this.selfMember;
    this.memberProfile = member?.profileId && await client.getProfileById(member.profileId);
    const firstName = this.memberProfile?.data?.firstName || client.defaultProfile?.data?.firstName;
    const lastName = this.memberProfile?.data?.lastName || client.defaultProfile?.data?.lastName;
    const phone = this.memberProfile?.data?.phone || client.defaultProfile?.data?.phone;
    const email = client.defaultProfile?.data?.email;
    contactFormLock(false);
    this.contactForm.set("firstName", firstName);
    this.contactForm.set("lastName", lastName);
    this.contactForm.set("phone", phone);
    this.contactForm.setField("email", {
      value: email,
      disabled: true
    });
    return this.contactForm.resetDirty();
  };

  autoHideReference = () => this.contactForm.ready && this.contactForm.setField("reference", {
    disabled: this.group.typeId !== groupTypeIds.caregiver,
    hidden: this.group.typeId !== groupTypeIds.caregiver
  });

  getProfileAvatarUri = (profile: Profile) => mcbSearchCtrl.getProfileAvatarUri(profile);

  getProfileAbilities = profile => mcbSearchCtrl.getProfileAbilities(profile);

  getProfileLanguages = profile => mcbSearchCtrl.getProfileLanguages(profile);

  createLeadTracking = () => {
    clearTimeout(this.leadTrackingDebouncer);
    this.leadTrackingDebouncer = setTimeout(async () => {
      const done = () => this.creatingLead = false;
      if (this.creatingLead) return;
      this.creatingLead = true;
      await when(() => !isEmpty(this.selfMember));
      if (this.leadTopic.creatorMemberId === this.selfMember.id) return done();
      const group = stateCtrl.finderContextGroupId ? this.finderContextGroup : toJS(mcbSearchCtrl.scratchpadGroup);
      const shouldUpdateLead = !mcbSessionCtrl.isValidVisitor && !isEmpty(this.leadTopic);
      const data: JSONString = shouldUpdateLead
        ? JSON.stringify({
          linkedLeadTopicId: this.leadTopic.id,
          includesProfileView: false
        } as Partial<LeadTopicData>)
        : !mcbSessionCtrl.isValidVisitor
          ? JSON.stringify({
            includesProfileView: true
          } as Partial<LeadTopicData>)
          : "{}";
      const previousTopicId = !mcbSessionCtrl.isValidVisitor && this.leadTopic.id;
      return api.POST({
        endpoint: endpointConfig.create_topic,
        data: {
          currentGroupId: group.id || undefined,
          currentMemberId: this.selfMember.id,
          otherGroupIdList: [this.group.id],
          topic: {
            typeId: topicTypeIds.lead,
            creatorMemberId: this.selfMember.id,
            description: "Lead",
            data,
            onCalendar: 0,
            isTemplate: 0,
            isParentTemplate: 0,
            isCompleted: 0,
            isDataLocked: 0,
            isLocked: 0,
            typeClassId: typeClassIds.leadTopic.v1.id,
            typeClassVersion: typeClassIds.leadTopic.v1.version // Default for now.
          }
        }
      })
      .then(response => this.leadTopic = response && response.data)
      .then(() => shouldUpdateLead && this.updateVisitorLinkedLead(previousTopicId))
      .finally(() => this.creatingLead = false);
    }, this.leadTrackingDebounceTime);
  };

  updateVisitorLinkedLead = async (previousTopicId: number) => {
    if (!previousTopicId) return;
    const previousTopic = await topicCtrl.getTopicById(previousTopicId).catch(contextReject);
    if (isEmpty(previousTopic)) return;
    const data: JSONString = JSON.stringify({
      ...previousTopic.data,
      linkedLeadTopicId: this.leadTopic.id
    } as Partial<LeadTopicData>);
    return api.PATCH({
      endpoint: endpointConfig.topic_by_id(previousTopicId, undefined),
      data: { data }
    });
  };

  onProfileSave = async () => {
    if (stateCtrl.finderContextGroupId) return stateCtrl.onMarketResultsPopupSave(null, this.profileId);

    return mcbSearchCtrl.addToShortlist(this.groupId);
  };

  onProfileUnSave = async () => {
    if (stateCtrl.finderContextGroupId) return stateCtrl.onMarketResultsPopupSave(null, this.profileId);

    const topic = this.candidateTopics.find(t => t.groupIdList.includes(this.groupId));
    if (!topic) return;
    return mcbSearchCtrl.removeFromShortlist(topic.id);
  };

  // onContactSubmit = async () => {
  //   const data: ContactFormData & {
  //     leadType?: string;
  //     groupName?: string;
  //     groupId?: number;
  //     envString?: string;
  //   } = toJS(this.contactForm.data);
  //   if (isEmpty(data)) return;
  //   data.leadType = this.selectedServiceName;
  //   data.groupName = getDisplayNameEng(this.profile);
  //   data.groupId = this.groupId;
  //   if (env !== "prod") data.envString = env.toUpperCase();
  //   return api.POST({
  //     endpoint: endpointConfig.submit_get_connected,
  //     data: {
  //       ...data,
  //       isTesting: env === "dev" || env === "local" || (data.needs || "").match(/\[devTest]/g)
  //     }
  //   });
  // };

  onFormSubmit = async () => {
    const valid = await this.contactForm.validate(null, true);
    if (!valid) return false;
    if (isEmpty(this.leadTopic)) return Promise.reject(new UIException("MISSING_LEAD_TOPIC"));
    const myGroup = stateCtrl.finderContextGroupId ? this.finderContextGroup : toJS(mcbSearchCtrl.scratchpadGroup);
    if (isEmpty(myGroup)) {
      if (!this.isEmailOnly) return Promise.reject(new UIException("MISSING_FORM_SUBMITTER_GROUP"));
      return mcbSearchCtrl.searchListings().then(this.onFormSubmit);
    }
    const data: ContactFormData & {
      leadTopicId: number;
      currentGroupId: number;
      currentGroupTypeId: number;
      currentMemberId: number;
      otherGroupId: number;
      otherGroupTypeId: number;
    } = {
      ...toJS(this.contactForm.data),
      leadTopicId: this.leadTopic.id,
      currentGroupId: myGroup.id || undefined,
      currentGroupTypeId: myGroup.typeId || undefined,
      currentMemberId: this.selfMember.id,
      otherGroupId: this.group.id,
      otherGroupTypeId: this.group.typeId
    };
    if (isEmpty(data)) return false;

    const leadGeneratingGroup = toJS(this.group);
    let noConvertHousehold = false;
    if (this.isEmailOnly && leadGeneratingGroup.typeId === groupTypeIds.cleaner) {
      // Use existing Care Circle instead
      const existingCareCircle = stateCtrl.finderContextGroupId
        ? this.finderContextGroup.typeId === groupTypeIds.careCircle
          ? this.finderContextGroup
          : toJS(mcbSearchCtrl.careCircleScratchpad)
        : toJS(mcbSearchCtrl.careCircleScratchpad);
      if (isEmpty(existingCareCircle)) {
        return mcbSearchCtrl.checkMissingGroup(true, true, false)
        .then(this.onFormSubmit);
      }
      data.currentGroupId = existingCareCircle.id;
      data.currentGroupTypeId = existingCareCircle.typeId;
      data.currentMemberId = (client.findMyMemberInGroup(existingCareCircle) || {}).id;
      noConvertHousehold = true;
    } else {
      if (data.reference && myGroup.typeId === groupTypeIds.careReceiver) {
        const frags = data.reference.trim().split(" ");
        const first = frags[0];
        const last = frags.length > 1 && frags[frags.length - 1];
        if (first) mcbSearchCtrl.searchForm.set("firstName", first);
        if (last) mcbSearchCtrl.searchForm.set("lastName", last);
        if (first && last) mcbSearchCtrl.searchForm.set("displayName", `${first} ${last}`);
      }
      if (myGroup.typeId === groupTypeIds.household) {
        mcbSearchCtrl.searchForm.set("firstName", data.lastName);
        mcbSearchCtrl.searchForm.set("lastName", "Household");
        mcbSearchCtrl.searchForm.set("displayName", `${data.lastName} Household`);
      }
      if (this.isEmailOnly && this.contactForm.isDirty && !isEmpty(this.memberProfile)) {
        await api.PATCH({
          endpoint: endpointConfig.profile_by_id(this.memberProfile.id),
          data: {
            data: JSON.stringify({
              ...this.memberProfile.data,
              firstName: data.firstName,
              lastName: data.lastName,
              phone: data.phone
            })
          }
        })
        .catch(contextReject);
      }
      await mcbSearchCtrl.updateGroupProfile({
        displayName: getDisplayNameEng(mcbSearchCtrl.searchForm.data),
        ...this.finderContextGroup && stateCtrl.customSearchForm.data
      }).catch(contextReject);
    }

    return api.POST({
      endpoint: endpointConfig.lead_generation,
      data
    })
    .then(() => env !== "prod" && !noConvertHousehold && this.isCleanerConvertToCareCircle(myGroup, leadGeneratingGroup))
    // .then(() => mcbSessionCtrl.setAppOAuth2Data(client.oauth))
    .then(response => true);
  };

  isCleanerConvertToCareCircle = async (householdGroup: Group, leadGroup: Group) => {
    if (householdGroup.typeId !== groupTypeIds.household || leadGroup.typeId !== groupTypeIds.cleaner) return;
    return api.POST({
      endpoint: endpointConfig.convert_household_to_care_circle,
      data: { groupId: householdGroup.id }
    });
  };

  onLogout = async () =>
    mcbSessionCtrl.sessionLogout()
    .then(client.isLoggedInAndReady)
    .then(this.loadAllData)
    .then(this.resetContactForm)
    .then(this.autoHideReference)
    .catch(() => window.location.reload());
}