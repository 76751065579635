import { observer } from "mobx-react";
import React from "react";
import { computed, observable } from "mobx";
import { McbMarketSearchController } from "./controller";
import MarketplaceCriteria from "../../components/MarketplaceCriteria";
import { Styled } from "direflow-component/dist";
import styles from "./styles.css";
import MarketplaceSearchBar from "../../components/MarketplaceSearchBar";
import { getEventRealValue, getQueryParameters, isEmpty, preventDefaultStopProp } from "../../utils/helpers";
import MarketplaceStatusBar from "../../components/MarketplaceStatusBar";
import { PickerOption } from "../../lib/types/formTypes.legacy";
import MarketplaceResultsList from "../../components/MarketplaceResultsList";
import { ui } from "../../client/ui";
import { Profile } from "../../lib/types/dataTypes";
import McbComponentPastry from "../../components/McbComponentPastry";
import { env } from "../../config/env";
import { groupTypeIds } from "../../mcb/config/constants";
import { McbSearchController } from "../../mcb/client/search";
import withJssMap from "../../components/withJssMap";
import { stateCtrl } from "../../client/state";
import RequireScratchpadNotice from "../../components/RequireScratchpadNotice";
import { UIText } from "../../client/lang";
import { MarketplacePopupAggregator } from "../../components/MarketplacePopupAggregator";

@observer
class McbMarketSearch extends React.Component<{ serviceType: McbSearchController["selectedService"] }> {
  controller: McbMarketSearchController = {} as McbMarketSearchController;
  container: HTMLDivElement;

  @observable _loading: boolean = true;
  @observable showCandidates: boolean = false;
  @observable _criteriaHidden: boolean = false;

  @observable ratingDetailOpen: boolean = false;
  @observable ratingDetailProfileId: number;

  @observable saveLoadingProfileIds: number[] = [];

  @observable visibleProfileIds: number[] = [];

  @computed get loading(): boolean {
    return !this.controller.isLoggedIn || !this.controller.searchReady || this._loading;
  };
  @computed get searching(): boolean {
    return this.loading || this.controller.searching;
  };
  @computed get selectedServiceText(): string {
    const { availableServices, selectedService } = this.controller;
    const selected = availableServices.find(o => o.name.toString() === selectedService.toString()) || {} as PickerOption;
    return selected.placeholder || "";
  };

  @computed get criteriaHidden(): boolean {
    return ui.isMobile && this._criteriaHidden;
  };

  @computed get savedProfileIds(): number[] {
    return this.controller.savedProfileIds || [];
  };
  @computed get ownProfileIds(): number[] {
    return this.controller.ownProfileIds || [];
  };
  @computed get profileOpen(): boolean {
    return !stateCtrl.bookingFormVisible && stateCtrl.mcbListingProfileIsOpen;
  };

  constructor(props) {
    super(props);
    this.controller = new McbMarketSearchController();
  };

  componentDidMount(): void {
    this._loading = true;
    this.controller.isReady()
    .then(() => !isNaN(Number(this.props.serviceType)) && this.controller.onServiceChange(this.props.serviceType))
    .finally(() => this._loading = false);
    stateCtrl.mcbListingProfileIsEmbedded = true;
    this.detectProfileQueryParam();
    this.detectBookingFormQueryParam();
  }

  showError = err => ui.showError({ err, actionName: UIText.generalError });

  containerRef = elm => this.container = elm;

  isProfileSaveLoading = (id: Profile["id"]): boolean => {
    if (this.controller.isCheckingShortlists) return true;
    return this.saveLoadingProfileIds.includes(id);
  };

  detectProfileQueryParam = () => {
    const { gid } = getQueryParameters(window.location.search) || {};
    const groupId = Number(gid);
    if (!gid || isNaN(groupId)) return;
    this.controller.onSearch().catch(this.showError);
    return this.controller.openProfileViewGroupId(groupId);
  };

  detectBookingFormQueryParam = () => {
    const { bfpid, bfgid } = getQueryParameters(window.location.search) || {};
    const profileId = Number(bfpid);
    const organizerGroupId = Number(bfgid)
    if (bfpid && !isNaN(profileId)) {
      stateCtrl.showBookingForm(profileId);
    }
    if (bfgid && !isNaN(organizerGroupId)) {
      stateCtrl.setBookingFormOrganizerGroupId(organizerGroupId);
    }
  };

  handleTopBarChange = event => {
    preventDefaultStopProp(event);
    const value = getEventRealValue(event);
    const name = event.target.name;
    if (name === "service") return this.controller.onServiceChange(value);
    return this.controller.searchForm.set(name, value);
  };

  handleProfileSaveClick = (event, id) => {
    preventDefaultStopProp(event);
    this.saveLoadingProfileIds.push(id);
    return (
      this.savedProfileIds.includes(id)
        ? this.controller.onProfileUnSave
        : this.controller.onProfileSave
    )(id)
    .catch(this.showError)
    .finally(() => this.removeSaveLoading(id));
  };

  handleProfileViewClick = (event, profileId) => {
    preventDefaultStopProp(event);
    return this.controller.setViewProfileId(profileId);
  };

  handleProfileModalClose = (event: any) => stateCtrl.closeMcbListingProfile();

  handleShowCandidateToggle = (event: any) => this.showCandidates = !this.showCandidates;

  handleRatingDetailClick = (event, profileId) => {
    this.ratingDetailProfileId = profileId;
    this.ratingDetailOpen = true;
  };

  handleBookingClick = (event: any, profileId: number) => stateCtrl.showBookingForm(profileId);

  handleProfileVisible = (event, profileId) => {
    if (!profileId || !event) return;
    !this.visibleProfileIds.includes(profileId) && this.visibleProfileIds.push(profileId);
    this.controller.getRatings(this.visibleProfileIds).catch(console.error);
    this.controller.getAchievements(this.visibleProfileIds).catch(console.error);
    this.controller.getHasInterviewAppointmentData(this.visibleProfileIds).catch(console.error);
  };

  handleRatingModalClose = (event: any) => this.ratingDetailOpen = false;

  removeSaveLoading = id => this.saveLoadingProfileIds = this.saveLoadingProfileIds.filter(i => i !== id);

  toggleCriteriaHide = () => this._criteriaHidden = !this._criteriaHidden;

  render() {
    const {
      scratchpadGroup,
      geoLoading,
      availableServices,
      selectedService,
      provinceField,
      municipalityField,
      municipalityOptions,
      searchForm,
      criteriaFields,
      criteriaDirty,
      isCaregiver,
      shortlistingAvailable,
      candidateProfiles,
      searchResultProfiles,
      getProfileAvatarUri,
      getProfileAbilities,
      getProfileLanguages,
      findProfileRatingScore,
      findProfileAchievement,
      getRatingLoading,
      findHasInterviewAppointment,
      getHasInterviewLoading,
      onServiceChange,
      onSearch,
      onGeo
    } = this.controller;

    const provinceOptions = provinceField.options || [];
    const serviceOptions = availableServices
    .filter(s => s.name !== groupTypeIds.caregiver)
    .map(o => ({ name: o.name.toString(), placeholder: o.placeholder }));

    // if (appIsLoggedIn) return <div>Marketplace for signed-in user under construction.</div>;

    return <McbComponentPastry>
      <Styled styles={styles}>
        <div className={`flex column mcbMarketSearch ${env}`} ref={this.containerRef}>
          <div className="flex column mcbMarketSearchBars relative">
            <MarketplaceSearchBar
              loading={this.loading}
              geoLoading={geoLoading}
              criteriaDirty={criteriaDirty}
              availableServices={selectedService !== groupTypeIds.caregiver && serviceOptions}
              provinceOptions={provinceOptions}
              municipalityOptions={municipalityOptions}
              service={selectedService}
              province={provinceField.value}
              city={municipalityField.value}
              onChange={this.handleTopBarChange}
              onSearch={onSearch}
              onGeo={onGeo}
            />
            <MarketplaceStatusBar
              loading={this.loading}
              quantity={this.showCandidates ? candidateProfiles.length : searchResultProfiles.length}
              unit={this.selectedServiceText}
              isShowSaved={this.showCandidates}
              toggleShowSaved={this.handleShowCandidateToggle}
            />
          </div>
          <div className={`flex mcbMarketSearchMain ${ui.isMobile ? "column" : ""}`}>
            <MarketplaceCriteria
              hidden={this.criteriaHidden}
              loading={this.loading}
              form={searchForm}
              fields={criteriaFields}
              // serviceField={serviceField}
              onServiceChange={(name, value) => onServiceChange(value)}
              onShowHideCriteria={this.toggleCriteriaHide}
            />
            {isEmpty(scratchpadGroup) && !this.searching ? (
              <RequireScratchpadNotice
                isMobile={ui.isMobile}
                isDirty={criteriaDirty}
                onSearch={onSearch}
              />
            ) : (
              <MarketplaceResultsList
                useVirtualPagination
                loading={this.searching}
                profiles={this.showCandidates ? candidateProfiles : searchResultProfiles}
                savedProfileIds={this.savedProfileIds}
                ownProfileIds={this.ownProfileIds}
                isProfileSaveLoading={this.isProfileSaveLoading}
                isCaregiver={isCaregiver}
                shortlistingAvailable={shortlistingAvailable}
                getAvatarUri={getProfileAvatarUri}
                getProfileAbilities={getProfileAbilities}
                getProfileLanguages={getProfileLanguages}
                getRatingLoading={getRatingLoading}
                findProfileRatingScore={findProfileRatingScore}
                findProfileAchievement={findProfileAchievement}
                getHasInterviewLoading={getHasInterviewLoading}
                findHasInterview={findHasInterviewAppointment}
                onVisible={this.handleProfileVisible}
                onSaveClick={this.handleProfileSaveClick}
                onViewClick={this.handleProfileViewClick}
                onRatingClick={this.handleRatingDetailClick}
                onChatClick={ui.comingSoon}
                onBookingClick={isCaregiver && this.handleBookingClick}
              />
            )}
          </div>

          <MarketplacePopupAggregator
            container={this.container}
            profileOpen={this.profileOpen}
            ratingDetailOpen={this.ratingDetailOpen}
            ratingDetailProfileId={this.ratingDetailProfileId}
            isCaregiver={isCaregiver}
            getProfileAvatarUri={getProfileAvatarUri}
            onProfileModalClose={this.handleProfileModalClose}
            onRatingModalClose={this.handleRatingModalClose}
          />
        </div>
      </Styled>
    </McbComponentPastry>;
  }
}

export default withJssMap(McbMarketSearch);