import React from "react";
import { observer } from "mobx-react";
import { Appointment, AppointmentStatus, AppointmentType } from "../../mcb/lib/types/dataTypes";
import styles from "./styles.css";
import { Styled } from "direflow-component";
import { capitalize, Card, CardActionArea, CardContent, CircularProgress, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment-timezone";
import { getTimeZoneLongName } from "../../utils/helpers";
import { ui } from "../../client/ui";

export interface AppointmentCardProps {
  loading?: boolean;
  appointment: Appointment;
  displayName: string;
  partyTypeName: string;
  partyAvatar: string;
  timezone: string;
  onClick?: (event: any) => any;
}

const AppointmentCard: React.FC<AppointmentCardProps> = observer(
  ({ loading, appointment, displayName, partyTypeName, partyAvatar, timezone, onClick }) => {
    if (!timezone) return null;
    const { appointmentStatus, dateTimeOptions, confirmedDateTimeOptionId } = appointment || {};
    const confirmedDateTimeOption = confirmedDateTimeOptionId && dateTimeOptions.find(opt => opt.id === confirmedDateTimeOptionId);
    const { start, end } = confirmedDateTimeOption || {};
    const isConfirmed = appointmentStatus === AppointmentStatus.CONFIRMED;
    const startString = isConfirmed ? moment(start).tz(timezone).format("YYYY-MM-DD, h:mm:ss a") : "TBD";
    const currentDateTimeOptions = dateTimeOptions.filter(option => new Date() < new Date(option.end));
    // const endString = isConfirmed ? moment(end).tz(timezone).format("YYYY-MM-DD, h:mm:ss a") : "TBD";
    const duration = isConfirmed && Number(new Date(end).getTime() - new Date(start).getTime()) / 1000 / 60;
    const durationString = isConfirmed ? `${duration} minutes` : "TBD";
    return <Styled styles={styles}>
      <Card className="appointmentCard">
        <CardActionArea onClick={onClick}>
          <CardContent className="flex">
            {loading ? (
              <div className="flex align-items-center justify-content-center loading">
                <CircularProgress size={30} />
              </div>
            ) : <>
              <Avatar variant={ui.isMobile ? "circular" : "square"} alt={displayName} src={partyAvatar} className="avatar">
                {displayName.charAt(0)}
              </Avatar>
              <div className="flex column align-items-start justify-content-center appointmentCardInfo">
                <Typography className="textBold font-l" color="secondary">
                  {appointment.description}
                </Typography>
                <Typography className="font-m" gutterBottom>
                  {capitalize(appointment.type)}
                </Typography>
                <Typography className="textBold font-m" gutterBottom>
                  {displayName} ({partyTypeName})
                </Typography>
                {appointment.type === AppointmentType.INTERVIEW && <>
                  <Typography className="font-s">
                    Start: <span className="textBold">{startString}</span>
                  </Typography>
                  <Typography className="font-s">
                    Duration: <span className="textBold">{durationString}</span>
                  </Typography>
                </>}
                {appointment.type === AppointmentType.SERVICE && (
                  <Typography className="font-s">
                    <span className="textBold">{dateTimeOptions.length} appointment(s)</span> ({currentDateTimeOptions.length} upcoming)
                  </Typography>
                )}
                <Typography className="font-s textBold">
                  {getTimeZoneLongName(timezone, true)}
                </Typography>
              </div>
            </>}
          </CardContent>
        </CardActionArea>
      </Card>
    </Styled>
  });

export default AppointmentCard;