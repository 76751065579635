const enCAExceptions = {
  SERVICE_UNAVAILABLE: "Service not available",
  ACCOUNT_NOT_VERIFIED: "Please verify your email address before attempting to sign in.",
  ACCOUNT_NEED_PASSWORD_RESET: "A password reset is required to sign in.",
  ACCOUNT_TERMS_NOT_AGREED: "",

  VERIFICATION_UNKNOWN_IDENTIFIER: "",
  VERIFICATION_EXPIRE_INVALID: "Sorry, the verification link is invalid or expired.",
  PASSWORD_RESET_EXPIRE_INVALID: "Sorry, the password reset link is invalid or expired.",
  RECEIVED_INVALID_CREDENTIALS: "The email address or password is incorrect.",
  INVALID_VERIFICATION_TYPE: "",

  POPOVER_CONTAINER_NOT_FOUND: "No popover container present in the app, forgot to include one?",
  INVALID_SETUP_URL: "",

  CAREGIVER_APPLICATION_EXCEPTION: "Something happened and application was not successful. [Empty user or calendlyUrl]",
  SERVICE_PROVIDER_APPLICATION_EXCEPTION: "Something happened and application was not successful. [Empty user or calendlyUrl]",
  CREATE_GROUP_PROFILE_EMPTY_RETURN: "",
  GET_GROUP_ROLES_INVALID_GROUP_ID: "",
  CREATE_CARE_RECIPIENT_EMPTY_GROUP: "",

  PROFILE_MANDATORY_MUNICIPALITY: "Please select at least one territory and one municipality that you are able to work in.",

  SHOPPING_CART_STATE_INCORRECT: "Sorry, the shopping cart checkout state is not correct. Please restart the app and try again later.",

  MISSING_LEAD_TOPIC: "",
  MISSING_FORM_SUBMITTER_GROUP: "",

  MARKET_SCRATCHPAD_NOT_AVAILABLE: "Sorry, Marketplace is not available at this time.",
};

export default enCAExceptions;