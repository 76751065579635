import { observer } from "mobx-react";
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { AlertBase } from "../AlertBase";
import React from "react";
import { isEmpty } from "../../utils/helpers";
import { AlertButton } from "../../lib/types/propTypes";

export interface AlertModalProps {
  isOpen?: boolean;
  onClose?: (event: any) => void;
  showBackdrop?: boolean;
  allowBackdropDismiss?: boolean;
  title: string;
  message?: string;
  buttons?: AlertButton[];
}

export const AlertModalContent = observer(({ title, message, buttons, onClose }) => <>
  <DialogTitle>
    <Typography color="secondary" className="MuiTypography-h6">
      {title}
    </Typography>
  </DialogTitle>
  <DialogContent>
    <Typography style={{ whiteSpace: "pre-wrap" }}>
      {message}
    </Typography>
  </DialogContent>
  <DialogActions>
    {!isEmpty(buttons) ? buttons.map(button => (
      <Button key={button.text} onClick={button.handler} color={button.color || "primary"} disabled={button.hasInputData}>
        {button.text}
      </Button>
    )) : (
      <Button onClick={onClose} color="primary">
        Ok
      </Button>
    )}
  </DialogActions>
</>);

const AlertModal: React.FC<AlertModalProps> = observer(
  ({
     isOpen,
     onClose,
     showBackdrop,
     allowBackdropDismiss,
     title,
     message,
     buttons
   }) => (
    <AlertBase
      isOpen={isOpen}
      onClose={onClose}
      showBackdrop={showBackdrop}
      disableBackdropClick={!allowBackdropDismiss && !isEmpty(buttons)}
    >
      <AlertModalContent title={title} message={message} buttons={buttons} onClose={onClose} />
    </AlertBase>
  ));

export default AlertModal;