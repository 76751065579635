import { Controller } from "../../lib/controller";
import { computed, observable, toJS, when } from "mobx";
import { Appointment, AppointmentDateTimeOption, Attendance, Attendee } from "../../mcb/lib/types/dataTypes";
import { Group, Member, Profile } from "../../lib/types/dataTypes";
import { client } from "../../client/client";
import { fileCtrl } from "../../client/file";
import { api } from "../../client/api";
import { endpointConfig } from "../../config/api";
import { isEmpty } from "../../utils/helpers";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { stateCtrl } from "../../client/state";
import { UIText } from "../../client/lang";
import {
  getAppointmentZoomMeetingUrl,
  getOrganizerGroupByAppointment,
  getProviderGroupByAppointment,
  handleAppointmentAttendeesUpdate,
  handleAppointmentEditAttendance,
  handleAppointmentNotesUpdate,
  handleAppointmentUpdate,
  handleConfirmAppointment,
  handleDownloadAppointmentIcs,
  handleRequestNewDateTimeOption,
  handleUpdateCreateZoomMeeting
} from "../../mcb/lib/appointment-utilities";

export class McbAppointmentManagerController extends Controller {
  @observable appointmentId: number;
  @observable appointment: Appointment;
  @observable providerGroup: Group;
  @observable organizerGroup: Group;

  @computed get selfAttendee(): Attendee {
    const { attendees } = this.appointment || {};
    return (attendees || []).find(at => (
      !isEmpty(client.findMembers(m => at.memberId === m.id && m.userId === client.userId))
    )) || {} as Attendee;
  };
  @computed get selfMember(): Member {
    const member = toJS(client.findMembers(m => (
      this.organizerGroup?.members?.some(mbr => mbr.id === m.id)
      || this.providerGroup?.members?.some(mbr => mbr.id === m.id)
    ) && m.userId === client.userId)[0]);
    if (member && !member.profile) {
      const potentialGroup = client.findGroupById(member.groupId);
      if (member.profileId === potentialGroup.profileId) member.profile = potentialGroup.profile;
    }
    return member;
  };
  @computed get selfIsProvider(): boolean {
    return this.providerGroup && this.providerGroup.id === this.selfMember?.groupId;
  };
  @computed get organizerGroupProfile(): Profile {
    return this.organizerGroup && this.organizerGroup.profile;
  };
  @computed get organizerGroupMembers(): Member[] {
    return (this.organizerGroup && this.organizerGroup.members) || [];
  };
  @computed get organizerAvatar() {
    return fileCtrl.getProfileAvatarUri(this.organizerGroupProfile?.data?.avatar, this.organizerGroup?.id, "group");
  };
  @computed get timezone(): string {
    return this.appointment?.timezone || this.organizerGroupProfile?.data?.timezone;
  };

  @computed get providerProfile(): Profile {
    return this.providerGroup && this.providerGroup.profile;
  };
  @computed get providerAvatar() {
    return fileCtrl.getProfileAvatarUri(this.providerProfile?.data?.avatar, this.providerGroup?.id, "group");
  };

  @computed get members(): Member[] {
    return [this.selfMember, ...this.organizerGroupMembers];
  };

  loadAllData = async (appointmentId: number) => {
    await when(() => !mcbSessionCtrl.runningAuthChangeSequence);
    await client.isLoggedInAndReady();

    if (mcbSessionCtrl.isValidVisitor) {
      stateCtrl.mcbSignInFormOpen = true;
      stateCtrl.mcbSignInFormOptions.subHeading = UIText.signInToSeeAppointment(false);
      stateCtrl.mcbSignInFormOptions.onClose = () => {
        stateCtrl.mcbSignInFormOptions.subHeading = null;
        stateCtrl.mcbSignInFormOptions.onClose = null;
      };
      await when(() => !mcbSessionCtrl.isValidVisitor);
    }

    if (!appointmentId) return;
    this.appointmentId = appointmentId;
    return this.getAppointment()
    .then(this.getProviderGroupByAppointment)
    .then(this.getOrganizerGroupByAppointment)
    .then(this.syncStateCtrlContextGroupId);
  };

  getAppointment = async () => {
    if (!this.appointmentId) return;
    return api.GET(endpointConfig.appointment_by_id(this.appointmentId))
    .then(response => response.data)
    .then(appointment => this.appointment = appointment)
    .then(() => {
      if (isEmpty(this.appointment)) return Promise.reject({ message: "No appointment available." });
    });
  };

  getProviderGroupByAppointment = async () => {
    if (isEmpty(this.appointment)) return;
    return getProviderGroupByAppointment(this.appointment)
    .then(group => this.providerGroup = group)
  };

  getOrganizerGroupByAppointment = async () => {
    if (isEmpty(this.appointment)) return;
    return getOrganizerGroupByAppointment(this.appointmentId)
    .then(group => this.organizerGroup = group);
  };

  getZoomMeetingUrl = async () => {
    if (isEmpty(this.appointment)) return;
    return getAppointmentZoomMeetingUrl(this.appointmentId);
  };

  onCancel = async () => {
    if (isEmpty(this.appointment)) return;
    return api.DELETE(endpointConfig.appointment_by_id(this.appointment.id));
  };

  onNameTypeUpdate = async (data: Partial<Appointment>) => handleAppointmentUpdate(this.appointment, data, this.getAppointment);

  onNotesUpdate = async (notes: string) => handleAppointmentNotesUpdate(this.appointment, notes, this.getAppointment);

  onAttendanceUpdate = async (
    attendeeId: number,
    attendance: Attendance
  ) => handleAppointmentEditAttendance(
    this.appointment,
    attendeeId,
    attendance,
    this.getAppointment
  );

  onUpdateAttendees = async (attendees: Partial<Attendee>[]) => handleAppointmentAttendeesUpdate(
    this.appointment,
    attendees,
    this.getAppointment
  );

  onUpdateCreateZoomMeeting = async (createZoomMeeting: boolean) => handleUpdateCreateZoomMeeting(
    this.appointment,
    createZoomMeeting,
    this.getAppointment
  );

  onRequestDateTimeOption = async (dateTimeOption: AppointmentDateTimeOption) => handleRequestNewDateTimeOption(
    this.appointment,
    dateTimeOption,
    false,
    this.getAppointment
  );

  onRequestDateTimeOptions = async (dateTimeOptions: AppointmentDateTimeOption[]) => handleRequestNewDateTimeOption(
    this.appointment,
    dateTimeOptions,
    true,
    this.getAppointment
  );

  onConfirmAppointment = async (dateTimeOptionId: string) => handleConfirmAppointment(
    this.appointment,
    dateTimeOptionId,
    this.getAppointment
  );

  onDownloadIcs = async () => handleDownloadAppointmentIcs(this.appointment);

  syncStateCtrlContextGroupId = async () => {
    await when(() => !isEmpty(this.selfMember));
    return stateCtrl.setAppointmentListContextGroup(this.selfMember.groupId);
  };
}