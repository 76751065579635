import { observer } from "mobx-react";
import { CircularProgress, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import styles from "./styles.css";
import { withStyles } from "direflow-component";
import { ratingStarColor } from "../../config/styles/theme";
import MdIcon from "../MdIcon";
import { mdiStar, mdiStarHalfFull } from "@mdi/js";
import { Achievement } from "../../mcb/lib/types/dataTypes";
import { achievementColors } from "../../mcb/config/constants";

export interface ProfileRatingDisplayProps extends React.HTMLProps<HTMLDivElement> {
  loading?: boolean;
  samples?: number;
  rating: number;
  achievement?: Achievement;
  size?: number;
  badgeSize?: number;
  hideSamples?: boolean;
  offsetStart?: boolean;
}

export const workspacePremium = '<path d="M9.68 13.69 12 11.93l2.31 1.76-.88-2.85L15.75 9h-2.84L12 6.19 11.09 9H8.25l2.31 1.84-.88 2.85zM20 10c0-4.42-3.58-8-8-8s-8 3.58-8 8c0 2.03.76 3.87 2 5.28V23l6-2 6 2v-7.72c1.24-1.41 2-3.25 2-5.28zm-8-6c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6z" />';

const ProfileRatingDisplay: React.FC<ProfileRatingDisplayProps> = observer(({ loading, samples, rating, achievement, onClick, size, badgeSize, hideSamples, offsetStart }) => {
  const rounded = rating ? Number((Math.round(rating * 2) / 2).toFixed(1)) : 0;
  return <div className="flex align-items-center profileRatingDisplay" style={{ marginLeft: offsetStart ? "-3px" : 0 }}>
    {achievement && achievement.achievementLevel && (
      <svg
        viewBox="0 0 24 24"
        dangerouslySetInnerHTML={{ __html: workspacePremium }}
        style={{
          width: `${badgeSize || 26}px`,
          height: `${badgeSize || 26}px`,
          fill: achievementColors[achievement.achievementLevel]
        }}
      />
    )}
    <Tooltip title={`${rounded} / 5${hideSamples ? "" : ` (${samples} reviews)`}`} arrow placement="right" PopperProps={{ style: { zIndex: 114514 }}}>
      <div className="flex align-items-center" onClick={onClick} style={{ cursor: onClick ? "pointer" : undefined }}>
        {Array.from(new Array(5)).map((d, i) => {
          if (i === Math.floor(rounded) && !Number.isInteger(rounded)) {
            return <MdIcon key={i} icon={mdiStarHalfFull} color={ratingStarColor} size={`${size || 20}px`} />;
          } else {
            const filled = rounded - 1 >= i;
            return <MdIcon key={i} icon={mdiStar} color={filled ? ratingStarColor : "#aaa"} size={`${size || 20}px`} />
          }
        })}&nbsp;{loading ? (
        <CircularProgress size={19} />
      ) : (
        !hideSamples && <Typography style={{ fontSize: size - 4 || undefined }} className={`${onClick ? "textUnderline" : ""} samples`}>({samples || 0})</Typography>
      )}
      </div>
    </Tooltip>
  </div>;
});

export default withStyles(styles)(ProfileRatingDisplay);