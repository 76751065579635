import React from "react";
import { withStyles } from "direflow-component";
import styles from "./styles.css";
import { Button, CircularProgress, FormControl, FormHelperText, InputLabel, OutlinedInput, TextField, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { UIText } from "../../client/lang";
import { FormLegacy } from "../../client/form.legacy";
import { groupTypeIds } from "../../mcb/config/constants";
import { Profile } from "../../lib/types/dataTypes";
import { getDisplayNameEng, getEventRealValue, isEmpty } from "../../utils/helpers";
import { stateCtrl } from "../../client/state";
import { EventAvailableSharp } from "@material-ui/icons";

export interface GetConnectedProps extends Omit<React.HTMLProps<HTMLDivElement>, "form"> {
  loading?: boolean;
  isEmailOnly?: boolean;
  submitting?: boolean;
  submitted?: boolean;
  hasInterview?: boolean;
  serviceType: typeof groupTypeIds[keyof typeof groupTypeIds];
  form: FormLegacy;
  clientProfile: Profile;
  onSubmit: (event: any) => any;
  onLogin: (event: any) => any;
  onLogout: (event: any) => any;
  onBooking: (event: any) => any;
}

const GetConnected: React.FC<GetConnectedProps> = observer(
  ({
     className,
     loading,
     isEmailOnly,
     submitting,
     submitted,
     hasInterview,
     serviceType,
     form,
     clientProfile,
     onSubmit,
     onLogin,
     onLogout,
     onBooking
   }) => {
    const handleChange = event => {
      const { value, name } = event.target;
      if (value === form.get(name)) return;
      form.set(name, value);
      // form.validate(name).catch(console.warn);
    };
    const validateField = event => {
      const { name } = event.target;
      const field = form.getField(name);
      if (!field || field.hasInputData) return;
      return form.validate(name, name === "repeat").catch(console.warn);
    };
    const handleSubmit = event => !submitted && onSubmit && onSubmit(event);

    return <div className={`flex column getConnected ${className || ""}`}>
      {loading ? <CircularProgress /> : <>
        <div className="getConnectedHeader font-m textBold">
          {UIText.getConnected}
        </div>
        <div className="getConnectedHeader_2 font-m textBold">
          {onBooking && <>
            <span className="getConnectedBookingLink textUpperCase" onClick={onBooking}>
            <EventAvailableSharp style={{ verticalAlign: "middle" }} />&nbsp;<span className="textUnderline">
              {UIText.profileUtilBookButton(hasInterview)}
              </span>
            </span> or
          </>}
        </div>
        {isEmailOnly ? (
          <div className="getConnectedHeader_2 font-m textBold textUpperCase">
            {UIText.getConnectedContactInfo}
          </div>
        ) : (
          <div className="getConnectedHeader_2 font-m textBold textUpperCase">
            {UIText.signUp}&nbsp;/&nbsp;<span onClick={onLogin} className="getConnectedSignInLink textUnderline">{UIText.login}</span>
          </div>
        )}
        <div className="getConnectedContent font-s pSans">
          {serviceType === groupTypeIds.caregiver
            ? UIText.getConnectedContentCaregivers
            : UIText.getConnectedContentServiceProviders
          }
        </div>
        {isEmailOnly && !isEmpty(clientProfile) && (
          <div className="getConnectedContent pSans">
            <Typography className="textBold font-s">
              {UIText.welcomeMessage(getDisplayNameEng(clientProfile))}
            </Typography>
            <Typography className="getConnectedSignInLink textUnderline font-s" color="secondary" onClick={onLogout}>
              {UIText.contactFormSignOut(getDisplayNameEng(clientProfile))}
            </Typography>
          </div>
        )}
        <form className="flex column getConnectedForm">
          <TextField
            color="secondary"
            required={!isEmailOnly}
            label={UIText.registrationFields.firstName}
            variant="outlined"
            name="firstName"
            error={!!form.getField("firstName")._errorMessage}
            helperText={form.getField("firstName")._errorMessage}
            value={form.data.firstName || ""}
            disabled={submitting || form.getField("firstName").hasInputData}
            onChange={handleChange}
            onBlur={validateField}
          />
          <TextField
            color="secondary"
            required={!isEmailOnly}
            label={UIText.registrationFields.lastName}
            variant="outlined"
            name="lastName"
            error={!!form.getField("lastName")._errorMessage}
            helperText={form.getField("lastName")._errorMessage}
            value={form.data.lastName || ""}
            disabled={submitting || form.getField("lastName").hasInputData}
            onChange={handleChange}
            onBlur={validateField}
          />
          {!isEmailOnly && !form.getField("reference").hidden && <TextField
            color="secondary"
            required
            label={UIText.getConnectedReference}
            variant="outlined"
            name="reference"
            error={!!form.getField("reference")._errorMessage}
            helperText={form.getField("reference")._errorMessage || UIText.referenceHelpText}
            value={form.data.reference || ""}
            disabled={submitting || form.getField("reference").hasInputData}
            onChange={handleChange}
            onBlur={validateField}
          />}
          <FormControl variant="outlined">
            <InputLabel
              color="secondary"
              error={!!form.getField("email")._errorMessage}
              htmlFor="contactEmail">{UIText.registrationFields.email}</InputLabel>
            <OutlinedInput
              id="contactEmail"
              label={UIText.registrationFields.email}
              color="secondary"
              required={!isEmailOnly}
              name="email"
              value={form.data.email || ""}
              disabled={submitting || form.getField("email").hasInputData}
              onChange={e => {
                if (!isEmailOnly) stateCtrl.onSignInFormUsernameChange(getEventRealValue(e));
                return handleChange(e);
              }}
              onBlur={validateField}
            />
            <FormHelperText
              error={!!form.getField("email")._errorMessage}
              dangerouslySetInnerHTML={{
                __html: form.getField("email")._errorMessage
                  ? form.getField("email")._errorMessage
                  : ""
              }}
            />
          </FormControl>
          <TextField
            color="secondary"
            // required
            label={UIText.registrationFields.phone}
            variant="outlined"
            name="phone"
            value={form.data.phone || ""}
            disabled={submitting || form.getField("phone").hasInputData}
            onChange={handleChange}
            onBlur={validateField}
          />
          {!isEmailOnly && <>
            <TextField
              color="secondary"
              required
              label={UIText.registrationFields.createPassword}
              variant="outlined"
              name="password"
              type="password"
              value={form.data.password || ""}
              error={!!form.getField("password")._errorMessage}
              helperText={form.getField("password")._errorMessage}
              disabled={submitting || form.getField("password").hasInputData}
              onChange={handleChange}
              onBlur={validateField}
            />
            <TextField
              color="secondary"
              required
              label={UIText.registrationFields.repeat}
              variant="outlined"
              name="repeat"
              type="password"
              value={form.data.repeat || ""}
              error={!!form.getField("repeat")._errorMessage}
              helperText={form.getField("repeat")._errorMessage}
              disabled={submitting || form.getField("repeat").hasInputData}
              onChange={handleChange}
              onBlur={validateField}
            />
          </>}
          <TextField
            color="secondary"
            label={UIText.getConnectedNeeds}
            variant="outlined"
            multiline
            rows={4}
            name="needs"
            value={form.data.needs || ""}
            disabled={submitting || form.getField("needs").hasInputData}
            onChange={handleChange}
            onBlur={validateField}
          />
          {/*<div className="getConnectedContent_2 font-s pSans">*/}
          {/*  {UIText.getConnectedFooter}*/}
          {/*</div>*/}
          <Button
            className={`getConnectedButton textNoTransform ${submitted ? "noEvents" : ""}`}
            size="large"
            variant="contained"
            color={submitted ? "primary" : "secondary"}
            disableElevation
            disabled={submitting}
            onClick={handleSubmit}
          >
            {submitting
              ? <CircularProgress size={22} color="secondary" />
              : submitted
                ? <Typography>
                  {UIText.getConnectedSubmitted}
                </Typography>
                : <Typography>
                  {isEmailOnly ? UIText.getConnectedSubmit : UIText.generalSubmit}
                </Typography>
            }
          </Button>
        </form>
      </>}
    </div>;
  });

export default withStyles(styles)(GetConnected);
