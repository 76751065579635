import React from "react";
import { observer } from "mobx-react";
import { McbAppointmentManagerController } from "./controller";
import styles from "./styles.css";
import McbComponentPastry from "../../components/McbComponentPastry";
import { Styled } from "direflow-component";
import { UIText } from "../../client/lang";
import { ui } from "../../client/ui";
import { Card, CardContent, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { observable } from "mobx";
import Avatar from "@material-ui/core/Avatar";
import BookingForm from "../../components/BookingForm";
import { getDisplayNameEng, getQueryParameters } from "../../utils/helpers";
import withJssMap from "../../components/withJssMap";
import { handleRemoveAttendee, popupAppointmentCancelConfirm } from "../../mcb/lib/appointment-utilities";
import { Appointment, AppointmentDateTimeOption, AppointmentType, Attendance, Attendee } from "../../mcb/lib/types/dataTypes";
import { stateCtrl } from "../../client/state";
import { Close } from "@material-ui/icons";

@observer
class McbAppointmentManager extends React.Component {
  controller: McbAppointmentManagerController = {} as McbAppointmentManagerController;

  @observable loading: boolean = true;
  @observable submitting: boolean;
  @observable submitted: boolean;
  @observable downloading: boolean;

  constructor(props) {
    super(props);
    this.controller = new McbAppointmentManagerController();
    this.detectQueryParam();
  };

  showError = err => ui.showError({ err, actionName: UIText.generalError });

  detectQueryParam = () => {
    const { aid } = getQueryParameters(window.location.search) || {};
    const appointmentId = Number(aid);
    if (!aid || isNaN(appointmentId)) return;
    return this.controller.loadAllData(appointmentId)
    .then(() => this.loading = false)
    .catch(this.showError);
  };

  handleCancel = () => {
    const { appointment } = this.controller;
    const handler = () => {
      ui.dismissAlert();
      this.submitting = true;
      return this.controller.onCancel()
      .then(() => {
        this.submitted = true;
        this.submitting = false;
      })
      .catch(this.showError)
      .finally(() => this.submitting = false);
    };
    return popupAppointmentCancelConfirm(appointment, handler);
  };

  handleNameTypeUpdate = (data: Partial<Appointment>) => {
    this.submitting = true;
    return this.controller.onNameTypeUpdate({
      description: data.description,
      // type: data.type
    })
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleNotesUpdate = (notes: string) => {
    this.submitting = true;
    return this.controller.onNotesUpdate(notes)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleAttendanceUpdate = (attendeeId: number, attendance: Attendance) => {
    this.submitting = true;
    return this.controller.onAttendanceUpdate(attendeeId, attendance)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleRemoveAttendee = (attendeeId: number, name: string) => {
    const { appointment, getAppointment } = this.controller;
    return handleRemoveAttendee(appointment, attendeeId, name, getAppointment);
  };

  handleUpdateAttendees = (newAttendees: Partial<Attendee>[]) => {
    this.submitting = true;
    return this.controller.onUpdateAttendees(newAttendees)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleUpdateCreateZoomMeeting = (createZooMeeting?: boolean) => {
    this.submitting = true;
    return this.controller.onUpdateCreateZoomMeeting(createZooMeeting)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleRequestDateTimeOption = (dateTimeOption: AppointmentDateTimeOption) => {
    this.submitting = true;
    return this.controller.onRequestDateTimeOption(dateTimeOption)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleRequestDateTimeOptions = (dateTimeOptions: AppointmentDateTimeOption[]) => {
    this.submitting = true;
    return this.controller.onRequestDateTimeOptions(dateTimeOptions)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleAcceptAppointment = (dateTimeOptionId: string) => {
    this.submitting = true;
    return this.controller.onConfirmAppointment(dateTimeOptionId)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleDownloadIcs = () => {
    this.downloading = true;
    return this.controller.onDownloadIcs()
    .catch(this.showError)
    .finally(() => this.downloading = false);
  };

  handleModalClose = () => stateCtrl.closeAppointmentManagerEntry();

  render() {
    const {
      appointment,
      selfMember,
      selfIsProvider,
      organizerGroup,
      organizerGroupProfile,
      organizerAvatar,
      members,
      providerAvatar,
      providerGroup,
      providerProfile,
      timezone,
      getZoomMeetingUrl
    } = this.controller;

    const avatar = selfIsProvider ? organizerAvatar : providerAvatar;
    const partyGroup = selfIsProvider ? organizerGroup : providerGroup;
    const partyDisplayName = getDisplayNameEng(selfIsProvider ? organizerGroupProfile : providerProfile);
    const isService = appointment?.type === AppointmentType.SERVICE;

    return <McbComponentPastry>
      <Styled styles={styles}>
        <div className="mcbAppointmentManager">
          <Card className="formModal">
            <CardContent className="flex column">
              <div className="flex justify-content-between align-items-end" style={{ marginBottom: "10px" }}>
                <Typography className="font-m textBold formTitle" color="secondary">
                  Your {isService ? UIText.appointmentGroup.toLowerCase() : UIText.appointment.toLowerCase()} with:
                </Typography>
                {stateCtrl.appointmentManagerIsEmbedded && (
                  <IconButton className="closeButton" onClick={this.handleModalClose} color="secondary">
                    <Close />
                  </IconButton>
                )}
              </div>
              {this.loading ? (
                <div className="flex align-items-center justify-content-center loading">
                  <CircularProgress size={30} />
                </div>
              ) : <>
                <div className="flex column bookingFormMainContent">
                  <div className="flex column bookingFormHeader">
                    <div className="flex align-items-center justify-content-start bookingFormProviderSummary">
                      <Avatar variant="circular" alt={partyDisplayName} src={avatar} className="avatar">
                        {partyDisplayName.charAt(0)}
                      </Avatar>
                      <div className="flex column">
                        <Typography className="font-s textBold">
                          {partyDisplayName}
                        </Typography>
                        <Typography className="font-xs">
                          {partyGroup?.groupTypeName}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="flex column bookingFormFields">
                    <BookingForm
                      timezone={timezone}
                      selfMember={selfMember}
                      members={members}
                      providerProfile={providerProfile}
                      inputData={appointment}
                      submitting={this.submitting}
                      submitted={this.submitted}
                      downloading={this.downloading}
                      getZoomMeetingUrl={getZoomMeetingUrl}
                      onCancel={this.handleCancel}
                      onUpdateNameType={this.handleNameTypeUpdate}
                      onUpdateNotes={this.handleNotesUpdate}
                      onUpdateAttendance={this.handleAttendanceUpdate}
                      onRemoveAttendee={this.handleRemoveAttendee}
                      onUpdateAttendees={this.handleUpdateAttendees}
                      onUpdateCreateZoomMeeting={this.handleUpdateCreateZoomMeeting}
                      onUpdateDateTimeOptions={this.handleRequestDateTimeOptions}
                      onAccept={this.handleAcceptAppointment}
                      onDownloadIcs={this.handleDownloadIcs}
                    />
                  </div>
                </div>
              </>}
            </CardContent>
          </Card>
        </div>
      </Styled>
    </McbComponentPastry>
  }
}

export default withJssMap(McbAppointmentManager);