import { observer } from "mobx-react";
import React from "react";
import styles from "./styles.css";
import { withStyles } from "direflow-component/dist";
import { FormControlLabel, Switch, Typography } from "@material-ui/core";
import { UIText } from "../../client/lang";

export interface MarketplaceStatusBarProps extends React.HTMLProps<HTMLDivElement> {
  loading?: boolean;
  quantity: number;
  unit: string;
  isShowSaved?: boolean;
  toggleShowSaved?: (event: any) => void;
}

const MarketplaceStatusBar: React.FC<MarketplaceStatusBarProps> = observer(
  ({ loading, quantity, unit, isShowSaved, toggleShowSaved }) => (
    <div className={`flex align-items-center justify-content-between marketplaceStatusBar pSans`}>
      <Typography className="indicator" dangerouslySetInnerHTML={{ __html: !loading ? UIText.marketplaceStatusBarText(quantity, unit) : UIText.pleaseWait }} />
      <FormControlLabel
        className="savedSwitch"
        color="primary"
        control={<Switch color="primary" checked={!!isShowSaved} onChange={toggleShowSaved} name={UIText.marketplaceSavedToggle} />}
        label={<Typography color="primary">{UIText.marketplaceSavedToggle}</Typography>}
      />
    </div>
  ));

export default withStyles(styles)(MarketplaceStatusBar);