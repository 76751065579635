import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

const withJssMap = (Component: React.ComponentClass) => observer(class extends React.Component {
    jss = observable({
      styles: ""
    });

    observer: MutationObserver;

    componentDidMount(): void {
      this.setStyles();
      this.setMutationObserver();
    }

    componentWillUnmount(): void {
      this.observer.disconnect();
    }

    setMutationObserver = () => {
      const head = document.getElementsByTagName("head")[0];
      const config = { childList: true };
      this.observer = new MutationObserver(this.setStyles);
      this.observer.observe(head, config);
    };

    setStyles = () => {
      const jssTags: HTMLElement[] = Array.from(document.querySelectorAll('[data-jss]'));
      const uniqueMetas = Array.from(new Set(jssTags.map(tag => tag.dataset.meta)));
      const uniqueTags = uniqueMetas.map(meta => jssTags.find(tag => tag.dataset.meta === meta));
      this.jss.styles = uniqueTags.map(tag => tag.innerHTML).join("");
    };

    render() {
      return <>
        <style id="mapped-Mui-styles" dangerouslySetInnerHTML={{ __html: this.jss.styles }}/>
        <Component {...this.props} />
      </>
    }
  }
);

export default withJssMap;