import { observer } from "mobx-react";
import React from "react";
import { ProductResponseDTO } from "../../mcb/lib/types/dataTypes";
import { Styled } from "direflow-component/dist";
import styles from "./styles.css";
import Avatar from "@material-ui/core/Avatar";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { UIText } from "../../client/lang";
import { isEmpty } from "../../utils/helpers";

export interface ProductCardProps {
  product: ProductResponseDTO;
  loading: boolean;
  cartable: boolean;
  onCarting: (event: any, product: ProductResponseDTO) => void;
}

const ProductCard: React.FC<ProductCardProps> = observer(
  ({
     product,
     loading,
     cartable,
     onCarting
   }) => {
    const { wpProduct } = product;
    if (isEmpty(wpProduct)) return null;

    const { name, description, priceHtml, permalink } = wpProduct;
    const productImg = (wpProduct.images || [])[0] || {} as any;

    const handleClick = e => cartable ? (onCarting && onCarting(e, product)) : window.open(permalink, "_blank");

    return <Styled styles={styles}>
      <div className="productCard flex">
        <a href={permalink} target="_blank" rel="noopener noreferrer">
          <Avatar variant="square" alt={productImg.alt} src={productImg.src} className="productImg">
            {wpProduct.name.charAt(0)}
          </Avatar>
        </a>

        <div className="productDetail flex column">
          <div className="productDetailName">
            <a href={permalink} target="_blank" rel="noopener noreferrer">
              <Typography className="textBold textUnderline font-l" color="secondary">
                {name}
              </Typography>
            </a>
          </div>
          <div className="productDetailPrice textBold font-m" dangerouslySetInnerHTML={{ __html: priceHtml }}/>
          <div className="productDetailDescription font-xs" dangerouslySetInnerHTML={{ __html: description }}/>
        </div>

        <div className="productButtonContainer flex">
          <Button
            className="productButton textNoTransform"
            size="large"
            variant="contained"
            color="secondary"
            disableElevation
            disabled={loading}
            onClick={handleClick}
          >
            {loading ? <CircularProgress size={22} color="secondary" /> : <Typography className="font-m">
              {cartable ? UIText.getStarted : UIText.learnMore}
            </Typography>}
          </Button>
        </div>
      </div>
    </Styled>;
  });

export default ProductCard;