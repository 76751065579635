import { setCDMReadyState } from "./boot";
import { McbSearchController, mcbSearchCtrl } from "../mcb/client/search";
import { computed, reaction, toJS, when } from "mobx";
import { Client, client } from "./client";
import { McbSessionController, mcbSessionCtrl } from "../mcb/client/session";
import { isEmpty, whenFulfill } from "../utils/helpers";
import { State, stateCtrl } from "./state";
import { Group, Member, Profile, User } from "../lib/types/dataTypes";
import { KeyValuePairs } from "../lib/types/miscTypes";
import { Api, api } from "./api";
import { endpointConfig } from "../config/api";
import { webAppUrl } from "../mcb/config/constants";
import { UI, ui } from "./ui";
import { Places, placesApi } from "./places";
import { serverConfig } from "../config/api/base";
import { LoginBoxOptions } from "../lib/types/stateTypes";
import { FormLegacy } from "./form.legacy";
import { assistEnglishSelection } from "../mcb/lib/common";
import { TopicController, topicCtrl } from "./topic";


/**
 * Function params for each methods
 */
type ServiceTypeParam = string;
type ProvinceParam = string; // BC | ON
type CityParam = string; // needMunicipalities01
type RelationshipParam = string; // self | mother
type FormOptionMapping = { [key: string]: string };

/**
 * Ref Class to expose the methods to be consumed.
 */
export default class DomRef {
  readonly identifier = "mcb-web-components";

  constructor() {
    this._ready();
    this.setLoginEventListeners();
    this.setLogoutEventListeners();
  }


  /** Legacy mCB FBC API **/
  @computed get relationshipOptions() { return (mcbSearchCtrl.searchForm.getRenderedField("relationship") || {}).options || []; }
  @computed get provinceOptions() { return (mcbSearchCtrl.searchForm.getRenderedField("provinces") || {}).options || []; }
  @computed get cityOptions() { return (mcbSearchCtrl.searchForm.getRenderedField("municipalities") || {}).options || []; }

  private _ready = () => setCDMReadyState();

  getServiceTypes = () => {};

  getRelationships = (): FormOptionMapping => {
    const result: FormOptionMapping = {};
    for (const relationship of this.relationshipOptions) {
      if (relationship.hidden) continue;
      result[relationship.placeholder] = relationship.name;
    }
    return result;
  };
  getProvinces = (): FormOptionMapping => {
    const result: FormOptionMapping = {};
    for (const province of this.provinceOptions) {
      if (province.hidden) continue;
      result[province.placeholder] = province.name;
    }
    return result;
  };
  getCities = (options): FormOptionMapping => {
    options = options || {};
    const { showAll } = options;
    const result: FormOptionMapping = {};
    for (const city of this.cityOptions) {
      if (city.hidden && !showAll) continue;
      result[city.placeholder] = city.name;
    }
    return result;
  };

  setServiceType = async (typeName: ServiceTypeParam): Promise<void> => {
    typeName = typeName.toLowerCase();
    if (!typeName) return;
    const services = mcbSearchCtrl.availableServices;
    const groupTypeId = (services.find(s => s.placeholder.toLowerCase() === typeName) || {}).name;
    if (!groupTypeId) return;
    return mcbSearchCtrl.setService(groupTypeId);
  };

  setCareCircleRelationship = async (relationship: RelationshipParam): Promise<void> =>
    when(() => mcbSearchCtrl.ready)
    .then(() => mcbSearchCtrl.careCircleSearchForm.set("relationship", relationship));
  setCareCircleProvince = async (province: ProvinceParam): Promise<void> =>
    when(() => mcbSearchCtrl.ready)
    .then(() => mcbSearchCtrl.careCircleSearchForm.set("provinces", province));
  setCareCircleCity = async (city: CityParam): Promise<void> =>
    when(() => mcbSearchCtrl.ready)
    .then(() => mcbSearchCtrl.careCircleSearchForm.set("municipalities", city));

  setHouseholdRelationship = async (relationship: RelationshipParam): Promise<void> =>
    when(() => mcbSearchCtrl.ready)
    .then(() => mcbSearchCtrl.householdSearchForm.set("relationship", relationship));
  setHouseholdProvince = async (province: ProvinceParam): Promise<void> =>
    when(() => mcbSearchCtrl.ready)
    .then(() => mcbSearchCtrl.householdSearchForm.set("provinces", province));
  setHouseholdCity = async (city: CityParam): Promise<void> =>
    when(() => mcbSearchCtrl.ready)
    .then(() => mcbSearchCtrl.householdSearchForm.set("municipalities", city));

  setViewGroupId = (id: number): number => mcbSearchCtrl.setViewGroupId(id);

  searchListings = (event: any): Promise<void> => mcbSearchCtrl.searchListings();

  onExpandCriteriaClick = (method: (event: any) => void): void => {
    mcbSearchCtrl.onGetStartedExpandClick = method;
  };


  /** Methods for CAF and other bridge-consumer Direflow projects **/
  openWebApp = () => window.open(webAppUrl, "blank");

  isUserLoggedIn = (): boolean =>
    client.isLoggedIn
    && !mcbSessionCtrl.isValidVisitor
    && !mcbSessionCtrl.runningAuthChangeSequence;

  loginEvents: ({ name: string; handler: (param?: any) => any })[] = [];
  logoutEvents: ({ name: string; handler: (param?: any) => any })[] = [];

  private loginEventDebounce;
  private logoutEventDebounce;

  addLoginEventListener = (name: string, handler: (param?: any) => any) => {
    if (this.loginEvents.some(e => e.name === name)) return;
    this.loginEvents.push({
      name, handler
    });
  };
  removeLoginEventHandler = (name: string) => {
    this.loginEvents = this.loginEvents.filter(e => e.name !== name);
  };
  setLoginEventListeners = () => reaction(this.isUserLoggedIn, () => {
    clearTimeout(this.loginEventDebounce);
    this.loginEventDebounce = setTimeout(() => {
      if (isEmpty(this.loginEvents)) return;
      if (!this.isUserLoggedIn()) return; // Not actually logged in return
      for (const event of this.loginEvents) {
        if (typeof event.handler === "function") event.handler();
      }
    }, 200);
  });

  addLogoutEventListener = (name: string, handler: (param?: any) => any) => {
    if (this.logoutEvents.some(e => e.name === name)) return;
    this.logoutEvents.push({
      name, handler
    });
  };
  removeLogoutEventHandler = (name: string) => {
    this.logoutEvents = this.logoutEvents.filter(e => e.name !== name);
  };
  setLogoutEventListeners = () => reaction(this.isUserLoggedIn, () => {
    clearTimeout(this.logoutEventDebounce);
    this.logoutEventDebounce = setTimeout(() => {
      if (isEmpty(this.logoutEvents)) return;
      if (this.isUserLoggedIn()) return; // Not actually logged out return
      for (const event of this.logoutEvents) {
        if (typeof event.handler === "function") event.handler();
      }
    }, 200);
  });

  waitLoginFlowToFinish = (): Promise<true> => whenFulfill(this.isUserLoggedIn);

  setLoginBoxVisible = (isVisible: boolean, options?: LoginBoxOptions) => {
    stateCtrl.mcbSignInFormOpen = isVisible;
    stateCtrl.mcbSignInFormOptions = options || {} as LoginBoxOptions;
  };

  getCurrentLoggedInUser = (): User => {
    if (!this.isUserLoggedIn()) {
      console.warn("Please sign in.");
      return;
    }
    return toJS(client.user);
  };

  getCurrentLoggedInUserGroups = (): Group[] => {
    if (!this.isUserLoggedIn()) {
      console.warn("Please sign in.");
      return [];
    }
    return JSON.parse(JSON.stringify(toJS(client.findVisibleGroups())));
  };

  getCurrentLoggedInUserMembers = (): Member[] => {
    if (!this.isUserLoggedIn()) {
      console.warn("Please sign in.");
      return [];
    }
    return toJS(client.members);
  };

  getProfileById = async (profileId: number): Promise<Profile> => {
    if (!this.isUserLoggedIn()) {
      console.warn("Please sign in.");
      return;
    }
    return client.getProfileById(profileId);
  };

  updateProfileDataById = async (profileId: number, data: KeyValuePairs): Promise<Profile> => {
    if (!this.isUserLoggedIn()) {
      console.warn("Please sign in.");
      return;
    }
    if (isEmpty(data)) {
      throw new Error("Profile data cannot be empty otherwise existing profile data will be erased.");
    }
    return api.PATCH({
      endpoint: endpointConfig.update_profile_data_by_id_caf(profileId),
      data: { data }
    })
    .then(response => response.data);
  }; 

  getNeighbourhoodsData = async (lat: number, lng: number, radius: number) => {
    return api.GET({
      endpoint: endpointConfig.get_neighbourhoods_data(lat, lng, radius),
      headers: serverConfig.defaultHeaders,
    })
    .then(response => response.data);
  };

  assistEnglishSelection = (form: FormLegacy) => assistEnglishSelection(form);

  /** CDM controller and class getters **/
  getApi: () => Api = () => api;

  getClient: () => Client = () => client;

  getUi: () => UI = () => ui;

  getPlacesApi: () => Places = () => placesApi;

  getStateCtrl: () => State = () => stateCtrl;

  getTopicCtrl: () => TopicController = () => topicCtrl;

  getMcbSessionCtrl: () => McbSessionController = () => mcbSessionCtrl;

  getMcbSearchCtrl: () => McbSearchController = () => mcbSearchCtrl;

  genFormLegacy = (...args: ConstructorParameters<typeof FormLegacy>) => new FormLegacy(...args);
};

export let domRef = {} as DomRef;
export const initDomRef = constructor => domRef = constructor;