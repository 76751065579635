import { DireflowComponent } from "direflow-component";
import component from "../mcb-registration-form/component";
import { initCDM } from "../../client/boot";

export default initCDM().then(() => DireflowComponent.create({
  component,
  configuration: {
    tagname: "mcb-signup-family",
  },
  properties: {
    type: "family",
    contained: false
  }
}));