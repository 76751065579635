import { env } from '../env';
import { minutesToMilli } from '../../utils/helpers';

export const devServer = "devtest.mycarebase.com";
export const testServer = "test.mycarebase.com";
export const prodServer = "prod.mycarebase.com";
export const debugLocalServer = "localhost";

const hostDomain = "mycarebase.com";
const hostname = (env === "dev" || env === "local")
  ? devServer
  : env === "debug"
  ? debugLocalServer
  : (env === "test")
  ? testServer
  : env === "prod"
  ? prodServer
  : `${env}.${hostDomain}`;
const port = env === "debug" ? 8081 : 443;
const https = env !== "debug";
const pathname = "";

export const serverConfig = {
  hostname,
  port,
  https,
  pathname,

  // For hosting with the same hostname, assuming backend is port forwarded to external port 8080 and https, use below
  // hostname: window.location.hostname,
  // port: 8080,
  // https: window.location.protocol.match(/https/g),

  defaultHeaders: {
    "Content-Type": "application/json",
    Authorization: `Basic ${btoa("webapp:webapp")}`
  },
};

export const serverPortString =
  serverConfig.port.toString().match(/^80$|^443$/g)
    ? ""
    : `:${serverConfig.port}`;

export const serverHostnameString = `${
  serverConfig.https ? "https://" : "http://"
  }${serverConfig.hostname}${serverPortString}${serverConfig.pathname}`;

export const baseURL = serverHostnameString;

export const tokenIdleTimeout = minutesToMilli(20);