import { api } from "../../client/api";
import { endpointConfig } from "../../config/api";
import { serverConfig } from "../../config/api/base";
import { FormLegacy } from "../../client/form.legacy";
import { UIException, UIText } from "../../client/lang";
import { Group, Profile } from "../../lib/types/dataTypes";
import { groupTypeIdMatchMap, groupTypeIds } from "../config/constants";
import { isEmpty } from "../../utils/helpers";
import { PickerField, PickerOption, SupportedFieldTypes, TypeClassField } from "../../lib/types/formTypes.legacy";
import { toJS } from "mobx";
import { AggregatedRatingReviewResponseDTO } from "./types/dataTypes";


/**
 * Public data fetchers
 */
let searchFields = [];
export const getSearchFields = async () => isEmpty(searchFields) ? api.GET({
  endpoint: endpointConfig.get_wrapper_fields,
  headers: serverConfig.defaultHeaders
})
.then(response => searchFields = response.data) : searchFields;

let caregiverFields = [];
export const getCaregiverFields = async () => isEmpty(caregiverFields) ? api.GET({
  endpoint: endpointConfig.get_wrapper_caregiver_fields,
  headers: serverConfig.defaultHeaders
})
.then(response => caregiverFields = response.data) : caregiverFields;

let serviceGroupTypes = [];
export const getServiceGroupTypes = async () => isEmpty(serviceGroupTypes) ? api.GET({
  endpoint: endpointConfig.get_service_group_types,
  headers: serverConfig.defaultHeaders
})
.then(response => serviceGroupTypes = response.data) : serviceGroupTypes;


/**
 * Auto select English and lock field when no other languages are selected;
 */
export const assistEnglishSelection = (form: FormLegacy) => {
  if (!form) return;
  const noLangSelected = !form.renderable.some(
    f => (f.set && f.set.match(/needLanguages|ableLanguages/g))
      && f.name !== "needLanguages00"
      && f.name !== "ableLanguages00"
      && !!f.value
  );
  const English = form.renderable.find(f => f.name.match(/needLanguages00|ableLanguages00/g));
  if (!English) return;
  if (noLangSelected) {
    (!English.value || !English.disabled) && form.setField(English.name, {
      value: true,
      disabled: true
    });
  } else {
    English.disabled && form.setField(English.name, {
      disabled: false
    });
  }
};


/**
 * Set hourly rate field to mandatory for cleaning service providers;
 */
export const assistProviderFormMandatory = (form: FormLegacy, groupTypeId?: number) => {
  if (isEmpty(form)) return;
  const groupTypeIdField = form.getField("groupTypeId");
  if (isEmpty(groupTypeIdField) && !groupTypeId) return;
  // Required Hourly Rate conditional required
  const requiredHourlyRateField = form.getField("requiredHourlyRate");
  if (isEmpty(requiredHourlyRateField)) return;
  form.setField("requiredHourlyRate", {
    required: (groupTypeId || Number(groupTypeIdField.value)) === groupTypeIds.cleaner
  });
};


/**
 * Remove value for fields that does not match groupTypeId (mainly used during registration)
 */
export const assistDevalueFieldsAccordingToTypeId = (form: FormLegacy) => {
  if (isEmpty(form)) return;
  const groupTypeIdField = form.getField("groupTypeId");
  if (isEmpty(groupTypeIdField)) return;
  for (const field of form.form) {
    const groupTypeId = Number(groupTypeIdField.value);
    if (isNaN(groupTypeId)) continue;
    if (!field.groupTypeIds) continue;
    const shouldDevalue = !field.groupTypeIds.includes(Number(groupTypeIdField.value));
    form.setField(field.name, {
      hidden: shouldDevalue,
      ...shouldDevalue && { value: false }
    });
  }
};


/**
 * Helper function to validate and throw error message for service provider municipality fields.
 * At least one municipality is required.
 */
export const mandatoryAbleMunicipalityValidate = (form: FormLegacy) => {
  if (isEmpty(form)) return true;
  const ableTerritoryFields = form.renderable.filter(f => f.set === "ableTerritories");
  const ableMunicipalityFields = form.renderable.filter(
    f => f.set === "ableMunicipalities"
  );
  if (isEmpty(ableTerritoryFields)) return true;
  const pass =
    ableTerritoryFields.some(f => !!f.value) &&
    ableMunicipalityFields.some(f => !!f.value);
  if (!pass) {
    const territoryTitleField = form.getRenderedField("ableTerritories00");
    if (!isEmpty(territoryTitleField)) form.setField(territoryTitleField.name, {
      _errorMessage: " "
    });
    setTimeout(() => form.setField(territoryTitleField.name, {
      _errorMessage: undefined
    }), 500);
    throw new UIException("PROFILE_MANDATORY_MUNICIPALITY");
  }
  return true;
};


export const addRelationshipSelfOption = (form: FormLegacy) => {
  const relationshipField = form.getField("relationship");
  form.setField("relationship", {
    options: [
      { name: "self", placeholder: UIText.myself },
      ...relationshipField.options
    ]
  });
  form.set("relationship", "self");
};

export const fixMunicipalityForHousehold = (form: FormLegacy) => {
  const territoryField = form.getField("territories");
  if (territoryField) form.setField("territories", {
    dependOn: "provinces",
    hidden: false
  });
  const municipalityField = form.getField("municipalities");
  if (municipalityField) form.setField("municipalities", {
    dependOn: "territories",
    hidden: false
  });
};


/**
 * Alter the field group title for needLanguages when search is for service providers
 */
export const changeFieldLabels = (form: FormLegacy, groupTypeId: number) => {
  form.setField("provinces", { placeholder: UIText.province });
  form.setField("territories", { placeholder: UIText.territory });
  form.setField("municipalities", { placeholder: UIText.city });
  const English = form.getField("needLanguages00");
  if (English && English.title && groupTypeId === groupTypeIds.household) form.setField(English.name, {
    title: UIText.languageSetTitleProvider
  });
};

export const setGroupProfileData = (
  form: FormLegacy & { groupId?: number },
  group: Group,
  detectLocation?: () => Promise<any>,
  searchListings?: (noSave?: boolean) => Promise<void>,
  currentGroupId?: number,
  restoredDataCallback?: () => void,
  searchDataQueue?: Profile["data"],
  clearDataQueue?: () => void,
  doNotRestoreSearchForm?: boolean
) => {
  const search = () => searchListings && searchListings(true).then(restoredDataCallback);
  const groupName = group.groupName || "";
  const hasPreviousLoc = !groupName.match(/scratchpad/g) || groupName.match(/searched/g);
  if (group.id && currentGroupId === group.id && !hasPreviousLoc) {
    console.log("No previous location.");
    setTimeout(() => detectLocation().then(search));
  }
  if (!doNotRestoreSearchForm) {
    form.clearData();
    const dataOverride = !isEmpty(searchDataQueue) && searchDataQueue;
    let data = (group.profile || {}).data || {};
    if (dataOverride) {
      data = Object.assign(data, dataOverride);
      clearDataQueue();
    }
    for (const key of Object.keys(data)) form.set(key, data[key]);
  }
  form.groupId = group.id;
  setTimeout(form.resetDirty);
  return hasPreviousLoc && search();
};

export const matchWebsiteShortlist = t => t.description.match(/Website/ig);

export const removeLastName = (profile, noDot?) => {
  if (isEmpty(profile)) return profile;
  const lastName = (profile.data || {}).lastName;
  if (lastName) {
    profile.data.lastName = `${lastName.substring(0, 1)}${noDot ? "" : "."}`;
    profile.data.displayName = undefined;
  }
  return profile;
};

export const filterFormFieldsByService = (form: TypeClassField[], selectedService: number) => form && form.map(f => {
  const field = toJS(f);
  if (
    field.groupTypeIds
    && !groupTypeIdMatchMap[selectedService].every(id => field.groupTypeIds.includes(id))
  ) return undefined;
  if (field.type === "picker" && selectedService) {
    (field as PickerField).options = toJS((field as PickerField).options)
    .filter(o => filterFormFieldOptionsByService(o, selectedService));
  }
  return field as TypeClassField<SupportedFieldTypes>;
}).filter(Boolean);

export const filterFormFieldOptionsByService = (option: PickerOption, selectedService: number) => {
  if (isEmpty(option) || !selectedService) return false;
  return !option.groupTypeIds || groupTypeIdMatchMap[selectedService].some(id => option.groupTypeIds.includes(id));
};

export const getYoEAndPrice = (profileData, isCaregiver) => {
  if (isEmpty(profileData)) return {};
  const yearOfExp = isCaregiver
    ? profileData.yearsOfExperienceAsCaregiver && Number(profileData.yearsOfExperienceAsCaregiver)
    : profileData.yearsInBusiness && Number(profileData.yearsInBusiness);

  const caregiverPrice = isCaregiver && profileData.publishedHourlyRate;
  const providerPrice = !isCaregiver && (
    (!isNaN(Number(profileData.publishedRequiredHourlyRate)) && profileData.publishedRequiredHourlyRate) ||
    (!isNaN(Number(profileData.requiredHourlyRate)) && profileData.requiredHourlyRate)
  );
  const price = isCaregiver ? caregiverPrice : providerPrice;
  return { yearOfExp, price };
};

export const matchWpAdminPages = () => window.location.pathname && window.location.pathname.match(/wp-admin|pro/ig);

export const isSnSOrAdmin = user => user && user.isSa;

export const getRatingAggregatedScoreAndSamples = (aggregatedRatingReview: AggregatedRatingReviewResponseDTO) => {
  if (!aggregatedRatingReview) return { samples: 0, rating: 0 };
  const { reviews, testimonials } = aggregatedRatingReview;
  return {
    samples: (reviews || []).length + (testimonials || []).length, // The number of admin ratings will not count
    rating: aggregatedRatingReview.aggregatedRating // But aggregated rating will factor in admin rating scores.
  };
};