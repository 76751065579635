import { Controller } from "../lib/controller";
import { observable } from "mobx";
import { client } from "./client";
import { Group, GroupTypeRole } from "../lib/types/dataTypes";
import { es6GetCurrURLQueryParams, isEmpty, serializeObject } from "../utils/helpers";
import { LoginBoxOptions } from "../lib/types/stateTypes";
import { FormLegacy } from "./form.legacy";
import { IObservableArray } from "mobx/lib/types/observablearray";

export interface GlobalState {
  unreadCounters: { [key: number]: number };
}

// State.
// Main class instance for global ui and logic state store and control
// persistent data management and store.
export class State extends Controller<GlobalState> {
  constructor() {
    super();
    client.onLogout(this.storage.clearStore);
    client.onLogout(() => this.store.unreadCounters = {});
    this.setGlobalMethodsForSignInForm();
  }

  /** Account verification states **/
  verificationLock: boolean = false;


  /** Group setup states **/
  lastSetupGroupId = 0;

  /** Registration states **/
  @observable registrationIntentLock: GroupTypeRole["id"][];
  @observable registrationLock: boolean = false;
  additionalRegistrationData = {};


  /** Profile detail modal **/
  @observable mcbListingProfileIsEmbedded = false;
  @observable mcbListingProfileIsOpen = false;
  openMcbListingProfile = (groupId?) => {
    if (stateCtrl.mcbListingProfileIsEmbedded && groupId) {
      const queryParams = es6GetCurrURLQueryParams();
      const newQueryParams = {
        ...queryParams,
        gid: groupId
      };
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${serializeObject(newQueryParams)}`
      );
    }
    this.mcbListingProfileIsOpen = true;
  };
  closeMcbListingProfile = () => {
    const queryParams = es6GetCurrURLQueryParams();
    const { gid } = queryParams;
    if (gid) {
      delete queryParams.gid;
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${serializeObject(queryParams)}`
      );
    }
    this.mcbListingProfileIsOpen = false;
  };


  /** Sign in modal **/
  @observable mcbSignInFormOpen = false;
  @observable mcbSignInFormOptions: LoginBoxOptions = {} as LoginBoxOptions;


  /** Sign in form auto username on global method invocation **/
  @observable signInFormValue: string;
  signInFormChangeHandler: (value: any) => any;
  registerSignInFormChangeHandler = method => this.signInFormChangeHandler = method;
  onSignInFormUsernameChange = value => this.signInFormValue = value;
  setGlobalMethodsForSignInForm = () => {
    (window as any).showMcbSignIn = () => {
      this.mcbSignInFormOpen = true;
      if (this.signInFormChangeHandler && this.signInFormValue) {
        this.signInFormChangeHandler(this.signInFormValue);
      }
    };
  };
  closeSignInform = (event: any, skipEvent?: boolean) => {
    this.mcbSignInFormOpen = false;
    if (this.mcbSignInFormOptions?.onClose && !skipEvent) {
      return setTimeout(() => {
        if (this.mcbSignInFormOptions?.onClose) {
          this.mcbSignInFormOptions?.onClose(event);
        }
      }, 300);
    }
  };

  /** Sign in form auto refresh after successful login and sync WP login states **/
  @observable signInPostRefresh: boolean;
  @observable signInWaitForWp: boolean;
  // @observable signInWaitForWp: boolean = true; // For better user sync reliability, default to true.


  /** CAF-use mcb-market-results-popup states **/
  @observable mcbMarketResultsPopupIsOpen = false;
  @observable mcbMarketResultsPopupGroups = observable([]);
  openMarketResultsPopup = (searchResults: Group[]) => {
    if (isEmpty(searchResults)) return;
    this.mcbMarketResultsPopupGroups.replace(searchResults);
    this.mcbMarketResultsPopupIsOpen = true;
  };
  closeMarketResultsPopup = () => {
    this.mcbMarketResultsPopupGroups.clear();
    this.mcbMarketResultsPopupIsOpen = false;
  };
  marketResultsPopupSaveHandlers: { [name: string]: Function } = {};
  addMarketResultsPopupSaveHandler = (name: string, method: Function) => this.marketResultsPopupSaveHandlers[name] = method;
  onMarketResultsPopupSave = (event: any, profileId: number) => {
    for (const method of Object.values(this.marketResultsPopupSaveHandlers)) method(event, profileId);
  };
  isMarketResultsPopupProfileSaving: (profileId: number) => boolean = () => false;
  getMarketResultsPopupProfileSaveLoading = (method: (profileId: number) => boolean) => this.isMarketResultsPopupProfileSaving = method;
  @observable marketResultsPopupSavedProfileIds: IObservableArray<number> = observable([]);
  setMarketResultsPopupSavedProfileIds = (profileIds: number[]) => this.marketResultsPopupSavedProfileIds.replace(profileIds);
  marketResultsPopupChatHandler: Function;
  setMarketResultsPopupChatHandler = (method: ((profileId: number) => any)) => this.marketResultsPopupChatHandler = method;

  /** CAF-use mcb-market-criteria-form states **/
  @observable mcbMarketCriteriaFormUseMobile: boolean;
  setMarketCriteriaFormUseMobile = (isMobile: boolean) => this.mcbMarketCriteriaFormUseMobile = isMobile;

  /** Booking form states **/
  @observable bookingFormProfileId: number;
  @observable bookingFormVisible: boolean;
  showBookingForm = (profileId: number) => {
    if (profileId) {
      this.bookingFormProfileId = null;
      const queryParams = es6GetCurrURLQueryParams();
      const newQueryParams = {
        ...queryParams,
        bfpid: profileId
      };
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${serializeObject(newQueryParams)}`
      );
    }
    this.bookingFormProfileId = profileId;
    if (this.finderContextGroupId) {
      this.setBookingFormOrganizerGroupId(this.finderContextGroupId);
    }
  };
  dismissBookingForm = () => {
    const queryParams = es6GetCurrURLQueryParams();
    const { bfpid } = queryParams;
    if (bfpid) {
      delete queryParams.bfpid;
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${serializeObject(queryParams)}`
      );
    }
    this.bookingFormProfileId = null;
  };
  setBookingFormVisible = (isVisible: boolean) => this.bookingFormVisible = isVisible;
  @observable bookingFormOrganizerGroupId: number;
  setBookingFormOrganizerGroupId = (groupId: number) => {
    const queryParams = es6GetCurrURLQueryParams();
    if (groupId) {
      this.bookingFormOrganizerGroupId = null;
      const newQueryParams = {
        ...queryParams,
        bfgid: groupId
      };
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${serializeObject(newQueryParams)}`
      );
    }
    this.bookingFormOrganizerGroupId = groupId;
  };

  /** Appointment manager states **/
  @observable appointmentListContextGroupId: number;
  @observable appointmentManagerIsEmbedded: boolean = false;
  @observable appointmentManagerId: number;
  openAppointmentManagerEntry = (appointmentId: number) => {
    if (appointmentId) {
      this.appointmentManagerId = null;
      const queryParams = es6GetCurrURLQueryParams();
      const newQueryParams = {
        ...queryParams,
        aid: appointmentId
      };
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${serializeObject(newQueryParams)}`
      );
    }
    this.appointmentManagerId = appointmentId;
  };
  closeAppointmentManagerEntry = () => {
    const queryParams = es6GetCurrURLQueryParams();
    const { aid } = queryParams;
    if (aid) {
      delete queryParams.aid;
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${serializeObject(queryParams)}`
      );
    }
    this.appointmentManagerId = null;
  };
  setAppointmentListContextGroup = (groupId: number) => {
    if (groupId) {
      this.appointmentListContextGroupId = null;
      const queryParams = es6GetCurrURLQueryParams();
      const newQueryParams = {
        ...queryParams,
        aplistid: groupId
      };
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${serializeObject(newQueryParams)}`
      );
    }
    this.appointmentListContextGroupId = groupId;
  };

  /** For CAF 2.0 use group context **/
  @observable finderContextGroupId: number;
  clearFinderContextGroupId = () => {
    const queryParams = es6GetCurrURLQueryParams();
    const { cafccid } = queryParams;
    if (cafccid) {
      delete queryParams.cafccid;
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${serializeObject(queryParams)}`
      );
    }
    this.finderContextGroupId = null;
  };
  setFinderContextGroup = (groupId: number) => {
    if (groupId) {
      this.finderContextGroupId = null;
      const queryParams = es6GetCurrURLQueryParams();
      const newQueryParams = {
        ...queryParams,
        cafccid: groupId
      };
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${serializeObject(newQueryParams)}`
      );
    }
    this.finderContextGroupId = groupId;
  };


  /** Custom FormLegacy instance to be used in criteria form web-component **/
  @observable customSearchForm: FormLegacy;
  setCustomSearchForm = (searchForm: FormLegacy) => this.customSearchForm = searchForm;
}

// We would append Controller name here to prevent confusion with actual data.
export let stateCtrl = {} as State;
export const initStateCtrl = constructor => stateCtrl = constructor;