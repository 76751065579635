import { Controller } from "../lib/controller";
import { action, observable, toJS } from "mobx";
import { isEmpty, minutesToMilli, runInAsync } from "../utils/helpers";
import { api } from "./api";
import { endpointConfig } from "../config/api";
import { client } from "./client";
import { IObservableArray } from "mobx/lib/types/observablearray";
import { Avatar, DefaultAvatar, DefaultAvatarEntityType, File } from "../lib/types/dataTypes";
import { computedFn } from "mobx-utils";

export class FileController extends Controller {
  @observable avatars = [];
  @observable defaultAvatars: DefaultAvatar = {
    group: {} as DefaultAvatarEntityType,
    member: {} as DefaultAvatarEntityType
  };
  avatarLoading = {};
  avatarBlobLoading = {};

  checkDefaultAvatarInterval = minutesToMilli(5);
  defaultAvatarLoadTimeout;
  getAvatarQueueTimeout;

  defaultAvatarQueue = [];
  getAvatarQueue = [];

  constructor() {
    super();
    client.setPreloadAvatar(this.preloadProfileAvatar);
    client.setPreloadDefaultAvatar(this.preloadEntityDefaultAvatar);
  }

  getFileBinaryById = async (id: File["id"]): Promise<Blob> => {
    if (!id) return null;
    return api.GET({
      responseType: "blob",
      endpoint: endpointConfig.file_download_by_id(id)
    })
    .then(response => response.data || null);
  };

  getUserAvatarById = async (id: Avatar["id"]) => {
    if (!id) return {};
    return api.GET(endpointConfig.user_avatar_by_id(id))
    .then(response => response.data || {});
  };

  @action
  preloadProfileAvatar = async (avatarId, options) => {
    if (!avatarId) return;
    if (this.isAvatarLoading(avatarId)) return;

    options = options || {};

    const avatar = this.getProfileAvatarById(avatarId);
    if (isEmpty(avatar)) {
      this.avatarLoading[avatarId] = true;
      this.getAvatarQueue.push(avatarId);
      clearTimeout(this.getAvatarQueueTimeout);
      return (this.getAvatarQueueTimeout = setTimeout(() => {
        const queue = toJS(this.getAvatarQueue);
        this.getAvatarQueue = [];
        this.bulkLoadUserAvatar(queue).then(() =>
          Promise.all(queue.map(id => this.loadAvatarBinary(id, options)))
        );
      }, 200));
    } else if (!avatar.uri || (options.loadOrig && !avatar.origUri)) {
      this.avatarLoading[avatarId] = true;
      return this.loadAvatarBinary(avatarId, options);
    }
  };

  @action
  preloadEntityDefaultAvatar = async (entityId, entityType, options?) => {
    if (!entityId || !entityType) return;
    if (!this.defaultAvatars[entityType][entityId]) {
      this.defaultAvatars[entityType][entityId] = {};
    }

    if (this.shouldCheckDefaultAvatar(entityId, entityType)) {
      this.defaultAvatars[entityType][entityId].lastChecked = new Date().getTime();
      const queue = this.defaultAvatarQueue.find(
        q => q.entityType === entityType && q.entityId === entityId
      );
      if (!!queue) return;
      this.defaultAvatarQueue.push({ entityType, entityId });
      clearTimeout(this.defaultAvatarLoadTimeout);
      this.defaultAvatarLoadTimeout = setTimeout(() => this.bulkLoadDefaultAvatars(options), 200);
    }
  };

  @action
  loadAvatarBinary = async (avatarId, options) => {
    options = options || {};
    this.avatarLoading[avatarId] = true;
    return this.getProfileAvatarBlobUriAsync(avatarId, options.loadOrig)
    .then(
      action(uri => {
        const avatar = this.getProfileAvatarById(avatarId);
        if (options.loadOrig) {
          avatar.origUri = uri;
        } else {
          avatar.uri = uri;
        }
        return avatar;
      })
    )
    .catch(console.warn)
    .finally(
      () =>
        this.isAvatarLoading(avatarId) && delete this.avatarLoading[avatarId]
    );
  };

  @action
  bulkLoadDefaultAvatars = async (options?) => {
    if (this.defaultAvatarQueue.length === 0) return;
    if (!client.isLoggedIn) return;

    return api.POST({
      endpoint: endpointConfig.get_default_avatar_id_bulk,
      data: this.defaultAvatarQueue
    })
    .then(action(async response => {
      const defaultAvatars = Array.isArray(response.data) && response.data;
      const getDefaultAvatar = avatar => {
        this.defaultAvatarQueue = this.defaultAvatarQueue.filter(
          q =>
            q.entityType !== avatar.entityType &&
            q.entityId !== avatar.entityId
        );
        const avatarId =
          !isNaN(Number(avatar.avatarId)) && Number(avatar.avatarId);
        if (!avatarId) return;
        this.defaultAvatars[avatar.entityType][avatar.entityId].id = avatarId;

        const a = this.getProfileAvatarById(avatarId);
        if (isEmpty(a)) return this.preloadProfileAvatar(avatarId, options);
      };
      return Promise.all(defaultAvatars.map(getDefaultAvatar));
    }))
    .catch(console.warn);
  };

  @action
  bulkLoadUserAvatar = async queue => {
    if (isEmpty(queue)) return;
    if (!client.isLoggedIn) return;
    return api.POST({
      endpoint: endpointConfig.user_avatar_bulk,
      data: queue
    })
    .then(action(response => {
      const userAvatars = (response || {}).data || [];
      for (const avatar of userAvatars) {
        if (this.avatars.some(a => a.id === avatar.id)) continue;
        this.avatars.push(avatar);
      }
    }))
    .catch(console.warn);
  };

  shouldCheckDefaultAvatar = (entityId, entityType) => {
    if (!entityId || !entityType) return false;

    const checkedDefaultAvatar = this.defaultAvatars[entityType][entityId];
    if (!checkedDefaultAvatar || !checkedDefaultAvatar.lastChecked) return true;

    return (
      new Date().getTime() -
      new Date(checkedDefaultAvatar.lastChecked).getTime() >=
      this.checkDefaultAvatarInterval
    );
  };

  isAvatarLoading = avatarId => !!this.avatarLoading[avatarId];

  @action
  resetDefaultAvatarChecks = () => {
    for (const entityTypeKey of Object.keys(this.defaultAvatars)) {
      for (const entityId of Object.keys(this.defaultAvatars[entityTypeKey])) {
        setTimeout(action(() => delete this.defaultAvatars[entityTypeKey][entityId]));
        if (this.defaultAvatars[entityTypeKey][entityId].lastChecked) {
          delete this.defaultAvatars[entityTypeKey][entityId].lastChecked;
        }
      }
    }
  };

  @action
  resetAllAvatars = () => {
    this.resetDefaultAvatarChecks();
    (this.avatars as IObservableArray).clear();
    this.defaultAvatars.group = {};
    this.defaultAvatars.member = {};
  };

  getProfileAvatarById = avatarId =>
    this.avatars.find(a => a.id === avatarId) || {};

  getDefaultAvatarByEntityId = (entityId, entityType) => {
    if (!entityId || !entityType) return {};
    const defaultAvatar = this.defaultAvatars[entityType][entityId];
    if (!defaultAvatar) return {};
    return this.avatars.find(a => a.id === defaultAvatar.id) || {};
  };

  @action
  getProfileAvatar = async avatarId => {
    return api.GET(endpointConfig.user_avatar_by_id(avatarId))
    .then(
      action(avatar => {
        if (isEmpty(avatar)) return;
        this.avatars.push(avatar);
        return Promise.resolve();
      })
    )
    .catch(console.warn);
  };

  @action
  getProfileAvatarBlobUriAsync = async (avatarId, loadOrig) => {
    if (this.avatarBlobLoading[avatarId]) return "";
    this.avatarBlobLoading[avatarId] = true;

    const avatar = this.getProfileAvatarById(avatarId);
    // console.log("a", avatar)
    // if (isEmpty(avatar)) {
    //   this.avatarBlobLoading[avatarId] = false;
    //   return this.getProfileAvatar(avatarId)
    //   .then(() => asyncPause(100))
    //   .then(() => this.getProfileAvatarBlobUriAsync(avatarId, loadOrig));
    // }

    const fileId = (!loadOrig && avatar["thumbFileId"]) || avatar["oriFileId"];
    return this.getFileBinaryById(fileId)
    .then(blob => window.URL.createObjectURL(blob))
    .catch(console.warn)
    .finally(action(() => this.avatarBlobLoading[avatarId] && delete this.avatarBlobLoading[avatarId]));
  };

  getProfileAvatarUri = computedFn((avatarId, entityId, entityType) => {
    const nativeAvatar = this.getProfileAvatarById(avatarId);
    const defaultAvatar = this.getDefaultAvatarByEntityId(entityId, entityType);

    const avatar = isEmpty(nativeAvatar)
      ? isEmpty(defaultAvatar)
        ? {}
        : defaultAvatar
      : nativeAvatar;

    if (avatarId && isEmpty(avatar)) {
      runInAsync(this.preloadProfileAvatar)(avatarId);
      return "";
    }

    if (entityId && entityType && isEmpty(avatar)) {
      runInAsync(this.preloadEntityDefaultAvatar)(entityId, entityType);
    } else if (this.shouldCheckDefaultAvatar(entityId, entityType)) {
      runInAsync(this.preloadEntityDefaultAvatar)(entityId, entityType);
    }

    return avatar.origUri || avatar.uri || "";
  });

  getProfileAvatarOriginalUri = computedFn(avatarId => {
    const avatar = this.getProfileAvatarById(avatarId);
    if ((avatarId && isEmpty(avatar)) || !avatar.origUri) {
      runInAsync(this.preloadProfileAvatar)(avatarId, { loadOrig: true });
      return avatar.uri || "";
    }

    return avatar.origUri || avatar.uri || "";
  });

  downloadFileById = fileId => {};

  invokeWebDownload = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
}


export let fileCtrl = {} as FileController;
export const initFileCtrl = constructor => fileCtrl = constructor;