import "mobx-react-lite/batchingForReactDom";
import React from "react";
import DomRef, { initDomRef } from "../../client/ref";
import McbComponentPastry from "../../components/McbComponentPastry";
import AlertModal from "../../components/AlertModal";
import { ui } from "../../client/ui";
import { getQueryParameters, parseErrorMsg } from "../../utils/helpers";
import { env, isCrawler } from "../../config/env";
import { observer } from "mobx-react";
import McbWPWebsiteRewire from "../../components/McbWPWebsiteRewire";

const emptyRenderer = { render: () => null } as McbInitializer;
const rewireRenderer = { render: () => <McbWPWebsiteRewire /> } as McbInitializer;

export interface McbInitializerProps {}

@observer
class McbInitializer extends React.Component<McbInitializerProps> {
  domRef: DomRef;
  constructor(props) {
    super(props);
    // Prevent multiple instances.
    const existsSelf = document.getElementsByTagName("mcb-initializer");
    if (existsSelf && existsSelf.length > 1) return emptyRenderer;
    if (this.domRef) return emptyRenderer;
    if ((getQueryParameters(window.location.search) || {})["noWc"] === "1") return emptyRenderer;
    if (isCrawler) return rewireRenderer;
    this.domRef = initDomRef(new DomRef());
  }

  render() {
    return <McbComponentPastry>
      <McbWPWebsiteRewire />
      <AlertModal
        isOpen={!!ui.alertOpen}
        showBackdrop
        allowBackdropDismiss={!!ui.alertBackdropDismiss}
        buttons={ui.alertButtons}
        title={ui.alertTitle}
        message={ui.alertMessage}
        onClose={ui.dismissAlert}
      />
      <AlertModal
        isOpen={!!ui.error}
        showBackdrop
        buttons={ui.errorButtons}
        title={ui.errorTitle}
        message={parseErrorMsg(ui.error, env !== "prod")}
        onClose={ui.dismissError}
      />
    </McbComponentPastry>;
  }
}

export default McbInitializer;