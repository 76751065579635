import { DireflowComponent } from "direflow-component";
import component from "../mcb-market-search/component";
import { initCDM } from "../../client/boot";
import { groupTypeIds } from "../../mcb/config/constants";

export default initCDM().then(() => DireflowComponent.create({
  component,
  configuration: {
    tagname: "mcb-market-search-caregiver",
  },
  properties: {
    serviceType: groupTypeIds.caregiver
  }
}));