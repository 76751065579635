import { Controller } from "../../lib/controller";
import { groupTypeIds, groupTypeRoleIds } from "../config/constants";
import { asyncPause, getCookie, isEmpty, isEqual, randomString, whenFulfill } from "../../utils/helpers";
import { api } from "../../client/api";
import { endpointConfig } from "../../config/api";
import { computed, observable, reaction, when } from "mobx";
import { AxiosResponse } from "axios";
import { Group, OAuth2Data } from "../../lib/types/dataTypes";
import * as publicIp from "public-ip";
import { Client, client } from "../../client/client";
import { serverConfig } from "../../config/api/base";
import { VisitorLoginData } from "../lib/types/dataTypes";
import $ from "jquery";
import { FrameEventListener } from "../../lib/types/miscTypes";
import { matchWpAdminPages } from "../lib/common";
import { env, rootDomain, webAppEnv } from "../../config/env";

export interface McbSessionStore {
  careCircleScratchpadId: Group["id"];
  householdScratchpadId: Group["id"];
}

export class McbSessionController extends Controller<McbSessionStore> {
  sourceUrl: string;
  eventListeners: FrameEventListener[] = [];

  isEmbedded: boolean = false;
  parentOrigin: string;

  groupDataChangeInfo: { id: Group["id"], typeId: typeof groupTypeIds[keyof typeof groupTypeIds]};
  groupDataChangeCallback: (groupInfo: McbSessionController["groupDataChangeInfo"]) => Promise<any>;

  authChangeDebouncer;
  authChangeDebounceTime = 250;
  @observable runningAuthChangeSequence: boolean = false;

  getLoggedInUserDataDebouncer;
  getLoggedInUserDataDebounceTime = 500;

  wpLoginDebounce;
  wpLogoutDebounce;
  wpAuthDebounceTime = 100;
  @observable wpLoginSuccess: boolean;
  @observable wpLoginError: any;
  @observable hasShoppingGroup: boolean;

  @observable deviceId: string;
  @observable webAppOAuth: OAuth2Data = {} as OAuth2Data;
  @observable appIsVisitor: boolean = false;
  renewingOAuthData: boolean = false;
  cookieInterval;

  get isFramed(): boolean {
    return window.parent && (window.self !== window.parent);
  };

  @computed get appCredentialReady(): boolean {
    return !isEmpty(this.webAppOAuth);
  };
  @computed get appIsLoggedIn(): boolean {
    return this.appCredentialReady && !this.appIsVisitor;
  };

  @computed get isInterimUser(): boolean {
    return !client.isVisitor && Number(client.user.status) === 2;
  };
  @computed get isValidVisitor(): boolean {
    return !!this.careCircleScratchpadId
      && !!this.householdScratchpadId
      && client.isVisitor;
  };

  @computed get careCircleScratchpadId(): number {
    return this.store.careCircleScratchpadId;
  };
  @computed get householdScratchpadId(): number {
    return this.store.householdScratchpadId;
  };
  @computed get careCircleScratchpad(): Group {
    return client.findGroupById(this.careCircleScratchpadId) || {} as Group;
  };
  @computed get householdScratchpad(): Group {
    return client.findGroupById(this.householdScratchpadId) || {} as Group;
  };

  constructor() {
    super();
    client.setOAuthRenewHandler(this.renewLoggedInUserOAuthData);
    console.log({ clientId: client.id });

    if (window.parent && (window.self !== window.parent)) {
      console.log("McbSessionController", "Web-component embedded mode detected");
      window.parent.postMessage({
          fromMCB: true,
          timestamp: new Date().getTime(),
          frame2AppParent: true,
          data: { clientId: client.id }
        },
        "*"
      );
      window.addEventListener("message", this.processMessages);
      this.initializeEmbedded();
    } else {
      (window as any).mcbSignOut = () => this.sessionLogout();
    }

    this.storage.isReady()
    .then(client.storage.isReady)
    .then(this.checkDeviceId)
    .then(this.startCookieTimer)
    .then(this.handleAppAuthChange)
    .then(() => this.disposers.push(reaction(() => [client.credentialReady, this.appIsLoggedIn], this.handleAppAuthChange)));
  }

  checkDeviceId = () => {
    const deviceId = getCookie(`${webAppEnv}device`);
    if (deviceId) return;
    (document.cookie = `${webAppEnv}device=${
      randomString()
    }; domain=.${rootDomain}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/; secure; samesite=lax`);
  };

  startCookieTimer = () => {
    const sync = () => {
      if (this.renewingOAuthData) return;
      const deviceId = getCookie(`${webAppEnv}device`);
      if (!isEqual(deviceId, this.deviceId)) this.deviceId = deviceId;
      const oauth = Client.deflavorOAuth2Data(getCookie(`${webAppEnv}oid`), this.deviceId) || {} as OAuth2Data;
      if (!isEqual(oauth, this.webAppOAuth)) this.webAppOAuth = oauth;
      if (!isEmpty(oauth) && !isEqual(oauth, client.oauth)) client.updateOAuth2Data(oauth);
      const appIsVisitor = getCookie(`${webAppEnv}visitor`) === "true";
      if (!isEqual(appIsVisitor, this.appIsVisitor)) this.appIsVisitor = appIsVisitor;
    };
    sync();
    clearInterval(this.cookieInterval);
    this.cookieInterval = setInterval(sync, 1000);
  };

  initializeEmbedded = () => {
    this.isEmbedded = true;
    this.getParentOrigin();
    this.enableParentStorageSync();
  };

  getParentOrigin = async () => this.postParentMessage({ getOrigin: true }, true);

  enableParentStorageSync = async () => {
    await whenFulfill(() => !!this.parentOrigin);
    return this.postParentMessage({ enableStorageSync: true });
  };

  updateParentOrigin = (origin: string) => this.parentOrigin = origin;

  postParentMessage = async (data, wildcard?: boolean) => {
    if (!wildcard) await whenFulfill(() => !!this.parentOrigin);
    return window.parent.postMessage(
      {
        fromMCB: true,
        timestamp: new Date().getTime(),
        frame2AppParent: true,
        data
      },
      wildcard ? "*" : this.parentOrigin
    );
  };

  processMessages = event => {
    const { data } = event;
    if (!data || !data.fromMCB) return;
    const messageData = data.data;
    const { app2FrameChild, app2FrameParent } = data;
    if (!app2FrameChild && !app2FrameParent) return;
    console.log(app2FrameChild
      ? `web app (parent) -> web-component:`
      : app2FrameParent
        ? `web app (iframe) -> web-component:`
        : `unknown/uni-direction:`
      , env !== "prod" && messageData);
    this.handleMessages(messageData);
  };

  handleMessages = message => {
    const types = Object.keys(message);
    for (const type of types) {
      if (!type) continue;
      if (type === "parentOrigin") this.updateParentOrigin(message[type]);
      if (type === "deviceId") this.deviceId = message[type];
      // if (type === "storage") this.extractOAuthData(message[type]);
      for (const listener of this.eventListeners) {
        if (type === listener.type) listener.handler(message[type]);
      }
    }
  };

  addEventListener = (type, handler) => {
    if (this.eventListeners.some(listener => listener.type === type)) return;
    return this.eventListeners.push({
      type,
      handler
    });
  };

  removeEventListener = (type: FrameEventListener["type"]) => this.eventListeners = this.eventListeners.filter(
    listener => listener.type !== type
  );

  handleAppAuthChange = async () => {
    clearTimeout(this.authChangeDebouncer);
    this.authChangeDebouncer = setTimeout(() => {
      return when(() => !this.runningAuthChangeSequence, () => {
        this.runningAuthChangeSequence = true;
        this.clearScratchpadIds();
        return this.execAuthChangeSequence()
        .finally(() => this.runningAuthChangeSequence = false);
      });
    }, this.authChangeDebounceTime);
  };

  execAuthChangeSequence = async () => {
    if (env !== "prod") {
      console.log("execAuthChangeSequence", {
        "client.credentialReady": client.credentialReady,
        "appIsLoggedIn": this.appIsLoggedIn,
        "client.isVisitor": client.isVisitor,
        "isValidVisitor": this.isValidVisitor
      });
    }
    if (client.credentialReady) {
      // Local data has credential
      if (this.appIsLoggedIn) {
        // Bg Sync Mode has non-visitor user credential, run in signed-in mode. <-------------------
        if (client.isVisitor) { //                                                                 |
          // Local data is still visitor then switch to signed-in credential first.                |
          return this.loginAsSignedInUser().then(this.getLoggedInUserData); //                     |
        } //                                                                                       |
        return this.getLoggedInUserData(); //                                                      |
      } else { //                                                                                  |
        // Bg Sync Mode has no credential, check existing local data for visitor validity. //      |
        if (!this.isValidVisitor) { //                                                             |
          // Local data has no valid visitor user, logout and go to next else.                     |
          return client.logout(); //                                                               |
        } //                                                                                       |
        // Load data for visitor experience  <------------------------------------                 |
        return this.getVisitorGroups() //                                        |                 |
      } //                                                                       |                 |
    } else { //                                                                  |                 |
      // Local data has no credential                                            |                 |
      if (!this.appIsLoggedIn) { //                                              |                 |
        // Bg Sync Mode has no credential, login as visitor, go to ---------------                 |
        return this.loginAsVisitor(); //                                                           |
      } else { //                                                                                  |
        // Bg Sync Mode has non-visitor credential, go to ------------------------------------------
        return this.loginAsSignedInUser();
      }
    }
  };

  loginAsVisitor = async () => {
    await whenFulfill(() => !!this.deviceId);
    const ipAddress = await publicIp.v4().catch(console.warn);
    const data = {
      ipAddress,
      sourceUrl: this.sourceUrl,
      deviceId: this.deviceId,
      clientType: "web-component"
    };
    return api.POST({
      endpoint: endpointConfig.visitor_device_id_login,
      headers: serverConfig.defaultHeaders,
      data
    })
    .then((response: AxiosResponse<VisitorLoginData>) => {
      const { oauth, careCircleScratchpadId, householdScratchpadId } = response.data || {};
      if (isEmpty(oauth)) return;
      client.updateOAuth2Data(oauth);
      this.store.careCircleScratchpadId = careCircleScratchpadId;
      this.store.householdScratchpadId = householdScratchpadId;
      setTimeout(this.logoutWpUserDebounce);
      return client.getAndStoreUser();
    })
    .catch(() => this.dispose());
  };

  loginAsSignedInUser = async () => when(() => !isEmpty(this.webAppOAuth))
    .then(client.logout)
    .then(client.getAndStoreUser)
    .catch(err => {
      console.warn(err);
    });

  setAppOAuth2Data = (oauth: OAuth2Data) => {
    const oid = Client.flavorOAuth2Data(oauth, this.deviceId);
    document.cookie = `${webAppEnv}oid=${oid}; domain=.${rootDomain}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT; secure; samesite=lax`;
  };

  getScratchpad = async (type: "careCircle" | "household") => {
    if (!client.credentialReady) return;
    const group = await client.getGroupById(this[`${type}ScratchpadId`]).catch(console.warn);
    return when(() => client.initialized)
    .then(() => client.updateGroup(group))
    .then(group => {
      if (typeof this.groupDataChangeCallback === "function" && (group || {}).id) {
        this.groupDataChangeCallback({ id: group.id, typeId: groupTypeIds[type] });
      }
    });
  };

  // Use sequential get to prevent group updates conflicting.
  getVisitorGroups = async () => {
    setTimeout(this.logoutWpUserDebounce);
    await client.initialize();
    return (this.careCircleScratchpadId ? this.getScratchpad("careCircle") : (async () => {})())
    .then(() => (this.householdScratchpadId ? this.getScratchpad("household") : (async () => {})()));
  };

  getLoggedInUserData = async () => {
    clearTimeout(this.getLoggedInUserDataDebouncer);
    this.getLoggedInUserDataDebouncer = setTimeout(async () => {
      await whenFulfill(() => !isEmpty(this.webAppOAuth));
      await client.initialize();
      // Time native 401 out for 10 seconds so auth sequence can continue.
      await when(() => client.initialized && client.isLoggedIn, { timeout: 10000 });
      const careCircles = client.findGroups(g => g.typeId === groupTypeIds.careReceiver);
      const households = client.findGroups(g => g.typeId === groupTypeIds.household);
      // There might be users with only one side of Care Circle / Household or no groups at all.
      if (!isEmpty(careCircles)) {
        // Only set Care Circle if self role is not Paid Provider
        const careCircle = careCircles.find(careCircle => {
          const selfMember = careCircle.members?.find(m => m.userId === client.userId);
          const selfRole = selfMember?.roleList[0];
          if (!selfRole) return null;
          return ![groupTypeRoleIds.paidCaregiver].includes(selfRole.groupTypeRoleId);
        });
        if (!isEmpty(careCircle)) {
          this.store.careCircleScratchpadId = careCircle.id;
          if (typeof this.groupDataChangeCallback === "function") {
            this.groupDataChangeCallback({ id: careCircle.id, typeId: groupTypeIds.careReceiver });
          }
        }
      }
      if (!isEmpty(households)) {
        this.store.householdScratchpadId = households[0].id;
        if (typeof this.groupDataChangeCallback === "function") {
          this.groupDataChangeCallback({ id: households[0].id, typeId: groupTypeIds.household });
        }
      }
      this.loginWpUser();
    }, this.getLoggedInUserDataDebounceTime);
  };

  renewLoggedInUserOAuthData = async () => {
    console.log("Web app refresh request");
    this.renewingOAuthData = true;
    return api.POST({
      renewingOAuth2Data: true,
      noRenew: true,
      endpoint: endpointConfig.login,
      headers: serverConfig.defaultHeaders,
      data: {
        grant_type: "refresh_token",
        refresh_token: this.webAppOAuth.refresh_token
      }
    })
    .then((response: AxiosResponse<OAuth2Data>) => {
      const oauth = response.data;
      client.updateOAuth2Data(oauth);
      this.setAppOAuth2Data(oauth);
    })
    .finally(() => this.renewingOAuthData = false);
  };

  setGroupDataChangeCallback = (method: McbSessionController["groupDataChangeCallback"]) => {
    this.groupDataChangeCallback = method;
  };

  clearScratchpadIds = () => {
    if (client.credentialReady) return;
    this.store.careCircleScratchpadId = undefined;
    this.store.householdScratchpadId = undefined;
  };

  loginWpUser = () => {
    clearTimeout(this.wpLoginDebounce);
    clearTimeout(this.wpLogoutDebounce);
    this.wpLoginDebounce = setTimeout(async () => {
      console.log("Check WP User", new Date().getTime());
      if (await this.wpAlreadyLoggedIn()) {
        return this.wpLoginSuccess = true;
      }
      return api.GET(endpointConfig.wp_auto_login_link("/my-account?noWc=1"))
      .then(async response => {
        const url = response.data;
        if (!url) return;
        return fetch(url);
      })
      .then(response => {
        this.wpLoginSuccess = !!response;
        console.log("WP login success", this.wpLoginSuccess);
      })
      .catch(err => {
        console.warn(err);
        this.wpLoginError = err;
      });
    }, this.wpAuthDebounceTime);
  };

  logoutWpUserDebounce = () => {
    clearTimeout(this.wpLogoutDebounce);
    this.wpLogoutDebounce = setTimeout(this.logoutWpUser, 5000);
  };

  logoutWpUser = () => {
    if (this.hasShoppingGroup || matchWpAdminPages()) return;
    console.log("Logout WP User");
    this.wpLoginSuccess = false;
    return fetch(`/my-account/customer-logout`).catch(console.warn);
  };

  wpAlreadyLoggedIn = async () => {
    return fetch(`/my-account?noWc=1`)
    .then(async response => {
      const html = await response.text();
      const $subDoc = $("<div></div>").append(html);
      const $strong = $subDoc.find(".woocommerce-MyAccount-content").find("strong");
      const username = $strong.length > 0 && $strong.html();
      const loggedIn = username && username === client.user.userName;
      console.log("WP user", loggedIn ? "already logged in" : "not logged in", username || "");
      return loggedIn;
    });
  };

  sessionLogout = async () => {
    this.runningAuthChangeSequence = true;
    document.cookie = `${webAppEnv}oid=; domain=.${rootDomain}; path=/; secure; samesite=lax`;
    this.webAppOAuth = {} as OAuth2Data;
    await this.logoutWpUser();
    await asyncPause(500);
    return client.logout()
    .then(() => window.location.reload())
    .catch(() => window.location.reload());
  };
}

// We would append Controller name here to prevent confusion with actual data.
export let mcbSessionCtrl = {} as McbSessionController;
export const initMcbSessionCtrl = constructor => mcbSessionCtrl = constructor;