import React from "react";
import { observer } from "mobx-react";
import withJssMap from "../../components/withJssMap";
import McbComponentPastry from "../../components/McbComponentPastry";

@observer
class TestComponent extends React.Component {
  render() {
    return<McbComponentPastry>
      <div style={{ width: "600px", height: "600px" }}>
        123
      </div>
    </McbComponentPastry>;
  }
}

export default withJssMap(TestComponent);
