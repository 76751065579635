import { Controller } from "../../lib/controller";
import { computed, IObservableArray, observable, when } from "mobx";
import { ProductResponseDTO } from "../../mcb/lib/types/dataTypes";
import { api } from "../../client/api";
import { endpointConfig } from "../../config/api";
import { contextReject, isEmpty } from "../../utils/helpers";
import { serverConfig } from "../../config/api/base";
import { Product } from "../../mcb/lib/types/wooCommerceTypes";
import { shopping, ShoppingController } from "../../mcb/client/shopping";

export class McbShopPresentationController extends Controller {
  @observable products: IObservableArray<ProductResponseDTO> = observable([]);
  @observable productCategorySlug: string;


  @computed get categorizedProducts(): ProductResponseDTO[] {
    return this.products.filter(product => {
      const wpProduct = product.wpProduct || {} as Product;
      return (wpProduct.categories || []).some(c => c.slug === this.productCategorySlug);
    });
  };
  @computed get productCategoryName(): string {
    const categories = ((this.categorizedProducts[0] || {}).wpProduct || {}).categories || [];
    return (categories[0] || {}).name;
  };

  @computed get cartable(): boolean {
    return ShoppingController.isFramed();
  };
  @computed get cartableReady(): boolean {
    return !!shopping.shoppingGroupId;
  };

  isReady = async () => {
    const pathnames = window.location.pathname.split("/");
    const slug = pathnames && pathnames[2];
    if (!slug) return window.location.pathname = "/";
    this.productCategorySlug = slug;
    await this.loadAllData().catch(contextReject);
    return when(() => !isEmpty(this.categorizedProducts));
  };

  loadAllData = async () => Promise.all([
    this.getAvailableProducts()
  ]);

  getAvailableProducts = async () => api.GET({
    endpoint: endpointConfig.get_available_products,
    headers: serverConfig.defaultHeaders
  })
  .then(response => this.products = (response && response.data) || []);

  addProductToCart = async (wpProduct: Product) => {
    return window.location.href = `//${window.location.hostname}/checkout?clear-cart&add-to-cart=${wpProduct.id}`
  };
}