import { Controller } from "../../lib/controller";
import { FormLegacy } from "../../client/form.legacy";
import { registrationForm, RegistrationFormData, serviceProviderGroupTypeField } from "../../mcb/config/registrationForm";
import { autorun, computed, observable, reaction, toJS, when } from "mobx";
import { asyncPause, contextReject, randomStringFour, safeParseJSON } from "../../utils/helpers";
import { mcbSearchCtrl } from "../../mcb/client/search";
import { groupTypeIds, groupTypeRoleIds, serviceProviderGroupTypeIds } from "../../mcb/config/constants";
import { KeyValuePairs } from "../../lib/types/miscTypes";
import publicIp from "public-ip";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { api } from "../../client/api";
import { serverConfig } from "../../config/api/base";
import { endpointConfig } from "../../config/api";
import { RegistrationData } from "../../lib/types/dataTypes";
import { UIException, UIText } from "../../client/lang";
import { matchServiceGroupTypeRoleByGroupTypeId } from "../../lib/group-utilities";
import {
  assistDevalueFieldsAccordingToTypeId,
  assistEnglishSelection,
  assistProviderFormMandatory,
  mandatoryAbleMunicipalityValidate
} from "../../mcb/lib/common";

export class McbRegistrationFormController extends Controller {
  form: FormLegacy<RegistrationFormData>;
  vendorForm: FormLegacy = {} as FormLegacy;

  @observable type: "family" | "vendor";
  @observable _termsChecked: boolean = false;
  @observable termsError: boolean = false;

  @observable recruitResultForm = {};

  @computed get vendorIntent(): string {
    return this.type === "vendor" && this.form && this.form.get("intents").toString();
  };
  @computed get isCaregiver(): boolean {
    return (safeParseJSON(this.vendorIntent, true) || []).includes(groupTypeRoleIds.caregiver);
  };
  @computed get isProviderRecruit(): boolean {
    return this.type === "vendor" && this.form && this.form.get("isRecruit") && !this.isCaregiver;
  };
  @computed get isProviderSignUp(): boolean {
    return this.type === "vendor" && !this.isCaregiver && !this.isProviderRecruit
  };
  @computed get hideTerms(): boolean {
    return this.isCaregiver || this.isProviderRecruit;
  };
  @computed get termsChecked(): boolean {
    return this.hideTerms || this._termsChecked;
  };

  @computed get formErrorFields() {
    return (this.form && this.form.errorFields) || [];
  };
  @computed get vendorFormErrorFields() {
    return (this.vendorForm && this.vendorForm.errorFields) || [];
  };

  isReady = () => when(() => mcbSearchCtrl.caregiverForm && mcbSearchCtrl.caregiverForm.ready);

  setType = (type: McbRegistrationFormController["type"]) => {
    this.type = type;
    this.form = new FormLegacy(registrationForm(type));
    if (type === "vendor") return this.setVendorForm().then(this.detectURLVendorSignUpType);
  };

  setVendorForm = async () => {
    await this.isReady();
    const vendorFields = toJS(mcbSearchCtrl.caregiverForm.blank);
    this.vendorForm = new FormLegacy([
      serviceProviderGroupTypeField,
      ...vendorFields.filter(field =>
        field.type !== "avatar" &&
        !Object.keys(this.form.data).some(f => field.name.match(f)) &&
        field.groupTypeIds &&
        serviceProviderGroupTypeIds.some(id =>
          field.groupTypeIds.includes(id)
        )
      )
    ], null, { caregiver: true });
    this.disposers.push(
      reaction(() => this.isProviderRecruit, this.autoShowHidePasswordFields),
      reaction(() => this.hideTerms, this.autoPhoneFieldRequired),
      autorun(() => assistEnglishSelection(this.vendorForm)),
      autorun(() => assistProviderFormMandatory(this.vendorForm)),
      autorun(() => assistDevalueFieldsAccordingToTypeId(this.vendorForm))
    );
    this.autoShowHidePasswordFields();
    this.autoPhoneFieldRequired();
  };

  detectURLVendorSignUpType = () => {
    const pathnames = window.location.pathname.split("/");
    const vendorType = pathnames && pathnames[2];
    if (!vendorType || vendorType === "caregiver") return;
    this.form.set("isRecruit", true);
    if (vendorType.match(/cleaning|cleaner/ig)) {
      this.form.set("intents", "other");
      this.vendorForm.set("groupTypeId", groupTypeIds.cleaner.toString());
    }
    if (vendorType.match(/handyman/ig)) {
      this.form.set("intents", "other");
      this.vendorForm.set("groupTypeId", groupTypeIds.handyman.toString());
    }
    if (vendorType.match(/transportation/ig)) {
      this.form.set("intents", "other");
      this.vendorForm.set("groupTypeId", groupTypeIds.transportation.toString());
    }
  };

  autoShowHidePasswordFields = () => {
    this.form.setField("password", {
      hidden: !!this.isProviderRecruit,
      value: !!this.isProviderRecruit && undefined
    });
    this.form.setField("repeat", {
      hidden: !!this.isProviderRecruit,
      value: !!this.isProviderRecruit && undefined
    });
  };

  autoPhoneFieldRequired = () => this.form.setField("phone", {
    required: this.hideTerms
  });

  onTermsCheck = () => {
    this.resetTermsError();
    this._termsChecked = !this._termsChecked;
  };

  resetTermsError = () => this.termsError = false;

  onRegister = async () => {
    if (!this.termsChecked) this.termsError = true;
    const validations = await Promise.all([
      this.form.validate(null, true),
      this.type !== "vendor" || this.isCaregiver || this.vendorForm.validate(null, true)
    ].filter(Boolean));
    const formPass = validations[0];
    const vendorFormPass = validations[1];

    if (!formPass) return;
    if (!vendorFormPass) return;
    if (this.termsError) return;

    try {
      this.vendorIntent === "other" && mandatoryAbleMunicipalityValidate(this.vendorForm)
    }
    catch (e) {
      return contextReject({
        ...e,
        actionName: UIText.registerCheckEntries
      });
    }

    const data: Partial<RegistrationData> = toJS(this.form.data);
    data.username = (this.form.get("email") as string).split("@")[0] + "_" + randomStringFour();
    data.ip = await publicIp.v4();
    data.deviceId = mcbSessionCtrl.deviceId;
    const profile = this.type === "vendor" && toJS(this.vendorForm.data);

    if (this.isCaregiver) return this._onCaregiverRecruit(data as Required<RegistrationData>);
    if (this.isProviderRecruit) return this._onProviderRecruit(data as Required<RegistrationData>, profile);
    if (this.isProviderSignUp) return this._onProviderRegister(data as Required<RegistrationData>, profile);
    return this._onNormalRegister(data as Required<RegistrationData>);
  };

  private _onNormalRegister = async (data: RegistrationData) =>
    api.POST({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.register,
      data
    })
    .then(() => true);

  private _onCaregiverRecruit = async (data: RegistrationData) =>
    api.POST({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.caregiver_onboard,
      data
    })
    .then(response => {
      const user = response.data;
      if (!user || !user.onboardingId) throw new UIException("CAREGIVER_APPLICATION_EXCEPTION");
      // this.recruitResultForm = {
      //   ...data,
      //   token: user.access_token,
      //   onboardingTopicId: user.topicId,
      //   calendlyUrl: user.calendlyUrl
      // };
      return asyncPause(0);
    })
    .then(() => true);

  private _onProviderRecruit = async (data: RegistrationData, profile: KeyValuePairs) => {
    const groupTypeId = Number(profile.groupTypeId);
    const profileData = { ...profile, groupTypeId: undefined };
    data.intents = JSON.stringify(
      [matchServiceGroupTypeRoleByGroupTypeId(groupTypeId)].filter(Boolean)
    );
    return api.POST({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.provider_onboard,
      data: {
        user: data,
        profile: profileData,
        groupTypeId,
      }
    })
    .then(response => {
      const user = response.data;
      if (!user || !user.calendlyUrl) throw new UIException("SERVICE_PROVIDER_APPLICATION_EXCEPTION");
      this.recruitResultForm = {
        ...data,
        token: user.access_token,
        onboardingTopicId: user.topicId,
        calendlyUrl: user.calendlyUrl
      };
      return asyncPause(0);
    })
    .then(() => true);
  };

  private _onProviderRegister = async (data: RegistrationData, profile: KeyValuePairs) => {
    const groupTypeId = Number(profile.groupTypeId);
    const profileData = { ...profile, groupTypeId: undefined };
    data.intents = JSON.stringify(
      [matchServiceGroupTypeRoleByGroupTypeId(groupTypeId)].filter(Boolean)
    );
    return api.POST({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.provider_signup_no_vet,
      data: {
        user: data,
        profile: profileData,
        groupTypeId
      }
    })
    .then(() => true);
  };
}