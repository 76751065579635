import Axios, { AxiosInstance, AxiosRequestConfig, Method } from "axios";
import { computed, observable } from "mobx";
import { baseURL, serverConfig } from "../config/api/base";
import { asyncPause, capitalize, isEmpty } from "../utils/helpers";
import { ApiOptions } from "../lib/types/miscTypes";
import { OAuth2Data } from "../lib/types/dataTypes";
import { Controller } from "../lib/controller";
import flags from "../config/flags";

const config: Partial<AxiosRequestConfig> = {
  baseURL,
  headers: serverConfig.defaultHeaders,
  // auth: serverConfig.auth
};

export interface ApiStore {
  baseUrl?: string;
  noRenew?: boolean;
  xBaseSwitch?: string;
  deepRenew?: boolean;
  deepRenewExpiresIn?: number;
  lastActive?: number;
}

// Api.
// Generic REST API client
// usable anywhere in the app.
export class Api extends Controller<ApiStore> {
  @observable baseUrl: AxiosRequestConfig["baseURL"];
  @observable pending: number = 0;
  @observable OAuth2Data: OAuth2Data;
  axios: AxiosInstance;
  execLogout: () => void;
  renewOAuthInQueue: boolean;
  renewOAuthInProgress: boolean;
  renewOAuthExec: (refreshToken?: OAuth2Data["refresh_token"]) => Promise<void>;
  renewTimeout;
  attemptedRenewal: boolean;

  @computed get baseChanged(): boolean {
    return !!this.store.baseUrl;
  };
  @computed get xBaseSwitch(): string {
    this.storage.initProperty("xBaseSwitch", flags.xBaseSwitch || undefined);
    return this.store.xBaseSwitch;
  };
  @computed get noAutoRenew(): boolean {
    this.storage.initProperty("noRenew", flags.renewDisable || undefined);
    return this.store.noRenew;
  };
  @computed get deepRenew(): boolean {
    this.storage.initProperty("deepRenew", flags.deepRenew || undefined);
    return this.store.deepRenew;
  };
  @computed get deepRenewExpiresIn(): number {
    this.storage.initProperty("deepRenewExpiresIn", flags.deepRenewExpiresIn || undefined);
    return this.store.deepRenewExpiresIn;
  };
  @computed get lastActive(): number {
    this.storage.initProperty("lastActive", new Date().getTime());
    return this.store.lastActive;
  };

  constructor() {
    super();
    this.axios = Axios.create(config);
    this.storage.isReady()
    .then(() => {
      if (this.xBaseSwitch) this.axios.defaults.headers["X-Base-Switch"] = this.xBaseSwitch;
      if (this.store.baseUrl) return this.baseSwitch(this.store.baseUrl);
      return this.baseUrl = config.baseURL;
    });
    this.pendingIncrement();
    this.pendingDecrement();
  }

  pendingIncrement = () => {
    this.axios.interceptors.request.use(request => {
      this.pending++;
      // console.log("Ajax pending", this.pending);
      return request;
    });
  };

  pendingDecrement = () => {
    this.axios.interceptors.response.use(
      response => {
        this.pending--;
        // console.log("Ajax pending", this.pending);

        // if (
        //   response.config.headers.Authorization !==
        //   serverConfig.defaultHeaders.Authorization &&
        //   !(response.config as ApiOptions).noRenew
        // ) {
        //   this.renewOAuthQueue();
        // }

        this.updateActivity(response).catch(console.warn);

        return response;
      },
      err => {
        this.pending--;
        // console.log("Ajax pending", this.pending);

        return this.handle401(err).then(is401 => is401 ? Promise.resolve(is401) : Promise.reject(err));
      }
    );
  };

  hardResetPending = () => this.pending = 0;

  baseSwitch = (baseUrl: string) => {
    console.warn("Base url changing to", baseUrl);
    this.baseUrl = baseUrl;
    return (this.axios.defaults.baseURL = this.baseUrl);
  };

  handle401 = async error => {
    const noKickOut =
      error.response &&
      error.response.config &&
      error.response.config.noKickOut;

    const logout = () => {
      this.execLogout && this.execLogout();
      this.attemptedRenewal = false;
      return true;
    };

    const code = error.response && error.response.status;

    if (code === 401 && !noKickOut && !this.deepRenew) return logout();

    if (code === 401 && this.deepRenew) {
      const idleLength = flags.tokenIdleTimeout;
      const lastActive = this.lastActive;
      const activityTimeout = isEmpty(this.OAuth2Data)
        || !lastActive
        || new Date().getTime() - lastActive > idleLength
        || this.attemptedRenewal;
      if (activityTimeout && !noKickOut) {
        return logout();
      }
      if (this.deepRenewExpiresIn && !noKickOut) {
        if (!this.OAuth2Data.timestamp) return logout();
        if (new Date().getTime() - this.OAuth2Data.timestamp > this.deepRenewExpiresIn) return logout();
      }
      const config = { ...error.config };
      config.endpoint = config.url;
      if (config.headers) delete config.headers;
      if (config.url) delete config.url;
      console.log("[mcb-web-components] Not yet idle, deep renewing");
      return this.renewOAuthExec(this.OAuth2Data["refresh_token"])
      .catch(err => {
        console.error(err);
        return !noKickOut && logout();
      })
      .then(() => this.async(config.method, { ...config }));
    }

    return false;
  };

  updateOAuthData = OAuth2Data => {
    if (isEmpty(OAuth2Data)) return;
    this.OAuth2Data = OAuth2Data;
    this.axios.defaults.headers["Authorization"] = `${capitalize(
      OAuth2Data["token_type"]
    )} ${OAuth2Data["access_token"]}`;
  };

  resetOAuthData = () => {
    this.OAuth2Data = null;
    this.axios.defaults.headers["Authorization"] =
      serverConfig.defaultHeaders.Authorization;
  };

  renewOAuthQueue = () => {
    const renew = (waitPending: boolean) => {
      if (this.renewOAuthInQueue && !waitPending) return;
      this.renewOAuthInQueue = true;
      if (this.pending > 0) {
        setTimeout(() => renew(true), 100);
      } else {
        this.renewOAuthExec && this.renewOAuthExec()
        .finally(() => (this.renewOAuthInQueue = false));
      }
    };
    clearTimeout(this.renewTimeout);
    this.renewTimeout = setTimeout(() => renew(false));
  };

  updateActivity = async response => {
    if (isEmpty(response)) return;
    if (
      ((response.config || {}).headers || {}).Authorization !==
      serverConfig.defaultHeaders.Authorization &&
      !(response.config || {}).noRenew &&
      !this.noAutoRenew
    ) {
      this.attemptedRenewal = false;
      return this.store.lastActive = new Date().getTime();
    }
  };

  registerRenewHandler = renewOAuthExec =>
    (this.renewOAuthExec = async (...args) => {
      if (this.renewOAuthInProgress) return;
      if (!this.OAuth2Data) return;
      this.renewOAuthInProgress = true;
      return renewOAuthExec(...args)
      .then(() => (this.attemptedRenewal = true))
      .finally(() => (this.renewOAuthInProgress = false));
    });

  registerLogoutHandler = execLogout => (this.execLogout = execLogout);

  async = async (method: Method, overload1: string | ApiOptions, overload2?: ApiOptions) => {
    if (!this.baseUrl) {
      await asyncPause(200);
      return this.async(method, overload1, overload2);
    }
    const waitRenew = async () => {
      const options = (typeof overload1 === "object" ? overload1 : overload2) || {} as ApiOptions;
      if (this.renewOAuthInProgress && !options.renewingOAuth2Data)
        return await asyncPause(100).then(waitRenew);

      return this.axios({
        method: method.toLowerCase(),
        url: (typeof overload1 === "string" && overload1) || options.endpoint,
        data: options.data,
        auth: !options.noAuth && options.auth,
        responseType: options.responseType,
        noRenew: options.noRenew || this.noAutoRenew,
        noKickOut: options.noKickOut,
        headers: {
          ...options.headers
        }
      } as ApiOptions);
    };

    return waitRenew();
  };

  GET = options => this.async("GET", options);

  POST = options => this.async("POST", options);

  PATCH = options => this.async("PATCH", options);

  PUT = options => this.async("PUT", options);

  DELETE = options => this.async("DELETE", options);
}

export let api = {} as Api;
export const initApi = constructor => api = constructor;