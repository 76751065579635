import { observer } from "mobx-react";
import React from "react";
import { computed, observable } from "mobx";
import { Styled } from "direflow-component/dist";
import styles from "./styles.css";
import { getQueryParameters, preventDefaultStopProp } from "../../utils/helpers";
import { ui } from "../../client/ui";
import McbComponentPastry from "../../components/McbComponentPastry";
import { env } from "../../config/env";
import withJssMap from "../../components/withJssMap";
import { stateCtrl } from "../../client/state";
import { UIText } from "../../client/lang";
import { MarketplacePopupAggregator } from "../../components/MarketplacePopupAggregator";
import { McbMarketResultsPopupController } from "./controller";
import { AlertBase } from "../../components/AlertBase";
import { MarketResultsPopupInsert } from "../../components/MarketResultsPopupInsert";

@observer
class McbMarketResultsPopup extends React.Component {
  controller: McbMarketResultsPopupController = {} as McbMarketResultsPopupController;
  container: HTMLDivElement;
  subContainer: HTMLDivElement;

  @observable ratingDetailOpen: boolean = false;
  @observable ratingDetailProfileId: number;

  @observable visibleProfileIds: number[] = [];

  @computed get isOpen(): boolean {
    return this.controller.isOpen;
  };
  @computed get profileOpen(): boolean {
    return !stateCtrl.bookingFormVisible && stateCtrl.mcbListingProfileIsOpen;
  };

  constructor(props) {
    super(props);
    this.controller = new McbMarketResultsPopupController();
  };

  componentDidMount(): void {
    stateCtrl.mcbListingProfileIsEmbedded = true;
    this.detectProfileQueryParam();
  }

  showError = err => ui.showError({ err, actionName: UIText.generalError });

  containerRef = elm => this.container = elm;

  detectProfileQueryParam = () => {
    const { gid } = getQueryParameters(window.location.search) || {};
    const groupId = Number(gid);
    if (!gid || isNaN(groupId)) return;
    // this.controller.onSearch().catch(this.showError);
    return this.controller.openProfileViewGroupId(groupId);
  };

  handleProfileViewClick = (event, profileId) => {
    preventDefaultStopProp(event);
    return this.controller.setViewProfileId(profileId);
  };

  handleProfileModalClose = (event: any) => stateCtrl.closeMcbListingProfile();

  handleRatingDetailClick = (event, profileId) => {
    this.ratingDetailProfileId = profileId;
    this.ratingDetailOpen = true;
  };

  handleRatingModalClose = (event: any) => this.ratingDetailOpen = false;

  handleBookingClick = (event: any, profileId: number) => stateCtrl.showBookingForm(profileId);

  handleChatClick = (event: any, profileId: number) => {
    if (!this.controller.isEmbedded) return;
    return this.controller.onChatClick(profileId);
  };

  handleProfileVisible = (event, profileId) => {
    if (!profileId || !event) return;
    !this.visibleProfileIds.includes(profileId) && this.visibleProfileIds.push(profileId);
    return this.controller.getHasInterviewAppointmentData(this.visibleProfileIds);
  };

  render() {
    const {
      profiles,
      ownProfileIds,
      isCaregiver,
      getShowChat,
      getProfileAvatarUri,
      getProfileAbilities,
      getProfileLanguages,
      getAdminLink,
      getRatingLoading,
      findProfileRatingScore,
      findProfileAchievement,
      getHasInterviewLoading,
      findHasInterviewAppointment
    } = this.controller;

    // if (appIsLoggedIn) return <div>Marketplace for signed-in user under construction.</div>;

    return <McbComponentPastry>
      <Styled styles={styles}>
        <div className={`flex column mcbMarketResultsPopup ${env}`} ref={this.containerRef}>
          <AlertBase
            className="mcbMarketResultsPopupAlert"
            container={this.container}
            isOpen={this.isOpen}
            onClose={stateCtrl.closeMarketResultsPopup}
            zIndex={114514}
            extraStyles={styles}
          >
            <MarketResultsPopupInsert
              profiles={profiles}
              ownProfileIds={ownProfileIds}
              isCaregiver={isCaregiver}
              getAvatarUri={getProfileAvatarUri}
              getProfileAbilities={getProfileAbilities}
              getProfileLanguages={getProfileLanguages}
              getAdminLink={getAdminLink}
              getRatingLoading={getRatingLoading}
              savedProfileIds={stateCtrl.marketResultsPopupSavedProfileIds}
              isProfileSaveLoading={stateCtrl.isMarketResultsPopupProfileSaving}
              findProfileRatingScore={findProfileRatingScore}
              findProfileAchievement={findProfileAchievement}
              getHasInterviewLoading={getHasInterviewLoading}
              getShowChat={getShowChat}
              findHasInterview={findHasInterviewAppointment}
              onSaveClick={stateCtrl.onMarketResultsPopupSave}
              onVisible={this.handleProfileVisible}
              onClose={stateCtrl.closeMarketResultsPopup}
              onViewClick={this.handleProfileViewClick}
              onRatingClick={this.handleRatingDetailClick}
              onBookingClick={this.handleBookingClick}
              onChatClick={this.handleChatClick}
            />
          </AlertBase>

          <MarketplacePopupAggregator
            container={this.subContainer}
            profileOpen={this.profileOpen}
            ratingDetailOpen={this.ratingDetailOpen}
            ratingDetailProfileId={this.ratingDetailProfileId}
            isCaregiver={isCaregiver}
            getProfileAvatarUri={getProfileAvatarUri}
            onProfileModalClose={this.handleProfileModalClose}
            onRatingModalClose={this.handleRatingModalClose}
          />
        </div>
      </Styled>
    </McbComponentPastry>;
  }
}

export default withJssMap(McbMarketResultsPopup);