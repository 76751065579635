import React from "react";
import { Observer, observer } from "mobx-react";
import { McbAppointmentListController } from "./controller";
import styles from "./styles.css";
import McbComponentPastry from "../../components/McbComponentPastry";
import { Styled } from "direflow-component";
import { UIText } from "../../client/lang";
import { ui } from "../../client/ui";
import { computed, observable } from "mobx";
import { getDisplayNameEng, getQueryParameters, isEmpty } from "../../utils/helpers";
import withJssMap from "../../components/withJssMap";
import { AlertBase } from "../../components/AlertBase";
import { stateCtrl } from "../../client/state";
import { CircularProgress, Typography } from "@material-ui/core";
import { Appointment } from "../../mcb/lib/types/dataTypes";
import AppointmentCard from "../../components/AppointmentCard";
import GroupSelector from "../../components/GroupSelector";
import { filterPresentPastAppointment } from "../../mcb/lib/appointment-utilities";

@observer
class McbAppointmentList extends React.Component {
  controller: McbAppointmentListController = {} as McbAppointmentListController;
  managerContainer: HTMLDivElement;

  @observable loading: boolean = true;

  @computed get showManager(): boolean {
    return !!stateCtrl.appointmentManagerId;
  };
  @computed get signInFailed(): boolean {
    return this.controller.signInBoxClosed && !this.controller.ready;
  };
  @computed get shouldRenderAppointments(): Appointment[] {
    return this.controller.appointments.filter(filterPresentPastAppointment);
  };

  constructor(props) {
    super(props);
    this.controller = new McbAppointmentListController();
    this.detectQueryParam();
    this.initialize();
  };

  showError = err => ui.showError({ err, actionName: UIText.generalError });

  detectQueryParam = () => {
    const { aid, aplistid } = getQueryParameters(window.location.search) || {};
    const appointmentId = Number(aid);
    const appointmentListGroupId = Number(aplistid)
    if (aid && !isNaN(appointmentId)) stateCtrl.openAppointmentManagerEntry(appointmentId);
    if (aplistid && !isNaN(appointmentListGroupId)) stateCtrl.setAppointmentListContextGroup(appointmentListGroupId);
  };

  initialize = () => {
    this.loading = true;
    this.controller.loadAllData()
    .catch(this.showError)
    .finally(() => this.loading = false);
    stateCtrl.appointmentManagerIsEmbedded = true;
  };

  onManagerClose = () => stateCtrl.closeAppointmentManagerEntry();

  onGroupChange = (groupId: number) => {
    this.loading = true;
    return this.controller.onGroupChange(groupId)
    .then(() => this.loading = false)
    .catch(this.showError);
  };

  renderAppointment = (appointment: Appointment) => <Observer key={appointment.id}>{() => {
    const providerGroup = this.controller.findProviderGroup(appointment);
    const organizerGroup = this.controller.findOrganizerGroup(appointment);
    const selfIsProvider = this.controller.selfIsProvider(appointment);
    const partyGroup = selfIsProvider ? organizerGroup : providerGroup;
    const partyProfile = partyGroup?.profile;
    const timezone = appointment.timezone || this.controller.timezone;
    const handleClick = () => stateCtrl.openAppointmentManagerEntry(appointment.id);
    return <AppointmentCard
      loading={isEmpty(partyGroup)}
      appointment={appointment}
      displayName={getDisplayNameEng(partyProfile)}
      partyTypeName={partyGroup?.groupTypeName}
      partyAvatar={this.controller.getPartyAvatar(partyProfile, partyGroup)}
      timezone={timezone}
      onClick={handleClick}
    />;
  }}</Observer>;

  render() {
    const { userGroups, currentGroupId } = this.controller;

    const appointment = {
      manager: "mcb-appointment-manager"
    };

    return <McbComponentPastry>
      <Styled styles={styles}>
        <div className="mcbAppointmentList">
          <div className="mcbAppointmentListMainContent">
            <Typography className="font-m textBold formTitle" color="secondary" gutterBottom>
              Ongoing and upcoming appointment(s):
            </Typography>
            {this.loading ? (
              <div className="flex align-items-center justify-content-center loading">
                <CircularProgress size={30} />
              </div>
            ) : this.signInFailed ? (
              <div className="flex align-items-center justify-content-center loading">
                <Typography className="font-s">
                  Please <span className="textUnderline signInLink" onClick={this.initialize}>sign in</span> to see your appointments.
                </Typography>
              </div>
            ) : <>
              <div className="flex align-items-end appointmentListGroupSelector">
                <Typography className="textBold font-s">
                  Showing appointment(s) for&nbsp;
                </Typography>
                <GroupSelector
                  groups={userGroups}
                  selectedGroupId={currentGroupId}
                  onGroupChange={this.onGroupChange}
                />
              </div>
              <br />
              <div className="flex justify-content-start appointmentCardContainer">
                {isEmpty(this.shouldRenderAppointments) && (
                  <Typography className="font-s emptyText" style={{ color: "#666" }}>
                    No appointments to show.
                  </Typography>
                )}
                {this.shouldRenderAppointments.map(this.renderAppointment)}
              </div>
            </>}
          </div>

          <AlertBase
            container={this.managerContainer}
            isOpen={this.showManager}
            onClose={this.onManagerClose}
            zIndex={114514}
          >
            <appointment.manager></appointment.manager>
          </AlertBase>
        </div>
      </Styled>
    </McbComponentPastry>
  }
}

export default withJssMap(McbAppointmentList);