import { Controller } from "../lib/controller";
import { devPlacesApiKey, placesApiKey, placesBaseUrl } from "../config/external";
import { observable } from "mobx";
import { Plugins } from "@capacitor/core";
import { JSONOfPlacesAutocompleteResult, PlacesAutocompleteResultLimiter } from "../lib/types/formTypes";
import { PlacesAutocompleteResult } from "../lib/types/dataTypes";
import { isEmpty, safeParseJSON } from "../utils/helpers";
import { env } from "../config/env";

const { Geolocation } = Plugins;

// PlacesStore
// Persistent storage model for Places API Library usage
export interface PlacesStore {
  enteredLocation: PlacesAutocompleteResult;
}

// Places.
// Main class instance for CDM/CDO utilization of Google Places API Library
// with persistent data management and store.
export class Places extends Controller<PlacesStore> {
  google: typeof google;
  apiKey: string;

  @observable initialized: boolean;

  constructor() {
    super();
    this.initialize();
  }

  initialize = () => {
    this.checkDomTag();
    (window as any).initPlacesApi = () => this.initialized = true;
    // whenFulfill(() => !!(window as any).google);
  };

  checkDomTag = () => {
    this.apiKey = (env === "dev" || env === "debug")
      ? devPlacesApiKey
      : placesApiKey;
    const scriptTags: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName("script");
    const existingPlacesTag: HTMLScriptElement = Array.from(scriptTags).find(
      tag => tag.src && tag.src.match(/https:\/\/maps.googleapis.com\/maps\/api/g)
    );
    if (existingPlacesTag) return;
    const newTag: HTMLScriptElement = document.createElement("script");
    newTag.src = `${placesBaseUrl}?key=${this.apiKey}&libraries=places&callback=initPlacesApi`;
    newTag.async = true;
    newTag.defer = true;
    return document.body.appendChild(newTag);
  };

  getApiKey = () => this.apiKey;

  getGeolocation = async (enableHighAccuracy?: boolean) => Geolocation.getCurrentPosition({ enableHighAccuracy });

  parseAutocompleteResult = (place: google.maps.places.PlaceResult, limitTo?: PlacesAutocompleteResultLimiter) => {
    const base: Partial<PlacesAutocompleteResult> = {};
    let result: PlacesAutocompleteResult = {
      street1: "", street2: "", city: "", provState: "", country: "", postalCode: ""
    };
    const { location } = place.geometry || {};
    if (location) {
      base.lng = result.lng = location.lng();
      base.lat = result.lat = location.lat();
    }
    base.formattedAddress = result.formattedAddress = (!limitTo && place.formatted_address) || "";
    base.placeId = result.placeId = place.place_id;
    if (place.address_components) {
      for (const component of place.address_components) {
        if (component.types.includes("postal_code")) result.postalCode = component.short_name;
        if (component.types.includes("country")) result.country += component.long_name;
        if (component.types.includes("administrative_area_level_1")) result.provState += component.long_name;
        if (component.types.includes("locality")) result.city += component.short_name;
        if (result.city === result.provState) result.provState = "";
        // if (!result.city) result.city = result.provState; // New York etc... not sure about this
        if (component.types.includes("street_number")) result.street1 += component.short_name;
        if (component.types.includes("route")) result.street1 += `${result.street1 ? ` ${component.short_name}` : component.short_name}`;
        if (!result.street1) {
          //TODO: Do Japan sublocality style...

        }
      }
      if (limitTo === "postalCode") result = { ...base, postalCode: result.postalCode };
      if (limitTo === "country") result = { ...base, country: result.country };
      if (limitTo === "provState") result = { ...base, provState: result.provState, country: result.country };
      if (limitTo === "city") result = { ...base, city: result.city, provState: result.provState, country: result.country };
    }
    if (!result.formattedAddress) result.formattedAddress = limitTo
      ? place.address_components
        ? this.parseLimitedAddress(result)
        : place.name
      : place.name;
    console.log(result);
    // return JSON.stringify(result);
    return result;
  };

  parseManualResult = (data: PlacesAutocompleteResult, limitTo?: PlacesAutocompleteResultLimiter) => {
    if (isEmpty(data)) return undefined;
    let base: Partial<PlacesAutocompleteResult> = {};
    if (limitTo === "postalCode") base = { ...data, postalCode: data.postalCode };
    if (limitTo === "country") base = { ...data, country: data.country };
    if (limitTo === "provState") base = { ...data, provState: data.provState, country: data.country };
    if (limitTo === "city") base = { ...data, city: data.city, provState: data.provState, country: data.country };
    if (!limitTo) base = { ...data };
    base.formattedAddress = this.parseManualFullAddress(base);
    console.log(base);
    // return JSON.stringify(base);
    return base;
  };

  parseManualFullAddress = (result: PlacesAutocompleteResult) => {
    const limitedAddress = this.parseLimitedAddress(result);
    return `${result.street1 ? `${result.street1}${result.street2 || limitedAddress ? ", " : ""}` : ""}${result.street2 ? `${result.street2}${limitedAddress ? ", " : ""}` : ""}${limitedAddress}`;
  };

  parseLimitedAddress = (result: PlacesAutocompleteResult) =>
    `${result.city ? `${result.city}${result.provState || result.country ? ", " : ""}` : ""}${result.provState ? `${result.provState}${result.country ? ", " : ""}` : ""}${result.country ? result.country : ""}${result.postalCode ? ` ${result.postalCode}` : ""}`;

  getFormattedAddress = (value: JSONOfPlacesAutocompleteResult | PlacesAutocompleteResult, limitTo?: PlacesAutocompleteResultLimiter) => {
    if (!value) return "";
    const place = typeof value === "string"
      ? safeParseJSON(value, true) || {}
      : value || {};
    const parsed = place.formattedAddress;
    // if (!parsed) return (typeof value === "string" ? value : JSON.stringify(value)) || "";
    if (!parsed) {
      if (place.lat && place.lng) return `${place.lat}, ${place.lng}`;
      return (typeof value === "string" ? value : JSON.stringify(value)) || "";
    }
    if (limitTo) {
      const frags = parsed.split(",");
      if (limitTo === "postalCode") return (frags[frags.length - 1] || "").trim();
      if (limitTo === "country") return frags[frags.length - 2];
      if (limitTo === "provState") {
        frags.pop();
        return `${frags.join(", ")}`;
      }
      if (limitTo === "city") return frags[0];
    }
    return parsed;
  };

  getEnteredLocation = () => this.store.enteredLocation;

  setEnteredLocation = (location: PlacesAutocompleteResult) => this.store.enteredLocation = location;
}

export let placesApi = {} as Places;
export const initPlacesApi = constructor => placesApi = constructor;