import { DireflowComponent } from "direflow-component";
import component from "./component";
import { initCDM } from "../../client/boot";

export default initCDM().then(() => DireflowComponent.create({
  component,
  configuration: {
    tagname: "mcb-signup-signin",
    useShadow: false
  }
}));