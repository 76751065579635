import { JSONString, KeyValuePairs } from "../lib/types/miscTypes";
import { PickerField, PickerOption, SupportedFieldTypes, TypeClass, TypeClassField } from "../lib/types/formTypes.legacy";
import { autorun, computed, observable, toJS, when } from "mobx";
import { capitalize, isEmpty, isEqual, isNonZeroFalse, safeParseJSON, validateEmail } from "../utils/helpers";
import { formCtrl } from "./form";
import { UIText } from "./lang";
import { client } from "./client";
import { Group } from "../lib/types/dataTypes";

// Form Class.
// Used for creating a smart object of typeClass Form instance
// and static utility methods of typeClass Form.
export class FormLegacy<Data = KeyValuePairs | any> {
  @observable ready: boolean = false;
  @observable flags: KeyValuePairs<boolean> = {};
  @observable readonly typeClassId: TypeClass["id"];
  @observable private _rawData: Data = {} as Data;
  @observable protected _origData: Data = {} as Data;
  @observable private _rawForm: TypeClassField<SupportedFieldTypes, Data>[] = [];
  @observable form: TypeClassField<SupportedFieldTypes, Data>[] = [];

  @computed get typeClass(): TypeClass {
    return formCtrl.findTypeClassById(this.typeClassId);
  };
  @computed get blank(): TypeClassField<SupportedFieldTypes, {}>[] {
    return FormLegacy.assembleFormData(this._rawForm,{}, this.flags);
  };
  @computed get renderable(): TypeClassField<SupportedFieldTypes, Data>[] {
    return this.form.filter(Boolean).filter(field => !field._hidden);
  };
  @computed get data(): Data {
    return FormLegacy.disassembleFormData(this.form) as Data;
  };
  @computed get json(): JSONString {
    return this.toJSON();
  };
  // @computed get isValid(): boolean {
  //   return this.form.every(field => !!field.value || !field.required);
  // };
  @computed get isDirty(): boolean {
    return !isEqual(toJS(this._origData), toJS(this.data));
  };
  @computed get errorFields(): TypeClassField<SupportedFieldTypes, Data>[] {
    return this.renderable.filter(f => !!f._errorMessage);
  };

  constructor(
    form: TypeClass["id"] | JSONString | TypeClassField[],
    inputData?: JSONString | Data,
    formFlags?: KeyValuePairs<boolean>
  ) {
    this._initForm();
    this._setData(inputData);
    if (typeof form === "number") {
      this._setForm();
      this.typeClassId = form as number;
    } else {
      this._rawForm = typeof form === "string"
        ? safeParseJSON(form)
        : typeof form === "object"
          ? form
          : undefined;
      this.ready = true;
    }
    if (formFlags) this.flags = formFlags;
    setTimeout(() => FormLegacy.checkUniqueFields(this._rawForm, this.typeClass));
    return this;
  }

  protected _initForm = () => autorun(() => {
    const newForm = FormLegacy.assembleFormData(this._rawForm, this._rawData, this.flags);
    const oldForm = [...this.form];
    for (let i = 0; i < newForm.length; i++) {
      if (isEqual(oldForm[i], newForm[i])) continue;
      this.form[i] = {...newForm[i]};
    }
  });

  protected _setData = (data: JSONString | Data) => {
    if (!data) return;
    this._rawData = typeof data === "string"
      ? safeParseJSON(data)
      : toJS(data);
  };

  // One time reaction to set form soon as typeClass data arrives.
  protected _setForm = () => when(
    () => (!isNonZeroFalse(this.typeClassId) && (!isEmpty(this.typeClass) || !!this.typeClass.metadata)),
    async () => {
      this._rawForm = FormLegacy.parseFormFromTypeClass(this.typeClass);
      when(() => !isEmpty(this.form), () => {
        this._origData = toJS(this.data);
        this.ready = true;
      });
    });

  toJSON = (overrides?: Data): JSONString => {
    let data = toJS(this.data);
    if (!isEmpty(overrides)) return JSON.stringify({
      ...data,
      ...overrides
    });
    return JSON.stringify(data);
  };

  get = (name: TypeClassField<SupportedFieldTypes, Data>["name"]): string | boolean | number => this.data[name];

  set = (name: TypeClassField<SupportedFieldTypes, Data>["name"], value: Data[keyof Data]) =>
    this._rawData[name] = value;

  getField = (name: TypeClassField["name"]): TypeClassField<any, Data> =>
    this.form.find(field => field.name === name) || {};

  setField = (
    name: TypeClassField<SupportedFieldTypes, Data>["name"],
    update: Partial<TypeClassField<SupportedFieldTypes>>
  ) => {
    const field = this._rawForm.find(field => field.name === name);
    if (isEmpty(field)) return;
    for (const key in update) {
      if (key === "value") {
        this.set(name, update[key]);
        continue;
      }
      field[key] = update[key];
    }
  };

  getRenderedField = (name: TypeClassField<SupportedFieldTypes, Data>["name"]): TypeClassField<any, Data> =>
    this.renderable.find(field => field.name === name) || {};

  getRenderedValue = (name: TypeClassField<SupportedFieldTypes, Data>["name"]): Data[keyof Data] =>
    this.getRenderedField(name).value || "";

  isReady = () => when(() => this.ready);

  getDirty = (): TypeClassField<SupportedFieldTypes, Data>[] => {
    if (!this.isDirty) return [];
    const data = toJS(this.data);
    const keys = Object.keys(this._origData);
    return keys.map(key => (data[key] !== this._origData[key]) && this.getField(key)).filter(Boolean);
  };

  resetDirty = () => this._origData = toJS(this.data);

  reset = () => {
    this._rawData = this._origData;
    const fresh = FormLegacy.parseFormFromTypeClass(this.typeClass);
    for (let i = 0; i < fresh.length; i++) (this._rawForm[i] = {...fresh[i]});
  };

  clearData = () => {
    for (const field of this.blank) this.set(field.name, field.value);
  };

  validate = async (name?: TypeClassField<SupportedFieldTypes, Data>["name"], forceError?: boolean): Promise<boolean> => {
    // TODO: Smarter target control for each individual fields.
    const validate = async field => {
      const getField = () => this.getField(field.name);
      if (getField().hasInputData) return;
      const value = this.get(getField().name);
      const requiredError =
        getField().required &&
        isEmpty(value) &&
        (getField().errorMessage || UIText.fieldValidationEmpty(getField().placeholder));
      if (requiredError) {
        if (getField()._errorMessage !== requiredError) this.setField(getField().name, {
          _errorMessage: requiredError
        });
      } else {
        if (getField()._errorMessage) this.setField(getField().name, { _errorMessage: undefined });
      }
      if (getField().name.match(/password/g)) {
        // const passwordOptions = {
        //   uppercase: 1,
        //   special: 1,
        //   min: 8,
        // };
        const passwordRegExp = new RegExp(
          `^(?!.*\\+)(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{8,}$`,
          `g`
        );
        const passwordError =
          value
            ? value.toString().match(passwordRegExp)
            ? undefined
            : UIText.registrationPasswordErrorUpperSpec8
            : UIText.fieldValidationEmpty(UIText.registrationFields.password);
        if (passwordError) {
          if (getField()._errorMessage !== passwordError) this.setField("password", {
            _errorMessage: passwordError
          });
        } else {
          if (getField()._errorMessage) this.setField(getField().name, { _errorMessage: undefined });
        }
      }
      if (getField().name.match(/repeat/g)) {
        const repeatError =
          (!!value || forceError) &&
          (!value || this.get("password") !== this.get("repeat")) &&
          UIText.registrationPasswordMismatch;
        if (repeatError) {
          if (getField()._errorMessage !== repeatError) this.setField("repeat", {
            _errorMessage: repeatError
          });
        } else {
          if (getField()._errorMessage) this.setField(getField().name, { _errorMessage: undefined });
        }
      }
      if (!!getField().unique) {
        await this.validateUniqueIdentifier(getField());
      } else if (!!getField().isEmail) {
        const isValidEmail = validateEmail(value);
        const isPlusSignError = !isValidEmail && (value as string || "").match(/\+/);
        this.setField(getField().name, { _errorMessage: !isValidEmail && (isPlusSignError ? UIText.registrationEmailNoPlusSign : UIText.invalidEmail) });
      }
    };
    if (name) {
      const field = this.getField(name);
      await validate(field);
      return (field.required ? !!field.value : true) && !field._errorMessage;
    }
    await Promise.all(this.renderable.map(validate));
    return this.renderable.every(field =>
      (field.required ? !!field.value : true) && !field._errorMessage
    );
  };

  validateUniqueIdentifier = async (field: TypeClassField<SupportedFieldTypes>) => {
    const identifier = field.unique;
    const value = this.get(identifier);
    const isValidEmail = validateEmail(value);
    const isPlusSignError = !isValidEmail && (value as string || "").match(/\+/);
    this.setField(identifier, { _errorMessage: !isValidEmail && (isPlusSignError ? UIText.registrationEmailNoPlusSign : UIText.invalidEmail) });
    if (!isValidEmail) return;
    const result = await client.checkUniqueIdentifier(identifier, value.toString());
    if (!result.exists && !result.error) {
      this.setField(identifier, { _errorMessage: undefined });
    } else {
      const _errorMessage = result.exists
        ? field.errorMessage
        ? field.errorMessage
        : `${capitalize(identifier)} ${UIText.registrationUniqueExists}`
        : result.error;
      this.setField(identifier, { _errorMessage });
    }
  };


  /**
   * Static Methods
   */
  static checkUniqueFields = (form: TypeClassField[], typeClass?: TypeClass) => {
    if (isEmpty(form) || !Array.isArray(form)) return;
    const fieldNames: string[] = [];
    for (const field of form) {
      if (fieldNames.includes(field.name)) console.warn(
        "Detected duplicate field name in form",
        typeClass || "Unknown typeClass",
        toJS(field)
      );
      fieldNames.push(field.name);
    }
  };

  static parseFormFromTypeClass = (typeClass: TypeClass): TypeClassField<SupportedFieldTypes>[] => {
    let formFields = [];
    if (isEmpty(typeClass)) return formFields;
    if (isEmpty(typeClass.metadata)) return formFields;
    formFields = safeParseJSON(typeClass.metadata) || [];
    return formFields;
  };

  static assembleFormData = (
    form: TypeClassField<SupportedFieldTypes>[],
    inputData: KeyValuePairs,
    flags: KeyValuePairs<boolean>
  ): TypeClassField<SupportedFieldTypes>[] => {
    // options = options || {};
    let formFields = toJS(form) || [];
    if (isEmpty(formFields)) return formFields;
    const data = inputData || [];
    const hiddenSets = [];
    const returnFields = formFields.map(field => {
      // Assign per field value.
      field.value = data[field.name] || field.value;
      // Picker options dependency
      if (field.type === "picker") {
        for (const option of (field as TypeClassField<PickerField>).options) {
          option.hidden = !FormLegacy.getDependencyHide(field, option, formFields) || !FormLegacy.getPickerOptionEmptyDependent(field, option, formFields);
        }
        // Reset picker value to visible ones if current value is hidden.
        const visibleOptions = field.options.filter(option => !option.hidden);
        if (!isEmpty(visibleOptions) && !visibleOptions.some(option => option.name === field.value)) {
          field.value = visibleOptions[0].name;
        }
      }
      // + Dependency hide
      const dependencyHidden = !FormLegacy.getDependencyHide(field, formFields);
      field._hidden = field.hidden || dependencyHidden ||
      // + Flag hide
      (!isEmpty(field.flags) && (flags && Object.keys(flags).some(key => flags[key] !== field.flags[key])));
      // + Dependency clear value
      if (dependencyHidden) field.value = false;
      // + Set dependency hidden clear value marker
      if (dependencyHidden && field.set && field.title) hiddenSets.push(field.set);
      // + linkedFields value modifier
      field.value = FormLegacy.getLinkedValue(field, formFields);
      // + Normalization formula
      field.value = FormLegacy.formulateValue(field);
      // Return field;
      return field;
    });
    // Set dependency hidden clear value
    return returnFields.map(field => {
      if (!field.set) return field;
      if (hiddenSets.includes(field.set)) field.value = false;
      return field;
    });
    // TODO: Form engine needs to handle dynamic data (Data pairs that are out-of-scope to the typeClassFields)
  };

  static disassembleFormData = (
    form: TypeClassField<SupportedFieldTypes>[]
  ): KeyValuePairs => {
    // options = options || {};
    let data = {};
    if (isEmpty(form)) return data;
    for (const field of form) {
      field.type !== "link" &&
      field.type !== "text" &&
      field.type !== "button" &&
      field.type !== "modifier" &&
      (data[field.name] = field.value);
    }
    return data;
  };

  static getDependencyHide = (field: TypeClassField, option?: PickerOption | TypeClassField[], form?: TypeClassField[]): boolean => {
    const { dependOn, setDependOn } = field || {};
    let dependOnFalsy, dependOnValue;
    if (!form) {
      dependOnFalsy = field.dependOnFalsy;
      dependOnValue = field.dependOnValue;
    } else {
      dependOnFalsy = (option as PickerOption).dependOnFalsy;
      dependOnValue = (option as PickerOption).dependOnValue;
    }
    if (!dependOn && !setDependOn) return true;
    const dependency = (form ? form : option as TypeClassField[]).find(field => field.name === dependOn || field.name === setDependOn);
    if (!dependency) return false;
    if (dependOnValue) {
      return dependOnFalsy ? dependency.value !== dependOnValue : dependency.value === dependOnValue;
    }
    return dependOnFalsy ? !dependency.value : !!dependency.value;
  };

  // This is how province fields work.
  static getPickerOptionEmptyDependent = (field: TypeClassField, option: PickerOption, form: TypeClassField[]): boolean => {
    const dependent: TypeClassField<PickerField> = form.find(f => f.dependOn === field.name && f.type === "picker") as TypeClassField<PickerField>;
    if (!dependent) return true;
    const dependentOptions = dependent.options;
    return !!dependent["dependOnValue"] ||
      (dependentOptions && dependentOptions.some(
        d => d.dependOnValue && d.dependOnValue === option.name
      ));
  };

  static getLinkedValue = (field: TypeClassField, form: TypeClassField[]) => {
    let value = field.value;
    const { linkedFields } = field;
    if (isEmpty(linkedFields)) return value;
    if (linkedFields) {
      if (linkedFields.name) {
        if (Array.isArray(linkedFields.name) && linkedFields.modifier) {
          const linkedFieldValues = form
          .map(f => linkedFields.name.includes(f.name) && f.value)
          .filter(Boolean);
          // eslint-disable-next-line no-new-func
          value = new Function(linkedFields.name.join(","), `return ${linkedFields.modifier}`)(...linkedFieldValues);
        } else if (typeof linkedFields.name === "string") {
          const linkedField = form.find(f => f.name === linkedFields.name) || {} as TypeClassField;
          value = linkedFields.modifier
            // eslint-disable-next-line no-new-func
            ? new Function(linkedFields.name, `return ${linkedFields.modifier}`)(linkedField.value)
            : linkedField.value;
        }
      }
    }
    return value;
  };

  static formulateValue = (field: TypeClassField) => {
    let value = field.value;
    return value || false;
  };

  static hideFieldsByGroupTypeId = (form: TypeClassField[], groupTypeId: Group["typeId"]) => {
    if (isEmpty(form)) return;
    for (const field of form) {
      if (field.groupTypeIds && !field.groupTypeIds.includes(groupTypeId)) {
        field.hidden = true;
        field.disabled = true;
      }
      if (field.type === "picker") {
        (field as PickerField).options = toJS((field as PickerField).options).filter(
          option => !option.groupTypeIds || option.groupTypeIds.includes(groupTypeId)
        );
      }
    }
  };
}
