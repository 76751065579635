import { UIText } from "../../client/lang";
import { AnySupportedFieldTypes, TypeClassField } from "../../lib/types/formTypes.legacy";
import { groupTypeIds } from "./constants";

export interface RegistrationFormData {
  intents: string;
  email: string;
  phone?: string;
  isRecruit?: boolean;
  firstName: string;
  lastName: string;
  gender?: string;
  password?: string;
  repeat?: string;
}

export const registrationForm = (type: "family" | "vendor"): TypeClassField<AnySupportedFieldTypes, RegistrationFormData>[] => [
  {
    name: "intents",
    type: "picker" as const,
    placeholder: UIText.familySigningUpAs,
    value: type === "family" ? "[3]" : "[9]",
    required: true,
    hidden: type === "family",
    options: [
      type === "family" && {
        name: "[3]",
        placeholder: UIText.careCircle
      },
      type === "family" && {
        name: "[17]",
        placeholder: UIText.household
      },
      type === "vendor" && {
        name: "[9]",
        placeholder: UIText.caregiver
      },
      type === "vendor" && {
        name: "other",
        placeholder: UIText.provider
      }
    ].filter(Boolean)
  },
  {
    name: "email",
    type: "input" as const,
    unique: "email" as const,
    required: true,
    dependOn: "intents",
    dependOnValue: "[9]",
    dependOnFalsy: true,
    placeholder: UIText.registrationFields.email
  },
  {
    name: "phone",
    type: "input" as const,
    dependOn: "intents",
    dependOnValue: "[9]",
    dependOnFalsy: true,
    placeholder: UIText.registrationFields.phone
  },
  {
    name: "isRecruit",
    type: "checkbox" as const,
    dependOn: "intents",
    dependOnValue: "other",
    placeholder: UIText.vendorRecruitOption,
    helperText: UIText.vendorRecruitHelperText
  },
  {
    name: "firstName",
    type: "input" as const,
    required: true,
    dependOn: "intents",
    dependOnValue: "[9]",
    dependOnFalsy: true,
    placeholder: UIText.registrationFields.firstName
  },
  {
    name: "lastName",
    type: "input" as const,
    required: true,
    dependOn: "intents",
    dependOnValue: "[9]",
    dependOnFalsy: true,
    placeholder: UIText.registrationFields.lastName
  },
  type === "vendor" && {
    name: "gender",
    type: "picker" as const,
    placeholder: UIText.registrationFields.gender,
    value: "male",
    required: true,
    dependOn: "intents",
    dependOnValue: "[9]",
    dependOnFalsy: true,
    options: [
      {
        name: "male",
        placeholder: UIText.registrationFields.genderMale
      },
      {
        name: "female",
        placeholder: UIText.registrationFields.genderFemale
      }
    ]
  },
  {
    name: "password",
    type: "input" as const,
    required: true,
    secured: true,
    dependOn: "intents",
    dependOnValue: "[9]",
    dependOnFalsy: true,
    placeholder: UIText.registrationFields.password
  },
  {
    name: "repeat",
    type: "input" as const,
    required: true,
    secured: true,
    dependOn: "intents",
    dependOnValue: "[9]",
    dependOnFalsy: true,
    placeholder: UIText.registrationFields.repeat
  },
].filter(Boolean);

export const serviceProviderGroupTypeField = {
  flags: {
    caregiver: true
  },
  name: "groupTypeId",
  type: "picker",
  placeholder: UIText.serviceCategory,
  value: groupTypeIds.handyman,
  options: [
    {
      name: groupTypeIds.cleaner.toString(),
      placeholder: "Cleaning"
    },
    {
      name: groupTypeIds.handyman.toString(),
      placeholder: "Handyman"
    },
    {
      name: groupTypeIds.transportation.toString(),
      placeholder: "Transportation"
    }
  ]
};