import React from "react";
import { observer } from "mobx-react";
import { UIText } from "../../client/lang";
import { Styled } from "direflow-component";
import { McbGetStartedSearchController } from "./controller";
import { computed, observable } from "mobx";
import styles from "./styles.css";
import { Button, CircularProgress, FormControl, InputLabel, Select, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { capitalize, getEventRealValue, preventDefaultStopProp } from "../../utils/helpers";
import McbComponentPastry from "../../components/McbComponentPastry";
import withJssMap from "../../components/withJssMap";
import GooglePlaceSelector from "../../components/GooglePlaceSelector";
import { placesApi } from "../../client/places";
import { groupTypeIds } from "../../mcb/config/constants";

@observer
class McbGetStartedSearch extends React.Component {
  controller: McbGetStartedSearchController = {} as McbGetStartedSearchController;

  @observable _loading: boolean = true;
  @observable searching: boolean = false;

  @computed get loading(): boolean {
    // return !this.controller.isLoggedIn || this._loading;
    return this._loading;
  };

  constructor(props) {
    super(props);
    this.controller = new McbGetStartedSearchController();
    this.controller.isReady().then(() => this._loading = false);
  };

  handleFieldChange = event => {
    preventDefaultStopProp(event);
    const { target } = event;
    if (!target) return;
    const { name } = target;
    const value = getEventRealValue(event);
    if (!name || !target) return;
    return this.controller[`on${capitalize(name)}Change`](value);
  };

  handleSearch = event => {
    preventDefaultStopProp(event);
    this.searching = true;
    return this.controller.onSearch()
    .catch(console.warn)
    .finally(() => this.searching = false);
  };

  render() {
    const {
      availableServices,
      relationshipOptions,
      provinceOptions,
      municipalityOptions,
      selectedRelationship,
      selectedService,
      selectedProvince,
      selectedMunicipality,
      handleMapCenterChange,
      onExpand
    } = this.controller;

    return <McbComponentPastry>
      <Styled styles={styles}>
        <div className="flex column mcbGetStartedSearch">
          {/*{appIsLoggedIn && <style>{`.mcbGetStartedForm { opacity: 0; pointer-events: none }`}</style>}*/}
          <Typography className="mcbGetStartedHeader font-m textBold" color="primary">
            {UIText.letsGetStarted}
          </Typography>
          <div className="mcbGetStartedSubHeader font-s pSans">
            {UIText.getStartedSubHeader}
          </div>
          {/*{appIsLoggedIn && <div>Marketplace for signed-in user under construction.</div>}*/}
          <form className="flex column mcbGetStartedForm">
            <FormControl variant="outlined">
              <InputLabel>{UIText.getStartedRoleSelect}</InputLabel>
              <Select
                native
                name="relationship"
                IconComponent={ExpandMore}
                label={UIText.getStartedRoleSelect}
                value={!this.loading && selectedRelationship}
                onChange={this.handleFieldChange}
              >
                {!this.loading && relationshipOptions.map(option =>
                  !option.hidden && <option key={option.name} value={option.name}>{option.placeholder}</option>
                )}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel>{UIText.selectService}</InputLabel>
              <Select
                native
                name="service"
                IconComponent={ExpandMore}
                label={UIText.selectService}
                value={!this.loading && selectedService}
                onChange={this.handleFieldChange}
              >
                {!this.loading && availableServices.map(option =>
                  <option key={option.name} value={option.name}>{option.placeholder}</option>
                )}
              </Select>
            </FormControl>
            {selectedService === groupTypeIds.caregiver && (
              <GooglePlaceSelector
                biasCenter
                label={UIText.location}
                helperText="Address, Neighbourhood, City, Postal code..."
                value={placesApi.getEnteredLocation()}
                onPositionChange={handleMapCenterChange}
              />
            )}
            {selectedService !== groupTypeIds.caregiver && <>
              <FormControl variant="outlined">
                <InputLabel>{UIText.province}</InputLabel>
                <Select
                  native
                  name="province"
                  IconComponent={ExpandMore}
                  label={UIText.province}
                  value={!this.loading && selectedProvince}
                  onChange={this.handleFieldChange}
                >
                  {!this.loading && provinceOptions.map(option =>
                    !option.hidden && <option key={option.name} value={option.name}>{option.placeholder}</option>
                  )}
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel>{UIText.city}</InputLabel>
                <Select
                  native
                  name="municipality"
                  IconComponent={ExpandMore}
                  label={UIText.city}
                  value={!this.loading && selectedMunicipality}
                  onChange={this.handleFieldChange}
                >
                  {!this.loading && municipalityOptions.map(option =>
                    !option.hidden && <option key={option.name} value={option.name}>{option.placeholder}</option>
                  )}
                </Select>
              </FormControl>
            </>}
            <Typography className="mcbGetStartedExpand font-s textBold" onClick={onExpand}>{UIText.expandCriteria}</Typography>
            <Button
              className="mcbGetStartedSearchButton textNoTransform"
              size="large"
              variant="contained"
              color="secondary"
              disableElevation
              disabled={this.loading || this.searching}
              onClick={this.handleSearch}
            >
              {this.searching ? <CircularProgress size={22} color="secondary" /> : <Typography>
                {UIText.search}
              </Typography>}
            </Button>
          </form>
        </div>
      </Styled>
    </McbComponentPastry>;
  }
}

export default withJssMap(McbGetStartedSearch);