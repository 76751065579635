import { observer } from "mobx-react";
import React from "react";
import { PickerOption } from "../../../../lib/types/formTypes.legacy";
import { FormFieldCommonProps } from "../../../../lib/types/propTypes";
import { FormControl, FormHelperText, InputLabel, Select } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

export interface PickerFieldProps extends FormFieldCommonProps {
  outlined?: boolean;
  options: PickerOption[];
}

const Picker: React.FC<PickerFieldProps> = observer(
  ({
     name,
     outlined,
     placeholder,
     helperText,
     errorMessage,
     value,
     options,
     disabled,
     onChange,
     onBlur
   }) => (
    <FormControl variant={outlined ? "outlined" : undefined} error={!!errorMessage}>
      <InputLabel>{placeholder}</InputLabel>
      <Select
        native
        name={name}
        IconComponent={ExpandMore}
        label={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      >
        {options.map(option =>
          !option.hidden && <option key={option.name} value={option.name}>{option.placeholder}</option>
        )}
      </Select>
      {(helperText || errorMessage) && (
        <FormHelperText>
          {helperText || errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  ));

export default Picker;