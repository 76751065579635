import { observer } from "mobx-react";
import { AnySupportedFieldTypes, InputField, PickerField, TypeClassField } from "../../../lib/types/formTypes.legacy";
import React from "react";
import Input from "./Input";
import Checkbox from "./Checkbox";
import Picker from "./Picker";

interface FormFieldProps extends Partial<React.HTMLProps<HTMLInputElement>> {
  field: TypeClassField<AnySupportedFieldTypes>,
  disabled?: boolean;
  onChange?: (event: any) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
}

const FormField: React.FC<FormFieldProps> = observer(
  ({
     className,
     field,
     disabled,
     onChange,
     onBlur,
     autoFocus
   }) => {
    const {
      name,
      type,
      required,
      value,
      placeholder,
      helperText,
      _errorMessage
    } = field;
    const {
      alwaysShowPlaceholder,
      multiline,
      minLength,
      maxLength,
      // autoLineHeight,
      secured
    } = field as InputField;
    const {
      options
    } = field as PickerField;
    const isDisabled = disabled || field.disabled;
    const errorMessage = _errorMessage;

    return type === "checkbox"
      ? <Checkbox
        name={name}
        placeholder={placeholder}
        helperText={helperText}
        errorMessage={errorMessage}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
      />
      : type === "picker"
        ? <Picker
          options={options}
          name={name}
          placeholder={placeholder}
          errorMessage={errorMessage}
          helperText={helperText}
          value={value}
          disabled={disabled}
          outlined
          onChange={onChange}
          onBlur={onBlur}
        />
        : type === "input"
          ? <Input
            name={name}
            multiline={multiline}
            minLength={minLength}
            maxLength={maxLength}
            errorMessage={errorMessage}
            helperText={helperText}
            secured={secured}
            required={required}
            placeholder={placeholder}
            alwaysShowPlaceholder={alwaysShowPlaceholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
          />
          : null;
  });

export default FormField;