// For future configurable flags.
import { observable } from "mobx";
import { daysToMilli, minutesToMilli } from "../utils/helpers";

const flags = observable({
  globalName: "mcbWebComponent",
  storageCleanupDate: 1686877609593,
  xBaseSwitch: "",
  renewDisable: false,
  txDisable: false,
  txPollingDisable: false,
  deepRenew: true,
  deepRenewExpiresIn: daysToMilli(30),
  loadHiresAvatar: false,
  tokenIdleTimeout: minutesToMilli(20),
  marketResultsListPaginationCount: 10
});

export default flags;