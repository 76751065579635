import React, { useEffect, useState } from "react";
import styles from "./styles.css";
import { DateTimePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { Styled } from "direflow-component";
import { getFakeDateForTimeString24, getISOStringLocal, getTimeString24Local } from "../../utils/helpers";
import { TextField } from "@material-ui/core";

export interface DatePickerProps {
  mode: "time" | "datetime-local";
  min?: ParsableDate;
  max?: ParsableDate;
  label: string;
  defaultValue: Date | string;
  disabled?: boolean;
  errorMessage?: string;
  onOpen?: (event: any) => any;
  onClose?: (event: any) => any;
  onChange?: (result: Date | string) => any;
}

const dateTimeValueNotChanged = (prevProps, nextProps) => {
  return prevProps.defaultValue === nextProps.defaultValue
    && prevProps.min === nextProps.min
    && prevProps.max === nextProps.max;
};

const DatePicker = React.memo<DatePickerProps>(props => {
  let datePicker;

  const [defaultValue, setDefaultValue] = useState(props.defaultValue);
  useEffect(() => setDefaultValue(props.defaultValue), [props.defaultValue]);

  const [muiDatetimeValue, setMuiDatetimeValue] = useState(
    defaultValue &&
    (props.mode === "time"
      ? defaultValue
      : new Date(defaultValue).toLocaleString())
  );
  useEffect(() => setMuiDatetimeValue(defaultValue &&
    (props.mode === "time"
      ? defaultValue
      : new Date(defaultValue).toLocaleString())
  ), [defaultValue, props.mode]);

  const [muiPickerOpen, setMuiPickerOpen] = useState(false);
  const handleOpen = (event?: any) => {
    setMuiPickerOpen(true);
    if (typeof props.onOpen === "function") props.onOpen(event);
    setTimeout(() => {
      const elm = document.getElementsByClassName("MuiPickersModal-dialog")[0];
      if (!elm) return;
      (elm.parentNode?.parentNode?.parentNode as HTMLElement).style.zIndex = "1145141415";
    });
  };
  const handleClose = (event?: any) => {
    setMuiPickerOpen(false);
    if (typeof props.onClose === "function") props.onClose(event);
  };

  const wrapMuiEvent = value => {
    let result;
    if (props.mode !== "time") {
      result = getISOStringLocal(
        new Date(new Date(new Date(value).setSeconds(0)).setMilliseconds(0))
      );
    } else {
      result = getTimeString24Local(value.toDate());
      setMuiDatetimeValue(getTimeString24Local(value.toDate(), true));
    }
    return props.onChange && result && props.onChange(result);
  };

  datePicker = (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {props.mode === "time" ? (
        <TimePicker
          value={getFakeDateForTimeString24(muiDatetimeValue)}
          // minDate={props.min}
          // maxDate={props.max}
          onChange={wrapMuiEvent}
          open={muiPickerOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          TextFieldComponent={() => null}
        />
      ) : (
        <DateTimePicker
          value={defaultValue}
          minDate={props.min}
          maxDate={props.max}
          onChange={wrapMuiEvent}
          open={muiPickerOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          TextFieldComponent={() => null}
        />
      )}
      <TextField
        variant="outlined"
        color="secondary"
        label={props.label}
        error={!!props.errorMessage}
        helperText={props.errorMessage}
        disabled={props.disabled}
        onClick={() => !props.disabled && handleOpen()}
        value={muiDatetimeValue}
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .MuiPickerDTTabs-tabs,
            .MuiPickersToolbar-toolbar,
            .MuiPickersClockPointer-pointer,
            .MuiPickersClockPointer-noPoint,
            .MuiPickersClock-pin,
            .MuiPickersDay-daySelected {
              background-color: rgb(84, 56, 132);
            }
            .MuiPickersClockPointer-thumb {
              border-color: rgb(84, 56, 132)
            }
            .MuiButton-textPrimary,
            .MuiPickersDay-current {
              color: rgb(241, 95, 34);
            }
            .PrivateTabIndicator-colorSecondary-3 {
              background-color: rgb(241, 95, 34);
            }
            .MuiTypography-h4 {
              font-size: 1.6rem;
            }
            .MuiTypography-h3 {
              font-size: 2.6rem;
            }
            .MuiTypography-h2 {
              font-size: 3.2rem;
            }
            .MuiPickersToolbar-toolbar {
              height: 85px;
            }
            .MuiGrid-align-items-xs-flex-end {
              align-items: center;
            }
            `
        }}
      />
    </MuiPickersUtilsProvider>
  );

  const HomogeneousDatePicker = () => datePicker;

  return (
    <Styled styles={styles}>
      <div className="datePicker">
        <HomogeneousDatePicker />
      </div>
    </Styled>
  );
}, dateTimeValueNotChanged);

export default DatePicker;