import { observer } from "mobx-react";
import React from "react";
import { FormFieldCommonProps } from "../../../../lib/types/propTypes";
import { FormControlLabel, FormHelperText, Typography } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';

export interface CheckboxFieldProps extends FormFieldCommonProps {}

const ICheckbox: React.FC<CheckboxFieldProps> = observer(
  ({ name, placeholder, value, disabled, helperText, errorMessage, color, onChange, onBlur }) => (
    <FormControlLabel
      control={
        <>
          <Checkbox
            disabled={disabled}
            checked={!!value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
          />
        </>
      }
      label={
        <>
          <Typography>{placeholder}</Typography>
          {(helperText || errorMessage) && (
            <FormHelperText error={!!errorMessage}>
              {helperText || errorMessage}
            </FormHelperText>
          )}
        </>
      }
    />
  ));

export default ICheckbox;