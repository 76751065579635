import { Controller } from "../../lib/controller";
import { computed, reaction } from "mobx";
import { mcbSearchCtrl } from "../../mcb/client/search";
import { groupTypeIds, webAppUrl } from "../../mcb/config/constants";
import { Group, Profile } from "../../lib/types/dataTypes";
import { stateCtrl } from "../../client/state";
import { getDisplayNameEng, isEmpty, safeUrlName } from "../../utils/helpers";
import { computedFn } from "mobx-utils";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { client } from "../../client/client";
import { removeLastName } from "../../mcb/lib/common";

export class McbMarketResultsPopupController extends Controller {
  getRatingsDebouncer;
  getAchievementsDebouncer;

  @computed get isOpen(): boolean {
    return stateCtrl.mcbMarketResultsPopupIsOpen;
  };
  @computed get groups(): Group[] {
    return stateCtrl.mcbMarketResultsPopupGroups || [];
  };
  @computed get finderContextGroup(): Group {
    return client.findGroupById(stateCtrl.finderContextGroupId);
  };
  @computed get finderContextIsMCBStaff(): boolean {
    return this.finderContextGroup.typeId === groupTypeIds.myCareBaseStaff;
  };
  @computed get profiles(): Profile[] {
    return this.groups.map(group => group.profile);
  };

  @computed get selectedService(): number {
    return Array.from(new Set(this.groups.map(group => group.typeId)))[0];
  };
  @computed get isCaregiver(): boolean {
    return this.selectedService === groupTypeIds.caregiver;
  };

  @computed get ownProfileIds(): Profile["id"][] {
    return Array.from(new Set([
      ...client.groups.map(g => g.profileId),
      ...client.members.map(m => m.profileId)
    ]));
  };

  @computed get ratingLoading(): boolean {
    return mcbSearchCtrl.ratingLoading;
  };

  get isEmbedded(): boolean {
    return mcbSessionCtrl.isEmbedded;
  };

  constructor() {
    super();
    this.autoGetRatingsAndAchievements();
  }

  autoGetRatingsAndAchievements = () => this.disposers.push(reaction(
    () => this.isOpen,
    () => {
      this.getRatings();
      this.getAchievements();
    }
  ));

  getProfileAvatarUri = (profile: Profile) => mcbSearchCtrl.getProfileAvatarUri(profile);

  getProfileAbilities = profile => mcbSearchCtrl.getProfileAbilities(profile);

  getProfileLanguages = profile => mcbSearchCtrl.getProfileLanguages(profile);

  getAdminLink = (profile: Profile) => {
    if (!this.finderContextIsMCBStaff) return;
    const group = this.groups.find(g => g.profileId === profile.id);
    if (!group) return;
    return `${webAppUrl}/AdminCentre?type=${group.typeId === groupTypeIds.caregiver ? "3" : "providers"}&profile=${profile.id}`;
  };

  openProfileViewGroupId = groupId => {
    mcbSearchCtrl.setViewGroupId(groupId);
    return stateCtrl.openMcbListingProfile(groupId);
  };

  setViewProfileId = id => {
    const group = this.groups.find(g => g.profileId === id);
    if (!group) return;
    const displayName = getDisplayNameEng(removeLastName(group?.profile, true));
    const url = displayName && `${window.location.pathname}/p/${safeUrlName(displayName, true)}`;
    window.open(url, "_blank");
    // mcbSearchCtrl.setViewGroupId(group.id);
    // return stateCtrl.openMcbListingProfile(group.id);
  };

  getRatings = () => {
    clearTimeout(this.getRatingsDebouncer);
    this.getRatingsDebouncer = setTimeout(() => mcbSearchCtrl.batchGetRatingForGroups(this.groups), 200);
  };

  getAchievements = () => {
    clearTimeout(this.getAchievementsDebouncer);
    this.getAchievementsDebouncer = setTimeout(() => mcbSearchCtrl.batchGetAchievementForGroups(this.groups), 200);
  };

  getRatingLoading = computedFn((profile: Profile) => {
    const group = this.groups.find(g => g.profileId === profile.id);
    return !mcbSearchCtrl.findAggregatedRatingForGroup(group);
  });

  findProfileRatingScore = computedFn((profile: Profile) => {
    const group = this.groups.find(g => g.profileId === profile.id);
    return mcbSearchCtrl.findGroupRatingScore(group);
  });

  findProfileAchievement = computedFn((profile: Profile) => {
    const group = this.groups.find(g => g.profileId === profile.id);
    return mcbSearchCtrl.findGroupAchievement(group);
  });

  getHasInterviewAppointmentData = (visibleProfileIds: number[]) => mcbSearchCtrl.getHasInterviewAppointmentDataForProfiles(
    visibleProfileIds,
    this.groups,
    this.finderContextGroup
  );

  getHasInterviewLoading = computedFn((profile: Profile) => {
    const group = this.groups.find(g => g.profileId === profile?.id);
    if (!group) return false;
    if (mcbSessionCtrl.isValidVisitor) return false;
    return isEmpty(mcbSearchCtrl.findHasInterviewAppointmentData(this.finderContextGroup.id, group.id));
  });

  findHasInterviewAppointment = computedFn((profile: Profile) => {
    const group = this.groups.find(g => g.profileId === profile?.id);
    if (!group) return false;
    const data = mcbSearchCtrl.findHasInterviewAppointmentData(this.finderContextGroup.id, group.id);
    return data?.hasInterview;
  });

  getShowChat = computedFn((profile: Profile): boolean => {
    return mcbSessionCtrl.isEmbedded
    && stateCtrl.finderContextGroupId
    && stateCtrl.marketResultsPopupSavedProfileIds
    && stateCtrl.marketResultsPopupSavedProfileIds.includes(profile.id);
  });

  onChatClick = (profileId: number) => stateCtrl.marketResultsPopupChatHandler && stateCtrl.marketResultsPopupChatHandler(profileId);
}