import { Controller } from "../../lib/controller";
import { computed, observable, toJS, when } from "mobx";
import { FormLegacy } from "../../client/form.legacy";
import { PickerField, PickerOption, TypeClassField } from "../../lib/types/formTypes.legacy";
import { McbSearchController, mcbSearchCtrl } from "../../mcb/client/search";
import { groupTypeIds, marketSearchUrls } from "../../mcb/config/constants";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { client } from "../../client/client";
import { filterFormFieldOptionsByService } from "../../mcb/lib/common";
import { PlacesAutocompleteResult } from "../../lib/types/dataTypes";
import { placesApi } from "../../client/places";

export class McbGetStartedSearchController extends Controller {
  @observable _searchForm: FormLegacy;
  @observable searching: boolean = false;

  @computed get isLoggedIn(): boolean {
    return client.isLoggedIn;
  };
  @computed get appIsLoggedIn(): boolean {
    return mcbSessionCtrl.appIsLoggedIn && !mcbSessionCtrl.isInterimUser;
  };

  @computed get availableServices(): McbSearchController["availableServices"] {
    return mcbSearchCtrl.availableServices || [];
  };
  @computed get searchForm(): FormLegacy {
    return this._searchForm || mcbSearchCtrl.searchForm;
  };
  @computed get dataRestored(): boolean {
    return mcbSearchCtrl.careCircleDataRestored && mcbSearchCtrl.householdDataRestored;
  };

  @computed get relationshipField(): TypeClassField<PickerField> {
    return (this.searchForm && this.searchForm.getRenderedField("relationship")) || {} as TypeClassField<PickerField>;
  };
  @computed get provinceField(): TypeClassField<PickerField> {
    return (this.searchForm && this.searchForm.getRenderedField("provinces")) || {} as TypeClassField<PickerField>;
  };
  @computed get territoryField(): TypeClassField<PickerField> {
    return (this.searchForm && this.searchForm.getRenderedField("territories")) || {} as TypeClassField<PickerField>;
  };
  @computed get municipalityField(): TypeClassField<PickerField> {
    return (this.searchForm && this.searchForm.getRenderedField("municipalities")) || {} as TypeClassField<PickerField>;
  };

  @computed get relationshipOptions(): PickerOption[] {
    return this.relationshipField.options;
  };
  @computed get provinceOptions(): PickerOption[] {
    return ((this.provinceField || {}).options || []).filter(o => filterFormFieldOptionsByService(o, this.selectedService));
  };
  @computed get municipalityOptions(): PickerOption[] {
    return ((this.municipalityField || {}).options || []).filter(o => filterFormFieldOptionsByService(o, this.selectedService));
  };

  @computed get selectedRelationship(): string {
    return this.relationshipField.value || "";
  };
  @computed get selectedService(): number {
    return mcbSearchCtrl.selectedService;
  };
  @computed get selectedProvince(): string {
    return this.provinceField.value || "";
  };
  @computed get selectedMunicipality(): string {
    return this.municipalityField.value || "";
  };

  constructor() {
    super();
    this.loadAllData().catch(console.warn);
  }

  loadAllData = async () => {
    await this.isReady();
    this.setSearchForm();
    this._searchForm.resetDirty();
    this.disposers = [
      when(
        () => this.dataRestored,
        () => !this._searchForm.isDirty && !this.searching && this.setSearchForm()
      )
    ];
  };

  isReady = async () => when(() =>
    mcbSearchCtrl.storage.ready
    && this.searchForm
    && this.searchForm.ready
  );

  setSearchForm = () => this._searchForm = new FormLegacy(mcbSearchCtrl.searchForm.blank, mcbSearchCtrl.searchForm.toJSON());

  onRelationshipChange = value => this.searchForm.set("relationship", value);

  onServiceChange = (value: typeof groupTypeIds[keyof typeof groupTypeIds]) => mcbSearchCtrl.setService(value);

  onProvinceChange = value => this.searchForm.set("provinces", value);

  onMunicipalityChange = value => this.searchForm.set("municipalities", value);

  onSearch = async () => {
    this.searching = true;
    await this._searchForm.isReady();
    await client.isLoggedInAndReady();
    await when(() => mcbSearchCtrl.ready);
    await when(() => this.dataRestored);
    if (this.selectedService === groupTypeIds.caregiver && !mcbSessionCtrl.isValidVisitor) {
      mcbSearchCtrl.queueSearchData(
        this.selectedService === groupTypeIds.caregiver
          ? groupTypeIds.careReceiver
          : groupTypeIds.household,
        toJS(this._searchForm.data)
      );
    } else {
      await mcbSearchCtrl.updateScratchpadSearched(toJS(this._searchForm.data));
    }
    return this.onSearchNavStart();
  };

  onSearchNavStart = async () => {
    const destinationUrl = marketSearchUrls[this.selectedService];
    if (!destinationUrl) return;
    return window.location.href = destinationUrl;
  };

  onExpand = (event: any) => {
    mcbSearchCtrl.onGetStartedExpandClick && mcbSearchCtrl.onGetStartedExpandClick(event);
    return this.onSearchNavStart();
  };

  handleMapCenterChange = (value: PlacesAutocompleteResult) => placesApi.setEnteredLocation(value);
}