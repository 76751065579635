import { Controller } from "../../lib/controller";
import { autorun, computed, IObservableArray, observable, reaction, toJS, when } from "mobx";
import { FormLegacy } from "../../client/form.legacy";
import {
  addRelationshipSelfOption,
  assistEnglishSelection,
  changeFieldLabels,
  fixMunicipalityForHousehold,
  getCaregiverFields,
  getRatingAggregatedScoreAndSamples,
  getSearchFields,
  getServiceGroupTypes,
  matchWebsiteShortlist,
  removeLastName,
  setGroupProfileData
} from "../lib/common";
import { UIException, UIText } from "../../client/lang";
import { Group, GroupType, Member, Profile } from "../../lib/types/dataTypes";
import {
  abbvLastNameDisplayNameEng,
  arrayFlat,
  asyncPause,
  capitalize,
  contextReject,
  getDisplayNameEng,
  isEmpty,
  removeFalsy,
  whenFulfill
} from "../../utils/helpers";
import { McbSessionController, mcbSessionCtrl } from "./session";
import { client } from "../../client/client";
import { Topic, TopicPrimitive } from "../../lib/types/topicTypes";
import { topicCtrl } from "../../client/topic";
import { groupTypeIds, topicTypeIds } from "../config/constants";
import { endpointConfig } from "../../config/api";
import { api } from "../../client/api";
import { ipGeolocationApiUrl } from "../../config/external";
import publicIp from "public-ip";
import { IpData, JSONString, KeyValuePairs } from "../../lib/types/miscTypes";
import { Achievement, AggregatedRatingReviewResponseDTO, HasInterviewAppointmentData, SearchResult } from "../lib/types/dataTypes";
import { fileCtrl } from "../../client/file";
import { computedFn } from "mobx-utils";
import { typeClassIds } from "../../config/constants";
import Axios, { AxiosResponse } from "axios";
import { createCareCircleGroup, createHouseholdGroup } from "../../lib/group-utilities";
import { ui } from "../../client/ui";

export interface McbSearchStore {
  selectedService: Group["typeId"];
  viewGroupId: Profile["id"];
  searchFormDataQueue: { [key: number]: Profile["data"] }; // key is groupTypeId
}

export class McbSearchController extends Controller<McbSearchStore> {
  onGetStartedExpandClick: (event: any) => void;

  getShortlistDebouncers = {};
  getShortlistDebounceTime = 500;

  getRatingDebouncer;
  getRatingDebounceTime = 300;

  getHasInterviewDebouncer;
  getHasInterviewDebounceTime = 500;

  getAchievementDebouncer;
  getAchievementDebounceTime = 300;

  doNotRestoreSearchForm: boolean;

  @observable ready: boolean = false;
  @observable geoLoading: boolean = false;
  @observable searching: boolean = false;
  @observable isCheckingShortlists = {};
  @observable isCheckingCandidates: boolean = false;

  @observable careCircleDataRestored: boolean = false;
  @observable householdDataRestored: boolean = false;

  @observable availableServices: { placeholder: string; name: number; }[] = [];

  @observable caregiverForm: FormLegacy;
  @observable careCircleSearchForm: FormLegacy & { groupId?: number };
  @observable householdSearchForm: FormLegacy & { groupId?: number };

  @observable searchResults: SearchResult[] = [];
  @observable candidateGroups: Group[] = [];

  @observable ratingLoading: boolean = false;
  @observable aggregatedRatings: IObservableArray<AggregatedRatingReviewResponseDTO> = observable([]);

  @observable achievementLoadingGroupIds: IObservableArray<number> = observable([]);
  @observable achievements: IObservableArray<Achievement> = observable([]);

  @observable hasInterviewAppointmentLoading: boolean = false;
  @observable hasInterviewAppointmentData: IObservableArray<HasInterviewAppointmentData> = observable([]);

  @observable _careCircleCandidateCache: Group["id"][] = [];
  @observable _householdCandidateCache: Group["id"][] = [];
  getProfileAvatarUri = computedFn((profile: Profile) => {
    if (!this.ready) return;
    if (isEmpty(profile)) return;
    const avatarId = (profile.data || {}).avatar;
    const profileId = profile.id;
    const group = this.searchResults.find(g => g.profileId === profileId) || {} as Group;
    return fileCtrl.getProfileAvatarUri(avatarId, group.id, "group");
  });
  getProfileAbilities = computedFn((profile: Profile) => {
    if (isEmpty(profile)) return [];
    const data = profile.data;
    const results = [];
    if (data.ableLiveIn) results.push(UIText.marketplaceLiveIn);
    if (data.ableOvernight) results.push(UIText.marketplaceOvernight);
    if (data.ableCar) results.push(UIText.marketplaceAbleCar);
    return results;
  });
  getProfileLanguages = computedFn((profile: Profile) => {
    if (isEmpty(profile)) return [];
    const data = profile.data;
    const languages = Object.keys(data)
    .filter(key => key.match(/language/ig))
    .map(key => !!data[key] && key)
    .filter(Boolean);
    languages.sort((a, b) => {
      const aMatch = this.searchForm.form.some(f => !!f.value && f.match && f.match.some(m => m.key === a));
      const bMatch = this.searchForm.form.some(f => !!f.value && f.match && f.match.some(m => m.key === b));
      if (aMatch && !bMatch) return -1;
      if (!aMatch && bMatch) return 1;
      return 0;
    });
    return languages.map(key => this.caregiverForm.getField(key).placeholder);
  });
  findAggregatedRatingForGroup = computedFn((group: Group): AggregatedRatingReviewResponseDTO => (
    this.aggregatedRatings.find(r => r.userId === (group || {}).owner)
  ));
  findAggregatedRatingForProfile = computedFn((profileId: number): AggregatedRatingReviewResponseDTO => {
    const group = this.allResultGroups.find(g => g.profileId === profileId);
    if (!group) return null;
    return this.findAggregatedRatingForGroup(group);
  });
  findProfileRatingScore = computedFn((profile: Profile): { samples: number; rating: number } => {
    const aggregatedRatingReview = this.findAggregatedRatingForProfile((profile || {}).id);
    return getRatingAggregatedScoreAndSamples(aggregatedRatingReview);
  });
  findGroupRatingScore = computedFn((group: Group): { samples: number; rating: number } => {
    const aggregatedRatingReview = this.findAggregatedRatingForGroup(group);
    return getRatingAggregatedScoreAndSamples(aggregatedRatingReview);
  });
  findHasInterviewAppointmentData = computedFn((organizerGroupId: number, providerGroupId: number): HasInterviewAppointmentData => (
    this.hasInterviewAppointmentData.find(d => (
      d.organizerGroupId === organizerGroupId &&
      d.providerGroupId === providerGroupId
    ))
  ));
  findGroupAchievement = computedFn((group: Group) => {
    return this.achievements.find(a => a.caregiverGroupId === group.id);
  });
  findProfileAchievement = computedFn((profile: Profile) => {
    const group = this.allResultGroups.find(g => g.profileId === profile?.id);
    if (!group) return null;
    return this.findGroupAchievement(group);
  });

  constructor() {
    super();
    this.loadAllData()
    // .then(() => when(() => !isEmpty(this.shortlistTopics)))
    .catch(console.warn)
    .finally(() => this.ready = true);

    this.disposers.push(
      autorun(() => assistEnglishSelection(this.searchForm)),
      reaction(() => this.ready && this.selectedService, () => this.searchListings(true)),
      autorun(this.getCandidateTopics),
      autorun(this.getCandidateTopicGroups),
      reaction(() => this.candidateTopics, this.cacheCandidateChanges),
      reaction(() => !mcbSessionCtrl.isValidVisitor, this.syncCandidateTopics)
      // reaction(() => this.allResultGroups, this.getRatings)
    );

    mcbSessionCtrl.setGroupDataChangeCallback(this.getOrCreateShortlistTopic);
  }

  @computed get selectedService(): typeof groupTypeIds[keyof typeof groupTypeIds] {
    return Number(this.store.selectedService || 0);
  };

  @computed get viewGroupId(): number {
    // This decides what to be displayed in mcb-listing-profile component,
    // stateCtrl doesn't store this state, only pushes URL history.
    return Number(this.store.viewGroupId || 0);
  };

  @computed get searchFormDataQueue(): McbSearchStore["searchFormDataQueue"] {
    this.storage.initProperty("searchFormDataQueue", {});
    return this.store.searchFormDataQueue;
  };

  @computed get searchForm(): FormLegacy & { groupId?: number } {
    return this.selectedService === groupTypeIds.caregiver
      ? this.careCircleSearchForm
      : this.householdSearchForm;
  };

  @computed get careCircleScratchpad(): Group {
    return mcbSessionCtrl.careCircleScratchpad;
  };

  @computed get householdScratchpad(): Group {
    return mcbSessionCtrl.householdScratchpad;
  };

  @computed get scratchpadGroup(): Group {
    return this.selectedService === groupTypeIds.caregiver
      ? this.careCircleScratchpad
      : this.householdScratchpad;
  };

  @computed get member(): Member {
    return client.findMyMemberByGroupId(this.scratchpadGroup.id);
  };

  @computed get shortlistTopics(): Topic[] {
    return topicCtrl.findTopics(t =>
      t.typeId === topicTypeIds.shortlist &&
      t["groupIdList"].includes(this.scratchpadGroup.id)
    );
  };

  @computed get shortlistTopic(): Topic {
    return this.shortlistTopics.find(matchWebsiteShortlist) || {} as Topic;
  };

  @computed get shortlistingAvailable(): boolean {
    return !isEmpty(this.scratchpadGroup) && !isEmpty(this.shortlistTopic);
  };

  @computed get allResultGroups(): SearchResult[] | Group[] {
    return [
      ...this.searchResults,
      ...this.candidateGroups
    ];
  };

  @computed get candidateTopicGroups(): Group[] {
    return this.allResultGroups.filter(g => this.candidateTopics.some(t => t.groupIdList && t.groupIdList.includes(g.id)));
  };

  @computed get candidateTopics(): Topic[] {
    return topicCtrl.findSubTopicsByParentId(this.shortlistTopic.id);
  };

  @computed get candidateCache(): IObservableArray<Group["id"]> {
    return (this.selectedService === groupTypeIds.caregiver
      ? this._careCircleCandidateCache
      : this._householdCandidateCache) as IObservableArray;
  };

  loadAllData = async () => Promise.all([
    this.getWrapperTypeClass(),
    this.getWrapperCaregiverTypeClass(),
    this.getServiceGroupTypes()
  ]);

  getSearchFields = async () => getSearchFields();

  getWrapperTypeClass = async () => this.getSearchFields()
  .then(fields => {
    this.careCircleSearchForm = new FormLegacy(fields);
    this.householdSearchForm = new FormLegacy(fields);
    changeFieldLabels(this.careCircleSearchForm, groupTypeIds.careReceiver);
    changeFieldLabels(this.householdSearchForm, groupTypeIds.household);
    addRelationshipSelfOption(this.careCircleSearchForm);
    addRelationshipSelfOption(this.householdSearchForm);
    fixMunicipalityForHousehold(this.householdSearchForm);
    this.disposers.push(reaction(() => this.careCircleScratchpad, this.onCareCircleScratchpadChange));
    this.disposers.push(reaction(() => this.householdScratchpad, this.onHouseholdScratchpadChange));
    return;
  });

  getWrapperCaregiverTypeClass = async () => getCaregiverFields()
  .then(fields => this.caregiverForm = new FormLegacy(fields));

  getServiceGroupTypes = async () => getServiceGroupTypes()
  .then((groupTypes: GroupType[]) => {
    if (isEmpty(groupTypes)) return;
    this.availableServices = groupTypes
    .map(groupType => ({
      placeholder: groupType.typeName,
      name: groupType.id
    }));
    if (!this.selectedService) mcbSearchCtrl.setService(this.availableServices[0].name);
  });

  restoreCareCircleFormData = () => when(
    () => this.ready && !isEmpty(this.careCircleSearchForm && this.careCircleSearchForm.data),
    () => setGroupProfileData(
      this.careCircleSearchForm,
      this.careCircleScratchpad,
      this.detectLocation,
      this.searchListings,
      this.scratchpadGroup.id,
      this.setCareCircleDataRestored,
      this.searchFormDataQueue[groupTypeIds.careReceiver],
      this.clearSearchDataQueue,
      this.doNotRestoreSearchForm
    )
  );

  restoreHouseholdFormData = () => when(
    () => this.ready && !isEmpty(this.householdSearchForm && this.householdSearchForm.data),
    () => setGroupProfileData(
      this.householdSearchForm,
      this.householdScratchpad,
      this.detectLocation,
      this.searchListings,
      this.scratchpadGroup.id,
      this.setHouseholdDataRestored,
      this.searchFormDataQueue[groupTypeIds.household],
      this.clearSearchDataQueue,
      this.doNotRestoreSearchForm
    )
  );

  onCareCircleScratchpadChange = () => {
    const careCircleFormGroupId = this.careCircleSearchForm && this.careCircleSearchForm.groupId;
    if (isEmpty(this.careCircleScratchpad) || careCircleFormGroupId !== this.careCircleScratchpad.id) {
      this.restoreCareCircleFormData();
    }
  };

  // createShortlistTopic = async (groupId: number) => {
  //   if (!groupId) return;
  //   console.log("Create shortlist for", groupId);
  //   const topic: TopicPrimitive = {
  //     creatorMemberId: this.member.id,
  //     typeId: topicTypeIds.shortlist,
  //     description: UIText.marketDefaultShortlistName,
  //     groupId,
  //     onCalendar: 0,
  //     isTemplate: 0,
  //     isParentTemplate: 0,
  //     isCompleted: 0,
  //     isDataLocked: 0,
  //     isLocked: 0,
  //     typeClassId: typeClassIds.shortlistTopic.v1.id,
  //     typeClassVersion: typeClassIds.shortlistTopic.v1.version // Default for now.
  //   };
  //   return api.POST({
  //     endpoint: endpointConfig.create_topic,
  //     data: {
  //       currentGroupId: groupId,
  //       caregiverGroupId: null,
  //       topic
  //     }
  //   })
  //   .then(() => this.getShortlistTopics(groupId));
  // };

  onHouseholdScratchpadChange = () => {
    const householdFormGroupId = this.householdSearchForm && this.householdSearchForm.groupId;
    if (isEmpty(this.householdScratchpad) || householdFormGroupId !== this.householdScratchpad.id) {
      this.restoreHouseholdFormData();
    }
  };

  setCareCircleDataRestored = () => {
    if (!this.careCircleDataRestored) this.careCircleDataRestored = true;
  };

  setHouseholdDataRestored = () => {
    if (!this.householdDataRestored) this.householdDataRestored = true;
  };

  detectLocation = async () => {
    const parsePossibleProvCity = (ipData: IpData) => {
      if (isEmpty(ipData)) return;
      const provinces = this.searchForm.getField("provinces").options;
      const cities = this.searchForm.getField("municipalities").options;
      const possibleProv = ipData.state_prov && provinces.find(o =>
        o.name.toLowerCase() === ipData.state_prov.toLowerCase() ||
        o.placeholder.toLowerCase() === ipData.state_prov.toLowerCase()
      );
      const possibleCity = ipData.city && cities.find(o =>
        o.name.toLowerCase() === ipData.city.toLowerCase() ||
        o.placeholder.toLowerCase() === ipData.city.toLowerCase()
      );
      if (possibleProv) this.searchForm.set("provinces", possibleProv.name);
      if (possibleCity) this.searchForm.set("municipalities", possibleCity.name);
    };
    if (this.geoLoading) return;
    if (mcbSessionCtrl.runningAuthChangeSequence) return;
    if (isEmpty(this.scratchpadGroup)) return;
    this.geoLoading = true;
    const ipAddr = await publicIp.v4().catch(console.warn);
    if (!ipAddr) return this.geoLoading = false;
    const ipGeolocationUrl = ipGeolocationApiUrl(ipAddr);
    return Axios.get(ipGeolocationUrl)
    .then(response => parsePossibleProvCity(response.data))
    .finally(() => this.geoLoading = false);
  };

  setService = (service: typeof groupTypeIds[keyof typeof groupTypeIds]) => {
    service = isNaN(Number(service)) ? null : Number(service);
    if (!service) return;
    const valid = this.availableServices.some(s => s.name === service);
    if (!valid) return;
    this.store.selectedService = service || undefined;
  };

  setViewGroupId = (id: Group["id"]) => this.store.viewGroupId = id;

  getOrCreateShortlistTopic = async ({ id, typeId }: McbSessionController["groupDataChangeInfo"]) => {
    clearTimeout(this.getShortlistDebouncers[id]);
    this.getShortlistDebouncers[id] = setTimeout(async () => {
      const done = () => this.isCheckingShortlists[id] = false;
      const shortListTopics = topicCtrl
      .findTopics(t => t.typeId === topicTypeIds.shortlist && t["groupIdList"].includes(id));
      const shortListTopic = shortListTopics.find(matchWebsiteShortlist);
      if (!isEmpty(shortListTopic)) return done();
      if (!id || !typeId) return done();
      const group = client.findGroupById(id);
      if (isEmpty(group)) return done();
      if (group.typeId !== typeId) return done();
      await asyncPause();
      return when(() => !mcbSessionCtrl.runningAuthChangeSequence)
      .then(() => when(() => !this.isCheckingShortlists[id]))
      .then(() => this.isCheckingShortlists[id] = true)
      .then(client.isLoggedInAndReady)
      .then(() => this.getShortlistTopics(id, shortListTopics))
      .then(this.getServiceGroupTypes)
      .catch(err => {
        console.error(err);
        return ui.showError({
          actionName: UIText.marketplaceSearchBarSearchBtn,
          err: new UIException("MARKET_SCRATCHPAD_NOT_AVAILABLE", err)
        });
      })
      .finally(done);
    }, this.getShortlistDebounceTime);
  };

  getShortlistTopics = async (id: Group["id"], shortListTopics?: Topic[]) =>
    api.GET(endpointConfig.get_or_create_website_shortlist(id))
    .then(response => [response.data])
    .then(topics => topicCtrl.existsOrRemove(shortListTopics || [], topics))
    .then(topics => topics.map(topicCtrl.updateTopic) && topics);

  getCandidateTopics = async () => {
    if (!client.initialized) return;
    this.isCheckingCandidates = true;
    return this.shortlistTopic.id && topicCtrl.getSubTopicsByParentId(this.shortlistTopic.id)
    .then(topics => topicCtrl.existsOrRemove(this.candidateTopics, topics))
    .then(topics => topics.map(topicCtrl.updateTopic))
    .then(() => this.isCheckingCandidates = false)
    .then(this.cacheCandidateChanges)
    .finally(() => this.isCheckingCandidates = false);
  };

  getCandidateTopicGroups = async () => {
    if (!this.ready || !client.credentialReady) return;
    const groupIds = arrayFlat(this.candidateTopics.map(t => t.groupIdList.filter(id => id !== this.scratchpadGroup.id)));
    return Promise.all(groupIds.filter(id => !this.searchResults.some(g => g.id === id)).map(client.getGroupById))
    .then(groups => this.candidateGroups = groups as Group[]);
  };

  searchListings = async (noSave?: boolean) => {
    await when(() => !mcbSessionCtrl.runningAuthChangeSequence);
    await client.isLoggedInAndReady();
    await whenFulfill(() => !isEmpty(this.searchForm));
    await this.searchForm.isReady();
    await when(() => !isEmpty(this.scratchpadGroup) || mcbSessionCtrl.appIsLoggedIn);
    // await when(() => !isEmpty(this.shortlistTopics));
    if (this.searching) return;
    this.searching = true;
    const groupId = this.scratchpadGroup.id || this.searchForm.groupId || 0;
    const data = this.searchForm.toJSON();
    const typeClassId = typeClassIds.careReceiverProfile.v3.id; // Both groups are using same typeClass
    // if (!groupId) return this.searching = false;
    if (!typeClassId) return this.searching = false;
    if (data === "{}") return this.searching = false;
    if (!mcbSessionCtrl.isValidVisitor) {
      await this.checkMissingGroup(!noSave).catch(console.error);
      // Very important not to override valid user's Care Circle profile upon searching!
      noSave = (this.scratchpadGroup.typeId === groupTypeIds.careReceiver) || noSave;
    }
    return Promise.all([
      this.getGroupsByCriteria(groupId, data, typeClassId)
      .then((groups: SearchResult[]) => {
        if (!this.viewGroupId && !isEmpty(groups)) this.setViewGroupId(groups[0].id);
        return this.searchResults = groups;
      }),
      noSave
        ? this.searchForm.resetDirty()
        : this.updateScratchpadSearched()
    ].filter(Boolean))
    .then(() => Promise.resolve(undefined))
    .finally(() => (this.searching = false));
  };

  getGroupsByCriteria = async (groupId: number, data: JSONString, typeClassId: number) =>
    api.POST({
      endpoint: endpointConfig.search_marketplace,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json"
      },
      data: {
        groupId,
        data,
        typeClassId,
        // isAdmin: isSnSOrAdmin(client.user) ? 1 : 0,
        isAdmin: 0,
        groupTypeIds: [this.selectedService].filter(Boolean)
      }
    })
    .then(client.groupParser.parseResponseArray)
    .then(async (groups: SearchResult[]) => {
      const fixProfile = async group => {
        if (isEmpty(group.profile)) await client
        .getProfileById(group.profileId)
        .then(profile => {
          group.profile = profile;
        });
        removeLastName(group.profile);
      };
      await Promise.all(groups.map(fixProfile)).catch(contextReject);
      return groups;
    });

  checkMissingGroup = async (clickedSearch: boolean, checkCareCircle?: boolean, checkHousehold?: boolean) => {
    // console.log(clickedSearch, checkCareCircle, checkHousehold)
    if ((!checkCareCircle && !checkHousehold && !isEmpty(this.scratchpadGroup)) || !clickedSearch) return;
    await client.getAndStoreUserGroupsAndMembers();
    await asyncPause(10);
    if (checkCareCircle && !isEmpty(this.careCircleScratchpad)) {
      return;
    } else if (checkHousehold && !isEmpty(this.householdScratchpad)) {
      return;
    } else if (!checkCareCircle && !checkHousehold && !isEmpty(this.scratchpadGroup)) {
      return;
    }
    console.log("Missing group on service search typeId " + this.selectedService);

    return (checkCareCircle
      ? createCareCircleGroup(this.careCircleSearchForm.data)
      : checkHousehold
        ? createHouseholdGroup(this.householdSearchForm.data)
        : this.selectedService === groupTypeIds.caregiver
          ? createCareCircleGroup(this.careCircleSearchForm.data)
          : createHouseholdGroup(this.householdSearchForm.data))
    .then(mcbSessionCtrl.getLoggedInUserData);
  };

  updateScratchpadSearched = async (data?: KeyValuePairs) => {
    const appendSearchedToGroupName = async () => {
      // Very important not to override valid user's groupName upon searching!
      if (!mcbSessionCtrl.isValidVisitor) return;
      const groupName = this.scratchpadGroup["groupName"];
      if (!groupName || groupName.match(/{scratchpad}{searched}/g)) return;
      const data = {
        groupName: groupName.replace(/{scratchpad}/g, "{scratchpad}{searched}")
      };
      return api.PATCH({
        endpoint: endpointConfig.group_by_id_wo_member(this.scratchpadGroup.id),
        data
      })
      .then(this.getAndUpdateGroups);
    };
    return this.updateGroupProfile(data)
    .then(appendSearchedToGroupName)
    .then(this.searchForm.resetDirty);
  };

  updateGroupProfile = async (update?: KeyValuePairs) => {
    const { profileId, profile } = this.scratchpadGroup;
    if (!profileId || isEmpty(profile)) return;
    const oriProfileData = (profile || {}).data || {};
    update = update || toJS(this.searchForm.data);
    update = removeFalsy(update);
    update = Object.assign(toJS(oriProfileData), update);
    const data = JSON.stringify(update);
    return api.PATCH({
      endpoint: endpointConfig.profile_by_id(profileId),
      data: { data }
    })
    .then(this.getAndUpdateGroups);
  };

  getAndUpdateGroups = async () => this.selectedService === groupTypeIds.caregiver
    ? mcbSessionCtrl.getScratchpad("careCircle")
    : mcbSessionCtrl.getScratchpad("household");

  addToShortlist = async groupId => {
    if (!this.shortlistingAvailable) return;
    if (isEmpty(this.shortlistTopic)) return;
    await this.candidateSyncReady();
    let group = this.searchResults.find(g => g.id === groupId);
    if (isEmpty(group)) group = await client.getGroupById(groupId);
    let caregiverProfile = (toJS(group.profile) || {}).data;
    if (isEmpty(caregiverProfile)) caregiverProfile = (await client.getProfileById(group.profileId)).data;
    const careCircleProfile = toJS(this.searchForm.data);
    let cP = {}, ccP = {};
    for (let field in caregiverProfile) {
      if (caregiverProfile.hasOwnProperty(field)) {
        cP[`caregiver${capitalize(field)}`] = caregiverProfile[field];
      }
    }
    for (let field in careCircleProfile) {
      if (careCircleProfile.hasOwnProperty(field)) {
        ccP[`careCircle${capitalize(field)}`] = careCircleProfile[field];
      }
    }
    if (group.rank) ccP["rank"] = group.rank.toString();
    if (group.total) ccP["score"] = group.total.toString();
    const data = {
      ...cP,
      ...ccP
    };
    const topic: TopicPrimitive = {
      creatorMemberId: this.member.id,
      typeId: topicTypeIds.candidate,
      description: abbvLastNameDisplayNameEng(
        getDisplayNameEng(caregiverProfile)
      ),
      parentId: this.shortlistTopic.id,
      isTemplate: 0,
      isParentTemplate: 0,
      typeClassId: typeClassIds.candidateTopic.v2.id,
      typeClassVersion: typeClassIds.candidateTopic.v2.version, // Default for now.
      data: JSON.stringify(data)
    };
    return api.POST({
      endpoint: endpointConfig.create_topic,
      data: {
        currentGroupId: this.scratchpadGroup.id,
        otherGroupIdList: [groupId],
        topic
      }
    })
    .then(this.getCandidateTopics);
  };

  removeFromShortlist = async (topicId: Topic["id"]) => {
    if (!this.shortlistingAvailable) return;
    if (isEmpty(this.shortlistTopic)) return;
    await this.candidateSyncReady();
    return topicCtrl.deleteTopicById(topicId);
  };

  candidateSyncReady = async () => {
    // console.log("candidateSyncReady", {
    //   "!runningAuthChangeSequence": !mcbSessionCtrl.runningAuthChangeSequence,
    //   "mcbSearchCtrl.ready": this.ready,
    //   "!mcbSearchCtrl.seaching": !this.searching,
    //   "mcbSearchCtrl.shortlistingAvailable": this.shortlistingAvailable,
    //   "isEmpty(mcbSearchCtrl.isCheckingShortlists)": isEmpty(this.isCheckingShortlists),
    //   "!mcbSearchCtrl.isCheckingCandidates": !this.isCheckingCandidates
    // });
    return Promise.all([
      when(() => !mcbSessionCtrl.runningAuthChangeSequence),
      when(() => this.ready),
      when(() => !this.searching),
      when(() => this.shortlistingAvailable),
      when(() => isEmpty(this.isCheckingShortlists)),
      when(() => !this.isCheckingCandidates)
    ]);
  };

  cacheCandidateChanges = async () => {
    await this.candidateSyncReady();
    if (!mcbSessionCtrl.isValidVisitor) return;
    const candidateGroupIds = this.candidateTopicGroups.map(g => g.id);
    this.candidateCache.replace(candidateGroupIds);
    // console.log("cached", toJS(this.candidateCache));
  };

  syncCandidateTopics = async () => {
    await this.candidateSyncReady();
    if (mcbSessionCtrl.isValidVisitor) return;
    const candidateGroupIds = this.candidateTopicGroups.map(g => g.id);
    const cached = toJS(this.candidateCache);
    const needToAddToShortlists = Array.from(new Set(cached.filter(id => !candidateGroupIds.includes(id))));
    // console.log("Need to add", needToAddToShortlists);
    if (isEmpty(needToAddToShortlists)) return;
    return Promise.all(needToAddToShortlists.map(this.addToShortlist));
  };

  getRatingsForProfiles = async (profileIds: number[]) => {
    profileIds = (Array.isArray(profileIds) && profileIds) || [];
    if (isEmpty(profileIds)) return;
    const groups = this.allResultGroups
    .filter(g => profileIds.includes(g.profileId))
    .filter(g => !this.aggregatedRatings.some(r => r.userId === (g || {}).owner));
    return this.getRatings(groups);
  };

  getRatings = async (groups?: Group[]) => {
    clearTimeout(this.getRatingDebouncer);
    if (this.ratingLoading) return;
    this.getRatingDebouncer = setTimeout(() => {
      this.ratingLoading = true;
      return this.batchGetRatingForGroups(groups)
      .finally(() => this.ratingLoading = false);
      // Promise.all((this.allResultGroups as Group[]).map(this.getRatingForGroup))
      // .finally(() => this.ratingLoading = false);
    }, this.getRatingDebounceTime);
  };

  batchGetRatingForGroups = async (groups?: Group[]) => {
    await this.candidateSyncReady();
    groups = (Array.isArray(groups) && groups) || (this.allResultGroups as Group[]);
    if (isEmpty(groups)) return;
    const userIdList = groups.map(group => group.owner);
    const data = {
      userIdList,
      showUnpublished: false
    };
    return api.POST({
      endpoint: endpointConfig.batch_get_aggregated_rating,
      data
    })
    .then((response: AxiosResponse<AggregatedRatingReviewResponseDTO[]>) => response.data || [])
    .then((ratings: AggregatedRatingReviewResponseDTO[]) => ratings.forEach(this.addOrUpdateRating));
  };

  getRatingForGroup = async (group: Group) => {
    await this.candidateSyncReady();
    return api.GET(endpointConfig.get_aggregated_rating(group.owner, false))
    .then((response: AxiosResponse<AggregatedRatingReviewResponseDTO>) => response.data)
    .then(this.addOrUpdateRating);
  };

  addOrUpdateRating = (rating: AggregatedRatingReviewResponseDTO) => {
    const existing = this.aggregatedRatings.find(r => r.userId === rating.userId);
    if (existing) return Object.assign(existing, rating);
    return this.aggregatedRatings.push(rating);
  };

  getHasInterviewAppointmentDataForProfiles = async (profileIds: number[], groups?: Group[], organizerGroup?: Group) => {
    const data = profileIds.map(profileId => {
      const group = (groups || this.searchResults).find(g => g.profileId === profileId);
      if (!group) return null;
      if (this.hasInterviewAppointmentData.some(d => (
        d.organizerGroupId === (organizerGroup || this.scratchpadGroup).id &&
        d.providerGroupId === group.id
      ))) return null;
      return {
        organizerGroupId: (organizerGroup || this.scratchpadGroup).id,
        providerGroupId: group.id
      };
    })
    .filter(Boolean);
    return this.getHasInterviewAppointmentData(data);
  };

  getHasInterviewAppointmentData = async (groups?: { organizerGroupId: number; providerGroupId: number }[]) => {
    if (mcbSessionCtrl.isValidVisitor) return;
    clearTimeout(this.getHasInterviewDebouncer);
    if (this.hasInterviewAppointmentLoading) return;
    this.getHasInterviewDebouncer = setTimeout(() => {
      this.hasInterviewAppointmentLoading = true;
      return this.batchGetHasInterviewAppointmentData(groups)
      .finally(() => this.hasInterviewAppointmentLoading = false);
      // Promise.all((this.allResultGroups as Group[]).map(this.getRatingForGroup))
      // .finally(() => this.ratingLoading = false);
    }, this.getHasInterviewDebounceTime);
  };

  batchGetHasInterviewAppointmentData = async (data?: { organizerGroupId: number; providerGroupId: number }[]) => {
    await this.candidateSyncReady();
    if (isEmpty(data)) return;
    return api.POST({
      endpoint: endpointConfig.batch_check_has_accepted_interview,
      data
    })
    .then((response: AxiosResponse<HasInterviewAppointmentData[]>) => response.data || [])
    .then((results: HasInterviewAppointmentData[]) => results.forEach(this.addOrUpdateHasInterviewAppointmentData));
  };

  getHasInterviewAppointmentDataForGroup = async (data: { organizerGroupId: number, providerGroupId: number }) => {
    await this.candidateSyncReady();
    return api.POST({
      endpoint: endpointConfig.check_has_accepted_interview,
      data
    })
    .then((response: AxiosResponse<HasInterviewAppointmentData>) => response.data)
    .then(this.addOrUpdateHasInterviewAppointmentData);
  };

  addOrUpdateHasInterviewAppointmentData = (data: HasInterviewAppointmentData) => {
    const existing = this.hasInterviewAppointmentData.find(d => (
      d.organizerGroupId === data.organizerGroupId &&
      d.providerGroupId === data.providerGroupId
    ));
    if (existing) return Object.assign(existing, data);
    return this.hasInterviewAppointmentData.push(data);
  };

  getAchievementForProfiles = async (profileIds: number[]) => {
    profileIds = (Array.isArray(profileIds) && profileIds) || [];
    if (isEmpty(profileIds)) return;
    const groups = this.allResultGroups
    .filter(g => profileIds.includes(g.profileId))
    .filter(g => (
      !this.achievements.some(a => a.caregiverGroupId === g.id)
      && !this.achievementLoadingGroupIds.includes(g.id)
    ));
    return this.getAchievements(groups);
  };

  getAchievements = async (groups?: Group[]) => {
    clearTimeout(this.getAchievementDebouncer);
    this.getAchievementDebouncer = setTimeout(() => {
      const groupIds = groups.map(g => g.id);
      this.achievementLoadingGroupIds.concat(groupIds);
      return this.batchGetAchievementForGroups(groups);
    }, this.getAchievementDebounceTime);
  };

  batchGetAchievementForGroups = async (groups?: Group[]) => {
    await this.candidateSyncReady();
    groups = (Array.isArray(groups) && groups) || (this.allResultGroups as Group[]);
    if (isEmpty(groups)) return;
    const data = groups.map(group => group.id);
    return api.POST({
      endpoint: endpointConfig.batch_get_caregiver_achievement,
      data
    })
    .then((response: AxiosResponse<Achievement[]>) => response.data || [])
    .then((achievements: Achievement[]) => achievements.forEach(this.addOrUpdateAchievement));
  };

  getAchievementForGroup = async (group: Group) => {
    await this.candidateSyncReady();
    return api.GET(endpointConfig.get_caregiver_achievement(group.id))
    .then((response: AxiosResponse<Achievement>) => response.data)
    .then(this.addOrUpdateAchievement);
  };

  addOrUpdateAchievement = (achievement: Achievement) => {
    if (isEmpty(achievement)) return;
    this.achievementLoadingGroupIds.remove(achievement.caregiverGroupId);
    const existing = this.achievements.find(a => a.caregiverGroupId === achievement.caregiverGroupId);
    if (existing) return Object.assign(existing, achievement);
    return this.achievements.push(achievement);
  };

  setDoNotRestoreSearchForm = (doNotRestore: boolean) => this.doNotRestoreSearchForm = doNotRestore;

  queueSearchData = (typeId: number, data: Profile["data"]) => this.store.searchFormDataQueue[typeId] = data || {};

  clearSearchDataQueue = () => this.store.searchFormDataQueue = {};
}

// We would append Controller name here to prevent confusion with actual data.
export let mcbSearchCtrl = {} as McbSearchController;
export const initMcbSearchCtrl = constructor => mcbSearchCtrl = constructor;
