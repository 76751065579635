export const endpointConfig = {
  // Maintenance status message.
  maintenance: `/maintenance_status`,

  // Before login
  register: `/registration/signup`,
  exists: (type, value) => `/registration/signup/exists/${type}?value=${value}`,
  verify: `/registration/signup/verify`,

  // Login Sequence
  login: "/auth/oauth/token",
  post_login: "/user/post_login",
  user: "/user/me",
  resend_verification: `/registration/signup/resend_verify`,

  // Reset password
  reset_pass_request: `/registration/reset`,
  reset_pass_verify: `/registration/reset/verify`,
  reset_pass_exec: `/registration/reset/exec`,

  // Change email
  reset_email_all: `/registration/reset/email`,
  reset_email_options: `/registration/reset/email/options`,
  verify_email_change: `/registration/reset/email/verify`,
  cancel_email_change: `/registration/reset/email/cancel`,
  revert_email_change_request: `/registration/reset/email/revert_request`,
  revert_email_change_exec: `/registration/reset/email/revert`,
  get_revert_email_expiry: changeRequestId =>
    `/registration/reset/email/revert_expiry/${changeRequestId}`,
  get_email_change: `/userEmail/me`,

  // Check terms and password reset
  get_user_terms_pass_reset: `/registration/users/check_terms_reset`,

  // Invitations
  invite: groupId => `/member/groupId/${groupId}/invite`,
  get_invitation: (invitationId, hash) =>
    `/registration/users/invitation?invitationId=${invitationId}&hash=${hash}`,
  // get_my_invitations: "/invitation/findForMe",
  get_my_invitations: `/userExt/invitation/findForMe`,
  invitation_by_id: invitationId =>
    `/invitation/invitationId/${invitationId}`,
  invitation_login: `/registration/users/invitation/login`,
  invitation_login_verify: `/registration/users/invitation/login/verify`,
  resend_invitation: (groupId, memberId) =>
    `/member/resendInvitation/groupId/${groupId}/memberId/${memberId}`,
  cancel_invitation: memberId =>
    `/member/cancelInvitation/memberId/${memberId}`,

  // Visitor
  save_visitor_info: `/registration/visitor/save`,
  visitor_device_id_login: `/registration/visitor/deviceId`,
  visitor_renew_token: `/registration/visitor/oauth/token`,
  lead_generation: "/groupExt/leadGeneration",

  // Groups
  get_default_group: "/group/me/default",
  get_my_groups: "/group/me",
  get_group_by_profile_id: profileId => `/groupExt/profileId/${profileId}`,
  group_by_id: (groupId, memberId) =>
    `/group/${groupId}?memberId=${memberId ? memberId : ""}`,
  group_by_id_wo_member: groupId => `/group/${groupId}`,
  create_group_profile: "/group/profile",
  groups_by_type_id: typeId => `/group/typeId/${typeId}`,
  group_types: typeId => `/groupType/${typeId ? typeId : ""}`,
  archive_group: (groupId, inform, refund) =>
    `/group/archive/${groupId}/inform/${inform ? 1 : 0}/refund/${
      refund ? 1 : 0
    }`,
  group_initial_data: "/groupExt/clientInitialData",

  // Members
  get_default_member: "/member/me/default",
  get_my_members: "/member/me",
  get_members: "/member",
  member_by_id: memberId => `/member/${memberId}`,
  create_member: "/member",
  create_member_profile: "/member/profile",
  update_member_profile: memberId =>
    `/member/memberId/${memberId}/profile`,
  update_member_role: "/memberRole",
  members_by_group_id: groupId => `/member/groupId/${groupId}`,
  archive_member: (memberId, inform) =>
    `/member/archive/${memberId}/inform/${inform ? 1 : 0}`,

  // Roles
  get_roles: "/role",
  role_by_id: roleId => `/role/${roleId}`,
  type_roles_by_group_id: (groupId, typeRoleId) =>
    `/role/groupId/${groupId}${
      typeRoleId ? "/groupTypeRoleId/" + typeRoleId : ""
    }`,
  type_roles_by_group_type_id: groupTypeId =>
    `/groupTypeRole/groupTypeId/${groupTypeId}`,

  // Profiles
  profile_by_id: profileId => `/profile/${profileId}`,
  profile_field_in_use: "/formExt/profile/field",
  update_profile_data_by_id_caf: profileId => `/CAFProfile/updateData/${profileId}`,

  // Topics
  topic_by_id: (topicId, memberId) =>
    `/topic/${topicId}?memberId=${memberId ? memberId : ""}`,
  topic_member_actor: (groupId, topicId, actorName) =>
    `/topic/topicId/${topicId}/groupId/${groupId}/actorName/${actorName}`,
  create_topic: `/topic`,
  topics_by_group_id: (groupId, topicOnly) =>
    `/topic/groupId/${groupId}?topicOnly=${topicOnly ? 1 : 0}`,
  topics_by_type_id: (typeId, isT, isPT, topicOnly) =>
    `/topic/typeId/${typeId}?isTemplate=${isT ? 1 : 0}&isParentTemplate=${
      isPT ? 1 : 0
    }&topicOnly=${topicOnly ? 1 : 0}`,
  type_topics_by_group_id: (groupId, typeId, isT, isPT) =>
    `/topic/groupId/${groupId}/typeId/${typeId}?isTemplate=${
      isT ? 1 : 0
    }&isParentTemplate=${isPT ? 1 : 0}`,
  sub_topics_by_parent_id: (parentId, topicOnly) =>
    `/topic/parentId/${parentId}?topicOnly=${topicOnly ? 1 : 0}`,
  topic_type_by_id: topicTypeId => `/topicType/${topicTypeId}`,
  topic_by_template_topic_id: (templateTopicId, topicOnly) =>
    `/topic/templateTopicId/${templateTopicId}?topicOnly=${topicOnly ? 1 : 0}`,
  lock_topic: topicId => `/topic/lock/topicId/${topicId}`,
  get_or_create_website_shortlist: groupId =>
    `/topicExt/getOrCreateWebsiteShortlist?groupId=${groupId}`,

  // Form Engine
  type_class_by_id: typeClassId => `/formTypeClass/${typeClassId}`,
  update_form_class_byId: typeClassId =>
    `/formExt/form/typeClassId/${typeClassId}`,

  // TX
  transaction_sse: clientId => `/sse/push?clientId=${clientId}`,
  logs_after_tx_id: txId => `/log/afterTxId/${txId}`,

  // Messaging
  create_default_thread: `/thread/default`,
  create_message: "/messaging",
  get_messages: `/messaging/getMessageHistory`,
  read_message: (messageId, memberId) =>
    `/messageMember/readMessage/memberId/${memberId}/messageId/${messageId}`,
  // get_unread_messages_count: "/topic/unreadCountForGroup",
  get_unread_messages_count: `/topicExt/unreadCountForGroup`,

  // Billing
  get_plans: groupId => `/plan/plans/groupId/${groupId}`,
  get_group_subscription: groupId =>
    `/planSubscription/groupId/${groupId}`,
  get_plan_price_breakdown: (planId, provState, isTrial, promo) =>
    `/plan/priceBreakDown/planId/${planId}/provState/${provState}/isTrial/${
      isTrial ? 1 : 0
    }/promo/${promo ? promo : 0}`,
  get_user_stored_payment: userId => `/payment/userId/${userId}`,
  get_promo_code_validity: (groupId, planId, code) =>
    `/promo/check/groupId/${
      groupId ? groupId : 0
    }/planId/${planId}/code/${code}`,
  create_payment_plan: "/planSubscription",
  update_payment: "/planSubscription/updateCustomerPayment",

  // Files
  file_by_id: fileId => `/fileExt/${fileId}`,
  file_download_by_id: fileId => `/fileExt/download/${fileId}`,
  user_avatar_by_id: avatarId => `/avatarExt/userAvatar/${avatarId}`,
  user_avatar_bulk: `/avatarExt/getBatchUserAvatar`,
  get_user_avatars: userId => `/avatarExt/userAvatar/userId/${userId}`,
  get_default_avatar_id: (memberId, groupId) => `/avatarExt/getDefaultAvatarId?memberId=${memberId || ""}&groupId=${groupId || ""}`,
  get_default_avatar_id_bulk: `/avatarExt/getBatchDefaultAvatarId`,
  upload_files: "/file/upload",
  upload_avatar: "/userAvatar/upload",

  // HTML Templator
  parse_template: templateName => `/template/${templateName}`,

  // Marketplace
  search_marketplace: `/marketplace/searchCaregivers`,
  invite_hire: groupId => `/member/groupId/${groupId}/hire`,
  admin_prep_broadcast: "/topic/broadcast",
  admin_send_broadcast: "/messaging/broadcast",

  // Shift Maker
  shift_template_active: (groupId, topicId, memberId, action) =>
    `/topic/activatedStatus/topicId/${topicId}/groupId/${groupId}/memberId/${memberId}?action=${action ||
    ""}`,
  clock_in_out_shift: (groupId, topicId, memberId, action) =>
    `/topic/topicId/${topicId}/groupId/${groupId}/memberId/${memberId}/clock?action=${action ||
    ""}`,
  // send_shift_notification: (groupId, topicId) =>
  //   `/topic/topic/sendEmailToShiftAssignees/groupId/${groupId}/topicId/${topicId}`,
  send_shift_notification_assigned: "/topic/sendEmailToShiftAssignees",
  send_shift_notification_unassigned: "/topic/sendEmailToShiftRemoved",

  // Report
  get_dashboard_summary: groupId =>
    `/report/dashboardSummary/groupId/${groupId}`,
  get_completed_shift_summary: "/report/payrollSummary",
  shift_summary_approval_disapproval:
    "/email/approveAndDisapprovePayroll",

  // Admin
  get_all_admin_centre_groups: `/profile/allNewGroups`,
  get_all_admin_centre_groups_paged: (sortBy, lastId, size, reverse) =>
    `/profile/pagination/allNewGroups?sortBy=${sortBy}&offset=${lastId}&pageSize=${size}&order=${
      reverse ? "desc" : "asc"
    }`,
  notify_care_circle_creation: "/member/emailForCareCircle",
  get_all_promos: "/promo",
  modify_screening_status: "/topic/modifyScreeningStatus",
  convert_household_to_care_circle: `/convertHouseholdToCareCircle`,

  // Issue
  send_issue_notification: (groupId, topicId) =>
    `/topic/sendEmailToIssueReceivers/groupId/${groupId}/topicId/${topicId}`,

  // Proactive chat
  send_proactive_chat_status: `/topic/proactiveChatStatus`,
  send_new_visitor_proactive_chat_sse: (topicId, isDevUser) =>
    `/topic/sendNewVisitorSSE/topicId/${topicId}?isDevUser=${
      isDevUser ? 1 : 0
    }`,

  // Caregiver Onboard
  caregiver_onboard: `registration/signup/caregiver`,
  caregiver_onboard_info_req_hash: `registration/signup/caregiver/information_request/verify`,
  caregiver_onboard_info_req_submit: `registration/signup/caregiver/information_request/submit`,
  caregiver_onboard_complete_profile: `registration/signup/caregiver/complete_profile/verify`,
  caregiver_onboard_resend_info_request: profileId =>
    `/topic/sendInfoRequestEmail/profileId/${profileId}`,
  caregiver_onboard_resend_profile_complete: profileId =>
    `/topic/sendProfileCompleteEmail/profileId/${profileId}`,

  provider_signup_no_vet: `registration/signup/provider/no_vet`,
  provider_onboard: `registration/signup/provider`,

  // Wrapper endpoints
  get_wrapper_fields: `/formExt/public/form/wrapperFields`,
  get_wrapper_caregiver_fields: `/formExt/public/form/wrapperCaregiverFields`,
  get_service_group_types: `/formExt/public/serviceGroupTypes`,
  submit_get_connected: "https://webmailer.mycarebase.com/get-connected",

  // WordPress
  wp_auto_login_link: redirectUrl => `/autoLogin/link?redirectUrl=${redirectUrl}`,
  get_available_products: `/subscriptionProduct/products/public`,

  // Rating
  get_aggregated_rating: (userId, showUnpublished) => `/ratingReview/aggregatedRating/${userId}?showUnpublished=${showUnpublished ? 1 : 0}`,
  batch_get_aggregated_rating: `/ratingReview/aggregatedRating`,

  // Achievement
  get_caregiver_achievement: groupId => `/achievement/${groupId}`,
  batch_get_caregiver_achievement: "/achievement",

  // Neighbourhoods
  get_neighbourhoods_data: (lat, lng, radius) => `/neighbourhood/byRadius?lat=${lat}&lng=${lng}&radius=${radius}`,

  // Appointment
  book_appointment: groupId => `/appointment/book/${groupId}`,
  appointment_by_id: id => `/appointment/${id}`,
  appointment_attendee_by_id: (id, attendeeId) => `/appointment/${id}/attendee/${attendeeId}`,
  get_appointments_by_member_id: memberId => `/appointment/memberId/${memberId}`,
  get_appointments_by_group_id: groupId => `/appointment/groupId/${groupId}`,
  get_appointment_by_member_id_provider_group_id: (memberId, groupId) => `/appointment/memberId/${memberId}/providerGroupId/${groupId}`,
  get_appointment_provider_group: id => `/appointment/${id}/providerGroup`,
  get_appointment_organizer_group: id => `/appointment/${id}/organizerGroup`,
  get_appointment_zoom_meeting_url: id => `/appointment/${id}/zoom/url`,
  request_new_date_time_options: (id, replace) => `/appointment/${id}/dateTimeOptions?replace=${replace ? 1 : 0}`,
  update_appointment_notes: id => `/appointment/${id}/notes`,
  update_appointment_attendance: id => `/appointment/${id}/attendance`,
  update_appointment_attendees: id => `/appointment/${id}/attendees`,
  confirm_appointment: (id, dateTimeOptionId) => `/appointment/${id}/confirm?dateTimeOption=${dateTimeOptionId}`,
  download_ics: id => `/appointment/${id}/ics`,
  check_has_accepted_interview: "/appointment/checkHasAcceptedInterview",
  batch_check_has_accepted_interview: "/appointment/batchCheckHasAcceptedInterview",

  geocoding: address => `/geoDistance/geocode/json?address=${address}`,
  reverse_geocoding: (lat, lon) => `/geoDistance/reverse/json?lat=${lat}&lon=${lon}&formatAddress=true`
};
