export const typeClassIds = {
  personalProfile: {
    v1: { id: 0, version: 1 }
  },
  caregiverProfile: {
    v1: { id: 1, version: 1 },
    v2: { id: 6, version: 2 },
    v3: { id: 10, version: 3 }
  },
  careReceiverProfile: {
    v1: { id: 2, version: 1 },
    v2: { id: 7, version: 2 },
    v3: { id: 9, version: 3 }
  },
  familyMemberProfile: {
    v1: { id: 3, version: 1 }
  },
  paidCaregiverProfile: {
    v1: { id: 29, version: 1 }
  },

  shortlistTopic: {
    v1: { id: 4, version: 1 }
  },
  messagingTopic: {
    v1: { id: 8, version: 1 }
  },
  candidateTopic: {
    v1: { id: 5, version: 1 },
    v2: { id: 11, version: 2 }
  },
  issueTopic: {
    v1: { id: 30, version: 1 }
  },
  shiftTemplateTopic: {
    v1: { id: 28, version: 1 }
  },
  leadTopic: {
    v1: { id: 39, version: 1 }
  }
};

export const userStatus = {
  archived: 0,
  normal: 1,
  unverified: 2
};

export const memberStatus = {
  archived: 0,
  normal: 1,
  unverified: 2,
  invited: 3
};

export const invitationStatus = {
  new: 0,
  pendingAccept: 1,
  completed: 2,
  cancelled: 3
};