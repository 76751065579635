import React from "react";
import { observer } from "mobx-react";
import { webAppUrl } from "../../mcb/config/constants";

export interface CaregiverWizardSignUpFormProps extends React.HTMLProps<HTMLIFrameElement> {
}

@observer
class CaregiverWizardSignUpForm extends React.Component<CaregiverWizardSignUpFormProps> {
  render() {
    return <iframe
      title="Provider App"
      name="providerApp"
      src={`${webAppUrl}/caregiver/signup`}
      style={{
        border: "none",
        outline: "none",
        height: 860
      }}
    />
  }
}

export default CaregiverWizardSignUpForm;
