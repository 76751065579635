import React from "react";
import { observer } from "mobx-react";
import withJssMap from "../../components/withJssMap";
import { McbBookingFormController } from "./controller";
import styles from "./styles.css";
import McbComponentPastry from "../../components/McbComponentPastry";
import { Styled } from "direflow-component";
import { Card, CardContent, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { UIText } from "../../client/lang";
import { Close } from "@material-ui/icons";
import { stateCtrl } from "../../client/state";
import { autorun, computed, observable } from "mobx";
import { asyncPause, getDisplayNameEng } from "../../utils/helpers";
import GroupSelector from "../../components/GroupSelector";
import Avatar from "@material-ui/core/Avatar";
import BookingForm from "../../components/BookingForm";
import { Appointment, AppointmentDateTimeOption, AppointmentType, Attendance, CreateAppointmentDTO } from "../../mcb/lib/types/dataTypes";
import { ui } from "../../client/ui";
import { StdErr } from "../../lib/types/miscTypes";
import { handleRemoveAttendee, handleUpdateCreateZoomMeeting, popupAppointmentCancelConfirm } from "../../mcb/lib/appointment-utilities";

@observer
class McbBookingForm extends React.Component {
  controller: McbBookingFormController = {} as McbBookingFormController;

  @observable loading: boolean;
  @observable submitting: boolean;
  @observable submitted: boolean;
  @observable downloading: boolean;

  @computed get profileId(): number {
    return this.controller.profileId;
  };
  @computed get isVisible(): boolean {
    return this.controller.isVisible;
  };

  constructor(props) {
    super(props);
    this.controller = new McbBookingFormController();
    this.onBookingFormProfileId();
  };

  showError = (err: StdErr, actionName?: string, close?: boolean) => (
    this.isVisible && ui.showError({
      err,
      actionName: actionName || UIText.generalError,
      buttons: close ? [{
        text: UIText.generalConfirm,
        handler: () => {
          ui.dismissError();
          this.handleClose();
        }
      }] : undefined
    })
  );

  onOrganizerGroupChange = (groupId: number) => {
    this.loading = true;
    return this.controller.onOrganizerGroupChange(groupId)
    .then(() => this.loading = false)
    .catch(this.showError);
  };

  onBookingFormProfileId = () => autorun(() => {
    if (this.profileId) {
      this.controller.setVisible(true);
      this.loading = true;
      this.controller.loadBookingForm()
      .then(() => this.loading = false)
      .catch(err => this.showError(err, null, true));
    } else {
      this.controller.setVisible(false);
      this.loading = this.submitting = this.submitted = false;
      this.controller.cleanup();
    }
  });

  handleClose = () => stateCtrl.dismissBookingForm();

  handleSubmit = (data: Partial<CreateAppointmentDTO>) => {
    this.submitting = true;
    const handler = () => {
      ui.dismissAlert();
      // this.loading = true;
      // this.submitted = false;
      // return this.controller.getExistingAppointments()
      // .then(() => this.loading = false)
      // .catch(this.showError);
      return this.handleClose();
    };
    return this.controller.onSubmit(data)
    .then(() => {
      this.submitted = true;
      this.submitting = false;
    })
    .then(() => asyncPause(1000))
    .then(() => ui.showAlert({
      title: UIText.bookAppointment,
      message: UIText.bookAppointmentThankYouMessage,
      buttons: [{
        text: UIText.generalConfirm,
        handler
      }]
    }))
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleCancel = () => {
    const { existingAppointment } = this.controller;
    const handler = () => {
      ui.dismissAlert();
      this.submitting = true;
      return this.controller.onCancel()
      .then(() => {
        this.submitted = true;
        this.submitting = false;
      })
      .then(() => asyncPause(2500))
      .then(this.controller.loadBookingForm)
      .then(() => {
        this.submitted = false;
      })
      .catch(this.showError)
      .finally(() => this.submitting = false);
    };
    return popupAppointmentCancelConfirm(existingAppointment, handler);
  };

  handleNameTypeUpdate = (data: Partial<Appointment>) => {
    this.submitting = true;
    return this.controller.onNameTypeUpdate({
      description: data.description,
      // type: data.type
    })
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleNotesUpdate = (notes: string) => {
    this.submitting = true;
    return this.controller.onNotesUpdate(notes)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleAttendanceUpdate = (attendeeId: number, attendance: Attendance) => {
    this.submitting = true;
    return this.controller.onAttendanceUpdate(attendeeId, attendance)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleRemoveAttendee = (attendeeId: number, name: string) => {
    const { existingAppointment, getExistingAppointments } = this.controller;
    return handleRemoveAttendee(existingAppointment, attendeeId, name, getExistingAppointments);
  };

  handleUpdateCreateZoomMeeting = (createZooMeeting?: boolean) => {
    const { existingAppointment, getExistingAppointments } = this.controller;
    return handleUpdateCreateZoomMeeting(existingAppointment, createZooMeeting, getExistingAppointments);
  };

  handleRequestDateTimeOption = (dateTimeOption: AppointmentDateTimeOption) => {
    this.submitting = true;
    return this.controller.onRequestDateTimeOption(dateTimeOption)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleRequestDateTimeOptions = (dateTimeOptions: AppointmentDateTimeOption[]) => {
    this.submitting = true;
    return this.controller.onRequestDateTimeOptions(dateTimeOptions)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleAcceptAppointment = (dateTimeOptionId: string) => {
    this.submitting = true;
    return this.controller.onConfirmAppointment(dateTimeOptionId)
    .catch(this.showError)
    .finally(() => this.submitting = false);
  };

  handleDownloadIcs = () => {
    this.downloading = true;
    return this.controller.onDownloadIcs()
    .catch(this.showError)
    .finally(() => this.downloading = false);
  };

  render() {
    const {
      existingAppointment,
      createAppointmentDTO,
      selfMember,
      selfProfile,
      bookableGroups,
      organizerGroupId,
      organizerGroup,
      organizerGroupMembers,
      providerAvatar,
      profile,
      profileGroup,
      timezone,
      hasInterview,
      getZoomMeetingUrl,
      onUpdateDTO
    } = this.controller;

    const providerDisplayName = getDisplayNameEng(profile);
    const { groupTypeName } = organizerGroup || {};

    const isService = createAppointmentDTO?.type === AppointmentType.SERVICE;

    return <McbComponentPastry>
      <Styled styles={styles}>
        {this.isVisible && (
          <div className="mcbBookingForm">
            <div className="backdrop" onClick={this.handleClose} />
            <Card className="formModal">
              <CardContent className="flex column">
                <div className="flex justify-content-between align-items-end" style={{ marginBottom: "10px" }}>
                  <Typography className="font-m textBold formTitle" color="secondary">
                    {isService ? UIText.bookAppointmentGroup : UIText.bookAppointment}
                  </Typography>
                  <IconButton className="closeButton" onClick={this.handleClose} color="secondary">
                    <Close />
                  </IconButton>
                </div>
                <div className="flex column bookingFormMainContent">
                  <div className="flex column bookingFormHeader">
                    <div className="bookingFormIntro">
                      <div className="flex align-items-end bookingFormGroupSelector">
                        <Typography className="textBold font-s">
                          Welcome, {getDisplayNameEng(selfProfile)} from&nbsp;&nbsp;
                        </Typography>
                        <GroupSelector
                          groups={bookableGroups}
                          selectedGroupId={organizerGroupId}
                          onGroupChange={this.onOrganizerGroupChange}
                        />
                      </div>
                      {existingAppointment && <Typography className="font-xs" color="primary">
                        You have an existing {isService ? UIText.appointmentGroup.toLowerCase() : UIText.appointment.toLowerCase()}.
                      </Typography>}
                      <Typography className="font-xs">
                        Please click on the {groupTypeName} name if you are booking for another {groupTypeName}.
                      </Typography>
                      {!existingAppointment && <Typography className="font-xs">
                        To view or modify an existing appointment, click <a href="/appointments">here</a>.
                      </Typography>}
                      {!this.loading && <>
                        <br />
                        <br />
                        <Typography className="textBold font-s" gutterBottom>
                          {existingAppointment ? "Your appointment with:" : `You are booking an ${
                            isService ? UIText.appointmentGroup.toLowerCase() : UIText.appointment.toLowerCase()
                          } with:`}
                        </Typography>
                      </>}
                    </div>
                    {!this.loading && (
                      <div className="flex align-items-center justify-content-start bookingFormProviderSummary">
                        <Avatar variant="circular" alt={providerDisplayName} src={providerAvatar} className="avatar">
                          {providerDisplayName.charAt(0)}
                        </Avatar>
                        <div className="flex column">
                          <Typography className="font-s textBold">
                            {providerDisplayName}
                          </Typography>
                          <Typography className="font-xs">
                            {profileGroup?.groupTypeName}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                  {this.loading ? (
                    <div className="flex align-items-center justify-content-center loading">
                      <CircularProgress size={30} />
                    </div>
                  ) : <>
                    <br />
                    <div className="flex column bookingFormFields">
                      <BookingForm
                        hasInterview={hasInterview}
                        timezone={timezone}
                        selfMember={selfMember}
                        members={organizerGroupMembers}
                        providerProfile={profile}
                        submitting={this.submitting}
                        submitted={this.submitted}
                        downloading={this.downloading}
                        inputData={existingAppointment}
                        getZoomMeetingUrl={getZoomMeetingUrl}
                        onChange={onUpdateDTO}
                        onSubmit={this.handleSubmit}
                        onCancel={this.handleCancel}
                        onUpdateNameType={this.handleNameTypeUpdate}
                        onUpdateNotes={this.handleNotesUpdate}
                        onUpdateAttendance={this.handleAttendanceUpdate}
                        onUpdateCreateZoomMeeting={this.handleUpdateCreateZoomMeeting}
                        onRemoveAttendee={this.handleRemoveAttendee}
                        onUpdateDateTimeOptions={this.handleRequestDateTimeOptions}
                        onAccept={this.handleAcceptAppointment}
                        onDownloadIcs={this.handleDownloadIcs}
                      />
                    </div>
                  </>}
                </div>
              </CardContent>
            </Card>
          </div>
        )}
      </Styled>
    </McbComponentPastry>
  }
}

export default withJssMap(McbBookingForm);