import general from "./general";
import exceptions from "./exceptions";
import mcb from "./mcb";

const enCA = {
  ...general,
  ...mcb,
  exceptions
};

export default enCA;