import { Controller } from "../../lib/controller";
import { computed, observable, when } from "mobx";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { OAuth2Data, UserLoginData } from "../../lib/types/dataTypes";
import { api } from "../../client/api";
import { serverConfig } from "../../config/api/base";
import { endpointConfig } from "../../config/api";
import { AxiosResponse } from "axios";
import { stateCtrl } from "../../client/state";
import { UIException } from "../../client/lang";

export class McbSignInFormController extends Controller {
  @observable loginData: UserLoginData<string> = {
    username: "",
    password: ""
  };

  @observable resetPasswordData = { username: "" };

  @observable loginError: UserLoginData<boolean> = {
    username: false,
    password: false
  };

  @computed get ready(): boolean {
    return !!mcbSessionCtrl.deviceId;
  };
  @computed get isLoggedIn(): boolean {
    return mcbSessionCtrl.appIsLoggedIn;
  };

  constructor() {
    super();
    stateCtrl.registerSignInFormChangeHandler(value => this.onUsernameChange(value));
  }

  resetError = () => this.loginError.password = this.loginError.username = false;

  onUsernameChange = value => {
    this.resetError();
    this.loginData.username = value;
  };

  onPasswordChange = value => {
    this.resetError();
    this.loginData.password = value;
  };

  onResetPasswordIdChange = value => this.resetPasswordData.username = value;

  onLogin = async () => {
    this.resetError();
    return api.POST({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.login,
      data: {
        grant_type: "password",
        username: this.loginData.username.toLowerCase(),
        password: this.loginData.password
      }
    })
    .then(async (response: AxiosResponse<OAuth2Data>) => {
      const oauth: OAuth2Data = response.data;
      return mcbSessionCtrl.setAppOAuth2Data(oauth);
    })
    .then(() => when(() => this.isLoggedIn))
    .then(() => {
      if (!stateCtrl.signInWaitForWp) return;
      return when(() => mcbSessionCtrl.wpLoginSuccess)
    })
    .catch(err => {
      if (err && err.response && err.response.status === 400) {
        this.loginError.username = this.loginError.password = true;
        throw new UIException("RECEIVED_INVALID_CREDENTIALS", err);
      }
      if (err && err.response && err.response.status === 429) {
        throw new UIException("SERVICE_UNAVAILABLE", err);
      }
      throw err;
    });
  };

  onReset = async () =>
    api.POST({
      headers: serverConfig.defaultHeaders,
      endpoint: endpointConfig.reset_pass_request,
      data: { identifier: this.resetPasswordData.username }
    })
    .then(response => true)
    .catch(err => Promise.resolve((err.response && err.response.message) || err.message));
}