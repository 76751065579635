import { AnySupportedFieldTypes, TypeClassField } from "../../lib/types/formTypes.legacy";
import { UIText } from "../../client/lang";

export interface ContactFormData {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  repeat?: string;
  reference?: string;
  phone: string;
  needs: string;
}

export const contactForm = (isSignUp?: boolean): TypeClassField<AnySupportedFieldTypes>[] => [
  {
    name: "firstName",
    type: "input" as const,
    required: isSignUp,
    placeholder: UIText.registrationFields.firstName
  },
  {
    name: "lastName",
    type: "input" as const,
    required: isSignUp,
    placeholder: UIText.registrationFields.lastName
  },
  {
    name: "email",
    type: "input" as const,
    unique: isSignUp ? "email" as const : undefined,
    isEmail: !isSignUp,
    required: isSignUp,
    placeholder: UIText.registrationFields.email,
    errorMessage: "Email already exists. Please <span class='getConnectedSignInLink textUnderline' onclick='showMcbSignIn()'>SIGN IN</span> instead or sign up with another email."
  },
  {
    name: "phone",
    type: "input" as const,
    placeholder: UIText.registrationFields.phone
  },
  isSignUp && {
    name: "password",
    type: "input" as const,
    placeholder: UIText.registrationFields.password
  },
  isSignUp && {
    name: "repeat",
    type: "input" as const,
    placeholder: UIText.registrationFields.repeat
  },
  isSignUp && {
    name: "reference",
    type: "input" as const,
    required: isSignUp,
    errorMessage: `Please enter a reference, ${UIText.referenceHelpText.toLowerCase()}`,
    placeholder: UIText.getConnectedReference
  },
  {
    name: "needs",
    type: "input" as const,
    placeholder: UIText.getConnectedNeeds,
    multiLine: true
  }
].filter(Boolean);