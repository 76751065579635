import { Controller } from "../lib/controller";
import { Group, Member } from "../lib/types/dataTypes";
import { client } from "./client";
import { computed, IObservableArray, observable } from "mobx";
import { arrayFlat, isEmpty, isEqual } from "../utils/helpers";
import { DataParser } from "../lib/parser";
import { api } from "./api";
import { endpointConfig } from "../config/api/index";
import { AxiosResponse } from "axios";
import { Topic, TopicDTO, TopicPrimitive, TopicType } from "../lib/types/topicTypes";

// TopicControllerStore
// Persistent storage model for Topic Service
interface TopicControllerStore {
  topics: IObservableArray<Topic>;
}

// TopicController.
// Main class instance for Topic Service
// persistent data management and store.
// We would append Controller name here to prevent confusion with actual data.
export class TopicController extends Controller<TopicControllerStore> {
  @observable initialized: boolean = false;

  @computed get topics(): IObservableArray<Topic> {
    this.storage.initProperty("topics", [] as IObservableArray);
    return this.store.topics;
  };

  parser: DataParser<TopicDTO> = new DataParser<TopicDTO>();

  constructor() {
    super("Topic");

    client.storage.isReady()
    .then(this.storage.isReady)
    .then(this.initialize);

    client.onLogin(this.initialize);
    client.onLogout(this.storage.clearStore);
    client.setTopicCtrl(this as Controller<any>);
  }

  initialize = async () => {
    if (!client.isLoggedIn) return;
    return this.loadAllData()
    .then(() => this.initialized = true);
  };

  loadAllData = async () => Promise.all([

  ]);


  /**
   * Api data getters
   */
  getTopicById = async (topicId: Topic["id"], memberId?: Member["id"]): Promise<Topic> => {
    if (!topicId) return {} as Topic;
    return api.GET({
      endpoint: endpointConfig.topic_by_id(topicId, memberId)
    })
    .then(this.parser.parseResponseObject);
  };

  getTopicsByTypeId = async (
    typeId: Topic["typeId"],
    isT?: boolean,
    isPT?: boolean,
    groupId?: Group["id"]
  ): Promise<Topic[]> => {
    if (!typeId) return [];
    return api.GET({
      endpoint: endpointConfig.topics_by_type_id(typeId, isT, isPT, groupId)
    })
    .then(this.parser.parseResponseArray);
  };

  getTopicsByGroupId = async (groupId: Group["id"], topicOnly?: boolean): Promise<Topic[]> => {
    if (!groupId) return [];
    return api.GET({
      endpoint: endpointConfig.topics_by_group_id(groupId, topicOnly) // topicOnly: Used for topic search, no return of actor member etc.
    })
    .then(this.parser.parseResponseArray);
  };

  getGroupTopicsByTypeId = async (
    groupId: Group["id"],
    typeId: Topic["typeId"],
    isT?: boolean, isPT?: boolean
  ): Promise<Topic[]> => {
    if (!groupId || !typeId) return [];
    return api.GET({
      endpoint: endpointConfig.type_topics_by_group_id(groupId, typeId, isT, isPT)
    })
    .then(this.parser.parseResponseArray);
  };

  getSubTopicsByParentId = async (parentId: Topic["parentId"], topicOnly?: boolean): Promise<Topic[]> => {
    if (!parentId) return [];
    return api.GET({
      endpoint: endpointConfig.sub_topics_by_parent_id(parentId, topicOnly)
    })
    .then(this.parser.parseResponseArray);
  };

  getTopicsByTemplateTopicId = async (templateTopicId: Topic["id"], topicOnly?: boolean): Promise<Topic[]> => {
    if (!templateTopicId) return [];
    return api.GET({
      endpoint: endpointConfig.topic_by_template_topic_id(templateTopicId, topicOnly)
    })
    .then(this.parser.parseResponseArray);
  };

  getTopicTypeById = async (topicTypeId: Topic["typeId"]): Promise<TopicType> => {
    if (!topicTypeId) return {} as TopicType;
    return api.GET({
      endpoint: endpointConfig.topic_type_by_id(topicTypeId)
    })
    .then((response: AxiosResponse<TopicType>) => response.data || {});
  };


  /**
   * Api data modifiers
   */
  patchTopic = async (id: Topic["id"], update: Partial<TopicPrimitive | TopicDTO>) => {
    if (isEmpty(update)) return;
    return api.PATCH({
      endpoint: endpointConfig.topic_by_id(id),
      data: update
    });
  };

  deleteTopicById = async (id: Topic["id"], dontRemove?: boolean) =>
    api.DELETE(endpointConfig.topic_by_id(id))
    .then(() => !dontRemove && this.removeTopic(id));

  /**
   * Local data updaters
   */
  updateTopic = (data: Topic): Topic => {
    const topicId = data && data.id;
    // if (!topicId || !data || !client.isLoggedIn) return;
    if (!topicId || !data) return data;
    const topic = this.findTopicById(topicId);
    if (isEmpty(topic)) return this.topics.push(data) && data;
    // Render is expensive, so skip on same values.
    if (isEqual(topic, data)) return data;
    // Use patch style update to prevent data error.
    Object.assign(topic, data);
    // Return final data;
    return topic;
  };

  existsOrRemove = (oldTopics: Topic[], newTopics: Topic[]): Topic[] => {
    oldTopics = oldTopics || [];
    newTopics = newTopics || [];
    for (const topic of oldTopics) {
      !newTopics.some(t => t.id === topic.id) && this.removeTopic(topic);
    }
    return newTopics;
  };

  removeTopic = (topic: Topic | number) => {
    if (isEmpty(topic)) return;
    const topicId = typeof topic === "number" ? topic : topic.id;
    const remove = this.findTopicById(topicId);
    if (!isEmpty(remove)) return this.topics.remove(remove);
  };


  /**
   * Local data finders
   */
  findTopicById = (topicId: number): Topic =>
    this.topics.find(t => t.id === topicId) || {} as Topic;

  findTopics = (query): Topic[] => {
    const topics = this.topics;
    if (Array.isArray(topics)) {
      return topics.filter(query);
    }
    return [];
  };

  findParentTopicById = (topicId: number): Topic => {
    const topic = this.findTopicById(topicId);
    if (!topic) return {} as Topic;
    return this.findTopicById(topic.parentId) || {} as Topic;
  };

  findSubTopicsByParentId = (parentId: number): Topic[] =>
    this.findTopics(t => t.parentId === parentId);

  flattenSubTopics = (topics: Topic[]): Topic[] => {
    const getTopics = T => {
      let ts = [];
      const { subTopics } = T;
      if (Array.isArray(subTopics)) {
        for (const item of subTopics) {
          ts.push(item);
          if (item.subTopics) {
            ts.push(...getTopics(item));
          }
        }
      }
      return ts;
    };
    return arrayFlat(topics.map(getTopics));
  }
}

export let topicCtrl = {} as TopicController;
export const initTopicCtrl = constructor => topicCtrl = constructor;
