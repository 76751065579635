import { observer } from "mobx-react";
import { ui } from "../../client/ui";
import { Button, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { UIText } from "../../client/lang";
import MarketplaceResultsList, { MarketplaceResultsListProps } from "../MarketplaceResultsList";
import React from "react";
import { Styled } from "direflow-component";
import styles from "./styles.css";

export interface MarketResultsPopupInsertProps extends Partial<MarketplaceResultsListProps> {
  onClose: (event: any) => void;
}

export const MarketResultsPopupInsert: React.FC<MarketResultsPopupInsertProps> = observer(
  ({
     profiles,
     ownProfileIds,
     isCaregiver,
     getAvatarUri,
     getProfileAbilities,
     getProfileLanguages,
     getRatingLoading,
     findProfileRatingScore,
     findProfileAchievement,
     getHasInterviewLoading,
     getAdminLink,
     getShowChat,
     isProfileSaveLoading,
     savedProfileIds,
     findHasInterview,
     onClose,
     onSaveClick,
     onVisible,
     onViewClick,
     onRatingClick,
     onBookingClick,
     onChatClick
   }) => (
    <Styled styles={styles}>
      <div className="relative marketResultsPopupInsert">
        {ui.isMobile && (
          <Button
            className="modalClose"
            variant="contained"
            color="secondary"
            disableElevation
            onClick={onClose}
          >
            <Close />&nbsp;{UIText.generalClose}
          </Button>
        )}
        {!ui.isMobile && (
          <IconButton
            color="secondary"
            className="absolute closeButton"
            onClick={onClose}
          >
            <Close />
          </IconButton>
        )}
        <MarketplaceResultsList
          profiles={profiles}
          ownProfileIds={ownProfileIds}
          isCaregiver={isCaregiver}
          getAvatarUri={getAvatarUri}
          getProfileAbilities={getProfileAbilities}
          getProfileLanguages={getProfileLanguages}
          getRatingLoading={getRatingLoading}
          getAdminLink={getAdminLink}
          isProfileSaveLoading={isProfileSaveLoading}
          savedProfileIds={savedProfileIds}
          findProfileRatingScore={findProfileRatingScore}
          findProfileAchievement={findProfileAchievement}
          getHasInterviewLoading={getHasInterviewLoading}
          getShowChat={getShowChat}
          findHasInterview={findHasInterview}
          onSaveClick={onSaveClick}
          onVisible={onVisible}
          onViewClick={onViewClick}
          onRatingClick={onRatingClick}
          onChatClick={onChatClick}
          onBookingClick={onBookingClick}
        />
      </div>
    </Styled>
  ));