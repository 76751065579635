import * as $ from "jquery";
import { Controller } from "../../lib/controller";
import { computed, observable, when } from "mobx";
import { asyncPause, contextReject, isEmpty, randomString } from "../../utils/helpers";
import { mcbSessionCtrl } from "./session";
import { wpCheckoutSuccessUrl, wpCheckoutUrl, wpCheckoutUrlList } from "../config/constants";
import { ui } from "../../client/ui";
import { UIException, UIText } from "../../client/lang";

export interface ShoppingStore {
  shoppingGroupReplyNonce: string;
}

export class ShoppingController extends Controller<ShoppingStore> {
  @observable shoppingGroupId: number;

  @computed get shoppingGroupReplyNonce(): string {
    this.storage.initProperty("shoppingGroupReplyNonce", randomString());
    return this.store.shoppingGroupReplyNonce;
  };

  setShoppingGroupDebounce;
  setShoppingGroupDebounceTime = 100;

  constructor() {
    super();
    if (ShoppingController.isFramed()) {
      this.initFrameShoppingGroup().catch(console.error);
    }
  }

  static isFramed() {
    return window.parent && (window.self !== window.parent)
  };

  initFrameShoppingGroup = async () => {
    // await when(() => sessionCtrl.appIsLoggedIn);
    clearTimeout(this.setShoppingGroupDebounce);
    this.setShoppingGroupDebounce = setTimeout(() => {
      mcbSessionCtrl.addEventListener("setCurrentShoppingGroup", this.setCurrentShoppingGroup);
      mcbSessionCtrl.postParentMessage({ getShoppingGroup: true });
    }, this.setShoppingGroupDebounceTime);
  };

  setCurrentShoppingGroup = data => {
    if (isEmpty(data)) return;
    const { groupId, nonce } = data;
    if (nonce !== this.shoppingGroupReplyNonce) return this.sendShoppingGroupReplyNonce();
    this.shoppingGroupId = groupId;
    if (this.shoppingGroupId) mcbSessionCtrl.hasShoppingGroup = true;
  };

  sendShoppingGroupReplyNonce = () => mcbSessionCtrl.postParentMessage({
    setShoppingGroupNonce: this.shoppingGroupReplyNonce // The random nonce that the parent web app should reply with.
  });

  resetNonce = () => this.store.shoppingGroupReplyNonce = randomString();

  detectUrlAndShoppingGroupState = async () => {
    await asyncPause(100);
    const pathname = window.location.pathname;
    if (!wpCheckoutUrlList.includes(
      pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
    )) return;
    return when(() => !!this.shoppingGroupId, { timeout: 3000 });
  };

  detectUrlAndRewriteGroupIdFields = async () => {
    let pathname = window.location.pathname;
    pathname = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
    if (pathname !== wpCheckoutUrl) return;
    console.log("Shopping group id:", this.shoppingGroupId);
    if (!this.shoppingGroupId) return;
    const $orderComment = $("#order_comments");
    const $additionalWrapper = $(".woocommerce-additional-fields");
    if (!$orderComment[0] || !$additionalWrapper[0]) return contextReject({ message: "Missing fields." });
    $orderComment.val(JSON.stringify({ groupId: this.shoppingGroupId }));
    return $additionalWrapper.hide();
  };

  detectUrlAndSyncUserSubscriptions = async () => {
    let pathname = window.location.pathname;
    pathname = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
    const regExp = new RegExp(wpCheckoutSuccessUrl, "ig");
    if (!pathname.match(regExp)) return;
    return mcbSessionCtrl.postParentMessage({
      syncUserSubscriptions: true
    });
  };

  alertErrorShoppingState = (err?) => {
    console.error(err);
    const handler = () => {
      if (ShoppingController.isFramed()) {
        return mcbSessionCtrl.postParentMessage({
          closeModal: true
        });
      }
      return window.location.pathname = "/";
    };
    return ui.showError({
      actionName: UIText.errorCheckout,
      err: new UIException("SHOPPING_CART_STATE_INCORRECT"),
      buttons: [{ text: UIText.generalConfirm, handler }]
    });
  }
}

export let shopping = {} as ShoppingController;
export const initShopping = constructor => shopping = constructor;