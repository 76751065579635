/**
 * Boot file to ensure all CDM controllers are whacked into the memory on initialization of any web-components.
 */

import { Api, initApi } from "./api";
import { Client, initClient } from "./client";
import { FormController, initFormCtrl } from "./form";
import { initMsgCtrl, Messaging } from "./msg";
import { initPlacesApi, Places } from "./places";
import { initStateCtrl, State } from "./state";
import { initTopicCtrl, TopicController } from "./topic";
import { initTx, Tx } from "./tx";
import { initUi, UI } from "./ui";
import { initMcbSessionCtrl, McbSessionController } from "../mcb/client/session";
import { observable, when } from "mobx";
import { initMcbSearchCtrl, McbSearchController } from "../mcb/client/search";
import { FileController, initFileCtrl } from "./file";
import { env } from "../config/env";
import { UIText } from "./lang";
import { initShopping, ShoppingController } from "../mcb/client/shopping";

class BootLoader {
  @observable ready: boolean = false;
  controllers: {
    api: Api;
    client: Client;
    formCtrl: FormController;
    fileCtrl: FileController;
    msgCtrl: Messaging;
    stateCtrl: State;
    topicCtrl: TopicController;
    places: Places;
    tx: Tx;
    ui: UI;

    mcbSessionCtrl: McbSessionController;
    mcbSearchCtrl: McbSearchController;
    shopping: ShoppingController;
  };


  constructor() {
    this.controllers = {} as BootLoader["controllers"];
    this.controllers.api = initApi(new Api());
    this.controllers.client = initClient(new Client());
    this.controllers.formCtrl = initFormCtrl(new FormController());
    this.controllers.fileCtrl = initFileCtrl(new FileController());
    this.controllers.msgCtrl = initMsgCtrl(new Messaging());
    this.controllers.stateCtrl = initStateCtrl(new State());
    this.controllers.topicCtrl = initTopicCtrl(new TopicController());
    this.controllers.places = initPlacesApi(new Places());
    this.controllers.tx = initTx(new Tx());
    this.controllers.ui = initUi(new UI());
    this.controllers.mcbSessionCtrl = initMcbSessionCtrl(new McbSessionController());
    this.controllers.mcbSearchCtrl = initMcbSearchCtrl(new McbSearchController());
    this.controllers.shopping = initShopping(new ShoppingController());
    this._assignGlobal();
    this._waitReady().catch(console.error);
  }

  private readonly _assignGlobal = () => {
    if (env.match(/prod/ig)) return;
    for (const controller in this.controllers) {
      (window as any)[controller] = this.controllers[controller];
    }
  };

  private readonly _waitReady = () => this.controllers.api.storage.isReady()
  .then(this.controllers.client.storage.isReady)
  .then(this.controllers.formCtrl.storage.isReady)
  .then(this.controllers.fileCtrl.storage.isReady)
  .then(this.controllers.msgCtrl.storage.isReady)
  .then(this.controllers.stateCtrl.storage.isReady)
  .then(this.controllers.topicCtrl.storage.isReady)
  .then(this.controllers.places.storage.isReady)
  .then(this.controllers.tx.storage.isReady)
  .then(this.controllers.ui.storage.isReady)
  .then(this.controllers.mcbSessionCtrl.storage.isReady)
  .then(this.controllers.mcbSearchCtrl.storage.isReady)
  .then(this.controllers.shopping.storage.isReady)
  .then(() => when(() => UIText.ready))
  .then(() => this.ready = true);
}

const readyState: { bootLoader: BootLoader } = observable({ bootLoader: undefined });

const setCDMReadyState = () => readyState.bootLoader = new BootLoader();

const initCDM = async () => await when(() => readyState.bootLoader && readyState.bootLoader.ready);

export { setCDMReadyState, initCDM };