import { useState } from "react";
import { capitalizeAll, getQueryParameters, isEmpty, preventDefaultStopProp, randomString, whenFulfill } from "../../utils/helpers";
import { stateCtrl } from "../../client/state";
import { UIText } from "../../client/lang";
import { mcbSessionCtrl } from "../../mcb/client/session";
import { reaction, when } from "mobx";
import $ from "jquery";
import { shopping } from "../../mcb/client/shopping";
import { wpMyAccountUrl } from "../../mcb/config/constants";
import { isCrawler } from "../../config/env";

const showBody = () => {
  document.body.style.opacity = "1";
  mcbSessionCtrl.postParentMessage({ load: true });
};

const addWooCommerceModCss = () => {
  const css = `
    .woocommerce-MyAccount-navigation { display: none; }
    .woocommerce-notices-wrapper { display: none; }
    .woocommerce-column--billing-address { float: none !important; }
    .woocommerce-column--billing-address > address,
    .woocommerce-column--billing-address > a {
      padding-left: 0.625em; 
    }
    .subscriptio-account-subscription-actions,
    .subscriptio-account-subscriptions-table {
      margin-bottom: 65px !important; 
    }
    .subscriptio-account-subscription-orders {
      margin-top: 65px !important;
    }
    .subscriptio-account-subscription-orders { margin-top: 1.313em; }
    form.cart, .add_to_cart_button { display: none; }
  `;
  const style = document.createElement("style");
  style.innerHTML = css;
  document.head.appendChild(style);

  // Related Subscriptions
  const $relatedSubscriptionH2 = $(".subscriptio-order-related-subscriptions-title");
  if (!isEmpty(Array.from($relatedSubscriptionH2))) $relatedSubscriptionH2.html(UIText.relatedSubscriptionH2);

  // Subscription cell heading
  const $subscriptionTh = $("th:contains('Subscription')");
  if (!isEmpty(Array.from($subscriptionTh))) $subscriptionTh.html("Subscription #");

  // Related orders
  const $relatedOrderH2 = $(".subscriptio-account-subscription-orders > h2");
  if (!isEmpty(Array.from($relatedOrderH2))) $relatedOrderH2.html(UIText.relatedOrderH2);

  // Order cell heading
  const $orderTh = $("th:contains('Order')");
  if (!isEmpty(Array.from($orderTh))) $orderTh.html("Order #");

  // Order #... title
  const $orderTitle = $(".entry-title:contains('Order #')");
  if (!isEmpty(Array.from($orderTitle)) && !!$orderTitle.html()) {
    const orderNumber = $orderTitle.html().replace("Order #", "");
    $orderTitle.html(`${capitalizeAll(UIText.relatedOrderH2)} # ${orderNumber}`);
  }

  // Remove "Note:" line
  const $orderDetailContainer = $(".woocommerce-table--order-details");
  if (!isEmpty(Array.from($orderDetailContainer))) {
    const $noteTr = $orderDetailContainer.find("tr:contains('Note:')");
    if (!isEmpty(Array.from($noteTr))) $noteTr.remove();
  }

  showBody();
  return true;
};

const preventAccessToMyAccount = () => {
  if (window.location.pathname !== `${wpMyAccountUrl}/` && window.location.pathname !== wpMyAccountUrl) return;
  const content = document.getElementsByClassName("woocommerce-MyAccount-content")[0];
  if (!content) return;
  content.lastElementChild.remove(); // WooCommerce paragraph
  // Logout link
  const $logoutLink = $(content).find("a");
  $logoutLink.prop("href", "");
  $logoutLink.on("click", e => {
    preventDefaultStopProp(e);
    $logoutLink.html("Please wait...");
    return mcbSessionCtrl.sessionLogout();
  });
  showBody();
  return true;
};

const replaceWPLogin = () => {
  const loginBox = Array.from(document.getElementsByClassName("woocommerce-form-login"));
  if (isEmpty(loginBox)) return;
  loginBox[0].parentElement.innerHTML = UIText.wpAccountAutoLoginPleaseWait;
  stateCtrl.signInPostRefresh = true;
  stateCtrl.signInWaitForWp = true;
  let authSequenceDebounce;
  const waitAuthSequence = () => {
    clearTimeout(authSequenceDebounce);
    authSequenceDebounce = setTimeout(() => {
      if (mcbSessionCtrl.isValidVisitor) {
        stateCtrl.mcbSignInFormOpen = true;
      } else {
        when(() => mcbSessionCtrl.wpLoginSuccess)
        .then(() => window.location.reload());
      }
    }, 500);
  };
  reaction(() => !mcbSessionCtrl.runningAuthChangeSequence, waitAuthSequence);
  showBody();
  return true;
};

const detectIsChildWindow = () => {
  const isChildWindow = window.top && window.top !== window.self;
  if (!isChildWindow) return;
  $(".x-masthead").remove();
  // $("#masthead").remove();
  $(".x-sidebar").remove();
  $(".x-colophon").remove();
  $(".x-main").css({ width: "100%" });
  showBody();
  return true;
};

const detectOrderCompleteWindow = () => {
  if (!window.top) return;
  const $thankYouDiv = $(".woocommerce-thankyou-order-received");
  if (isEmpty(Array.from($thankYouDiv))) return;
  const $parent = $thankYouDiv.parent();
  if ($parent.hasClass("woocommerce-order")) {
    (window as any).closeModal = () => mcbSessionCtrl.postParentMessage({ closeModal: true });
    $parent[0].innerHTML += `<button class="woocommerce-Button woocommerce-Button--alt button alt" style="" onclick="window.closeModal()">Done</button>`;
  }
  return;
};

// const detectPaymentMethodListWindow = () => {
//   if (!window.top) return;
//   const isPaymentPage = !isEmpty([
//     ...Array.from($(".woocommerce-MyAccount-paymentMethods")),
//     ...Array.from($(".subscriptio-account-subscription-overview")),
//     ...Array.from($(".woocommerce-order-details"))
//   ]);
//   if (!isPaymentPage) return;
//   const header = document.querySelector(".entry-header");
//   if (!header) return;
//   const css = `.entry-header { display: flex; justify-content: space-between; }`;
//   const style = document.createElement("style");
//   style.innerHTML = css;
//   document.head.appendChild(style);
//   (window as any).closeModal = () => mcbSessionCtrl.postParentMessage({ closeModal: true });
//   header.innerHTML += `<button class="woocommerce-Button woocommerce-Button--alt button alt" style="" onclick="window.closeModal()">Done</button>`;
//   showBody();
//   return true;
// };

// const detectAndRedirectToCart = () => {
//   const matchMessageRegExp = new RegExp("has been added to your cart|You cannot add another", "g");
//   const $wooInfo = $(".woocommerce-notices-wrapper");
//   if ($wooInfo[0] && ($wooInfo.html().trim().match(matchMessageRegExp))) {
//     window.location.href = `.${wpCartUrl}`;
//     return true;
//   }
//   return;
// };

const postFrameTopNavUrls = () => {
  mcbSessionCtrl.postParentMessage({
    currentUrl: window.location.href
  }).catch(console.error);
  return;
};

const rewriteSubscriptionLinkFunctions = () => {
  if (!window.top) return;
  (window as any).confirm = () => {};
  const sendReconfirm = confirm => mcbSessionCtrl.postParentMessage({ confirm });
  const getTargetUrl = $link => {
    const url = $link.attr("href");
    $link.attr("href", "");
    return url;
  };
  const setOneTimeSessionListener = (returnKey, targetUrl) => (
    mcbSessionCtrl.addEventListener(returnKey, (result: boolean) => {
      !!result && (window.location.href = targetUrl);
      return mcbSessionCtrl.removeEventListener(returnKey);
    })
  );
  const $cancelLink = $(".subscriptio-subscription-action-set_to_cancel");
  const $reactivateLink = $(".subscriptio-subscription-action-reactivate");
  if ($cancelLink[0]) {
    const targetUrl = getTargetUrl($cancelLink);
    $cancelLink.on("click", () => {
      const returnKey = `rpSubCancelConfirm_${randomString()}`;
      sendReconfirm({
        header: "Cancel Subscription",
        message: "Are you sure you want to set this subscription to cancel at the end of the current billing cycle?",
        returnKey
      });
      return setOneTimeSessionListener(returnKey, targetUrl);
    });
  }
  if ($reactivateLink[0]) {
    const targetUrl = getTargetUrl($reactivateLink);
    $reactivateLink.on("click", () => {
      const returnKey = `rpSubReactivateConfirm_${randomString()}`;
      sendReconfirm({
        header: "Reactivate Subscription",
        message: "Are you sure you want to reactivate this subscription?",
        returnKey
      });
      return setOneTimeSessionListener(returnKey, targetUrl);
    });
  }
  return;
};

const setNavigationListeners = () => {
  mcbSessionCtrl.addEventListener("navBack", (exec: boolean) => exec && window.history.back());
  mcbSessionCtrl.addEventListener("navForward", (exec: boolean) => exec && window.history.forward());
  return;
};

const checkCorrectShoppingState = () => {
  shopping.detectUrlAndShoppingGroupState()
  .then(shopping.detectUrlAndRewriteGroupIdFields)
  .then(showBody)
  .catch(shopping.alertErrorShoppingState);
  return true;
};

const checkUrlAndSyncUserSubscriptions = () => {
  shopping.detectUrlAndSyncUserSubscriptions().then(showBody);
  return true;
};

const setUnloadSignal = () => {
  window.onbeforeunload = () => {
    mcbSessionCtrl.postParentMessage({ unload: true });
    return undefined;
  };
  return;
};

const hideReCAPTCHA = () => {
  const style = document.createElement("style");
  style.innerHTML = `.grecaptcha-badge { visibility: hidden !important; }`;
  document.head.appendChild(style);
};

const McbWPWebsiteRewire = () => {
  const [initialized, setInitialized] = useState(false);
  if (isCrawler) {
    showBody();
    return null;
  }
  if (initialized) return null;
  if ((getQueryParameters(window.location.search) || {})["noWc"] === "1") return null;
  setInitialized(true);
  console.log("Processing WP JS injection");
  whenFulfill(() => document.readyState === "complete" || (document.readyState as any) === "loaded", 20)
  .then(() => {
    const doNotUnHide = [
      // // detectAndRedirectToCart(),
      detectOrderCompleteWindow(),
      // // detectPaymentMethodListWindow(),
      detectIsChildWindow(),
      addWooCommerceModCss(),
      preventAccessToMyAccount(),
      replaceWPLogin(),
      postFrameTopNavUrls(),
      setNavigationListeners(),
      rewriteSubscriptionLinkFunctions(),
      checkCorrectShoppingState(),
      checkUrlAndSyncUserSubscriptions(),
      setUnloadSignal(),
      hideReCAPTCHA()
    ].filter(Boolean);
    if (isEmpty(doNotUnHide)) showBody();
  });
  return null;
};

export default McbWPWebsiteRewire;