import React from "react";
import styles from "./styles.css";
import { Observer, observer } from "mobx-react";
import { Profile } from "../../lib/types/dataTypes";
import ProfileCard from "../ProfileCard";
import { getDisplayNameEng, isEmpty } from "../../utils/helpers";
import { Styled } from "direflow-component/dist";
import { UIText } from "../../client/lang";
import { CircularProgress, debounce, Select } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { getYoEAndPrice } from "../../mcb/lib/common";
import VizSensor from "react-visibility-sensor";
import { computed, observable, toJS } from "mobx";
import flags from "../../config/flags";
import { Achievement } from "../../mcb/lib/types/dataTypes";

export interface MarketplaceResultsListProps {
  loading?: boolean;
  profiles: Profile[];
  savedProfileIds?: number[];
  ownProfileIds?: number[];
  isProfileSaveLoading?: (id: number) => boolean;
  isCaregiver?: boolean;
  shortlistingAvailable?: boolean;
  useVirtualPagination?: boolean;
  getAvatarUri: (profile: Profile) => string;
  getProfileAbilities: (profile: Profile) => string[];
  getProfileLanguages: (profile: Profile) => string[];
  findProfileRatingScore: (profile: Profile) => { samples: number, rating: number };
  findProfileAchievement: (profile: Profile) => Achievement;
  getRatingLoading: (profile: Profile) => boolean;
  findHasInterview: (profile: Profile) => boolean;
  getHasInterviewLoading: (profile: Profile) => boolean;
  getShowChat?: (profile: Profile) => boolean;
  getAdminLink?: (profile: Profile) => string;
  onVisible?: (event: any, id: number) => void;
  onSaveClick?: (event: any, id: number) => void;
  onViewClick?: (event: any, id: number) => void;
  onRatingClick?: (event: any, id: number) => void;
  onChatClick?: (event: any, id: number) => void;
  onBookingClick?: (event: any, id: number) => void;
}

@observer
class MarketplaceResultsList extends React.Component<MarketplaceResultsListProps> {
  readonly initialVisibleProfilesCount: number = flags.marketResultsListPaginationCount;

  @observable visibleProfilesCount: number = this.initialVisibleProfilesCount;

  @computed get visibleProfiles(): Profile[] {
    if (!this.props.useVirtualPagination) return this.props.profiles;
    return toJS(this.props.profiles).slice(0, this.visibleProfilesCount);
  };

  constructor(props) {
    super(props);
    this.setEventListeners();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.detectScrollBottom);
  };

  setEventListeners = () => {
    window.removeEventListener("scroll", this.detectScrollBottom);
    window.addEventListener("scroll", this.detectScrollBottom);
  };

  detectScrollBottom = debounce(() => {
    const { scrollHeight, scrollTop, offsetHeight } = document.body;
    const isAtBottom = scrollHeight === scrollTop + offsetHeight;
    if (!isAtBottom) return;
    if (this.visibleProfiles.length >= this.props.profiles.length) return;
    this.visibleProfilesCount += this.initialVisibleProfilesCount;
  }, 100);

  renderProfileCard = (profile: Profile) => {
    const {
      savedProfileIds,
      ownProfileIds,
      isProfileSaveLoading,
      isCaregiver,
      shortlistingAvailable,
      getAvatarUri,
      getProfileAbilities,
      getProfileLanguages,
      getRatingLoading,
      findHasInterview,
      getHasInterviewLoading,
      findProfileRatingScore,
      findProfileAchievement,
      getAdminLink,
      getShowChat,
      onVisible,
      onSaveClick,
      onViewClick,
      onRatingClick,
      onChatClick,
      onBookingClick
    } = this.props;

    return <Observer key={profile.id}>{() => {
      const { data } = profile;
      if (isEmpty(data)) return null;
      const { yearOfExp, price } = getYoEAndPrice(data, isCaregiver);

      const { samples, rating } = findProfileRatingScore(profile);
      const achievement = findProfileAchievement(profile);
      const hasInterviewLoading = !getHasInterviewLoading || getHasInterviewLoading(profile);
      const hasInterview = findHasInterview && findHasInterview(profile);

      const avatar = getAvatarUri && getAvatarUri(profile);
      const businessName = !isCaregiver && data.businessName;
      const abilities = getProfileAbilities && getProfileAbilities(profile);
      const languages = getProfileLanguages && getProfileLanguages(profile);
      const isSaved = (savedProfileIds || []).includes(profile.id);
      const ratingLoading = getRatingLoading && getRatingLoading(profile);
      const saveLoading = isProfileSaveLoading && isProfileSaveLoading(profile.id);
      const introduction = data.introduction || data.oldIntroduction;
      const adminLink = getAdminLink && getAdminLink(profile);
      const showChat = getShowChat && getShowChat(profile);

      const handleBookingClick = !(ownProfileIds || []).includes(profile.id) && onBookingClick;

      return <VizSensor
        partialVisibility
        scrollDelay={500}
        intervalDelay={250}
        onChange={visible => visible && onVisible(visible, profile.id)}
      >
        <ProfileCard
          avatar={avatar}
          displayName={getDisplayNameEng(profile)}
          businessName={businessName}
          intro={introduction}
          yearOfExp={yearOfExp}
          abilities={abilities}
          languages={languages}
          price={price}
          isSaved={isSaved}
          saveLoading={saveLoading}
          adminLink={adminLink}
          shortlistingAvailable={shortlistingAvailable}
          ratingLoading={ratingLoading}
          rating={rating}
          ratingSamples={samples}
          hasInterviewLoading={hasInterviewLoading}
          hasInterview={hasInterview}
          achievement={achievement}
          isCaregiver={isCaregiver}
          onSaveClick={e => onSaveClick && onSaveClick(e, profile.id)}
          onViewClick={e => onViewClick && onViewClick(e, profile.id)}
          onRatingClick={e => onViewClick && onRatingClick(e, profile.id)}
          onChatClick={onChatClick && showChat && (e => onChatClick(e, profile.id))}
          onBookingClick={handleBookingClick && (e => handleBookingClick(e, profile.id))}
        />
      </VizSensor>;
    }}</Observer>;
  };

  render() {
    const { loading } = this.props;
    return <Styled styles={styles}>
      <div className="flex column align-items-center marketplaceResultsList">
        <form className="flex align-items-center justify-content-start toolbar">
          {UIText.marketplaceSortBy}:
          <Select
            native
            IconComponent={ExpandMore}
            value={UIText.marketplaceRelevance}
          >
            {/*TODO: Replace with sorting options, this is a temporary hard-code*/}
            <option value={UIText.marketplaceRelevance}>{UIText.marketplaceRelevance}</option>
          </Select>
          {loading && <div style={{ flex: 1 }} className="flex justify-content-center align-items-center">
            <CircularProgress size={30} />
          </div>}
        </form>

        {!loading && this.visibleProfiles.map(this.renderProfileCard)}
      </div>
    </Styled>;
  }
}


export default MarketplaceResultsList;