import React from "react";
import { Observer, observer } from "mobx-react";
import { Styled } from "direflow-component";
import styles from "./styles.css";
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { UIText } from "../../client/lang";
import { EventNoteSharp, ExpandMore, RemoveSharp } from "@material-ui/icons";
import {
  Appointment,
  AppointmentDateTimeOption,
  AppointmentStatus,
  AppointmentType,
  Attendance,
  Attendee,
  AttendeeType,
  CreateAppointmentDTO,
  OtherAttendeeDTO
} from "../../mcb/lib/types/dataTypes";
import {
  getDatetimeInputValueFromDate,
  getDisplayNameEng,
  getTimeZoneLongName,
  getUTCOffsetMilli,
  isEmpty,
  isEqual,
  minutesToMilli,
  preventDefaultStopProp,
  randomString
} from "../../utils/helpers";
import { computed, IObservableArray, observable, reaction } from "mobx";
import DatePicker from "../DatePicker";
import moment from "moment-timezone";
import { ElementType } from "../../lib/types/miscTypes";
import { Member, Profile } from "../../lib/types/dataTypes";
import { computedFn } from "mobx-utils";
import { ui } from "../../client/ui";
import { fileCtrl } from "../../client/file";
import { appointmentDefaultDuration, interviewAppointmentDurationOptions } from "../../mcb/config/constants";

export interface BookingFormProps {
  timezone: string;
  hasInterview?: boolean;
  selfMember: Member;
  members?: Member[];
  providerProfile?: Profile;
  submitting?: boolean;
  submitted?: boolean;
  downloading?: boolean;
  onChange?: (data: Partial<CreateAppointmentDTO>) => void;
  onSubmit?: (data: Partial<CreateAppointmentDTO>) => void;
  onCancel?: (event: any) => void;
  inputData?: Partial<Appointment>;
  getZoomMeetingUrl?: () => Promise<string>;
  onUpdateNameType?: (data: Pick<Appointment, "type" | "description">) => void;
  onUpdateNotes?: (notes: string) => void;
  onUpdateAttendance?: (attendeeId: number, attendance: Attendance) => void;
  onUpdateCreateZoomMeeting?: (createZoomMeeting: boolean) => void;
  onRemoveAttendee?: (attendeeId: number, name: string) => void;
  onUpdateAttendees?: (newAttendees: Partial<Attendee>[]) => void;
  onUpdateDateTimeOption?: (dateTimeOption: AppointmentDateTimeOption) => void;
  onUpdateDateTimeOptions?: (dateTimeOptions: AppointmentDateTimeOption[]) => void;
  onAccept?: (dateTimeOptionId: string) => void;
  onDownloadIcs?: (event: any) => void;
}

@observer
class BookingForm extends React.Component<BookingFormProps> {
  disposer;

  @observable fieldErrors: { [key: string]: string } = {};

  @observable appointmentName: string = "";
  @observable appointmentType: AppointmentType = AppointmentType.INTERVIEW;
  @observable dateTimeOptions: IObservableArray<(AppointmentDateTimeOption & {
    startError?: string;
    endError?: string;
    duration?: number;
  })> = observable([]);
  @observable attendees: IObservableArray<Partial<OtherAttendeeDTO | Attendee>> = observable([]);
  @observable createZoomMeeting: boolean = false;
  @observable zoomLink?: string;
  @observable selfRequired: boolean = true;
  @observable notes: string;

  @observable editingDateTimeOptionIds: IObservableArray<string> = observable([]);
  @observable editingAttendees: boolean = false;

  @computed get isService(): boolean {
    return this.appointmentType === AppointmentType.SERVICE;
  };
  @computed get hasError(): boolean {
    return isEmpty(this.appointmentName) || !isEmpty([
      ...Object.values(this.fieldErrors),
      ...this.dateTimeOptions.map(option => option.startError || option.endError)
    ].filter(Boolean))
  };
  @computed get createAppointmentDTO(): Partial<CreateAppointmentDTO> {
    return {
      otherAttendeeMembers: this.attendees as OtherAttendeeDTO[],
      description: this.appointmentName,
      type: this.appointmentType,
      dateTimeOptions: this.dateTimeOptions.map(option => ({
        id: option.id,
        start: (option.start as Date).toISOString(),
        end: (option.end as Date).toISOString()
      })),
      organizerAttendance: this.selfRequired
        ? Attendance.REQUIRED
        : Attendance.OPTIONAL,
      createZoomMeeting: this.createZoomMeeting,
      notes: this.notes
    };
  };
  @computed get hasInputData(): boolean {
    return !isEmpty(this.props.inputData);
  };
  @computed get confirmed(): boolean {
    return this.props.inputData?.appointmentStatus === AppointmentStatus.CONFIRMED;
  };
  @computed get selfAttendee(): Attendee {
    return this.hasInputData && this.attendees.find(at => at.memberId === this.props.selfMember?.id) as Attendee;
  };
  @computed get selfIsOrganizer(): boolean {
    return this.selfAttendee?.type === AttendeeType.ORGANIZER;
  };
  @computed get selfIsInvitee(): boolean {
    return this.selfAttendee?.type === AttendeeType.INVITEE;
  };

  @computed get appointmentNameChanged(): boolean {
    return this.hasInputData && !isEqual(this.appointmentName || "", this.props.inputData?.description || "");
  };
  @computed get notesChanged(): boolean {
    return this.hasInputData && !isEqual(this.notes || "", this.selfAttendee?.data?.notes || "");
  };

  @computed get canRequestNewDateTimeOptions(): boolean {
    if (this.confirmed) return false;
    if (this.isService) return false;
    return (this.selfIsOrganizer || this.selfIsInvitee) &&
      this.dateTimeOptions.every(opt => opt.lastRequestedAttendeeId !== this.selfAttendee.id);
  };
  @computed get isRequestingNewDateTimeOptions(): boolean {
    return !this.isService && !isEmpty(this.editingDateTimeOptionIds);
  };

  @computed get canEditDateTimeOptions(): boolean {
    const isOrganizerRequesting = this.selfIsOrganizer && this.isRequestingNewDateTimeOptions;
    return !this.hasInputData || isOrganizerRequesting;
  };
  @computed get showAddDateTimeOption(): boolean {
    return this.canEditDateTimeOptions && !this.isService && this.dateTimeOptions.length < 3;
  };
  @computed get showRemoveDateTimeOption(): boolean {
    return this.canEditDateTimeOptions && this.dateTimeOptions.length > 1;
  };
  @computed get showAddAnotherAppointment(): boolean {
    return this.isService && (this.selfIsOrganizer || !this.hasInputData);
  };
  @computed get showUpdateBooking(): boolean {
    return this.isService && this.hasInputData && isEmpty(this.editingDateTimeOptionIds) && this.dateTimeOptionChanged;
  };
  @computed get currentDateTimeOptions(): ElementType<BookingForm["dateTimeOptions"]>[] {
    return this.dateTimeOptions.filter(option => new Date() < new Date(option.end));
  };
  @computed get dateTimeOptionChanged(): boolean {
    const { dateTimeOptions } = this.props.inputData || {};
    const orig = (dateTimeOptions || []).map(opt => ({
      id: opt.id,
      start: new Date(opt.start),
      end: new Date(opt.end)
    }));
    const current = this.dateTimeOptions.map(opt => ({
      id: opt.id,
      start: new Date(opt.start),
      end: new Date(opt.end)
    }));
    return !isEqual(orig, current);
  };
  @computed get confirmedDateTimeOption(): ElementType<BookingForm["dateTimeOptions"]> {
    return this.dateTimeOptions.find(option => option.id === this.props?.inputData?.confirmedDateTimeOptionId);
  };

  @computed get otherNotes(): { attendeeId: number, name: string, content: string }[] {
    if (!this.hasInputData) return [];
    const { inputData, providerProfile, members } = this.props;
    const { attendees } = inputData || {};
    if (isEmpty(attendees)) return [];
    return attendees.map(attendee => {
      if (attendee.id === this.selfAttendee?.id) return null;
      const { data, type } = attendee;
      if (!data?.notes) return null;
      const isProvider = type === AttendeeType.INVITEE;
      const attendeeMember = members.filter(Boolean).find(m => m.id === attendee.memberId);
      if (!attendeeMember && !isProvider) return null;
      return {
        attendeeId: attendee.id,
        name: getDisplayNameEng(isProvider ? providerProfile : attendeeMember.profile),
        content: data.notes
      };
    }).filter(Boolean);
  };

  get dateTimeOptionTemplate(): ElementType<BookingForm["dateTimeOptions"]> {
    const { timezone } = this.props;
    if (!timezone) return null;
    return {
      id: randomString(),
      start: moment().tz(timezone).add(1, "day").hour(8).startOf("hour").toDate(),
      end: moment().tz(timezone).add(1, "day").hour(8).startOf("hour").add(appointmentDefaultDuration, "minutes").toDate(),
      duration: appointmentDefaultDuration
    };
  };

  constructor(props) {
    super(props);
    moment.updateLocale("en", {
      meridiem: (hour) => hour < 12 ? "a.m." : "p.m."
    });
  }

  componentDidMount() {
    this.populatePropsData(null);
    this.populateDateTimeOptions();
    this.disposer = reaction(() => this.createAppointmentDTO, this.handleDataChange);
  }

  componentWillUnmount() {
    this.cleanup();
    this.disposer && this.disposer();
  }

  componentDidUpdate(prevProps: Readonly<BookingFormProps>, prevState: Readonly<{}>, snapshot?: any) {
    this.populatePropsData(prevProps)
  }

  memberAttendee = computedFn((member: Member) => this.attendees.find(a => a.memberId === member.id));

  isDateTimeOptionEditing = computedFn((id: string) => this.editingDateTimeOptionIds.includes(id));

  cleanup = () => {
    this.fieldErrors = {};
    this.appointmentName = "";
    this.appointmentType = AppointmentType.INTERVIEW;
    this.dateTimeOptions.clear();
    this.attendees.clear();
    this.createZoomMeeting = false;
    this.zoomLink = null;
    this.selfRequired = true;
    this.notes = null;
  };

  populateDateTimeOptions = () => {
    if (this.hasInputData) return;
    while (this.dateTimeOptions.length < 3) {
      this.handleAddDateTimeOption();
    }
  };

  repopulateDateTimeOptions = () => {
    if (this.hasInputData) return;
    if (this.isService) {
      this.dateTimeOptions.replace([this.dateTimeOptions[0]]);
    } else {
      this.populateDateTimeOptions();
    }
  };

  populatePropsData = (prevProps: Readonly<BookingFormProps>) => {
    if (isEmpty(this.props.inputData)) return;
    if (prevProps && isEqual(prevProps.inputData, this.props.inputData)) return;
    const {
      description,
      type,
      attendees,
      createZoomMeeting,
    } = this.props.inputData;
    const selfAttendee = attendees.find(at => at.memberId === this.props.selfMember?.id)
    this.appointmentName = description;
    this.appointmentType = type;
    this.createZoomMeeting = !!createZoomMeeting;
    this.attendees.replace(attendees);
    this.selfRequired = selfAttendee && selfAttendee.attendance === Attendance.REQUIRED;
    this.restoreDateTimeOptions();
    this.notes = selfAttendee?.data?.notes;
    if (this.confirmed && this.props.getZoomMeetingUrl) {
      this.props.getZoomMeetingUrl().then(url => this.zoomLink = url);
    }
  };

  restoreDateTimeOption = (id: string) => {
    const { dateTimeOptions } = this.props.inputData || {};
    const oriDateTimeOption = dateTimeOptions.find(opt => opt.id === id);
    const dateTimeOption = this.dateTimeOptions.find(opt => opt.id === id);
    if (isEmpty(oriDateTimeOption) || isEmpty(dateTimeOption)) return;
    dateTimeOption.id = oriDateTimeOption.id;
    dateTimeOption.start = new Date(oriDateTimeOption.start);
    dateTimeOption.end = new Date(oriDateTimeOption.end);
    dateTimeOption.duration = (dateTimeOption.end.getTime() - dateTimeOption.start.getTime()) / 1000 / 60;
    dateTimeOption.lastRequestedAttendeeId = oriDateTimeOption.lastRequestedAttendeeId;
  };

  restoreDateTimeOptions = () => {
    const { dateTimeOptions } = this.props.inputData || {};
    if (isEmpty(dateTimeOptions)) return;
    if (this.isRequestingNewDateTimeOptions) return;
    this.dateTimeOptions.replace(dateTimeOptions.map(option => {
      const set = {} as ElementType<BookingForm["dateTimeOptions"]>;
      const { start, end } = option;
      set.id = option.id;
      set.start = new Date(start);
      set.end = new Date(end);
      set.duration = (set.end.getTime() - set.start.getTime()) / 1000 / 60;
      set.lastRequestedAttendeeId = option.lastRequestedAttendeeId;
      return set;
    }));
    // for (const dateTimeOption of dateTimeOptions) {
    //   const existing = this.dateTimeOptions.find(opt => opt.id === dateTimeOption.id);
    //   if (existing) {
    //     if (this.editingDateTimeOptionIds.includes(dateTimeOption.id)) continue;
    //     existing.start = new Date(dateTimeOption.start);
    //     existing.end = new Date(dateTimeOption.end);
    //     existing.duration = (existing.end.getTime() - existing.start.getTime()) / 1000 / 60;
    //     existing.lastRequestedAttendeeId = dateTimeOption.lastRequestedAttendeeId;
    //   } else {
    //     const start = new Date(dateTimeOption.start);
    //     const end = new Date(dateTimeOption.end);
    //     this.dateTimeOptions.push({
    //       id: dateTimeOption.id,
    //       start,
    //       end,
    //       duration: (end.getTime() - start.getTime()) / 1000 / 60,
    //       lastRequestedAttendeeId: dateTimeOption.lastRequestedAttendeeId
    //     });
    //   }
    // }
  };

  handleDateTimeOptionChange = (id: string, field: "start" | "end" | "duration", value: string) => {
    const option = this.dateTimeOptions.find(opt => opt.id === id);
    if (!option) return;
    if (field === "duration") {
      option.duration = Number(value);
      return option.end = new Date((option.start as Date).getTime() + minutesToMilli(Number(option.duration)));
    }
    const date = new Date(`${value}Z`);
    const offset = -getUTCOffsetMilli(this.props.timezone, date);
    option[field] = new Date(date.getTime() + offset);
    if (field === "start") {
      if (option.end <= option.start) {
        option.end = moment(option.start).add(appointmentDefaultDuration, "minutes").toDate();
      }
      if (!this.isService) {
        option.end = new Date((option.start as Date).getTime() + minutesToMilli(Number(option.duration)));
      }
      option.startError = option.start <= new Date()
        ? "Start date/time cannot be in the past."
        : null;
    }
    if (field === "end") {
      option.endError = option.end <= option.start
        ? "End date/time cannot be before start date/time."
        : null;
    }
  };

  handleAddDateTimeOption = () => {
    if (this.selfIsOrganizer && !this.isRequestingNewDateTimeOptions) {
      this.handleEditDateTimeOptions();
    }
    return this.dateTimeOptions.push(this.dateTimeOptionTemplate);
  };

  handleAddDateTimeOptionAppointment = () => {
    if (!this.isService || (this.hasInputData && !this.selfIsOrganizer)) return;
    this.dateTimeOptions.push(this.dateTimeOptionTemplate);
    return this.handleSubmitNewDateTimeOptions();
  };

  handleRemoveDateTimeOption = (index: number) => {
    this.dateTimeOptions.splice(index, 1);
    if (this.selfIsOrganizer && this.hasInputData && this.appointmentType === AppointmentType.SERVICE) {
      return this.handleSubmitNewDateTimeOptions();
    }
  };

  handleEditDateTimeOptions = () => this.editingDateTimeOptionIds.replace(this.dateTimeOptions.map(opt => opt.id));

  handleCancelEditDateTimeOptions = () => {
    this.editingDateTimeOptionIds.clear();
    if (!this.dateTimeOptionChanged) return;
    return this.restoreDateTimeOptions();
  };

  handleSubmitNewDateTimeOptions = async () => {
    if (!this.dateTimeOptionChanged) return;
    const dateTimeOptions = this.dateTimeOptions.map(set => ({
      id: set.id,
      start: (set.start as Date).toISOString(),
      end: (set.end as Date).toISOString(),
    })) as AppointmentDateTimeOption[];
    if (this.props.onUpdateDateTimeOptions) {
      await this.props.onUpdateDateTimeOptions(dateTimeOptions);
    }
    this.editingDateTimeOptionIds.clear();
    return this.restoreDateTimeOptions();
  };

  handleToggleMember = (member: Member) => () => {
    if (this.hasInputData && !this.editingAttendees) return;
    if (this.memberAttendee(member)) return this.attendees.replace(
      this.attendees.filter(a => a.memberId !== member.id)
    );
    return ui.showAlert({
      title: "Participant attendance",
      message: `Is ${getDisplayNameEng(member.profile)} required in this appointment?`,
      buttons: [{
        color: "primary",
        text: "Optional",
        handler: () => this.addAttendee(member, false)
      }, {
        color: "secondary",
        text: "Required",
        handler: () => this.addAttendee(member, true)
      }]
    });
  };

  addAttendee = (member: Member, isRequired: boolean) => {
    ui.dismissAlert();
    if (this.memberAttendee(member)) return;
    return this.attendees.push({
      memberId: member.id,
      attendance: isRequired ? Attendance.REQUIRED : Attendance.OPTIONAL
    });
  };

  handleToggleCheckbox = (name: string) => (event: any) => {
    preventDefaultStopProp(event);
    if (this.props.submitting) return;
    if (this.hasInputData) {
      if (!this.selfIsOrganizer) return;
      this[name] = !this[name];
      if (name === "selfRequired") {
        return setTimeout(() => {
          this.props.onUpdateAttendance && this.props.onUpdateAttendance(
            this.selfAttendee.id,
            this.selfRequired ? Attendance.REQUIRED : Attendance.OPTIONAL
          )
        });
      } else if (name === "createZoomMeeting") {
        return setTimeout(() => {
          this.props.onUpdateCreateZoomMeeting && this.props.onUpdateCreateZoomMeeting(
            this.createZoomMeeting
          )
        });
      } else {
        return;
      }
    }
    this[name] = !this[name];
  };

  handleChange = event => {
    const { value, name, required } = event.target;
    if (value === this[name]) return;
    if (this.hasInputData && name !== "appointmentName" && name !== "notes") return;
    this[name] = value;
    this.fieldErrors[name] = required && !value
      ? `Required.`
      : null;
    if (name === "appointmentType") this.repopulateDateTimeOptions();
  };

  handleDataChange = () => this.props.onChange && this.props.onChange(this.createAppointmentDTO);

  handleSubmit = (event: any) => {
    preventDefaultStopProp(event);
    if (this.hasError) return ui.showAlert({
      title: UIText.generalError,
      message: "Please check your input for errors."
    });
    return this.props.onSubmit && this.props.onSubmit(this.createAppointmentDTO);
  };

  handleCancel = (event: any) => {
    preventDefaultStopProp(event);
    return this.props.onCancel && this.props.onCancel(event);
  };

  handleEditAttendee = (attendee: Attendee) => (event: any) => {
    preventDefaultStopProp(event);
    if (!this.selfIsOrganizer) return;
    if (isEmpty(attendee)) return;
    if (attendee.id === this.selfAttendee.id) return;
    const member = this.props.members.find(m => m.id === attendee.memberId);
    const displayName = getDisplayNameEng(member?.profile);
    const toggleAttendance = () => {
      ui.dismissAlert();
      this.props.onUpdateAttendance && this.props.onUpdateAttendance(
        attendee.id,
        attendee.attendance === Attendance.OPTIONAL
          ? Attendance.REQUIRED
          : Attendance.OPTIONAL
      );
    };
    const removeAttendee = () => {
      ui.dismissAlert();
      this.props.onRemoveAttendee && this.props.onRemoveAttendee(attendee.id, displayName);
    };
    return ui.showAlert({
      title: "Edit attendee",
      message: displayName,
      buttons: [{
        color: "secondary",
        text: UIText.generalCancel,
        handler: ui.dismissAlert
      }, {
        color: attendee.attendance === Attendance.OPTIONAL ? "secondary" : "primary",
        text: attendee.attendance === Attendance.OPTIONAL
          ? "Set required"
          : "Set optional",
        handler: toggleAttendance
      }, {
        color: "primary",
        text: UIText.generalRemove,
        handler: removeAttendee
      }]
    });
  };

  handleUpdateNameType = (event: any) => {
    preventDefaultStopProp(event);
    if (!this.selfIsOrganizer) return;
    if (!this.appointmentNameChanged) return;
    return this.props.onUpdateNameType && this.props.onUpdateNameType({
      description: this.appointmentName,
      type: this.appointmentType
    });
  };

  handleUpdateNotes = (event: any) => {
    preventDefaultStopProp(event);
    if (!this.notesChanged) return;
    return this.props.onUpdateNotes && this.props.onUpdateNotes(this.notes);
  };

  handleEditAttendees = async (event: any) => {
    preventDefaultStopProp(event);
    if (!this.selfIsOrganizer || !this.hasInputData) return;
    if (this.editingAttendees && !isEqual(this.attendees, this.props.inputData?.attendees)) {
      return this.confirmAttendeesChange();
    }
    return this.editingAttendees = !this.editingAttendees;
  };

  confirmAttendeesChange = async () => {
    const restore = () => {
      ui.dismissAlert();
      this.attendees.replace(this.props.inputData?.attendees);
      return this.editingAttendees = false;
    };
    const update = async () => {
      ui.dismissAlert();
      await this.props.onUpdateAttendees && this.props.onUpdateAttendees(this.attendees);
      return this.editingAttendees = false;
    };
    return ui.showAlert({
      title: "Update participants",
      message: UIText.appointmentInvitationChangeConfirm,
      buttons: [{
        color: "secondary",
        text: UIText.generalCancel,
        handler: ui.dismissAlert
      }, {
        color: "secondary",
        text: "Discard changes",
        handler: restore
      }, {
        text: UIText.generalConfirm,
        handler: update
      }]
    });
  };

  renderDateTimeSet = (set: ElementType<BookingForm["dateTimeOptions"]>, index?: number) => (
    <Observer key={set?.id}>{() => {
      if (isEmpty(set)) return null;
      const { timezone } = this.props;
      const { id, start, end, startError, endError, duration } = set;
      const startValue = getDatetimeInputValueFromDate(start, timezone);
      const endValue = getDatetimeInputValueFromDate(end, timezone);
      const isEditing = this.isDateTimeOptionEditing(id);
      const isPast = this.isService && new Date() >= new Date(end);
      const showRemoveDateTimeOption = this.isService && this.selfIsOrganizer && !isPast && this.currentDateTimeOptions.length > 1;
      const handleStartChange = (result: string) => this.handleDateTimeOptionChange(id, "start", result);
      const handleEndChange = (result: string) => this.handleDateTimeOptionChange(id, "end", result);
      const handleDurationChange = (event: any) => {
        const { value } = event.target;
        return this.handleDateTimeOptionChange(id, "duration", value);
      };
      const handleRemove = () => this.handleRemoveDateTimeOption(index);
      return <div className="dateTimeOption">
        <div className="flex justify-content-between align-items-center">
          {!this.confirmed && <Typography gutterBottom>
            {this.isService
              ? `Appointment ${index + 1}${isPast ? " (Past)" : ""}`
              : `Option ${index + 1}`}
          </Typography>}
          {(this.showRemoveDateTimeOption || showRemoveDateTimeOption) && (
            <IconButton color="secondary" className="removeButton" onClick={handleRemove}>
              <RemoveSharp />
            </IconButton>
          )}
        </div>
        <div className={`flex justify-content-evenly dateTimeOptionFields ${isPast ? "past" : ""} ${ui.isMobile ? "column" : ""}`}>
          <DatePicker
            key={isEditing.toString() + "start"}
            mode="datetime-local"
            min={getDatetimeInputValueFromDate(new Date(), timezone)}
            label="Start date/time"
            defaultValue={startValue}
            errorMessage={startError}
            disabled={this.hasInputData && !isEditing}
            onChange={handleStartChange}
          />
          {!this.isService ? (
            <FormControl variant="outlined">
              <InputLabel>{UIText.appointmentDuration}</InputLabel>
              <Select
                native
                IconComponent={ExpandMore}
                label={UIText.appointmentDuration}
                value={duration.toString()}
                disabled={this.hasInputData && !isEditing}
                onChange={handleDurationChange}
              >
                {interviewAppointmentDurationOptions.map(duration => (
                  <option key={duration} value={duration.toString()}>{duration} minutes</option>
                ))}
              </Select>
              <FormHelperText>
                Ends at {moment(end).tz(timezone).format("YYYY-MM-DD, h:mm:ss a")}
              </FormHelperText>
            </FormControl>
          ) : (
            <DatePicker
              key={isEditing.toString() + "end"}
              mode="datetime-local"
              min={startValue}
              label="End date/time"
              defaultValue={endValue}
              errorMessage={endError}
              disabled={this.hasInputData && !isEditing}
              onChange={handleEndChange}
            />
          )}
        </div>
        {this.isService && isEditing && (
          <Typography style={{ fontSize: "13px" }} color="secondary" gutterBottom>
            Choose new starting and ending dates/times and then press "Save"
          </Typography>
        )}
        {this.hasInputData && this.selfAttendee?.type !== AttendeeType.ATTENDEE && !this.confirmed && (
          this.renderDateTimeOptionButtons(set)
        )}
      </div>
    }}</Observer>
  );

  renderDateTimeOptionButtons = (set: ElementType<BookingForm["dateTimeOptions"]>) => {
    if (!this.hasInputData) return null;
    const { submitting } = this.props;
    if (isEmpty(this.selfAttendee)) return null;
    const { dateTimeOptions } = this.props.inputData || {};
    const oriSet = dateTimeOptions.find(opt => opt.id === set.id);
    const isEditing = this.isDateTimeOptionEditing(set.id);
    const isPast = this.isService && new Date() >= new Date(set.end);
    const isDirty = !isEqual([set.start, set.end], [new Date(oriSet?.start), new Date(oriSet?.end)]);
    const handleAccept = () => this.props.onAccept && this.props.onAccept(set.id);
    const handleEdit = () => this.editingDateTimeOptionIds.push(set.id);
    const handleCancel = () => {
      this.restoreDateTimeOption(set.id);
      this.editingDateTimeOptionIds.remove(set.id);
    };
    const handleSave = () => this.handleSubmitNewDateTimeOptions()
    .then(() => this.editingDateTimeOptionIds.remove(set.id));
    return <Observer>{() => (
      <div className="flex justify-content-end dateTimeOptionButtons">
        {!!set.id && !!set.lastRequestedAttendeeId &&
        set.lastRequestedAttendeeId !== this.selfAttendee?.id &&
        !this.isService && (
          <Button
            className="sectionButton textNoTransform"
            size="small"
            variant="contained"
            color="secondary"
            disableElevation
            disabled={submitting || isEditing}
            onClick={handleAccept}
          >
            <Typography>
              {UIText.generalAccept}
            </Typography>
          </Button>
        )}
        {this.selfIsOrganizer && this.isService && !isPast && <>
          {!isEditing && <Button
            className="sectionButton textNoTransform"
            size="small"
            variant="contained"
            color="secondary"
            disableElevation
            disabled={submitting || isEditing}
            onClick={handleEdit}
          >
            <Typography>
              {UIText.generalEdit}
            </Typography>
          </Button>}
          {isEditing && (
            <Button
              className="sectionButton textNoTransform"
              size="small"
              variant="contained"
              color="secondary"
              disableElevation
              disabled={submitting}
              onClick={handleCancel}
            >
              <Typography>
                {UIText.generalCancel}
              </Typography>
            </Button>
          )}
          {isEditing && (
            <Button
              className="sectionButton textNoTransform"
              size="small"
              variant="contained"
              color="secondary"
              disableElevation
              disabled={submitting || !isDirty}
              onClick={handleSave}
            >
              <Typography>
                {UIText.generalSave}
              </Typography>
            </Button>
          )}
        </>}
      </div>
    )}</Observer>;
  };

  renderMembers = (members: Member[]) => {
    const isDisabled = this.hasInputData && !this.editingAttendees;
    const { selfMember } = this.props;
    members = (members || [])
    .filter(Boolean)
    .filter(member => !isEmpty(member.profile) && !!member.userId)
    .filter(member => (!isDisabled && member.id !== selfMember?.id)
      || this.attendees.some(at => at.memberId === member.id));
    if (isEmpty(members)) return null;
    return <Observer>{() => <>
      <Typography className="textBold font-xs" gutterBottom>
        {isDisabled ? "Participants" : "Choose participants"}
      </Typography>
      {this.selfIsOrganizer && !this.editingAttendees && (
        <Typography className="font-xs" gutterBottom>
          Click on other participants to edit their attendance.
        </Typography>
      )}
      <List className="flex column align-items-center participantMembers">
        {members.filter(Boolean).map((member: Member) => <Observer key={member.id}>{() => {
          if (this.editingAttendees && member.id === this.props.selfMember.id) return null;
          const labelId = `checkbox-list-label-${member.id}`;
          const attendee = this.memberAttendee(member);
          const isSelfAttendee = isDisabled && (attendee as Attendee)?.id === this.selfAttendee?.id;
          const isOrganizer = isDisabled && (attendee as Attendee).type === AttendeeType.ORGANIZER;
          const displayName = `${getDisplayNameEng(member.profile)}${
            (isDisabled && isOrganizer) ? " (Organizer)" : ""}${isSelfAttendee ? " (Me)" : ""
          }`;
          const avatarId = member.profile?.data?.avatar;
          const avatarSrc = fileCtrl.getProfileAvatarUri(avatarId, member.id, "member");
          const handleClick = isDisabled
            ? this.handleEditAttendee(attendee as Attendee)
            : this.handleToggleMember(member);
          return (
            <ListItem dense button onClick={handleClick}>
              <ListItemAvatar>
                <Avatar
                  alt={displayName}
                  src={avatarSrc}
                />
              </ListItemAvatar>
              <ListItemText id={labelId} primary={displayName} className="font-xs" />
              <ListItemSecondaryAction>
                {isDisabled ? <>
                  <Typography
                    color={attendee && attendee.attendance === Attendance.REQUIRED
                      ? "secondary"
                      : "primary"}
                    onClick={handleClick}
                  >
                    {attendee && attendee.attendance === Attendance.REQUIRED
                      ? "Required"
                      : "Optional"}
                  </Typography>
                </> : (
                  <Checkbox
                    edge="end"
                    checked={!!attendee}
                    color={attendee && attendee.attendance === Attendance.REQUIRED
                      ? "secondary"
                      : "primary"}
                    tabIndex={-1}
                    disableRipple
                    onClick={handleClick}
                  />
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        }}</Observer>)}
      </List>
    </>}</Observer>
  };

  render() {
    const {
      timezone,
      hasInterview,
      members,
      submitted,
      submitting,
      downloading,
      onCancel,
      onSubmit,
      onUpdateAttendees,
      onDownloadIcs
    } = this.props;

    return <Styled styles={styles}>
      <div className="bookingFormContainer">
        <form className="flex column align-items-center bookingForm">
          <Observer>{() => (
            <FormControl variant="outlined">
              <InputLabel>{UIText.typeOfAppointment}</InputLabel>
              <Select
                native
                name="appointmentType"
                IconComponent={ExpandMore}
                label={UIText.typeOfAppointment}
                value={this.appointmentType}
                disabled={this.hasInputData}
                onChange={this.handleChange}
              >
                <option key={AppointmentType.INTERVIEW} value={AppointmentType.INTERVIEW}>{UIText.interview}</option>
                {(hasInterview || this.hasInputData) && (
                  <option key={AppointmentType.SERVICE} value={AppointmentType.SERVICE}>{UIText.service}</option>
                )}
              </Select>
            </FormControl>
          )}</Observer>
          <Observer>{() => (
            <TextField
              color="secondary"
              required
              label={this.isService ? UIText.appointmentGroupName : UIText.appointmentName}
              error={!!this.fieldErrors["appointmentName"]}
              helperText={this.fieldErrors["appointmentName"]}
              variant="outlined"
              name="appointmentName"
              disabled={this.hasInputData && (this.isService || !this.selfIsOrganizer)}
              value={this.appointmentName}
              onChange={this.handleChange}
            />
          )}</Observer>
          {this.hasInputData && this.appointmentNameChanged && (
            <Button
              className="sectionButton textNoTransform"
              size="medium"
              variant="contained"
              color="secondary"
              disableElevation
              disabled={isEmpty(this.appointmentName) || submitting}
              onClick={this.handleUpdateNameType}
            >
              <Typography>
                Update {this.isService ? UIText.appointmentGroup.toLowerCase() : UIText.appointment.toLowerCase()} name
              </Typography>
            </Button>
          )}
          <Observer>{() => (
            (!this.isService && (!this.hasInputData || this.selfIsOrganizer)) ? (
              <div className="flex align-items-center justify-content-start linkCheckbox">
                <Checkbox
                  name="selfRequired"
                  checked={!this.selfRequired}
                  onChange={this.handleToggleCheckbox("selfRequired")}
                  disabled={submitting}
                />
                <Typography onClick={this.handleToggleCheckbox("selfRequired")}>
                  Make myself optional in this appointment.
                </Typography>
              </div>
            ) : null
          )}</Observer>
          <Observer>{() => (
            this.confirmed ? (
              !this.isService && this.zoomLink ? <>
                <br />
                <Typography className="textBold font-xs">
                  Zoom meeting link
                </Typography>
                <a href={this.zoomLink} target="_blank" rel="noopener noreferrer" className="itemLinks">
                  <Typography className="font-xs" color="secondary">
                    {this.zoomLink}
                  </Typography>
                </a>
              </> : null
            ) : !this.isService ? (
              <div className="flex align-items-center justify-content-start linkCheckbox">
                <Checkbox
                  name="createZoomMeeting"
                  checked={this.createZoomMeeting}
                  onChange={this.handleToggleCheckbox("createZoomMeeting")}
                  disabled={submitting}
                />
                <Typography className="flex column" onClick={this.handleToggleCheckbox("createZoomMeeting")}>
                  Create a Zoom meeting for this appointment.
                  <span className="zoomNote">(Max duration of 40 minutes)</span>
                </Typography>
              </div>
            ) : null
          )}</Observer>
          <Observer>{() => (
            this.hasInputData ? <>
              <br />
              <Typography className="textBold font-xs">
                Add to calendar
              </Typography>
              <div className="flex align-items-center itemLinks icsButtonContainer">
                <Button
                  className="font-xs textNoTransform"
                  size="small"
                  variant="text"
                  color="secondary"
                  disableElevation
                  disabled={!this.confirmed && !this.isService}
                  onClick={onDownloadIcs}
                >
                  <span className="MuiTypography-root flex align-items-center downloadIcon">
                    {downloading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <EventNoteSharp />
                    )}
                  </span>&nbsp;
                  Download ICS file
                </Button>
                {!this.confirmed && !this.isService && <Typography>
                  (Available after confirming date/time)
                </Typography>}
              </div>
            </> : null
          )}</Observer>
          <br />
          <Typography className="textBold font-s" gutterBottom>
            {this.confirmed
              ? "Confirmed date/time:"
              : this.hasInputData
              ? "Date/time options:"
              : !this.isService
              ? "Please provide three or more different date/time options:"
              : "Please set up one or more appointments for this appointment group:"
            }
          </Typography>
          <Typography className="font-xs" gutterBottom>
            Time zone: <span className="textBold timezone">{getTimeZoneLongName(timezone, true)}</span>
          </Typography>
          {this.isRequestingNewDateTimeOptions && (
            <Typography style={{ fontSize: "13px" }} color="secondary" gutterBottom>
              Choose new starting dates/times and then press "Submit"—other parties will be notified of your request.
            </Typography>
          )}
          <Observer>{() => <>
            {!this.confirmed && this.dateTimeOptions.map(this.renderDateTimeSet)}
            {this.confirmed && this.renderDateTimeSet(this.confirmedDateTimeOption)}
          </>}</Observer>
          <Observer>{() => (
            <div className="flex dateTimeOptionsButtonContainer">
              {!this.isRequestingNewDateTimeOptions && this.canRequestNewDateTimeOptions && (
                <Button
                  className="sectionButton textNoTransform"
                  size="medium"
                  variant="contained"
                  color="secondary"
                  disableElevation
                  onClick={this.handleEditDateTimeOptions}
                >
                  <Typography>
                    Request new date/times
                  </Typography>
                </Button>
              )}
              {this.isRequestingNewDateTimeOptions && <>
                <Button
                  className="sectionButton textNoTransform"
                  size="medium"
                  variant="contained"
                  color="secondary"
                  disableElevation
                  disabled={submitting}
                  onClick={this.handleCancelEditDateTimeOptions}
                >
                  <Typography>
                    {UIText.generalCancel}
                  </Typography>
                </Button>
                <Button
                  className="sectionButton textNoTransform"
                  size="medium"
                  variant="contained"
                  color="secondary"
                  disableElevation
                  disabled={
                    submitting ||
                    !this.isRequestingNewDateTimeOptions ||
                    !this.dateTimeOptionChanged
                  }
                  onClick={this.handleSubmitNewDateTimeOptions}
                >
                  <Typography>
                    {UIText.generalSubmit}
                  </Typography>
                </Button>
              </>}
              {/*{this.showUpdateBooking && (*/}
              {/*  <Button*/}
              {/*    className="sectionButton textNoTransform"*/}
              {/*    size="medium"*/}
              {/*    variant="contained"*/}
              {/*    color="secondary"*/}
              {/*    disableElevation*/}
              {/*    disabled={submitting || !this.dateTimeOptionChanged}*/}
              {/*    onClick={this.handleSubmitNewDateTimeOptions}*/}
              {/*  >*/}
              {/*    <Typography>*/}
              {/*      Update booking*/}
              {/*    </Typography>*/}
              {/*  </Button>*/}
              {/*)}*/}
              {this.showAddDateTimeOption && (
                <Button
                  className="sectionButton textNoTransform"
                  size="medium"
                  variant="contained"
                  color="secondary"
                  disableElevation
                  disabled={submitting}
                  onClick={this.handleAddDateTimeOption}
                >
                  <Typography>
                    Add another date/time option
                  </Typography>
                </Button>
              )}
              {this.showAddAnotherAppointment && (
                <Button
                  className="sectionButton textNoTransform"
                  size="medium"
                  variant="contained"
                  color="secondary"
                  disableElevation
                  disabled={submitting}
                  onClick={this.handleAddDateTimeOptionAppointment}
                >
                  <Typography>
                    Add another appointment
                  </Typography>
                </Button>
              )}
            </div>
          )}</Observer>
          <br />
          <Observer>{() => this.renderMembers(members)}</Observer>
          {this.hasInputData && this.selfIsOrganizer && onUpdateAttendees && (
            <Button
              className="sectionButton textNoTransform"
              size="medium"
              variant="contained"
              color="secondary"
              disableElevation
              disabled={submitting}
              onClick={this.handleEditAttendees}
            >
              <Typography>
                {this.editingAttendees ? "Done edit participants" : "Edit participants"}
              </Typography>
            </Button>
          )}
          <br />
          {(!this.hasInputData || !isEmpty(this.selfAttendee)) && (
            <Observer>{() => <>
              <Typography className="textBold font-xs" gutterBottom>
                My notes:
              </Typography>
              <Typography className="zoomNote">
                Please include basic details of the client needs, and if there are any specific dates/times that you are looking for care.
              </Typography>
              <TextField
                className="appointmentNotes"
                color="secondary"
                label="Notes"
                variant="outlined"
                multiline
                rows={8}
                name="notes"
                value={this.notes}
                onChange={this.handleChange}
              />
            </>}</Observer>
          )}
          {this.hasInputData && this.notesChanged && !isEmpty(this.selfAttendee) && (
            <Button
              className="sectionButton textNoTransform"
              size="medium"
              variant="contained"
              color="secondary"
              disableElevation
              disabled={submitting}
              onClick={this.handleUpdateNotes}
            >
              <Typography>
                Update notes
              </Typography>
            </Button>
          )}
          {this.hasInputData && this.otherNotes.map(note => (
            <Observer key={note.attendeeId}>{() => <>
              <Typography className="textBold font-xs" gutterBottom>
                Notes from {note.name}:
              </Typography>
              <Typography className="textPre">
                {note.content}
              </Typography>
            </>}</Observer>
          ))}
          <br />
          {!this.hasInputData && onSubmit && (
            <Button
              className={`submitButton textNoTransform ${submitted ? "noEvents" : ""}`}
              size="large"
              variant="contained"
              color={submitted ? "primary" : "secondary"}
              disableElevation
              disabled={submitting || this.hasError}
              onClick={this.handleSubmit}
            >
              {submitting
                ? <CircularProgress size={22} color="secondary" />
                : submitted
                  ? <Typography>
                    {UIText.appointmentSubmitted}
                  </Typography>
                  : <Typography className="textUpperCase">
                    {UIText.appointmentSubmit}
                  </Typography>
              }
            </Button>
          )}
          {this.hasInputData && onCancel && this.selfIsOrganizer && (
            <Button
              className={`submitButton textNoTransform ${submitted ? "noEvents" : ""}`}
              size="large"
              variant="contained"
              color="secondary"
              disableElevation
              disabled={submitting || this.hasError}
              onClick={this.handleCancel}
            >
              {submitting
                ? <CircularProgress size={22} color="secondary" />
                : submitted
                  ? <Typography>
                    {this.isService ? UIText.appointmentGroupCancelled : UIText.appointmentCancelled}
                  </Typography>
                  : <Typography className="textUpperCase">
                    {this.isService ? UIText.cancelAppointmentGroup : UIText.cancelAppointment}
                  </Typography>
              }
            </Button>
          )}
        </form>
      </div>
    </Styled>;
  }
}

export default BookingForm;