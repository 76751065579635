import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F15F22",
    },
    secondary: {
      main: "#543884",
    },
  },
});


export const ratingStarColor = "#F9B403";
export const ratingStarOffColor = "#aaa";