import { Group, OAuth2Data } from "../../../lib/types/dataTypes";
import { Product } from "./wooCommerceTypes";
import { KeyValuePairs, NumBool } from "../../../lib/types/miscTypes";
import { AttendeeData } from "../../../../../../back-end/nestjs/src/modules/appointment/Attendee/constants";

export interface VisitorLoginData {
  oauth: OAuth2Data;
  careCircleScratchpadId: Group["id"];
  householdScratchpadId: Group["id"];
}

export interface SearchResult extends Group {
  total: number;
  rank: number;
}

export interface LeadTopicData {
  includesProfileView?: boolean;
  linkedLeadTopicId?: number;
  email: string;
  needs?: string;
  phone?: string;
  lastName: string;
  firstName: string;
  reference?: string
  leadGeneratingGroupId?: number;
  leadGeneratingGroupName?: string;
}

export interface SubscriptionProduct {
  id?: number;
  wpProductId: number;
  productName?: string;
  productStatus: "draft" | "publish";
  description?: string;
  features: KeyValuePairs<boolean>;
  status: string;
}

export interface ProductResponseDTO {
  wpProduct: Product;
  subscriptionProduct: SubscriptionProduct;
}

export interface RatingReview {
  id?: number;
  reviewerGroupId: number;
  authorMemberId: number;
  targetMemberId: number;
  review?: string;
  rating: number;
  createTime: Date;
  updateTime: Date;
  submissionDate?: Date;
  isPublished?: NumBool;
  targetMember?: any;
  authorMember?: any;
  reviewerGroup?: any;
  submissionDateLong?: number;
  historicalRatings?: RatingReview[];
}

export interface AggregatedRatingReviewResponseDTO {
  userId: number;
  reviews: Partial<RatingReview>[];
  aggregatedRating: number;
  adminRatings: Array<AdminRating>;
  testimonials: Array<Testimonial>;
}

export interface AdminRating {
  id?: number;
  targetUserId: number;
  rating: number;
  isPublished?: NumBool;
  createTime: Date;
  updateTime: Date;
}

export interface Testimonial {
  id?: number;
  targetUserId: number;
  name: string;
  review?: string;
  isPublished?: NumBool;
  createTime: Date;
  updateTime: Date;
}

export interface Appointment {
  id?: number;
  type: AppointmentType;
  timezone: string;
  description: string;
  appointmentStatus: AppointmentStatus;
  dateTimeOptions?: AppointmentDateTimeOption[];
  confirmedDateTimeOptionId?: string;
  createZoomMeeting?: boolean;
  data?: any;
  attendees?: Attendee[];
  inviteeResponded?: boolean;
}

export interface Attendee {
  id?: number;
  appointmentId: number;
  memberId: number;
  type: AttendeeType;
  attendance: Attendance;
  data: AttendeeData;
}

export enum AppointmentType {
  INTERVIEW = "interview",
  SERVICE = "service"
}

export enum AppointmentStatus {
  INVITED = "invited",
  CONFIRMED = "confirmed",
  CANCELLED = "cancelled"
}

export interface AppointmentDateTimeOption {
  id: string;
  start: Date | string | number;
  end: Date | string | number;
  lastRequestedAttendeeId?: number;
}

export interface OtherAttendeeDTO {
  memberId: number;
  attendance: Attendance;
}

export interface CreateAppointmentDTO {
  groupId: number;
  providerGroupId: number;
  otherAttendeeMembers?: OtherAttendeeDTO[];
  description: string;
  type: AppointmentType;
  dateTimeOptions: AppointmentDateTimeOption[];
  organizerAttendance?: Attendance;
  createZoomMeeting?: boolean;
  notes?: string;
}

export interface UpdateAppointmentDTO {
  dateTimeOptions: AppointmentDateTimeOption[];
  notes?: string;
}

export enum AttendeeType {
  ORGANIZER = "organizer",
  INVITEE = "invitee",
  ATTENDEE = "attendee"
}

export enum Attendance {
  REQUIRED = "required",
  OPTIONAL = "optional"
}

export interface HasInterviewAppointmentData {
  organizerGroupId: number;
  providerGroupId: number;
  hasInterview: boolean;
}

export enum AchievementLevel {
  BRONZE = "bronze",
  SILVER = "silver",
  GOLD = "gold"
}

export interface Achievement {
  caregiverGroupId: number;
  achievementLevel: AchievementLevel | null;
  caregiverDisplayName?: string;
  hours?: number;
  numOf5StarRatings?: number;
  isProfileComplete?: boolean;
}