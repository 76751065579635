import { Controller } from "../lib/controller";
import { GetMessageHistoryOptions, GroupThread, Message, Thread, Topic, TopicThreadDTO } from "../lib/types/topicTypes";
import { Group, Member, Profile } from "../lib/types/dataTypes";
import { endpointConfig } from "../config/api/index";
import { api } from "./api";
import { DataParser } from "../lib/parser";
import { arrayFlat, contextReject, isEmpty } from "../utils/helpers";
import { client } from "./client";
import { computed, IObservableArray, observable } from "mobx";

// MessagingStore
// Persistent storage model for Messaging Service
export interface MessagingStore {
  groupThreads: GroupThread[];
  threadInputValues: { [key: number]: string };
}

// Messaging.
// Main class instance for Messaging Service and pop-up chat modal
// persistent data management and store.
export class Messaging extends Controller<MessagingStore> {
  messageHistoryQueue: GetMessageHistoryOptions;

  threadParser: DataParser<Thread>;
  messageParser: DataParser<Message> = new DataParser<Message>();

  @observable messageReadingQueue: IObservableArray<Message["id"]> = [] as IObservableArray;

  @computed get groupThreads(): GroupThread[] {
    this.storage.initProperty("groupThreads", []);
    return this.store.groupThreads;
  };
  @computed get allThreads(): Thread[] {
    return arrayFlat(this.groupThreads.map(gt => gt.threads));
  };

  constructor() {
    super();
    this.threadParser = new DataParser<Thread>(this.parseThread);
    client.onLogout(this.storage.clearStore);
  }

  parseThread = thread => {
    if (isEmpty(thread) || isEmpty(thread.groupAndProfiles)) return thread;
    thread.groups = thread.groupAndProfiles.map(client.groupParser.parseDTO);
    if (thread.groupAndProfiles) delete thread.groupAndProfiles;
    return thread;
  };

  getThreadsByTopicId = async (topicId: Topic["id"], memberId: Member["id"]) =>
    api.GET(endpointConfig.threads_by_topic_id(topicId, memberId))
    .then(this.threadParser.parseResponseArray);

  getMessagesByThreadId = async (threadId: Thread["id"], options?: GetMessageHistoryOptions) =>
    api.POST({
      endpoint: endpointConfig.get_messages,
      data: {
        threadId,
        ...options
      }
    })
    .then(this.messageParser.parseResponseArray);
  // {
  // if (!isEmpty(this.messageHistoryQueue)) {
  //   await asyncPause(250);
  //   return this.getMessagesByThreadId(threadId, options);
  // }
  // this.messageHistoryQueue = {
  //   threadId: threadId,
  //   ...options
  // };
  // return api.POST({
  //   endpoint: endpointConfig.get_messages,
  //   data: this.messageHistoryQueue
  // })
  // .then(this.messageParser.parseResponseArray)
  // .finally(() => (this.messageHistoryQueue = undefined));
  // };

  getThreadsAndMessagesByTopicId = async (topicId: Thread["id"], memberId: Member["id"]) => {
    const threads: Thread[] = await this.getThreadsByTopicId(topicId, memberId).catch(contextReject);
    // const threadIds = threads.map(t => t.id);
    const getTheadMessages = async id => this.updateThreadMessages(id, memberId);
    // return Promise.all(threadIds.map(getTheadMessages));
    return Promise.all(threads.map(getTheadMessages) as Promise<Thread>[]);
  };

  updateThreadMessages = async (thread: Thread | Thread["id"], memberId: Member["id"]) => {
    thread = typeof thread === "number" ? this.allThreads.find(t => t.id === thread as number) : thread;
    if (!thread) return;
    return this.getMessagesByThreadId(thread.id, {
      count: 1000,
      from: null,
      until: null,
      // topicId,
      memberId
    })
    .then(messages => {
      messages.reverse();
      (thread as Thread).messages = messages;
      return thread;
    });
  };

  getInboxUnreads = async (typeId: Topic["typeId"], groupId: Group["id"], memberId: Member["id"], includeGroupAndMember?: boolean) => {
    const data = {
      memberId,
      groupId,
      itemNum: 1000,
      fromTime: null,
      toTime: null,
      typeId,
      includeGroupAndMember
    };
    return api.POST({
      endpoint: endpointConfig.get_inbox_messages,
      data
    })
    .then(this.threadParser.parseResponseArray);
  };

  isSelf = (msg: Message, memberId: Member["id"]) => msg.senderMemberId === memberId;

  isUnread = (msg: Message, memberId: Member["id"]) =>
    msg.id && (!msg.readMemberList || !msg.readMemberList.includes(memberId));

  isSystem = (msg: Message) => msg.senderMemberId === -2;

  isInfoThread = (thread: Thread) => thread.memberIdList && thread.memberIdList.length === 2 && thread.memberIdList.includes(-2);

  getThreadsUnreadCount = (threads: Thread[], memberId: Member["id"]) => (threads || [])
  .filter(Boolean)
  .reduce(
    (a, b) => a += (b.messages && b.messages.filter(msg => msgCtrl.isUnread(msg, memberId)).length) || 0, 0
  );

  getGroupThreadUnreadCount = (groupId: Group["id"]) => {
    const member: Member = client.findMyMemberByGroupId(groupId);
    const groupThread = this.groupThreads.find(gt => gt.groupId === groupId) || {} as GroupThread;
    return this.getThreadsUnreadCount(groupThread.threads, member.id);
  };

  updateGroupThreads = (groupId: Group["id"], threads: Thread[]) => {
    const groupThread = this.groupThreads.find(gt => gt.groupId === groupId);
    if (!groupThread) {
      msgCtrl.groupThreads.push({
        groupId,
        threads,
        unreadCount: this.getGroupThreadUnreadCount(groupId)
      })
    } else {
      groupThread.threads = [
        ...groupThread.threads.filter(t => !threads.some(nt => nt.id === t.id)),
        ...threads
      ];
      groupThread.unreadCount = this.getGroupThreadUnreadCount(groupId);
    }
    return threads;
  };

  flattenTopicThreads = (topics: TopicThreadDTO[]): Thread[] => {
    if (isEmpty(topics)) return [];
    const allThreads = topics.map(topic => topic.threads);
    console.log("threads", arrayFlat(allThreads).length);
    console.log("messages", arrayFlat(arrayFlat(allThreads).map(tr => tr.messages)).length);
    return arrayFlat(allThreads);
  };

  findThreadById = (id: Thread["id"]) => this.allThreads.find(t => t.id === id) || {} as Thread;

  readMessage = async (id: Message["id"], memberId: Member["id"]) => {
    if (this.messageReadingQueue.includes(id)) return;
    this.messageReadingQueue.push(id);
    return api.GET({
      endpoint: endpointConfig.read_message(id, memberId)
    })
    .finally(() => this.messageReadingQueue.remove(id));
  };

  sendMessage = async (
    topicId: Topic["id"],
    threadId: Thread["id"],
    memberId: Member["id"],
    text: string,
    watermark
  ) => {
    const data = {
      topicId,
      threadId,
      senderMemberId: memberId,
      text,
      textIsMd: 0,
      watermark
    };
    return api.POST({
      endpoint: endpointConfig.create_message,
      data
    });
  };

  onNewMessage = async data => {
    // TODO: New message handler.
  };

  /**
   * Modal controls
   */
  @observable modalThreadId: number;
  @observable modalGroupId: number;
  @observable modalOpen: boolean;

  @computed get threadInputValues() {
    this.storage.initProperty("threadInputValues", {});
    return this.store.threadInputValues;
  };

  openChatHandlers: Array<(param?: any) => any> = [];
  dismissChatHandlers: Array<(param?: any) => any> = [];

  openChat = (threadId: Thread["id"], groupId: Group["id"]) => {
    if (!threadId || !groupId) return;
    this.modalThreadId = threadId;
    this.modalGroupId = groupId;
    this.modalOpen = true;
  };

  dismissChat = () => {
    this.modalOpen = false;
    this.modalGroupId = undefined;
    this.modalThreadId = undefined;
    for (const handler of this.dismissChatHandlers) {
      if (typeof handler === "function") handler();
    }
  };

  getMessageSenderProfile = (message: Message): Profile => {
    if (isEmpty(message)) return {} as Profile;
    let profile;
    const sender = client.findMembers(m => m.id === message.senderMemberId && !isEmpty(m.profile))[0];
    if (!sender) return {} as Profile;
    profile = sender.profile;
    if (profile) {
      const group = client.findGroups(g => g.profileId === sender.profileId && !isEmpty(g.profile))[0];
      if (!group) return {} as Profile;
      profile = group.profile;
    }
    return profile || {} as Profile;
  };

  addOpenChatHandler = (handler: (param?: any) => any) =>
    !this.openChatHandlers.includes(handler) && this.openChatHandlers.push(handler);

  removeOpenChatHandler = (handler: (param?: any) => any) =>
    this.openChatHandlers.includes(handler) && this.openChatHandlers.splice(
    this.openChatHandlers.indexOf(handler), 1
    );

  addDismissChatHandler = (handler: (param?: any) => any) =>
    !this.dismissChatHandlers.includes(handler) && this.dismissChatHandlers.push(handler);

  removeDismissChatHandler = (handler: (param?: any) => any) =>
    this.dismissChatHandlers.includes(handler) && this.dismissChatHandlers.splice(
    this.dismissChatHandlers.indexOf(handler), 1
    );
}

// We would append Controller name here to prevent confusion with actual data.
export let msgCtrl = {} as Messaging;
export const initMsgCtrl = constructor => msgCtrl = constructor;