import React from "react";
import { observer } from "mobx-react";
import { Styled } from "direflow-component";
import { McbRegistrationFormController } from "./controller";
import styles from "./styles.css";
import McbComponentPastry from "../../components/McbComponentPastry";
import withJssMap from "../../components/withJssMap";
import GenericForm from "../../components/GenericForm";
import { Button, CircularProgress, Divider, Typography } from "@material-ui/core";
import TermsCheckbox from "../../components/TermsCheckbox";
import { UIText } from "../../client/lang";
import { observable } from "mobx";
import { isEmpty, preventDefaultStopProp } from "../../utils/helpers";
import AlertModal from "../../components/AlertModal";
import { webAppUrl } from "../../mcb/config/constants";
import { ui } from "../../client/ui";
import CaregiverWizardSignUpForm from "../../components/CaregiverWizardSignUpForm";

export interface McbRegistrationFormProps {
  type?: "family" | "vendor";
  contained?: boolean;
}

@observer
class McbRegistrationForm extends React.Component<McbRegistrationFormProps> {
  controller: McbRegistrationFormController = {} as McbRegistrationFormController;

  @observable loading: boolean = true;
  @observable submitting: boolean = false;
  @observable submitted: boolean = false;
  @observable submittedMsgOpen: boolean = false;

  @observable calendlyClicked: boolean = false;

  formFields: { name: string, field: HTMLDivElement }[] = [];
  vendorFormFields: { name: string, field: HTMLDivElement }[] = [];

  constructor(props) {
    super(props);
    this.controller = new McbRegistrationFormController();
    this.controller.setType(props.type);
    this.controller.isReady().then(() => this.loading = false);
  };

  showError = err => ui.showError({ err, actionName: err.actionName || UIText.generalError });

  formFieldRefs = (field: HTMLDivElement, formField) => {
    const index = this.formFields.findIndex(f => f.name === formField.name);
    if (index > -1) {
      this.formFields[index].field = field;
    } else {
      this.formFields.push({
        name: formField.name,
        field
      });
    }
  };

  vendorFormFieldRefs = (field: HTMLDivElement, formField) => {
    const index = this.vendorFormFields.findIndex(f => f.name === formField.name);
    if (index > -1) {
      this.vendorFormFields[index].field = field;
    } else {
      this.vendorFormFields.push({
        name: formField.name,
        field
      });
    }
  };

  initCalendly = () => {
    const { recruitResultForm, isProviderRecruit } = this.controller;
    if (isEmpty(recruitResultForm)) return;
    this.calendlyClicked = true;
    const base64 = btoa(JSON.stringify(recruitResultForm));
    const url = `${webAppUrl}/${isProviderRecruit ? "Vendor" : "Caregiver"}?form=${base64}`;
    return window.open(url);
  };

  handleFinishCalendly = (event: any) => {
    preventDefaultStopProp(event);
    if (!this.calendlyClicked) return;
    return this.submittedMsgOpen = false;
  };

  handleRegister = (event: any) => {
    preventDefaultStopProp(event);
    this.submitting = true;
    this.controller.resetTermsError();
    return this.controller.onRegister()
    .then(ok => {
      if (!ok) this.scrollToError();
      this.submitted = !!ok;
      this.submittedMsgOpen = !!ok;
    })
    .catch(err => {
      this.showError(err);
      this.scrollToError();
    })
    .finally(() => this.submitting = false);
  };

  scrollToError = () => {
    const { formErrorFields, vendorFormErrorFields } = this.controller;
    const errorFields = [
      ...formErrorFields,
      ...vendorFormErrorFields
    ];
    const fieldRefs = [
      ...this.formFields,
      ...this.vendorFormFields
    ];
    if (isEmpty(errorFields)) return;
    const fieldToScroll = errorFields[0];
    const fieldRef = fieldRefs.find(f => f.name === fieldToScroll.name);
    if (!fieldRef || !fieldRef.field) return;
    return fieldRef.field.scrollIntoView({
      behavior: "smooth"
    });
  };

  render() {
    const {
      vendorIntent,
      hideTerms,
      isCaregiver,
      isProviderRecruit,
      form,
      vendorForm,
      termsChecked,
      termsError,
      onTermsCheck
    } = this.controller;
    const { contained } = this.props;

    return <McbComponentPastry>
      <Styled styles={styles}>
        <div className={`flex column mcbRegistrationForm ${contained ? "contained" : ""}`}>
          {this.loading ? (
            <div style={{ flex: 1 }} className="flex justify-content-center align-items-center">
              <CircularProgress size={30} />
            </div>
          ) : (
            <GenericForm form={form} fieldRef={this.formFieldRefs} />
          )}
          {isCaregiver && <>
            <Divider />
            <CaregiverWizardSignUpForm />
          </>}
          {vendorIntent === "other" && <GenericForm form={vendorForm} fieldRef={this.vendorFormFieldRefs} />}
          {!hideTerms && <TermsCheckbox checked={termsChecked} onChange={onTermsCheck} error={termsError} />}
          {!isCaregiver && (
            <Button
              className={`registrationButton textNoTransform ${this.submitted ? "noEvents" : ""}`}
              size="large"
              variant="contained"
              color={this.submitted ? "primary" : "secondary"}
              disableElevation
              disabled={this.loading || this.submitting}
              onClick={this.handleRegister}
            >
              {this.submitting
                ? <CircularProgress size={22} color="secondary" />
                : this.submitted
                  ? <Typography>
                    {hideTerms ? UIText.caregiverApplicationSubmitted : UIText.genericSignUpSubmitted}
                  </Typography>
                  : <Typography>
                    {hideTerms ? UIText.caregiverSubmitApplication : UIText.signUp}
                  </Typography>}
            </Button>
          )}
          <AlertModal
            title={hideTerms ? UIText.caregiverApplicationSubmitted : UIText.signUpSuccessful}
            message={hideTerms
              ? isCaregiver
                ? UIText.caregiverApplicationSubmittedMessage
                : UIText.providerBookInterviewMessage
              : UIText.signUpSuccessfulMessage}
            isOpen={this.submittedMsgOpen}
            onClose={() => this.submittedMsgOpen = false}
            buttons={isProviderRecruit && [
              observable({
                disabled: !this.calendlyClicked,
                color: "secondary",
                text: UIText.generalFinish,
                handler: this.handleFinishCalendly
              }),
              {
                text: UIText.bookAppointment,
                handler: this.initCalendly
              }
            ]}
          />
        </div>
      </Styled>
    </McbComponentPastry>;
  }
}

export default withJssMap(McbRegistrationForm);