import { observer } from "mobx-react";
import React, { useRef } from "react";
import styles from "./styles.css";
import { withStyles } from "direflow-component/dist";
import { UIText } from "../../client/lang";
import { Typography } from "@material-ui/core";
import { whenFulfill } from "../../utils/helpers";

const RequireScratchpadNotice = observer(({ isMobile, isDirty, onSearch }) => {
  const textEl = useRef(null);
  const bindButtonListener = () => {
    const el = textEl.current;
    if (!el) return;
    const button = el.querySelector(".searchButton");
    if (!button) return;
    button.onclick = onSearch;
  };
  const __html = UIText.requireScratchpad(
      isMobile,
      isDirty
        ? UIText.marketplaceSearchBarSearchBtnDirty
        : UIText.marketplaceSearchBarSearchBtn,
      isDirty
        ? "#F15F22"
        : "#543884"
    );

  whenFulfill(() => !!textEl.current).then(bindButtonListener);

  return <div className="flex textBold requireScratchpadNotice">
    <Typography ref={textEl} dangerouslySetInnerHTML={{ __html }} />
  </div>
});

export default withStyles(styles)(RequireScratchpadNotice);