import { observer } from "mobx-react";
import { computed, observable, when } from "mobx";
import React from "react";
import { PlacesAutocompleteResult } from "../../lib/types/dataTypes";
import { PlacesAutocompleteResultLimiter } from "../../lib/types/formTypes";
import { isEmpty, isEqual, preventDefaultStopProp, randomString } from "../../utils/helpers";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Styled } from "direflow-component";
import styles from "./styles.css";
import { placesApi } from "../../client/places";

export interface GooglePlaceSelectorProps extends Omit<React.ComponentProps<typeof TextField>, "value"> {
  mapOptions?: google.maps.MapOptions;
  autocompleteOptions?: google.maps.places.AutocompleteOptions;
  biasCenter?: boolean;
  limitTo?: PlacesAutocompleteResultLimiter;
  value?: PlacesAutocompleteResult;
  onPositionChange?: (value: PlacesAutocompleteResult) => void;
}

@observer
class GooglePlaceSelector extends React.Component<GooglePlaceSelectorProps> {
  @observable initialized: boolean;
  @observable input: HTMLInputElement;

  @observable value: PlacesAutocompleteResult | string = "";

  @computed get displayValue(): string {
    return typeof this.value === "string"
      ? this.value
      : typeof this.value === "object"
      ? this.value?.formattedAddress || ""
      : "";
  };

  constructor(props) {
    super(props);
    const { value } = this.props;
    this.value = value;
    this.fixPacContainerOutOfBound();
  }

  componentDidUpdate(prevProps: Readonly<GooglePlaceSelectorProps>) {
    if (!isEqual(prevProps.value, this.props.value)) this.value = this.props.value;
    // if (!this.props.readonly && !this.initialized) this.initGooglePlaces();
  }

  inputRef = (ref: HTMLInputElement) => {
    if (ref) this.input = ref;
    return this.initGooglePlaces(this.input);
  };

  setMapCircleCenter = (autocomplete: google.maps.places.Autocomplete, options: google.maps.CircleOptions) => {
    const circle = new google.maps.Circle(options);
    return autocomplete.setBounds(circle.getBounds());
  };

  setCenterUsingLocation = async (autocomplete: google.maps.places.Autocomplete) => {
    const position = await placesApi.getGeolocation(true).catch(console.warn);
    if (!position) return;
    const circle: google.maps.CircleOptions = {
      center: {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      },
      radius: position.coords.accuracy
    };
    return this.setMapCircleCenter(autocomplete, circle);
  };

  setCenterUsingOptions = async (autocomplete: google.maps.places.Autocomplete) =>
    this.setMapCircleCenter(autocomplete, this.props.mapOptions);

  initGooglePlaces = async (inputElm: HTMLInputElement) => {
    if (this.props.disabled) return;
    if (this.initialized) return;
    if (!inputElm) return;
    await when(() => placesApi.initialized);
    const autocomplete = new google.maps.places.Autocomplete(inputElm, this.props.autocompleteOptions);
    if (this.props.biasCenter) this.setCenterUsingLocation(autocomplete).catch(console.warn);
    if (this.props.mapOptions) this.setCenterUsingOptions(autocomplete).catch(console.warn);
    autocomplete.addListener("place_changed", e => {
      const place = autocomplete.getPlace();
      if (!place.place_id) return; // Prevent enter-key clearing places data.
      const value = placesApi.parseAutocompleteResult(place, this.props.limitTo);
      return this.props.onPositionChange && this.props.onPositionChange(value);
    });
    return this.initialized = true;
  };

  handleChange = (event: any) => {
    preventDefaultStopProp(event);
    const { target } = event;
    if (!target) return;
    const { value } = target;
    this.value = value;
    if (isEmpty(value)) return this.handleClear(null);
  };

  handleClear = (event: any) => {
    preventDefaultStopProp(event);
    this.value = "";
    this.props.onPositionChange(null);
  };

  fixPacContainerOutOfBound = () => {
    const pacContainer = () => Array.from(document.getElementsByClassName("pac-container"))[0] as HTMLElement;
    if (!pacContainer()) return setTimeout(this.fixPacContainerOutOfBound, 2000);
    const setPacContainerOffset = () => {
      const elm = pacContainer();
      if (!elm) return;
      elm.style.zIndex = "999999";
      // const top = Number((elm.style.top || "").toString().replace("px", ""));
      // if (isNaN(top)) return;
      // if ((top + elm.clientHeight) < (window.innerHeight - 50)) return;
      // return elm.style.marginTop = `-${elm.clientHeight + 50}px`;
    };
    new (window as any).ResizeObserver(setPacContainerOffset).observe(pacContainer());
  };

  render() {
    const {
      className,
      helperText,
      placeholder,
      label,
      value,
      disabled
    } = this.props;

    return <Styled styles={styles}>
      <TextField
        className={`googlePlaceSelector_ ${className || ""}`}
        id={`googlePlaceSelector_${randomString()}`}
        variant="outlined"
        inputRef={this.inputRef}
        label={label}
        value={this.displayValue}
        placeholder={placeholder}
        disabled={disabled}
        onChange={this.handleChange}
        helperText={helperText}
        InputProps={{
          endAdornment: !isEmpty([value, this.value].filter(Boolean)) && <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={this.handleClear}
            >
              <Close />
            </IconButton>
          </InputAdornment>
        }}
      />
    </Styled>;
  }
}

export default GooglePlaceSelector;